import React, { useEffect, useState } from "react";
import { ContentUpdate } from "../../products/content/components/ContentUpdate";
import { Col, Card } from "react-bootstrap";
import { useParams } from "react-router";
import { WorkflowStepper } from "./WorkflowStepper";
import { useDispatch, useSelector } from "react-redux";
import { IAllStates } from "../../../store/rootReducer";
import { WorkflowService } from "../service";
import { IWorkflow } from "../dto/IWorkflow";
import { ErrorToast } from "../../../shared/toasters/toasters";
import step5PaginationSlice from "../../../store/workflow/step5PaginationSlice";
import { t } from "i18next";

interface IProgressData {
  done: number;
  total: number;
  percent: number;
}
interface IProgressBar {
  assigment: IProgressData;
  copywriting: IProgressData;
}
const WorkflowStep5Edit = () => {
  const { id, productId } = useParams();
  const currentUser = JSON.parse(localStorage.getItem("currentUser") as string);
  const stepPagination = useSelector(
    (state: IAllStates) => state.step5Pagination as any
  );
  const isCopyWriter = true;
  const [currentWorkflow, setCurrentWorkflow] = useState<IWorkflow | null>(
    null
  );
  const [firstTabLang, setFirstTabLang] = useState<string>();
  const dispatch = useDispatch();
  const progressArr = ["assigment", "copywriting"];
  const isManager = currentUser?.roles.includes("ROLE_MANAGER");
  const isAdmin = currentUser?.roles.includes("ROLE_ADMIN");
  const isCompanyAdmin = currentUser?.roles.includes("ROLE_COMPANY_ADMIN");
  const [progressBarData, setProgressBarData] = useState<
    IProgressBar | undefined
  >(undefined);

  const changeFilterHandler = (name: string, value: string) => {
    if ((stepPagination as any)?.[name] === value) {
      dispatch(
        step5PaginationSlice.actions.setStep5Pagination({
          ...(stepPagination as any),
          [name]: "",
        })
      );
    } else {
      dispatch(
        step5PaginationSlice.actions.setStep5Pagination({
          ...(stepPagination as any),
          [name]: value,
        })
      );
    }
  };
  const handlePerPage = (e: any) => {
    dispatch(
      step5PaginationSlice.actions.setStep5Pagination({
        ...(stepPagination as any),
        page: 1,
        perPage: e.value,
      })
    );
  };

  useEffect(() => {
    WorkflowService.getCopywritingProducts(stepPagination, +id!)
      .then((response) => {
        const {
          list,
          view,
          workflow,
          workflow: { copywriters, sourceLang },
          assigment,
          copywriting,
        } = response as any;

        // setProductsList(list?.data);
        setCurrentWorkflow(workflow as IWorkflow);
        setFirstTabLang(sourceLang);
        setProgressBarData({
          assigment,
          copywriting,
        });
        window.scrollTo(0, 0);
        // setIsCopyWriter(+view !== 1);
        // setCopywriterList(copywriters);
        // setTotalPages(Math.ceil(list?.count / list?.perPage));
        // setPage(list?.page);
      })
      .catch((error) => ErrorToast(error));
  }, []);
  return (
    <Card>
      <Card.Body>
        <div className="row">
          <WorkflowStepper
            stepPagination={stepPagination}
            step={"COPY_WRITING"}
            id={id}
            changeFilterHandler={changeFilterHandler}
            precent={progressBarData?.["copywriting"]?.percent}
            showNextButton={false}
            showMarkAsComplete={
              currentWorkflow?.status === "COPY_WRITING" &&
              (isAdmin || isCompanyAdmin || isManager)
            }
            status={stepPagination?.status}
            assigned={!isCopyWriter && stepPagination?.assigned}
            // setSelectedProductId={!selectedProductId && setSelectedProductId}
            handlePerPage={handlePerPage}
            showFilters={false}
          />

          {progressArr?.map((data) => {
            if (data === "assigment" && isCopyWriter) {
              return null;
            }
            return (
              <div
                className="card bg-light overflow-hidden shadow-none"
                key={data}
              >
                <div className="card-body">
                  <div className="d-flex">
                    <div className="flex-grow-1">
                      <h6 className="mb-0">
                        {t("workflow.current")} {data}:
                        <b className="text-success">
                          {" "}
                          {
                            progressBarData?.[data as keyof IProgressBar]
                              ?.percent
                          }
                          %
                        </b>
                      </h6>
                    </div>
                    <div className="flex-shrink-0">
                      <h6 className="mb-0">
                        {progressBarData?.[data as keyof IProgressBar]?.done}/
                        {progressBarData?.[data as keyof IProgressBar]?.total}{" "}
                        {t("workflow.finished")}
                      </h6>
                    </div>
                  </div>
                </div>
                <div className="progress">
                  {/*//@ts-ignore*/}
                  <div
                    className="progress-bar bg-success"
                    style={{
                      width: `${
                        progressBarData?.[data as keyof IProgressBar]
                          ?.percent ?? 0
                      }%`,
                    }}
                    aria-valuenow={
                      progressBarData?.[data as keyof IProgressBar]
                        ?.percent as number
                    }
                    aria-valuemin="0"
                    aria-valuemax="100"
                  />
                </div>
              </div>
            );
          })}
          {firstTabLang && (
            <ContentUpdate
              selectedProductId={productId as any}
              // setSelectedProductId={setSelectedProductId}
              isWorkflowUpdate={true}
              translationLangTab={firstTabLang}
            />
          )}
        </div>
      </Card.Body>
    </Card>
  );
};

export default WorkflowStep5Edit;
