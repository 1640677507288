import React, { useState } from "react";
import { t } from "i18next";
import { Spinner, Table } from "react-bootstrap";
import { ErrorToast, SuccessToast } from "../../../../shared/toasters/toasters";
import { ProductsService } from "../../service";

export const OffcanvasAttributeLeftSide = ({
  currentProduct,
  isLoadingTable,
  setCurrentProduct,
  getProductId,
  closeOffcanvas,
}: any) => {
  const [isCheckedAll, setIsCheckedAll] = useState(false);
  const [fillLoading, setFillLoading] = useState(false);
  const [fillAndReplaceLoading, setFillAndReplaceLoading] = useState(false);
  const FillOrReplaceSubmit = (type: string) => {
    if (type === "fill") {
      setFillLoading((prev: boolean) => !prev);
    } else {
      setFillAndReplaceLoading((prev: boolean) => !prev);
    }
    ProductsService.enrichFillOrReplaceAttribute(
      Number(currentProduct?.id),
      type,
      {
        attributes: (currentProduct as any)?.enrichmentData
          ?.formBuilderAttributesData,
      }
    )
      .then((response) => {
        SuccessToast(`Success`);
        setIsCheckedAll(false);
        getProductId();
        closeOffcanvas();
        // setCurrentProduct(response);
      })
      .catch((error) => ErrorToast(error))
      .finally(() => {
        setFillLoading(false);
        setFillAndReplaceLoading(false);
      });
  };
  const checkAll = (e: any) => {
    if (e.target.checked) {
      setIsCheckedAll(true);
      setCurrentProduct((prev: any) => ({
        ...prev,
        enrichmentData: {
          ...prev?.enrichmentData,
          formBuilderAttributesData: (
            currentProduct as any
          )?.enrichmentData?.formBuilderAttributesData?.map((item: any) => ({
            ...item,
            insert: true,
          })),
        },
      }));
    } else {
      setIsCheckedAll(false);
      setCurrentProduct((prev: any) => ({
        ...prev,
        enrichmentData: {
          ...prev?.enrichmentData,
          formBuilderAttributesData: (
            currentProduct as any
          )?.enrichmentData?.formBuilderAttributesData?.map((item: any) => ({
            ...item,
            insert: false,
          })),
        },
      }));
    }
  };
  const checkDisabled = () => {
    let counter = 0;
    (currentProduct as any)?.enrichmentData?.formBuilderAttributesData?.map(
      (item: any) => {
        if (item?.insert === true) {
          counter = counter + 1;
        }
      }
    );
    return counter > 0 ? false : true;
  };
  const checkAllFields = () => {
    let counter = 0;
    (currentProduct as any)?.enrichmentData?.formBuilderAttributesData?.map(
      (item: any, index: number) => {
        if (item?.insert === true) {
          counter = counter + 1;
        } else {
          return setIsCheckedAll(false);
        }
      }
    );
    if (
      counter ===
      (currentProduct as any)?.enrichmentData?.formBuilderAttributesData
        ?.length -
        1
    ) {
      setIsCheckedAll(true);
    }
  };
  const checkboxHandler = (e: any, id: number) => {
    if (e.target.checked) {
      const newArrTrue = (
        currentProduct as any
      )?.enrichmentData?.formBuilderAttributesData?.map(
        (item: any, index: number) => {
          if (index === id) {
            return {
              ...item,
              insert: true,
            };
          } else {
            return item;
          }
        }
      );
      setCurrentProduct((prev: any) => ({
        ...prev,
        enrichmentData: {
          ...prev?.enrichmentData,
          formBuilderAttributesData: newArrTrue,
        },
      }));
      checkAllFields();
    } else {
      setIsCheckedAll(false);
      const newArrFalse = (
        currentProduct as any
      )?.enrichmentData?.formBuilderAttributesData?.map(
        (item: any, index: number) => {
          if (index === id) {
            return {
              ...item,
              insert: false,
            };
          } else {
            return item;
          }
        }
      );
      setCurrentProduct((prev: any) => ({
        ...prev,
        enrichmentData: {
          ...prev?.enrichmentData,
          formBuilderAttributesData: newArrFalse,
        },
      }));
    }
  };
  return (
    <div className="col-6 border-end pt-3">
      {!isLoadingTable ? (
        <div className="d-flex justify-content-center">
          <Spinner animation="border" size="sm" role="status" />
        </div>
      ) : (
        <>
          <div className="table-responsive">
            <Table className="mb-0 align-middle table-bordered">
              <thead className="table-light">
                <tr>
                  <th className="" scope="col">{`${t(
                    "mapping.attribute"
                  )}`}</th>
                  <th className="p-0" scope="col">
                    <span
                      className="d-block border-bottom"
                      style={{ padding: ".375rem .3rem" }}
                    >
                      {`${t("global.old_value")}`}
                    </span>
                    <span
                      className="d-block"
                      style={{ padding: ".375rem .3rem" }}
                    >
                      {`${t("global.new_value")}`}
                    </span>
                  </th>
                  <th className="vertical-middle text-center">
                    <input
                      className="m-auto form-check-input m-0 cursor-pointer"
                      checked={isCheckedAll}
                      type="checkbox"
                      onChange={checkAll}
                    />
                  </th>
                </tr>
              </thead>
              {Boolean(
                (currentProduct as any)?.enrichmentData
                  ?.formBuilderAttributesData
              ) && (
                <tbody>
                  {(
                    currentProduct as any
                  )?.enrichmentData?.formBuilderAttributesData?.map(
                    (item: any, index: number) => {
                      return (
                        <tr key={item?.id}>
                          <td
                            className="text-nowrap text-start"
                            style={{
                              backgroundColor: "#f3f6f9",
                              fontWeight: "500",
                            }}
                          >
                            {item?.title}
                          </td>
                          <td className="text-start border-left p-0">
                            <span
                              className="d-block border-bottom"
                              style={{
                                padding: ".75rem .6rem",
                                backgroundColor: "#f7d0d0",
                              }}
                            >
                              {item?.currentValues?.length > 0 &&
                                item?.currentValues
                                  ?.map((current: any) => current?.title)
                                  .join(", ")}
                            </span>
                            <div
                              style={{
                                padding: ".75rem .6rem",
                                backgroundColor: "#e1f7da",
                              }}
                            >
                              <div>
                                {item?.gptValues?.length > 0 &&
                                  item?.gptValues
                                    .map((obj: any) => obj.title)
                                    .join(", ")}
                              </div>
                            </div>
                          </td>
                          <td className="">
                            <input
                              type="checkbox"
                              className="form-check-input m-0 cursor-pointer"
                              onChange={(e) => checkboxHandler(e, index)}
                              value={item?.title}
                              checked={item?.insert ?? false}
                            />
                          </td>
                        </tr>
                      );
                    }
                  )}
                </tbody>
              )}
            </Table>
          </div>
          <div className="d-flex align-items-center justify-content-end mt-3">
            <button
              onClick={() => FillOrReplaceSubmit("fill")}
              className="btn btn-info me-2"
              style={{ minWidth: "50px" }}
              disabled={checkDisabled()}
            >
              {fillLoading ? (
                <Spinner animation="border" size="sm" role="status" />
              ) : (
                <span> {t("global.fill")}</span>
              )}
            </button>
            <button
              className="btn btn-primary"
              onClick={() => FillOrReplaceSubmit("fillAndReplace")}
              style={{ minWidth: "116px" }}
              disabled={checkDisabled()}
            >
              {fillAndReplaceLoading ? (
                <Spinner animation="border" size="sm" role="status" />
              ) : (
                <span> {t("global.fill_replace")}</span>
              )}
            </button>
          </div>
        </>
      )}
    </div>
  );
};
