import axios, { AxiosResponse } from "axios";
import { customNavigate } from "../..";
import { ErrorToast } from "../../shared/toasters/toasters";
import { StoreKeeper } from "../../store/store";
import { appVersionSlice } from "../general/appVersionSlice";
import open423ErrModalSlice from "../general/open423ErrModalSlice";

const language = localStorage.getItem("language");

const defaultOptions = {
  headers: {
    "Content-Type": "application/json",
    accept: "text/html",
    "Accept-Language": `${language ? language : "de"}`,
  },
};

export const instance = axios.create(defaultOptions);

instance.interceptors.request.use(function (config) {
  const appVersion = localStorage.getItem("appVersion");
  config!.headers!["app-version"] = appVersion ?? "";
  const token = localStorage.getItem("token") ?? "{}";
  config!.headers!.Authorization = token ? `Bearer ${token}` : "";
  // config!.headers!.Authorization = appVersion ? `${appVersion}` : '';
  return config;
});

instance.interceptors.response.use(
  function (config) {
    return config;
  },
  (err) => {
    const { status, data } = err?.response ?? {};

    if (status === 505) {
      StoreKeeper.store.dispatch(appVersionSlice.actions.setReloadModal(true));
      localStorage.setItem("appVersion", data?.appVersion ?? "");
    }

    if (status === 403) {
      (customNavigate as any).navigate("/403");
    }
    if (status === 401) {
      localStorage.setItem("token", "");
      return (customNavigate as any).navigate("/");
    }
    if (status === 423) {
      StoreKeeper.store.dispatch(
        open423ErrModalSlice.actions.setOpen423ErrModal(true)
      );
    }
    return Promise.reject(err);
  }
);
const responseBody = (response: AxiosResponse) => response.data;
export const requests = {
  get: (url: string, params?: any) =>
    instance
      .get(url, { params })
      .then(responseBody)
      .catch((e) => {
        throw e;
      }),
  post: (url: string, body: {}) =>
    instance
      .post(url, body)
      .then(responseBody)
      .catch((e) => {
        throw e;
      }),
  put: (url: string, body?: {}) =>
    instance
      .put(url, body)
      .then(responseBody)
      .catch((e) => {
        throw e;
      }),
  patch: (url: string, body: {}) =>
    instance
      .patch(url, body)
      .then(responseBody)
      .catch((e) => {
        throw e;
      }),
  delete: (url: string, body?: {}) =>
    instance
      .delete(url, { data: body })
      .then(responseBody)
      .catch((e) => {
        throw e;
      }),
};

export function multipartFormData(
  files: any,
  url: string,
  body?: any,
  method = "put",
  fileKey = "file"
) {
  const formData = new FormData();
  if (files?.length) {
    for (let file in files) {
      formData.append(fileKey, files[file]);
    }
  } else {
    formData.append(fileKey, files);
  }
  formData.append("body", body);
  if (method === "put") {
    return instance.put(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        "app-version": localStorage.getItem("appVersion")!,
      },
    });
  } else {
    return instance.post(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        "app-version": localStorage.getItem("appVersion")!,
      },
    });
  }
}
