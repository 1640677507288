import { useTranslation } from "react-i18next";
import { WorkflowService } from "../../../workflow/service";
import { IProducts, IProductsList } from "../../dto/IProducts";
import { Modal } from "react-bootstrap";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { themeSelect } from "../../../../shared/components/Select2";
import Select, { MultiValue, SingleValue } from "react-select";
import React, { SetStateAction, useEffect, useState } from "react";
import { ErrorToast, SuccessToast } from "../../../../shared/toasters/toasters";
import { IPicture } from "../../../../model/shared/IPicture";
import { AttributesService } from "../../../attributes/service";
import { IAttributes } from "../../../attributes/names/dto/IAttributes";
import { AttributeValueForm } from "../../../attributes/values/components/AttributeValueForm";
import { IAttributeValue } from "../../../attributes/values/dto/IAttributeValue";
import { ConfirmDelete } from "../../../../shared/components/ConfirmDelete";
import { ProductsService } from "../../service";
import { EmptyState } from "../../../../shared/components/EmptyState";
import { useDispatch, useSelector } from "react-redux";
import { IAllStates } from "../../../../store/rootReducer";
import { LoadingAnimation } from "../../../../shared/components/Loading";
import changeLocationSlice from "../../../../store/category/changeLocation";

interface IProductsActionsProps {
  currentProduct: IProductsList | IProducts;
  handleClose?: (() => void | undefined) | undefined;
  setCurrentProduct: React.Dispatch<SetStateAction<IProductsList | IProducts>>;
  changeValuesHandler: (values: any, attributeId: number) => void;
  handleSelectMainCategory: (
    selectedSingle: SingleValue<{ label: string; value: number }>,
    inputKey: string
  ) => void;
  selectedProductId?: number;
  isWorkflowUpdate?: boolean;
  setSelectedProductId?: React.Dispatch<SetStateAction<number>> | undefined;
  productStaticts: any;
  setTriggerRadialbarUpdate?:
    | React.Dispatch<SetStateAction<boolean>>
    | undefined;
}

export const ProductsAttributesActions = ({
  productStaticts = {},
  isWorkflowUpdate = false,
  selectedProductId,
  setSelectedProductId,
  handleClose,
  currentProduct,
  setCurrentProduct,
  changeValuesHandler,
  setTriggerRadialbarUpdate,
}: IProductsActionsProps) => {
  const isAdmin = useSelector((state: IAllStates) =>
    state?.auth?.roles?.some((role) => role === "ROLE_ADMIN")
  );
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state: close }: any = useLocation();
  const location = useLocation();
  const isWorkflow = location?.pathname?.includes("workflow");
  const dispatch = useDispatch();
  const [openAttributeModal, setOpenAttributeModal] = useState(false);
  const [currentCreateAttribute, setCurrentCreateAttribute] =
    useState<IAttributeValue>();
  const [selectedAttributeId, setSelectedAttributeId] = useState<number>(0);
  const [openModal, setOpenModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState<IPicture>();
  const [categoryAttributes, setCategoryAttributes] = useState<
    IAttributes[] | undefined
  >();

  const handleSelectAttribute = (
    selectedValue: MultiValue<{ label: string; value: number; isAdd: boolean }>,
    attrId: number
  ) => {
    const itemToAdd = selectedValue?.findIndex((value) => value.isAdd);
    if (itemToAdd !== -1) {
      setOpenAttributeModal(true);
      setSelectedAttributeId(attrId as number);
    }
    changeValuesHandler(selectedValue, attrId);
  };

  const getAllAttributes = ({ currentProduct, setCategoryAttributes }: any) => {
    if (isAdmin) {
      AttributesService.getAllAttributes({
        page: 1,
        perPage: 0,
        category: currentProduct?.category?.id ?? currentProduct?.category,
      })
        .then((response) => {
          response.data.forEach((item) => {
            item.attributeValues
              ?.sort((a, b) => a.id - b.id)
              .push({
                title: `+ Add`,
                isAdd: true,
                id: (item?.attributeValues?.at(-1)?.id ?? 0) + 1,
              });
          });
          setCategoryAttributes(response.data);
        })
        .catch((error) => ErrorToast(error));
    } else {
      AttributesService.getAllAttributes({
        page: 1,
        perPage: 0,
        category: currentProduct?.category?.id ?? currentProduct?.category,
      })
        .then((response) => {
          response.data.forEach((item) => {
            item.attributeValues?.sort((a, b) => a.id - b.id);
          });
          setCategoryAttributes(response.data);
        })
        .catch((error) => ErrorToast(error));
    }
  };
  const backHandle = () => {
    if (close?.close === "export") {
      navigate(`/app/export/list/${close?.idExport}`, {
        state: (location.state as any)?.headerName,
      });
      //  navigate(-1)
    } else if (close?.close === "attributes") {
      navigate(`/admin/attributes/list/${close?.idExport}`, {
        state: (location.state as any)?.headerName,
      });
    } else if (handleClose) {
      handleClose();
    } else {
      navigate(-1);
    }
  };
  const changeAttributeHandler = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value, name } = event.target;
    setCurrentCreateAttribute((prev) => ({
      ...prev,
      translation: {
        ...prev?.translation,
        [name]: value,
      },
    }));
  };
  // value for attribute
  const handleCreateAttribute = () => {
    AttributesService.createAttributeValue({
      ...(currentCreateAttribute as IAttributeValue),
      attribute: Number(selectedAttributeId),
    })
      .then((response) => {
        if (response) {
          addNewAttribute(response?.attribute?.id, response?.id);
          setOpenAttributeModal(false);
          setCurrentCreateAttribute({} as any);
          SuccessToast(
            `${t("toasts.successfully_created")} ${response?.title}`
          );
          getAllAttributes({
            currentProduct: currentProduct,
            setCategoryAttributes: setCategoryAttributes,
          });
        }
      })
      .catch((error) => ErrorToast(error));
  };

  const handleSubmit = (event: React.FormEvent, checkNext = false) => {
    event.preventDefault();
    const workflowIds = {
      productId: selectedProductId,
      workflowId: Number(id),
    };
    // const formData = ne
    ProductsService.updateProductAttributesById(
      {
        values: currentProduct?.values,
        ...workflowIds,
      } as any,
      Number(isWorkflowUpdate ? selectedProductId : id),
      isWorkflowUpdate,
      Number(id)
    )
      .then((response) => {
        dispatch(changeLocationSlice.actions.setChangeLocation(false));
        setTriggerRadialbarUpdate &&
          setTriggerRadialbarUpdate((prev: boolean) => !prev);
        if (isWorkflowUpdate && checkNext) {
          WorkflowService.getNextAttributesWorkflow(Number(id), "ATTRIBUTES")
            .then((response) => {
              if (!response.nextProduct?.id) {
                navigate(`/app/workflow/step4/${id}`);
              } else {
                navigate(
                  `/app/workflow/step4/${response.nextProduct?.id}/${id}`
                );
                window.scrollTo(0, 0);
              }
            })
            .catch((error) => ErrorToast(error));
        }
        SuccessToast(
          `${t("toasts.successfully_updated")} ${currentProduct.title} `
        );
        // navigate(-1);
      })
      .catch((error) => ErrorToast(error));
  };

  const addNewAttribute = (attributeId: number, valueId: number) => {
    const copy = currentProduct?.values;
    copy?.find((item) => item.attribute === attributeId)?.value?.push(valueId);
    setCurrentProduct((prev) => ({ ...prev, values: copy }));
  };

  const remoAddItem = () => {
    const copy = currentProduct?.values;
    const indexToReplace = copy?.findIndex(
      (attribute) => attribute.attribute === selectedAttributeId
    ) as number;
    //@ts-ignore
    copy[indexToReplace] = {
      ...copy?.[indexToReplace],
      value: copy?.[indexToReplace]?.value.slice(0, -1) as number[],
    };
    setCurrentProduct((prev) => ({ ...prev, values: copy }));
    setOpenAttributeModal(false);
  };

  useEffect(() => {
    if (currentProduct?.category) {
      getAllAttributes({
        currentProduct: currentProduct,
        setCategoryAttributes: setCategoryAttributes,
      });
    } else if (currentProduct?.category === null) {
      setCategoryAttributes([]);
    } else {
      setCategoryAttributes(undefined);
    }
  }, [currentProduct?.category]);

  return (
    <>
      <div className="col-md-12" key={currentProduct?.id}>
        <div className="card">
          <div className="card-body">
            <div className="row">
              {categoryAttributes === undefined ? (
                <LoadingAnimation />
              ) : categoryAttributes.length === 0 ? (
                <>
                  <EmptyState />
                  {/* {!isWorkflowUpdate && (
                    <div className="d-flex justify-content-end mt-3">
                      <button
                        className="btn btn-info"
                        onClick={() => navigate(-1)}
                      >
                        {t("products.close")}
                      </button>
                    </div>
                  )} */}
                </>
              ) : (
                categoryAttributes?.map((attribute) => {
                  const selectedAttributeValue = currentProduct?.values?.find(
                    (value) => value.attribute === attribute?.id
                  );
                  const defaultValues = selectedAttributeValue?.value
                    ?.map((id) =>
                      attribute?.attributeValues?.find(
                        (value) => value?.id === id
                      )
                    )
                    .filter((item) => item);
                  const ids = defaultValues?.map((o) => o?.id);
                  const filtered = defaultValues?.filter(
                    (item, index) => !ids?.includes(item?.id, index + 1)
                  );
                  return (
                    <div className="col-md-3 my-3" key={attribute?.id}>
                      <label
                        htmlFor="title"
                        style={{
                          color: `${
                            productStaticts?.attributesStatistics?.mandatoryAttributes?.includes(
                              attribute?.id
                            )
                              ? "#0ab39c"
                              : "black"
                          }`,
                        }}
                      >
                        {attribute?.title ?? "-"}
                      </label>
                      <Select
                        className="react-select-field"
                        theme={themeSelect}
                        value={filtered?.map((value) => ({
                          label: value?.title ?? "-",
                          value: value?.id,
                          isAdd: !!value?.isAdd,
                        }))}
                        options={attribute?.attributeValues?.map((value) => ({
                          label: value?.title ?? "-",
                          value: value?.id,
                          isAdd: !!value?.isAdd,
                        }))}
                        isMulti
                        onChange={(e) => {
                          handleSelectAttribute(
                            e as MultiValue<{
                              label: string;
                              value: number;
                              isAdd: boolean;
                            }>,
                            attribute?.id
                          );
                        }}
                        key={currentProduct?.id}
                      />
                    </div>
                  );
                })
              )}
            </div>
            {isWorkflowUpdate && (
              <div className="d-flex justify-content-end mt-3">
                {location?.pathname?.includes("workflow/") && (
                  <button className="btn btn-info" onClick={backHandle}>
                    {t("products.close")}
                  </button>
                )}
                <button
                  className="btn btn-secondary ms-2"
                  onClick={(e) => handleSubmit(e)}
                >
                  {id ? t("products.save") : t("global.create")}
                </button>
                {id && isWorkflow && (
                  <button
                    className="btn btn-primary ms-2"
                    onClick={(e) => handleSubmit(e, true)}
                  >
                    {t("products.next")}
                  </button>
                )}
              </div>
            )}
            {!isWorkflowUpdate && (
              <div className="d-flex justify-content-end mt-3">
                <button className="btn btn-info" onClick={backHandle}>
                  {t("products.close")}
                </button>
                <button
                  className="btn btn-secondary ms-2"
                  onClick={(e) => handleSubmit(e)}
                >
                  {id ? t("products.save") : t("global.create")}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      <ConfirmDelete
        show={openModal}
        setShow={setOpenModal}
        itemName={selectedImage?.file?.nameOriginal}
        selectedProduct={selectedImage}
      />

      <Modal
        show={openAttributeModal}
        onHide={() => setOpenAttributeModal(false)}
      >
        <Modal.Header>
          <Modal.Title>{t("modals.add_attribute")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AttributeValueForm
            setCurrentValue={setCurrentCreateAttribute as any}
            key={selectedAttributeId}
            handleClose={remoAddItem}
            currentValue={currentCreateAttribute as IAttributeValue}
            isMenuOpened={true}
            setIsMenuOpened={setOpenAttributeModal}
            handleCreate={handleCreateAttribute}
            changeHandler={changeAttributeHandler}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};
