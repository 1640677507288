import { IClientsDTO, IClientsQuery } from "./dto/IClients";
import { BaseModel } from "../../model/shared/BaseModel";
import {
  CLIENT_MAPPING_URL,
  CLIENT_URL,
  DATA_QUALITY_URL,
  IMPORT_MAPPING_URL,
} from "../../utils/helpers/api.routes";
import { requests } from "../../utils/helpers/api.services";
import { IClient } from "../../model/authentification/IRegister";

export const ClientService = {
  createClient: (params: IClient) => requests.post(`${CLIENT_URL}`, params),
  getAllClients: (params: IClientsQuery): Promise<BaseModel<IClientsDTO[]>> =>
    requests.get(`${CLIENT_URL}`, params),
  getImportMapping: (id: number): any =>
    requests.get(`${IMPORT_MAPPING_URL}client/${id}`),
  getClientById: (id: number) => requests.get(`${CLIENT_URL}/${id}`),
  updateClientById: (id: number, params: IClient) =>
    requests.put(`${CLIENT_URL}/${id}`, params),
  updateMappingImportClientById: (id: number, params: any) =>
    requests.put(`${IMPORT_MAPPING_URL}client/${id}`, params),
  deleteClientById: (id: number) => requests.delete(`${CLIENT_URL}/${id}`),
  saveDataQuality: (id: number, params: any) =>
    requests.put(`${DATA_QUALITY_URL}/${id}`, params),
  getDataQuality: (id: number): any =>
    requests.get(`${DATA_QUALITY_URL}/${id}`),
};
