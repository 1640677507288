import { IProductsList } from "../dto/IProducts";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  formatDate,
  getCorrectLanguage,
} from "../../../shared/functions/Functions";
import { CustomImageHandler } from "../../../shared/components/CustomImageHandler";
import { useTranslation } from "react-i18next";
import { ConfirmDelete } from "../../../shared/components/ConfirmDelete";
import { useSelector } from "react-redux";
import { IAllStates } from "../../../store/rootReducer";

interface IProductsListViewProps {
  workflow?: boolean;
  toForLink?: string;
  variationsTable?: boolean;
  firstView?: boolean;
  headerName?: string;
  attOrExp?: string;
  nameOfTable?: string;
  checkedValues?: number[];
  infoButton?: boolean;
  product: IProductsList | any;
  deleteHandler: (
    product: IProductsList,
    selectedProduct: IProductsList
  ) => void;
  onEdit: (e: any, id: number) => any;
  toForLinkFunc?: (idProduct: number) => string;
  onView?: (id: number) => void;
  handleCheck?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  mainTable?: boolean;
  showActionButtons?: boolean;
  showAssignColumn?: boolean;
  status?: boolean;
  showDeleteButton?: boolean;
  showViewButton?: boolean;
  staticsView2?: any;
  listWithoutLinks?: boolean;
}

export const ProductsListView = ({
  listWithoutLinks = false,
  toForLinkFunc,
  workflow = false,
  toForLink,
  firstView = false,
  variationsTable = false,
  headerName,
  staticsView2,
  infoButton,
  product,
  deleteHandler,
  onEdit,
  onView,
  mainTable,
  handleCheck,
  status,
  showDeleteButton = true,
  showActionButtons = true,
  showAssignColumn = false,
  showViewButton = true,
  nameOfTable,
  checkedValues,
  attOrExp,
}: IProductsListViewProps) => {
  const { t } = useTranslation();
  const currentUser = JSON.parse(localStorage.getItem("currentUser") as string);
  const isManager = currentUser?.roles.includes("ROLE_MANAGER");
  const isAdmin = currentUser?.roles.includes("ROLE_ADMIN");
  const isCompanyAdmin = currentUser?.roles.includes("ROLE_COMPANY_ADMIN");
  const [selectedProduct, setSelectedProduct] = useState<IProductsList>();
  const [selectedVariation, setSelectedVariation] = useState<any>();
  const [show, setShow] = useState(false);
  const { id } = useParams();
  const idExport = id;
  const navigate = useNavigate();
  const isExportList = window.location.pathname?.includes("app/export/list/");
  const handleShow = () => setShow(true);
  const [disabledBtn, setDisabledBtn] = useState(false);
  const [disabledDeleteBtn, setDisabledDeleteBtn] = useState(false);
  const { accessRights } = useSelector((state: IAllStates) => state.auth);

  const editDisabledBtn = () => {
    if (
      nameOfTable === "baseData" &&
      accessRights?.includes("product_base_data_can_edit")
    ) {
      return false;
    } else if (
      nameOfTable === "attributes" &&
      accessRights?.includes("product_attributes_can_edit")
    ) {
      return false;
    } else if (
      nameOfTable === "content" &&
      accessRights?.includes("product_content_can_edit")
    ) {
    } else if (accessRights?.includes("workflow_can_update")) {
      return false;
    } else if (nameOfTable === "pending") {
      return false;
    } else if (nameOfTable === "contentWorkflow") {
      return false;
    } else if (nameOfTable === "translator") {
      return false;
    } else if (nameOfTable === "crawled") {
      return false;
    } else if (nameOfTable === "productByAttribute") {
      return false;
    } else {
      return true;
    }
  };
  const checkForCheckbox = () => {
    if (
      nameOfTable === "baseMainWorkflow" &&
      (isAdmin || isCompanyAdmin || isManager)
    ) {
      return true;
    } else {
      switch (nameOfTable) {
        case "baseData":
          return true;
        case "attributes":
          return true;
        case "content":
          return true;
        case "pending":
          return true;
        default:
          return false;
      }
    }
  };

  const handleShowAssignColumn = () => {
    if (product?.additionalInformation?.copyWriter) {
      return `${product?.additionalInformation?.copyWriter?.firstName} ${
        product?.additionalInformation?.copyWriter?.lastName ?? ""
      }`;
    } else if ((product as any)?.workflowProduct?.translator) {
      return `${(product as any)?.workflowProduct?.translator?.firstName} ${
        (product as any)?.workflowProduct?.translator?.lastName ?? ""
      }`;
    } else if ((product as any)?.worker) {
      return `${(product as any)?.worker?.firstName} ${
        (product as any)?.worker?.lastName ?? ""
      }`;
    } else {
      return "-";
    }
  };
  const checkForFinished = () => {
    if (product?.additionalInformation || (product as any)?.workflowProduct) {
      return true;
    } else {
      return false;
    }
  };
  const customFuncForTo = (productId: number) => {
    return (
      workflow
        ? toForLinkFunc && toForLinkFunc(product?.id as number)
        : !editDisabledBtn()
        ? `${toForLink}${product.id}`
        : "#"
    ) as string;
  };
  useEffect(() => {
    if (product?.lockedProduct) {
      setDisabledBtn(product?.lockedProduct);
    }
  }, [product, accessRights]);
  useEffect(() => {
    if (
      ![
        "product_attributes_can_edit",
        "product_can_delete",
        "workflow_can_delete",
      ].some((right) => accessRights?.includes(right))
    ) {
      setDisabledDeleteBtn(true);
    }
  }, [accessRights]);

  const onEditAtt = (id: number, status: string, isImported: boolean) => {
    if (status === "APPROVED") {
      navigate(`/app/products/base-data/${id}`, {
        state: { close: attOrExp, idExport: idExport, headerName: headerName },
      });
    } else if (isImported === true) {
      navigate(`/app/pending-products/imported/base-data/${id}`, {
        state: { close: attOrExp, idExport: idExport, headerName: headerName },
      });
    } else {
      navigate(`/app/pending-products/crawled/base-data/${id}`, {
        state: { close: attOrExp, idExport: idExport, headerName: headerName },
      });
    }
  };

  const isFinishedStep = () => {
    return (
      product?.additionalInformation?.isBaseDataDone ||
      product?.additionalInformation?.isAttributesDone ||
      product?.additionalInformation?.isCopyWritingDone
    );
  };
  return (
    <tr key={product?.id}>
      {
        /* {nameOfTable === "baseData" ||
        nameOfTable === "attributes" ||
        nameOfTable === "content" ||
        nameOfTable === "pending" ||
        (nameOfTable === "baseMainWorkflow" && ( */
        checkForCheckbox() && (
          <td>
            <input
              type="checkbox"
              className="form-check-input m-0 cursor-pointer"
              onChange={(e) => handleCheck && handleCheck(e)}
              value={product?.id}
              checked={checkedValues?.includes(Number(product?.id))}
            />
          </td>
        )
      }

      {/* // ))} */}
      <td>
        {listWithoutLinks ? (
          <>
            <span>#{product?.id}</span>
          </>
        ) : (
          <Link
            // to={
            //   workflow ? ( toForLinkFunc && toForLinkFunc(product?.id as number)): !editDisabledBtn()
            //     ? `${toForLink}${product.id}`
            //     : "#"
            // }
            to={customFuncForTo(product.id as number)}
            onClick={(e) => {
              if (!editDisabledBtn()) {
                onEdit(e, product?.id as number);
              } else {
                e.preventDefault();
              }
            }}
            onContextMenu={(e) => {
              if (!editDisabledBtn()) {
                onEdit(e, product?.id as number);
              } else {
                e.preventDefault();
              }
            }}
            className={`text-dark ${!editDisabledBtn() && "cursor-pointer"}`}
          >
            #{product?.id}
          </Link>
        )}
      </td>
      <td>
        {!editDisabledBtn() && !listWithoutLinks ? (
          <Link
            to={customFuncForTo(product.id as number)}
            onClick={(e) => {
              if (!editDisabledBtn()) {
                onEdit(e, product?.id as number);
              } else {
                e.preventDefault();
              }
            }}
            onContextMenu={(e) => {
              if (!editDisabledBtn()) {
                onEdit(e, product?.id as number);
              } else {
                e.preventDefault();
              }
            }}
            className={`text-dark ${!editDisabledBtn() && "cursor-pointer"}`}
          >
            {product?.eanCode ?? "-"}
          </Link>
        ) : (
          <span className="text-dark">{product?.eanCode ?? "-"}</span>
        )}
      </td>
      {nameOfTable === "translator" && (
        <td>
          {/* // <span className="text-dark">{(product as any)?.workflowProduct?.assignedTo?.map((lang: string) => lang) ?? '-'}</span> */}
          {!!(product as any)?.workflowProduct?.assignedTo
            ? (product as any)?.workflowProduct?.assignedTo
                ?.map(
                  (language: any) => `${getCorrectLanguage(language as any, t)}`
                )
                .join(", ")
            : "-"}
        </td>
      )}
      {firstView ? (
        <>
          <td>
            <span
              className={`badge badge-outline-${
                (product as any)?.workflowProduct?.isTranslationDone
                  ? "success"
                  : "danger"
              } badge-soft-${
                (product as any)?.workflowProduct?.isTranslationDone
                  ? "success"
                  : "danger"
              }`}
            >
              {(product as any)?.workflowProduct?.isTranslationDone
                ? "Yes"
                : "No"}
            </span>
          </td>
        </>
      ) : (
        <>
          {checkForFinished() && nameOfTable !== "translator" && (
            <td>
              <span
                className={`badge badge-outline-${
                  isFinishedStep() ? "success" : "danger"
                } badge-soft-${isFinishedStep() ? "success" : "danger"}`}
              >
                {isFinishedStep() ? "Yes" : "No"}
              </span>
            </td>
          )}
        </>
      )}
      {product?.workflowProduct && (
        <td>
          <span
            className={`badge badge-outline-${
              product?.workflowProduct.similar ? "success" : "danger"
            } badge-soft-${
              product?.workflowProduct.similar ? "success" : "danger"
            }`}
          >
            {product?.workflowProduct.similar ? "Yes" : "No"}
          </span>
        </td>
      )}
      {staticsView2?.length &&
        staticsView2?.map((item: any) => {
          return (
            <td key={item?.lang}>
              {(product as any)?.workflowProduct?.finished?.[
                item?.lang as string
              ]?.isDone !== undefined ? (
                (product as any)?.workflowProduct?.finished?.[
                  item?.lang as string
                ]?.isDone ? (
                  <span className="badge badge-outline-success badge-soft-success">
                    Yes
                  </span>
                ) : (
                  <span className="badge badge-outline-danger badge-soft-danger">
                    No
                  </span>
                )
              ) : (
                ""
              )}
            </td>
          );
        })}
      {/* {nameOfTable === "translator" &&
                <td>{(product as any)?.workflowProduct?.finished?.['en' as string]?.isDone !== undefined ? (product as any)?.workflowProduct?.finished?.['en' as string]?.isDone ? 'Yes' : 'No' : ''}</td>}
            {nameOfTable === "translator" &&
                <td>{(product as any)?.workflowProduct?.finished?.['de' as string]?.isDone !== undefined ? (product as any)?.workflowProduct?.finished?.['de' as string]?.isDone ? 'Yes' : 'No' : ''}</td>} */}
      {showAssignColumn && <td>{handleShowAssignColumn()}</td>}
      <td>
        <span className="d-flex justify-content-start">
          <div className="d-flex align-items-center">
            <div className="flex-shrink-0 me-3">
              <div className="avatar-sm bg-light rounded p-1">
                <CustomImageHandler
                  path={product?.productPictures?.[0]?.path}
                  alt="Product"
                  classes="avatar-xs h-100 w-100"
                />
              </div>
            </div>
            <div className="flex-grow-1">
              <h5 className="fs-14 mb-1">
                {listWithoutLinks ? (
                  <>{product?.title ?? "-"}</>
                ) : (
                  <Link
                    to={customFuncForTo(product.id as number)}
                    onClick={(e) => {
                      if (!editDisabledBtn()) {
                        onEdit(e, product?.id as number);
                      } else {
                        e.preventDefault();
                      }
                    }}
                    onContextMenu={(e) => {
                      if (!editDisabledBtn()) {
                        onEdit(e, product?.id as number);
                      } else {
                        e.preventDefault();
                      }
                    }}
                    className={`text-dark ${
                      !editDisabledBtn() && "cursor-pointer"
                    }`}
                  >
                    {product?.title ?? "-"}
                  </Link>
                )}
                {/* <span
                  onClick={(e) => {
                    if (!editDisabledBtn()) {
                      onEdit(e, product?.id as number);
                    } else {
                      e.preventDefault();
                    }
                  }}
                  onContextMenu={(e) => {
                    if (!editDisabledBtn()) {
                      onEdit(e, product?.id as number);
                    } else {
                      e.preventDefault();
                    }
                  }}
                  className={`text-dark ${
                    !editDisabledBtn() && "cursor-pointer"
                  }`}
                >
                  {product?.title ?? "-"}
                </span> */}
              </h5>
            </div>
          </div>
        </span>
      </td>
      {window.location.pathname?.includes("/workflow/") && (
        <td>{product?.brand?.name ?? "-"}</td>
      )}

      {isExportList && <td>{(product?.variation as any)?.title ?? "-"}</td>}
      {status && <td>{(product?.status as string) ?? "-"}</td>}

      {nameOfTable === "pending" && (
        <td>{product.sapId ? product.sapId : "-"}</td>
      )}
      <td>{(product?.category as any)?.title ?? "Without category"}</td>
      <td>{formatDate(product?.createdAt)}</td>

      <td>
        <div className="d-flex align-items-center justify-content-center">
          {/* {showViewButton && showActionButtons && !variationsTable && (
            <button
              className="btn btn-sm btn-secondary d-flex align-items-center me-2"
              onClick={() => onView && onView(product?.id as number)}
            >
              <i className="ri-eye-fill me-1" />
              {`${t("global.view")}`}
            </button>
          )} */}

          <button
            // disabled={disabledBtn}
            disabled={
              window.location.pathname?.includes("/workflow/step4")
                ? false
                : editDisabledBtn()
            }
            className="btn btn-sm btn-warning d-flex align-items-center me-2"
            // onClick={() => onEdit(product?.id as number)}
            onClick={
              nameOfTable === "productByAttribute"
                ? () =>
                    onEditAtt(
                      product?.id as number,
                      product?.status as string,
                      product?.isImported as boolean
                    )
                : (e: any) => onEdit(e, product?.id as number)
            }
            onContextMenu={
              nameOfTable === "productByAttribute"
                ? () =>
                    onEditAtt(
                      product?.id as number,
                      product?.status as string,
                      product?.isImported as boolean
                    )
                : (e: any) => onEdit(e, product?.id as number)
            }
          >
            <i className="ri-edit-2-fill me-1" />
            {`${t("global.edit")}`}
          </button>
          {window.location.pathname?.includes("admin/attributes/list/") ||
            (!disabledDeleteBtn && showDeleteButton && showActionButtons && (
              <>
                <button
                  className="btn btn-sm btn-danger d-flex align-items-center"
                  data-toggle={isExportList === true ? "tooltip" : ""}
                  title={"Remove only from export"}
                  data-placement="top"
                  onClick={() => {
                    if (nameOfTable === "variations") {
                      setSelectedProduct(product?.product);
                      setSelectedVariation(product);
                      handleShow();
                    } else {
                      setSelectedProduct(product);
                      setSelectedVariation(product?.variation);
                      handleShow();
                    }
                  }}
                >
                  <i className="ri-delete-bin-fill me-1" />
                  {isExportList === true || nameOfTable === "baseMainWorkflow"
                    ? `${t("global.remove")}`
                    : `${t("global.delete")}`}
                </button>
              </>
            ))}
        </div>

        <ConfirmDelete
          show={show}
          setShow={setShow}
          itemName={
            nameOfTable === "variations"
              ? selectedVariation?.title
              : selectedProduct?.title
          }
          deleteHandler={deleteHandler}
          selectedProduct={selectedProduct}
          selectedVariation={selectedVariation}
        />
      </td>
    </tr>
  );
};
