import { Table } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Pagination } from "../../../shared/paginations/Paginations";
import { useTranslation } from "react-i18next";
import React, { SetStateAction, useEffect, useState } from "react";
import { EmptyState } from "../../../shared/components/EmptyState";
import { LoadingAnimation } from "../../../shared/components/Loading";

interface ICompaniesListProps {
  companiesList: any[] | undefined;
  changeFilterHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
  setTriggerUpdate: React.Dispatch<SetStateAction<boolean>>;
  page: number;
  totalPages: number;
  handlePages: (updateNumber: number) => void;
}

export const ListMapping = ({
  companiesList,
  changeFilterHandler,
  setTriggerUpdate,
  page,
  totalPages,
  handlePages,
}: ICompaniesListProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <>
      <div className="card">
        <div className="card-header pb-0">
          <div className="d-flex justify-content-between">
            <h4 className="card-title mg-b-0">
              {/* {`${t("companies.list_of_companies")}`} */}
              List of items
            </h4>
          </div>
        </div>
        <div className="card-body">
          {companiesList == undefined ? (
            <LoadingAnimation />
          ) : companiesList?.length === 0 ? (
            <EmptyState />
          ) : (
            <div className="table-responsive">
              <Table className="align-middle table-nowrap mb-0">
                <thead className="table-light">
                  <tr>
                    <th scope="col">{`${t("global.id")}`}</th>
                    <th scope="col">{`${t("global.name")}`}</th>
                    <th scope="col">{`${t("global.action")}`}</th>
                  </tr>
                </thead>
                <tbody>
                  {companiesList?.map((company) => {
                    return (
                      <tr key={company?.id}>
                        <td className="">
                          <Link to="#" className="fw-medium ">
                            #{company?.id}
                          </Link>
                        </td>
                        <td>
                          <td className="text-start">{company?.name}</td>
                        </td>
                        <td>
                          <div className="d-flex align-items-center justify-content-center">
                            <button
                              className="btn btn-sm btn-warning d-flex align-items-center"
                              onClick={() =>
                                navigate(
                                  `/app/clients/mapping-update/${company?.id}`
                                )
                              }
                            >
                              <i className="ri-edit-2-fill me-1" />
                              {`${t("global.edit")}`}
                            </button>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          )}

          <div className="d-flex justify-content-end ">
            {totalPages <= 1 ? (
              ""
            ) : (
              <Pagination
                page={page}
                totalPages={totalPages}
                handlePagination={handlePages}
              />
            )}
          </div>
          {/* <ConfirmDelete
          show={show}
          setShow={setShow}
          itemName={selectedCompany?.title}
          deleteHandler={deleteHandler}
          selectedProduct={selectedCompany}
        /> */}
        </div>
      </div>
    </>
  );
};
