import React, { useEffect, useState } from "react";
import { EditCopywrite } from "./EditCopywrite";
import { useParams } from "react-router";
import { ContentWorkflowService } from "../service";
import { ErrorToast } from "../../../shared/toasters/toasters";
import { Card } from "react-bootstrap";
import { ContentStepper } from "./ContentStepper";
import { t } from "i18next";

export const ContentStep3Edit = () => {
  const [currentContent, setCurrentContent] = useState({});
  const [contentCopywrite, setContentCopywrite] = useState({} as any);
  const [updateCopywriteList, setUpdateCopywriteList] = useState(false);
  const [currentSourceLang, setCurrentSourceLang] = useState("");
  const { id, contentId } = useParams();
  const [currentWorkflowStatus, setCurrentWorkflowStatus] = useState("");
  const [otherDataBar, setOtherDataBar] = useState({} as any);
  const currentUser = JSON.parse(localStorage.getItem("currentUser") as string);
  const isManager = currentUser?.roles.includes("ROLE_MANAGER");
  const isAdmin = currentUser?.roles.includes("ROLE_ADMIN");
  const isCompanyAdmin = currentUser?.roles.includes("ROLE_COMPANY_ADMIN");
  const [triggerUpdateData, setTriggerUpdateData] = useState(false);
  useEffect(() => {
    id &&
      contentId &&
      ContentWorkflowService.getCopywriteById(+id!, +contentId!, "COPY_WRITING")
        .then((res) => {
          setContentCopywrite(res);
        })
        .catch((err) => ErrorToast(err));
  }, []);
  useEffect(() => {
    ContentWorkflowService.getAllCopywrite({ page: 1, perPage: 0 }, +id!)
      .then((response) => {
        const { workflow, otherData }: any = response;
        setCurrentWorkflowStatus(workflow?.status);
        setCurrentSourceLang(workflow?.sourceLang);
        setOtherDataBar(otherData?.copywriting);
      })
      .catch((error) => ErrorToast(error));
  }, [triggerUpdateData]);
  return (
    <>
      <Card>
        <Card.Body>
          <ContentStepper
            // changeFilterHandler={changeFilterHandler}
            step={"COPY_WRITING"}
            id={id}
            showNextButton={true}
            copywriteId={contentId}
            showMarkAsComplete={
              currentWorkflowStatus === "COPY_WRITING" &&
              (isAdmin || isCompanyAdmin || isManager)
            }
            isDisabledComplete={true}
            precent={otherDataBar?.percent}
          />
          <div className="card bg-light overflow-hidden shadow-none">
            <div className="card-body">
              <div className="d-flex">
                <div className="flex-grow-1">
                  <h6 className="mb-0">
                    {`${t("workflow.current")}`}:{" "}
                    <b className="text-success">
                      {otherDataBar?.percent ?? 0}%
                    </b>
                  </h6>
                </div>
                <div className="flex-shrink-0">
                  <h6 className="mb-0">
                    {otherDataBar?.done ?? 0}/{otherDataBar?.total ?? 0}{" "}
                    {`${t("workflow.finished")}`}
                  </h6>
                </div>
              </div>
            </div>
            <div className="progress">
              {/*//@ts-ignore*/}
              <div
                className="progress-bar bg-success"
                style={{ width: `${otherDataBar?.percent ?? 0}%` }}
                aria-valuenow={otherDataBar?.percent as number}
                aria-valuemin="0"
                aria-valuemax="100"
              />
            </div>
          </div>
        </Card.Body>
      </Card>
      <EditCopywrite
        step={"COPY_WRITING"}
        copywriteId={contentId}
        setCurrentContent={setCurrentContent}
        currentContent={currentContent}
        //  setCopywriteId={setCopywriteId}
        setUpdateCopywriteList={setUpdateCopywriteList}
        currentSourceLang={currentSourceLang}
        contentCopywrite={contentCopywrite}
        setTriggerUpdateData={setTriggerUpdateData}
      />
    </>
  );
};
