import React, { useEffect, useState } from "react";
import { ContentStepper } from "./ContentStepper";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router";
import { LoadingAnimation } from "../../../shared/components/Loading";
import { EmptyState } from "../../../shared/components/EmptyState";
import { Card, Table } from "react-bootstrap";
import { ContentWorkflowService } from "../service";
import { ErrorToast, SuccessToast } from "../../../shared/toasters/toasters";
import { Pagination } from "../../../shared/paginations/Paginations";
import { useDispatch, useSelector } from "react-redux";
import { IAllStates } from "../../../store/rootReducer";
import contentCopywritingPaginationSlice from "../../../store/contentWorkflow/contentCopywritingPagination";

export const ContentStep3 = () => {
  const { search } = useLocation();
  const [copywriterList, setCopywriterList] = useState([]);
  const [otherDataBar, setOtherDataBar] = useState({} as any);
  const [currentWorkflowStatus, setCurrentWorkflowStatus] = useState("");
  const currentUser = JSON.parse(localStorage.getItem("currentUser") as string);
  const isManager = currentUser?.roles.includes("ROLE_MANAGER");
  const isAdmin = currentUser?.roles.includes("ROLE_ADMIN");
  const isCompanyAdmin = currentUser?.roles.includes("ROLE_COMPANY_ADMIN");
  const [checkedValues, setCheckedValues] = useState<number[]>([]);
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const currentPage = Number(
    +(search.split("=").at(-1) ?? 0) !== 0 ? search.split("=").at(-1) : 1
  );
  const dispatch = useDispatch();
  const stepPagination = useSelector(
    (state: IAllStates) => state.contentCopywritingPagination as any
  );
  // const [pagination, setPagination] = useState({
  //   page: currentPage,
  //   perPage: 10,
  //   assigned: "",
  //   status: "",
  // });
  const [pagination, setPagination] = useState({
    ...(stepPagination as unknown as any),
    // page: currentPage,
    // perPage: 10,
  });
  const [page, setPage] = useState(currentPage);
  const [totalPages, setTotalPages] = useState(0);
  const [triggerUpdate, setTriggerUpdate] = useState(false);
  const [userList, setUserList] = useState([]);
  // const [updateCopywriteList, setUpdateCopywriteList] = useState(false);
  const handlePages = (updatePage: number) => {
    setPagination({ ...pagination, page: updatePage });
    dispatch(
      contentCopywritingPaginationSlice.actions.setContentCopywritingPagination(
        {
          ...(stepPagination as any),
          page: updatePage,
        }
      )
    );
    setPage(updatePage);
  };
  const editHandler = (e: any, copywriteId: number) => {
    e.preventDefault();
    if (e.type === "click") {
      navigate(`/app/content-workflow/copywriting/${id}/${copywriteId}`);
    } else {
      window.open(
        `/app/content-workflow/copywriting/${id}/${copywriteId}`,
        "_blank"
      );
      // setCopywriteId(copywriteId);

      // setCopywriteId(copywriteId);
      // ContentWorkflowService.getCopywriteById(+id!, copywriteId, "COPY_WRITING")
      //   .then((res) => {
      //     setContentCopywrite(res);
      //   })
      //   .catch((err) => ErrorToast(err));
    }
  };
  const checkAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setCheckedValues(copywriterList?.map((product: any) => product.id));
    } else {
      setCheckedValues([]);
    }
  };
  const checkForMainRoles = () => {
    if (isAdmin || isCompanyAdmin || isManager) {
      return true;
    } else {
      return false;
    }
  };
  const handlePerPage = (e: any) => {
    setPagination((prev: any) => ({ ...prev, page: 1, perPage: e.value }));
    dispatch(
      contentCopywritingPaginationSlice.actions.setContentCopywritingPagination(
        {
          ...(stepPagination as any),
          page: 1,
          perPage: e.value,
        }
      )
    );
  };
  const changeFinishHandler = async (finishedOrUnfinished: boolean) => {
    id &&
      (await ContentWorkflowService.changeFinishedStatusCopyWrite(Number(id), {
        isDone: finishedOrUnfinished,
        contents: checkedValues,
      })
        .then((response) => {
          if (response) {
            setTriggerUpdate((prev) => !prev);
            setCheckedValues([]);
            SuccessToast(t("toasts.successfully_updated_status"));
          }
        })
        .catch((error) => ErrorToast(error)));
  };
  const checkboxHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setCheckedValues((prev) => [...prev, +e.target.value]);
    } else {
      setCheckedValues((prev) =>
        prev.filter((value) => value !== +e.target.value)
      );
    }
  };
  const changeFilterHandler = (name: string, value: string) => {
    if (value === "") {
      setPagination((prev: any) => ({
        ...prev,
        [name]: value,
        copywriter: null,
        assigned: "",
      }));
      dispatch(
        contentCopywritingPaginationSlice.actions.setContentCopywritingPagination(
          {
            ...(stepPagination as any),
            [name]: value,
            copywriter: null,
            assigned: "",
          }
        )
      );
    } else if (name === "assigned") {
      setPagination((prev: any) => ({
        ...prev,
        [name]: value,
        copywriter: null,
      }));
      dispatch(
        contentCopywritingPaginationSlice.actions.setContentCopywritingPagination(
          {
            ...(stepPagination as any),
            [name]: value,
            copywriter: null,
          }
        )
      );
    } else {
      setPagination((prev: any) => ({ ...prev, [name]: value }));
      dispatch(
        contentCopywritingPaginationSlice.actions.setContentCopywritingPagination(
          {
            ...(stepPagination as any),
            [name]: value,
          }
        )
      );
    }
  };
  // const changeFilterHandler = (name: string, value: string) => {
  //   if ((pagination as any)?.[name] === value) {
  //     setPagination((prev) => ({
  //       ...prev,
  //       [name]: "",
  //       page: 1,
  //       copywriter: null,
  //     }));
  //   } else {
  //     setPagination((prev) => ({ ...prev, [name]: value, page: 1 }));
  //   }
  // };
  const handleFilterUser = (e: any) => {
    setPagination((prev: any) => ({
      ...prev,
      copywriter: e?.value,
      assigned: "",
      page: 1,
    }));
    dispatch(
      contentCopywritingPaginationSlice.actions.setContentCopywritingPagination(
        {
          ...(stepPagination as any),
          copywriter: e?.value,
          assigned: "",
          page: 1,
        }
      )
    );
  };
  useEffect(() => {
    ContentWorkflowService.getAllCopywrite(pagination, +id!)
      .then((response) => {
        const { data, otherData, workflow, assignedCopywriters }: any =
          response;
        setCurrentWorkflowStatus(workflow?.status);
        setCopywriterList(data as any);
        setOtherDataBar(otherData?.copywriting);
        // setCurrentSourceLang(workflow?.sourceLang);
        setUserList(assignedCopywriters);
        setTotalPages(Math.ceil(response?.count / response?.perPage));
        setPage(response?.page);
      })
      .catch((error) => ErrorToast(error));
  }, [pagination, currentPage, triggerUpdate]);
  return (
    <>
      <Card>
        <Card.Body>
          <ContentStepper
            pagination={pagination}
            changeFilterHandler={changeFilterHandler}
            userList={userList}
            step={"COPY_WRITING"}
            id={id}
            showNextButton={true}
            handleFilterUser={handleFilterUser}
            // setCurrentContent={setCurrentContent}
            // setCopywriteId={setCopywriteId}
            // copywriteId={copywriteId}
            showMarkAsComplete={
              currentWorkflowStatus === "COPY_WRITING" &&
              (isAdmin || isCompanyAdmin || isManager)
            }
            isDisabledComplete={true}
            precent={otherDataBar?.percent}
            handlePerPage={handlePerPage}
            assigned={
              (isAdmin || isCompanyAdmin || isManager) &&
              (pagination?.assigned ?? "")
            }
          />
          <div className="card bg-light overflow-hidden shadow-none">
            <div className="card-body">
              <div className="d-flex">
                <div className="flex-grow-1">
                  <h6 className="mb-0">
                    {`${t("workflow.current")}`}:{" "}
                    <b className="text-success">
                      {otherDataBar?.percent ?? 0}%
                    </b>
                  </h6>
                </div>
                <div className="flex-shrink-0">
                  <h6 className="mb-0">
                    {otherDataBar?.done ?? 0}/{otherDataBar?.total ?? 0}{" "}
                    {`${t("workflow.finished")}`}
                  </h6>
                </div>
              </div>
            </div>
            <div className="progress">
              {/*//@ts-ignore*/}
              <div
                className="progress-bar bg-success"
                style={{ width: `${otherDataBar?.percent ?? 0}%` }}
                aria-valuenow={otherDataBar?.percent as number}
                aria-valuemin="0"
                aria-valuemax="100"
              />
            </div>
          </div>
        </Card.Body>
      </Card>

      <div className="card">
        <div className="card-body">
          {copywriterList == undefined ? (
            <LoadingAnimation />
          ) : copywriterList?.length === 0 ? (
            <EmptyState />
          ) : (
            <div className="table-responsive">
              {checkForMainRoles() && (
                <div className="d-flex justify-content-start align-items-center mb-1">
                  <button
                    onClick={() => changeFinishHandler(true)}
                    className="btn  btn-success d-flex align-items-center me-2"
                    disabled={
                      // translatorData?.user ||
                      !checkedValues?.length
                    }
                  >
                    <i className="ri-share-box-fill me-1" />
                    {t("global.finished")}
                  </button>
                  <button
                    onClick={() => changeFinishHandler(false)}
                    className="btn  btn-danger d-flex align-items-center me-2"
                    disabled={
                      // translatorData?.user ||
                      !checkedValues?.length
                    }
                  >
                    <i className="ri-share-box-fill me-1" />
                    {t("global.unfinished")}
                  </button>
                </div>
              )}

              <Table className="align-middle table-nowrap mb-0">
                <thead className="table-light">
                  <tr>
                    {checkForMainRoles() && (
                      <th scope="col">
                        <input
                          className="form-check-input m-0 cursor-pointer"
                          checked={
                            checkedValues.length === copywriterList?.length
                          }
                          type="checkbox"
                          onChange={checkAll}
                        />
                      </th>
                    )}

                    <th scope="col">{`${t("global.id")}`}</th>
                    <th scope="col">{`${t("global.title")}`}</th>
                    <th scope="col">{t("global.finished")}</th>
                    <th scope="col">{`${t("global.assign")}`}</th>
                    <th scope="col">{`${t("global.action")}`}</th>
                  </tr>
                </thead>
                <tbody>
                  {copywriterList?.map((copywrite: any) => {
                    return (
                      <tr>
                        {checkForMainRoles() && (
                          <td>
                            <input
                              type="checkbox"
                              className="form-check-input m-0 cursor-pointer"
                              onChange={(e) =>
                                checkboxHandler && checkboxHandler(e)
                              }
                              value={copywrite?.id}
                              checked={checkedValues?.includes(
                                Number(copywrite?.id)
                              )}
                            />
                          </td>
                        )}

                        <td>{copywrite.id}</td>
                        <td>{copywrite?.title ?? ""}</td>
                        <>
                          {copywrite?.otherData && (
                            <td>
                              <span
                                className={`badge badge-outline-${
                                  copywrite?.otherData?.isCopyWritingDone
                                    ? "success"
                                    : "danger"
                                } badge-soft-${
                                  copywrite?.otherData?.isCopyWritingDone
                                    ? "success"
                                    : "danger"
                                }`}
                              >
                                {copywrite?.otherData?.isCopyWritingDone
                                  ? "Yes"
                                  : "No"}
                              </span>
                            </td>
                          )}
                        </>
                        <td>
                          {copywrite?.otherData?.copyWriter
                            ? copywrite?.otherData?.copyWriter?.firstName.concat(
                                ` ${copywrite?.otherData?.copyWriter?.lastName}`
                              )
                            : "-"}
                        </td>
                        <td>
                          <div className="d-flex align-items-center justify-content-center">
                            {
                              // accessRights?.includes(
                              //   "brand_can_update"
                              // )
                              true && (
                                <button
                                  className="btn btn-sm btn-warning d-flex align-items-center me-2"
                                  onClick={(e) =>
                                    editHandler(e, copywrite?.id as number)
                                  }
                                  onContextMenu={(e) =>
                                    editHandler(e, copywrite?.id as number)
                                  }
                                >
                                  <i className="ri-edit-2-fill me-1" />
                                  {`${t("global.edit")}`}
                                </button>
                              )
                            }
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          )}
        </div>
        <div className="card-footer">
          <div className="d-flex justify-content-end ">
            {totalPages! <= 1 ? (
              ""
            ) : (
              <Pagination
                page={page}
                totalPages={totalPages}
                handlePagination={handlePages}
              />
            )}
          </div>
        </div>
      </div>
      {/* )} */}
    </>
  );
};
