import React, { useEffect, useState } from "react";
import Close from "../../../shared/icons/close.svg";
import { useSelector } from "react-redux";
import { IAllStates } from "../../../store/rootReducer";
import { Button, Modal } from "react-bootstrap";
import { t } from "i18next";
import Select from "react-select";
import { themeSelect } from "../../../shared/components/Select2";
import { AttributesService } from "../../attributes/service";
import { ErrorToast, SuccessToast } from "../../../shared/toasters/toasters";
import { useNavigate, useParams } from "react-router";
import { ClientService } from "../service";
import { nanoid } from "nanoid";
export const Requirements = () => {
  const { id } = useParams();
  const {
    translationLanguageEnum,
    dataQualityContentFieldValue,
    dataQualityFieldValue,
    dataQualityFieldTranslatedValue,
  } = useSelector((state: IAllStates) => state.enums);
  const [modalTitle, setModalTitle] = useState("");
  const [contentLanguage, setContentLanguage] = useState("");
  const [contentIndex, setContentIndex] = useState(0);
  const [modalOptions, setModalOptions] = useState<string[]>([]);
  const [checkedValue, setCheckedValue] = useState<string[]>([]);
  const [attributesValue, setAttributesValue] = useState<string[]>([]);
  const [attributesList, setAttributesList] = useState([]);
  const navigate = useNavigate();
  const [attributesRule, setAttributesRule] = useState({
    percentMedium: undefined,
    percentHigh: undefined,
  });
  const [checkedValueTranslation, setCheckedValueTranslation] = useState([
    { language: "de", fields: [] },
    { language: "en", fields: [] },
    { language: "fr", fields: [] },
    { language: "it", fields: [] },
  ]);
  const [checkedValueContent, setCheckedValueContent] = useState([
    {
      language: "de",
      fields: [],
    },
    {
      language: "en",
      fields: [],
    },
    {
      language: "fr",
      fields: [],
    },
    {
      language: "it",
      fields: [],
    },
  ]);
  const [show, setShow] = useState(false);
  const [clientName, setClientName] = useState({});
  const handleClose = () => {
    setShow(false);
    setModalTitle("");
    setModalOptions([]);
  };
  const handleShow = (field: string, array: string[]) => {
    setShow(true);
    setModalTitle(field);
    setModalOptions(array);
  };
  const [chooseduniqueOptions, setChoosedUniqueOptions] = useState<any[]>([]);
  const [translationOptions, setTranslationOptions] = useState([
    { language: "de", fields: [] },
    { language: "en", fields: [] },
    { language: "fr", fields: [] },
    { language: "it", fields: [] },
  ]);
  const [contentOptions, setContentOptions] = useState([
    {
      language: "de",
      fields: [],
    },
    {
      language: "en",
      fields: [],
    },
    {
      language: "fr",
      fields: [],
    },
    {
      language: "it",
      fields: [],
    },
  ]);
  const checkboxHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setCheckedValue((prev: any) => [...prev, e.target.value]);
    } else {
      setCheckedValue((prev: any) =>
        prev.filter(
          (value: string) => value !== (e.target.value as unknown as string)
        )
      );
    }
  };
  const checkboxHandlerTranslation = (
    e: React.ChangeEvent<HTMLInputElement>,
    language: string
  ) => {
    if (e.target.checked) {
      setCheckedValueTranslation((prevState: any) =>
        prevState.map((option: any) =>
          option.language === language
            ? {
                ...option,
                fields: [...option.fields, e.target.value], // Ako je checkbox selektovan, dodaj polje
              }
            : option
        )
      );
    } else {
      setCheckedValueTranslation((prevState: any) =>
        prevState.map((option: any) =>
          option.language === language
            ? {
                ...option,
                fields: option.fields.filter(
                  (field: string) => field !== e.target.value
                ), // Ako je deselectovan, ukloni polje
              }
            : option
        )
      );
    }
  };
  const checkboxHandlerContent = (
    e: React.ChangeEvent<HTMLInputElement>,
    language: string
  ) => {
    if (e.target.checked) {
      const newObjectValue = {
        field: e.target.value,
        wordCountMedium: undefined,
        wordCountHigh: undefined,
      };
      setCheckedValueContent((prevState: any) =>
        prevState.map((option: any) =>
          option.language === language
            ? {
                ...option,
                fields: [...option?.fields, newObjectValue],
              }
            : option
        )
      );
    } else {
      setCheckedValueContent((prevState: any) =>
        prevState.map((option: any) =>
          option.language === language
            ? {
                ...option,
                fields: option?.fields?.filter(
                  (field: any) => field?.field !== e.target.value
                ),
              }
            : option
        )
      );
    }
  };
  const handleSubmit = () => {
    if (modalTitle === "Base Data") {
      setChoosedUniqueOptions(checkedValue);
    } else if (modalTitle?.includes("Translation")) {
      setTranslationOptions(checkedValueTranslation);
    } else if (modalTitle?.includes("Content")) {
      setContentOptions(checkedValueContent);
    } else {
      return;
    }
    handleClose();
  };
  const deleteHandlerColumn = (inx: number) => {
    const removeItem = chooseduniqueOptions?.filter(
      (item: any, index: number) => index !== inx
    );
    setChoosedUniqueOptions(removeItem);
    setCheckedValue(removeItem);
  };
  const deleteHandlerTranslation = (inx: number, language: string) => {
    setTranslationOptions((prevState: any) =>
      prevState.map((option: any) =>
        option.language === language
          ? {
              ...option,
              fields: option?.fields?.filter(
                (item: any, index: number) => index !== inx
              ),
            }
          : option
      )
    );
    setCheckedValueTranslation((prevState: any) =>
      prevState.map((option: any) =>
        option.language === language
          ? {
              ...option,
              fields: option?.fields?.filter(
                (item: any, index: number) => index !== inx
              ),
            }
          : option
      )
    );
  };
  const deleteHandlerContent = (inx: number, language: string) => {
    setContentOptions((prevState: any) =>
      prevState.map((option: any) =>
        option.language === language
          ? {
              ...option,
              fields: option?.fields?.filter(
                (item: any, index: number) => index !== inx
              ),
            }
          : option
      )
    );
    setCheckedValueContent((prevState: any) =>
      prevState.map((option: any) =>
        option.language === language
          ? {
              ...option,
              fields: option?.fields?.filter(
                (item: any, index: number) => index !== inx
              ),
            }
          : option
      )
    );
  };
  useEffect(() => {
    AttributesService.getAllAttributes({
      page: 1,
      perPage: 0,
    })
      .then((response) => {
        const { data } = response;

        setAttributesList(data as any);
      })
      .catch((error) => ErrorToast(error));
  }, []);
  function handleSelectSingle(selectedSingle: any) {
    setAttributesValue(
      selectedSingle?.map(
        (selectedSingle: { value: string }) => selectedSingle?.value
      )
    );
  }
  const percentageHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event.target;

    setAttributesRule((prev: any) => ({
      ...prev,
      [name]: +value,
    }));
  };
  const wordsCountNumber = (e: any, indexField: number, language: string) => {
    const { value, name } = e.target;
    setCheckedValueContent((prev: any) =>
      prev?.map((item: any, index: number) => {
        if (item?.language == language) {
          const updatedFields = item?.fields.map(
            (field: any, fieldIndex: number) => {
              if (fieldIndex === indexField) {
                return { ...field, [name]: Number(value) };
              } else {
                return field;
              }
            }
          );
          return { ...item, fields: updatedFields };
        }
        return item;
      })
    );
    setContentOptions((prev: any) =>
      prev?.map((item: any, index: number) => {
        if (item?.language == language) {
          const updatedFields = item?.fields.map(
            (field: any, fieldIndex: number) => {
              if (fieldIndex === indexField) {
                return { ...field, [name]: Number(value) };
              } else {
                return field;
              }
            }
          );
          return { ...item, fields: updatedFields };
        }

        return item;
      })
    );
  };
  const submitHandler = (event: React.FormEvent) => {
    event.preventDefault();
    if (id) {
      ClientService.saveDataQuality(+id, {
        fields: checkedValue,
        translatedFields: checkedValueTranslation,
        mandatoryAttributes: attributesValue,
        attributesRule: attributesRule,
        contentFields: checkedValueContent,
      })
        .then((response) => {
          SuccessToast(`${t("toasts.successfully_updated")} `);
        })
        .catch((error) => ErrorToast(error));
    }
  };
  useEffect(() => {
    ClientService.getDataQuality(Number(id))
      .then((response: any) => {
        const {
          attributesRule,
          contentFields,
          fields,
          mandatoryAttributes,
          translatedFields,
        } = response;
        setCheckedValueTranslation(translatedFields);
        setCheckedValueContent(contentFields);
        setCheckedValue(fields);
        setTranslationOptions(translatedFields);
        setContentOptions(contentFields);
        setChoosedUniqueOptions(fields);
        setAttributesRule(attributesRule);
        setAttributesValue(mandatoryAttributes);
      })
      .catch((error: any) => ErrorToast(error));
  }, [id, attributesList]);
  useEffect(() => {
    ClientService.getAllClients({ page: 1, perPage: 0 })
      .then((response) => {
        const { data } = response;
        setClientName(
          data?.length > 0
            ? (data as any)
                ?.map((user: any) => ({
                  name: user?.name,
                  value: user?.id,
                }))
                ?.find((user: any) => user?.value == id)
            : {}
        );
      })
      .catch((error) => ErrorToast(error));
  }, []);
  return (
    <div className="row">
      <div className="col-md-12">
        <div className="d-flex justify-content-between align-items-center">
          <button
            className="btn btn-info mb-3 d-flex align-items-center"
            onClick={() => navigate("/app/clients")}
          >
            <i className="ri-arrow-left-line"></i>
            <span className="ms-1">Back</span>
          </button>
          <h4>{(clientName as any)?.name ?? ""}</h4>
        </div>
      </div>
      <div className="col-md-6">
        <div className="card" style={{ minHeight: "200px" }}>
          <div className="card-body">
            <div className="card-title">{t("side_bar.base_data")}</div>
            <div className="row">
              <div className="col-md-4 text-start">
                <Button
                  variant="info"
                  onClick={() => handleShow(`Base Data`, dataQualityFieldValue)}
                >
                  {t("requirement.add_rule")}
                </Button>
              </div>
              <div className="col-md-8 text-start">
                <Button
                  variant="info"
                  onClick={() =>
                    handleShow(
                      `Base Data Translation`,
                      dataQualityFieldTranslatedValue
                    )
                  }
                >
                  {t("requirement.add_translation_rule")}
                </Button>
              </div>
            </div>

            <div className="row">
              <div className="col-md-4 mt-3">
                {chooseduniqueOptions?.length > 0 ? (
                  chooseduniqueOptions?.map((field: string, index: number) => {
                    return (
                      <div className="d-flex justify-content-between align-items-center my-1">
                        <input
                          className="w-100"
                          type="text"
                          disabled
                          value={t(`requirement.${field}`) as string}
                        />
                        <img
                          src={Close}
                          className="cursor-pointer ms-1"
                          onClick={() => {
                            deleteHandlerColumn(index);
                          }}
                          alt="close"
                        />
                      </div>
                    );
                  })
                ) : (
                  <span className=""></span>
                )}
              </div>
              <div className="col-md-8 mt-3">
                {translationOptions?.map(
                  (item: any) =>
                    item?.fields?.length > 0 && (
                      <div className="row mb-2" key={item?.language}>
                        <h5 className="mb-1">
                          {" "}
                          {t(`languages.${item?.language}`)}
                        </h5>
                        {item?.fields?.map((field: string, index: number) => (
                          <div className="col-md-6" key={index}>
                            <div className="d-flex justify-content-between align-items-center mb-1">
                              <input
                                className="w-100"
                                type="text"
                                disabled
                                value={field}
                              />
                              <img
                                src={Close}
                                className="cursor-pointer ms-1"
                                onClick={() => {
                                  deleteHandlerTranslation(
                                    index,
                                    item?.language
                                  );
                                }}
                                alt="close"
                              />
                            </div>
                          </div>
                        ))}
                      </div>
                    )
                )}
              </div>
            </div>
            <Modal show={show} onHide={handleClose} centered>
              <Modal.Header closeButton>
                <Modal.Title>{modalTitle}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  {!modalTitle.includes("Translation")
                    ? modalOptions?.map((field: string) => {
                        return (
                          <div className="col-md-4">
                            <div className="d-flex justify-content-between align-items-center">
                              <label htmlFor="" className="mb-0">
                                {t(`requirement.${field}`)}
                              </label>
                              <input
                                className={`form-check-input`}
                                style={{
                                  marginRight: `${
                                    modalTitle?.includes("Content")
                                      ? "30px"
                                      : "16px"
                                  }`,
                                }}
                                type="checkbox"
                                value={field}
                                checked={
                                  !modalTitle.includes("Content")
                                    ? checkedValue?.includes(field)
                                    : checkedValueContent?.[
                                        contentIndex
                                      ]?.fields
                                        ?.map((item: any) => item?.field)
                                        ?.includes(field)
                                }
                                onChange={(e) =>
                                  !modalTitle.includes("Content")
                                    ? checkboxHandler(e)
                                    : checkboxHandlerContent(e, contentLanguage)
                                }
                              ></input>
                            </div>
                          </div>
                        );
                      })
                    : translationLanguageEnum.map(
                        (langauge: string, idx: number) => (
                          <div className="row mb-3" key={langauge}>
                            <h5 className="fw-bold">
                              {t(`languages.${langauge}`)}
                            </h5>
                            <div className="row d-flex">
                              {modalOptions.map((field: any, index) => (
                                <div
                                  className="col-md-4 d-flex justify-content-between align-items-center"
                                  key={index}
                                >
                                  <label htmlFor="" className="mb-0">
                                    {t(`requirement.${field}`)}
                                  </label>
                                  <input
                                    className="form-check-input"
                                    style={{
                                      marginRight: "25px",
                                    }}
                                    type="checkbox"
                                    value={field}
                                    checked={checkedValueTranslation?.[
                                      idx
                                    ]?.fields?.includes(field as never)}
                                    onChange={(e) =>
                                      checkboxHandlerTranslation(e, langauge)
                                    }
                                  ></input>
                                </div>
                              ))}
                            </div>
                          </div>
                        )
                      )}
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  {t("modals.close")}
                </Button>
                <Button variant="primary" onClick={() => handleSubmit()}>
                  {t("requirement.add_rule")}
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      </div>
      <div className="col-md-6">
        <div className="card" style={{ minHeight: "200px" }}>
          <div className="card-body">
            <div className="card-title">{t("side_bar.attributes")}</div>
            <div className="row">
              <div className="col-md-8">
                <label htmlFor="name" className="">
                  {t("requirement.mandatory_attributes")}
                </label>
                <Select
                  key={nanoid()}
                  className="react-select-field"
                  theme={themeSelect}
                  isMulti={true}
                  onChange={(e) => handleSelectSingle(e as any)}
                  options={attributesList?.map((value: any) => ({
                    label: value?.title,
                    value: value?.id,
                  }))}
                  defaultValue={attributesList
                    ?.filter((attribute: any) =>
                      attributesValue?.includes(attribute?.id)
                    )
                    .map((att: any) => ({
                      label: att?.title,
                      value: att?.id,
                    }))}
                />
              </div>
              <div className="col-md-4">
                <label htmlFor="">{t("requirement.add_percentage")}</label>
                <div className="d-flex align-items-center">
                  <span className="me-2">
                    {/* {t("requirement.from")} */}
                    Medium
                  </span>
                  <input
                    name="percentMedium"
                    style={{ width: "40px" }}
                    type="number"
                    value={attributesRule?.percentMedium}
                    onChange={(e) => percentageHandler(e)}
                  />
                  <span className="ms-3 me-2">
                    {/* {t("requirement.to")} */}
                    High
                  </span>
                  <input
                    name="percentHigh"
                    style={{ width: "40px" }}
                    type="number"
                    value={attributesRule?.percentHigh}
                    onChange={(e) => percentageHandler(e)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-12">
        <div className="card">
          <div className="card-body">
            <div className="row">
              {translationLanguageEnum?.map(
                (language: string, index: number) => {
                  return (
                    <div className="col-md-3">
                      <div className="card-title">
                        {t("side_bar.content")} {t(`languages.${language}`)}
                      </div>
                      <div className="row d-flex">
                        <div className="col-md-6">
                          <Button
                            variant="info"
                            onClick={() => {
                              //tu
                              handleShow(
                                t(`requirement.content_${language}`) as string,
                                dataQualityContentFieldValue
                              );
                              setContentLanguage(language);
                              setContentIndex(index);
                            }}
                          >
                            {t("requirement.add_rule")}
                          </Button>
                        </div>
                        <div className="col-md-6 d-flex align-items-center justify-content-center">
                          {contentOptions[index].fields?.length > 0 && (
                            <p className="mb-0 fw-bold">
                              {" "}
                              {t("requirement.words_count")}
                            </p>
                          )}
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-12 mt-3">
                          {contentOptions[index]?.fields?.map(
                            (field: any, index: number) => {
                              return (
                                <div className="row d-flex justify-content-between align-items-center my-1">
                                  <div className="col-md-6 d-flex">
                                    <input
                                      className="w-100"
                                      type="text"
                                      disabled
                                      value={
                                        t(
                                          `requirement.${field?.field}`
                                        ) as string
                                      }
                                    />
                                    <img
                                      src={Close}
                                      className="cursor-pointer ms-1"
                                      onClick={() => {
                                        // testt
                                        deleteHandlerContent(index, language);
                                      }}
                                      alt="close"
                                    />
                                  </div>
                                  <div className="col-md-6">
                                    <div className="d-flex align-items-center justify-content-center">
                                      <input
                                        name={"wordCountMedium"}
                                        placeholder="Medium"
                                        style={{ width: "60px" }}
                                        type="number"
                                        value={field?.wordCountMedium}
                                        onChange={(e) =>
                                          wordsCountNumber(e, index, language)
                                        }
                                      />
                                      <span className="mx-2">-</span>
                                      <input
                                        name={"wordCountHigh"}
                                        placeholder="High"
                                        style={{ width: "60px" }}
                                        type="number"
                                        value={field?.wordCountHigh}
                                        onChange={(e) =>
                                          wordsCountNumber(e, index, language)
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              );
                            }
                          )}
                        </div>
                      </div>
                    </div>
                  );
                }
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-12">
        <div className="d-flex justify-content-end">
          <Button
            variant="primary"
            onClick={(e) => {
              submitHandler(e);
            }}
          >
            {t("global.save")}
          </Button>
        </div>
      </div>
    </div>
  );
};
