// @ts-nocheck
import { useTranslation } from "react-i18next";
import { IProducts, IProductsList } from "../../dto/IProducts";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import React, { SetStateAction, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { IAllStates } from "../../../../store/rootReducer";
import { BlockService } from "../../../block/service";
import { ErrorToast, SuccessToast } from "../../../../shared/toasters/toasters";
import { MultiValue } from "react-select";
import { ProductsService } from "../../service";
// eslint-disable-next-line @typescript-eslint/no-redeclare
import _debounce from "lodash/debounce";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Select from "react-select";
import { themeSelect } from "../../../../shared/components/Select2";
import { getCorrectLanguage } from "../../../../shared/functions/Functions";
import { Spinner } from "react-bootstrap";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import changeLocationSlice from "../../../../store/category/changeLocation";
import { getCorrectFormality } from "../../.../../../../shared/functions/Functions";
import { GlossaryService } from "../../../glossary/service";
import { EnumService } from "../../../../store/enums/enumService";
import enumsSlice from "../../../../store/enums/enumsRedux";
import "../../../../ckeditor.css";
import Chart from "react-apexcharts";
import { ContentWordsChart } from "./ContentWordsChart";
interface ICounts {
  [nameField: string]: {
    words: number;
    characters: number;
  };
}

interface IProductsActionsProps {
  allBlocks: any[];
  isTranslator?: boolean;
  handleCloseContent?: (() => void | undefined) | undefined;
  firstView?: boolean;
  setTriggerUpdate?: any;
  currentProduct: IProductsList | IProducts;
  setCurrentProduct: React.Dispatch<SetStateAction<IProductsList | undefined>>;
  handleSubmit: (event: React.FormEvent, language: string) => void;
  trigerUpdate?: boolean;
  isWorkflowUpdate?: boolean;
  selectedProductId?: number;
  translationLangTab?: string | undefined;
  glossaryShow?: boolean;
  productStaticts?: any;
}

interface IEditorCounter {
  [key: string]: ICounts;
}

const editorConfigurationCustom = {
  toolbar: {
    items: [
      "undo",
      "redo",
      "findAndReplace",
      "alignment",
      "heading",
      "textPartLanguage",
      "|",
      "bold",
      "italic",
      "underline",
      "link",
      "|",
      "horizontalLine",
      "fontColor",
      "fontBackgroundColor",
      "fontSize",
      "highlight",
      "selectAll",
      "pageBreak",
      "strikethrough",
      "specialCharacters",
      "restrictedEditingException",
      "numberedList",
      "bulletedList",
      "indent",
      "outdent",
      "removeFormat",
      "subscript",
      "superscript",
      "HtmlEmbed",
      "sourceEditing",
      "insertTable",
      "|",
      "glossary-scan",
      // "glossary-ignore",
      "glossary-change",
    ],
    shouldNotGroupWhenFull: true, // optional, to prevent grouping when the toolbar is full
  },
  table: {
    contentToolbar: [
      "tableColumn",
      "tableRow",
      "mergeTableCells",
      "tableProperties",
      "tableCellProperties",
      "toggleTableCaption",
    ],
  },
  removePlugins: ["Title", "Markdown"], // remove the title plugin from the editor
};

export const ContentViewSide = ({
  allBlocks,
  currentProduct,
  setCurrentProduct,
  firstView,
  handleSubmit,
  setTriggerUpdate,
  trigerUpdate,
  handleCloseContent,
  selectedProductId,
  isWorkflowUpdate,
  isTranslator = false,
  glossaryShow = false,
  translationLangTab,
  productStaticts = {},
}: IProductsActionsProps) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const step6 = location?.pathname?.includes("step6");
  const step5 = location?.pathname?.includes("step5");
  const isWorkflow = location?.pathname?.includes("workflow");
  const workflowData = useSelector((state: IAllStates) => state.workflow);
  const [activeTab, setActiveTab] = useState(
    currentProduct?.workflow?.sourceLang
  );
  const { id } = useParams();
  const { deeplFormality } = useSelector((state: IAllStates) => state.enums);
  const [formality, setFormality] = useState(null);
  const { state: close }: any = useLocation();
  const { translationLanguageEnum } = useSelector(
    (state: IAllStates) => state.enums
  );

  const [sourceLanguage, setSourceLanguage] = useState("");
  const [targetLanguage, setTargetLanguage] = useState("");
  const [field, setField] = useState("");
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n?.language;
  const [certainBlocks, setCertainBlocks] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingCopy, setIsLoadingCopy] = useState(false);
  const navigate = useNavigate();
  const changeActiveTab = (tab: string) => {
    setActiveTab(tab);
  };
  const [wordCount, setWordCount] = useState<IEditorCounter>();
  const currentUser = JSON.parse(localStorage.getItem("currentUser") as string);
  const updateWordsCound = _debounce(
    ({ wordsCount, charactersCount, language }, nameField) => {
      setWordCount((prev) => ({
        ...prev,
        [language]: {
          ...prev?.[language],
          [nameField]: {
            ...prev?.[language]?.[nameField],
            words: wordsCount,
            characters: charactersCount,
          },
        },
      }));
    },
    500
  );
  function handleSelectSingleFormality(
    selectedSingle: SingleValue<{ label: string; value: string }>
  ) {
    setFormality(selectedSingle?.value as any);
  }
  const clientGlossary = () => {
    if (glossaryShow && workflowData && activeTab) {
      GlossaryService.getAllGlossary({
        client: workflowData?.client?.id as number,
        language: activeTab,
        page: 1,
        perPage: 0,
      })
        .then((response) => {
          const { data } = response;
          const glossaries = data.map((glossary) => {
            return {
              [glossary.word]: glossary.alternative,
            };
          });
          const glossaryObject = glossaries.reduce((result, currentObject) => {
            return Object.assign(result, currentObject);
          }, {});
          const glossaryClientObject = { client: workflowData?.client?.name };

          const objectStorage = JSON.stringify(glossaryObject);
          const objectClient = JSON.stringify(glossaryClientObject);

          localStorage.setItem("glossary", objectStorage);
          localStorage.setItem("glossaryClient", objectClient);
        })
        .catch((error) => ErrorToast(error));
    }
  };
  const handleSelectBlock = (
    selectedValue: MultiValue<{ label: string; value: number; isAdd: boolean }>
  ) => {
    const selectedValues: number[] = [];
    selectedValue.forEach((value) => selectedValues.push(value.value));

    ProductsService.updateProductBlockById(
      { ...currentProduct, block: selectedValues } as any,
      isWorkflowUpdate ? Number(selectedProductId) : +id
    )
      .then((response) => {
        setCurrentProduct(response);
        SuccessToast(
          `${t("toasts.successfully_updated_block_of")} ${response.title}`
        );
      })
      .catch((error) => ErrorToast(error));
  };

  function handleSelectSingle(
    selectedSingle: SingleValue<{ label: string; value: string }>,
    language: string,
    fieldName: string
  ) {
    setSourceLanguage(selectedSingle?.value as string);
    setTargetLanguage(language as string);
    setField(fieldName as string);
  }

  const handleTranslate = () => {
    ProductsService.postTranslation({
      text: currentProduct?.translation?.[sourceLanguage]?.[field],
      sourceLanguage: sourceLanguage,
      targetLanguage: targetLanguage,
      formality: formality as any,
    })
      .then((response) => {
        const result = response;
        setCurrentProduct((prev: any) => ({
          ...prev,
          translation: {
            ...prev?.translation,
            [targetLanguage]: {
              ...prev?.translation?.[targetLanguage],
              [field]: result,
            },
          },
        }));
        SuccessToast(`${t("toasts.successfully_translated")}`);
      })
      .catch((error) => ErrorToast(error));
  };

  const getCertainBlocks = (block: any, listOfAllBlocks: any) => {
    const data: any[] = [];
    listOfAllBlocks?.forEach((item: any) => {
      if (block.some((a: number) => a === item.id)) {
        data.push(item);
      }
    });
    return data;
  };

  useEffect(() => {
    if (currentProduct?.id && !!allBlocks.length) {
      const certain = getCertainBlocks(currentProduct?.block as any, allBlocks);
      setCertainBlocks(certain as any);
    }
  }, [currentProduct, JSON.stringify(allBlocks)]);

  const changeHandlerEditor = (
    language: string,
    value: string,
    eventName: string
  ) => {
    setCurrentProduct((prev: any) => ({
      ...prev,
      translation: {
        ...prev?.translation,
        [language]: {
          ...prev?.translation?.[language],
          [eventName]: value,
        },
      },
    }));
  };
  const handleTranslateBlock = () => {
    ProductsService.postTranslation({
      text: currentProduct?.translation?.[sourceLanguage]?.blocks?.[field],
      sourceLanguage: sourceLanguage,
      targetLanguage: targetLanguage,
      formality: formality as any,
    })
      .then((response) => {
        const result = response;
        setCurrentProduct((prev: any) => ({
          ...prev,
          translation: {
            ...prev?.translation,
            [targetLanguage]: {
              ...prev?.translation?.[targetLanguage],
              blocks: {
                ...prev?.translation?.[targetLanguage]?.blocks,
                [field]: result,
              },
            },
          },
        }));
        SuccessToast(`${t("toasts.successfully_translated")}`);
      })
      .catch((error) => ErrorToast(error));
  };
  const changeHandlerEditorBlock = (
    value: string,
    language: string,
    blockId: number
  ) => {
    setCurrentProduct((prevState: any) => ({
      ...prevState,
      translation: {
        ...prevState?.translation,
        [language]: {
          ...prevState?.translation?.[language],
          blocks: {
            ...prevState?.translation?.[language]?.blocks,
            [blockId]: value,
          },
        },
      },
    }));
  };
  // const handleCopyVariation = () => {
  //   setIsLoadingCopy(true);
  //   id &&
  //     ProductsService.cloneProductContent(
  //       isWorkflowUpdate ? Number(selectedProductId) : +id
  //     )
  //       .then((response) => {
  //         setTriggerUpdate((prev) => !prev);
  //         window.scrollTo(0, 0);
  //       })
  //       .catch((error) => ErrorToast(error))
  //       .finally(() => setIsLoadingCopy(false));
  // };
  // const handleAiVariation = () => {
  //   setIsLoading(true);
  //   id &&
  //     ProductsService.addNewAiVaraiton(
  //       isWorkflowUpdate ? Number(selectedProductId) : +id
  //     )
  //       .then((response) => {
  //         setTriggerUpdate(true);
  //         window.scrollTo(0, 0);
  //       })
  //       .catch((error) => ErrorToast(error))
  //       .finally(() => setIsLoading(false));
  // };
  const checkShowTranslation = () => {
    return isTranslator
      ? currentProduct?.translation?.[activeTab]?.isTranslator === true
      : true;
  };

  useEffect(() => {
    if (isWorkflow) {
      setActiveTab(
        currentProduct?.workflow?.sourceLang ?? workflowData?.sourceLang
      );
    } else {
      setActiveTab(localStorage.getItem("language"));
    }
  }, [currentProduct?.workflow?.sourceLang, isWorkflow]);
  useEffect(() => {
    if (workflowData?.id && activeTab) {
      clientGlossary();
    }
  }, [activeTab]);
  const CKDescription = useCallback(
    ({
      language,
      currentProduct,
      wordCount,
      nameOfField,
      firstView,
      setWordCount,
      changeHandlerEditor,
      updateWordsCound,
    }: any) => {
      return (
        <div>
          <CKEditor
            disabled={
              firstView ||
              currentProduct?.translation?.[activeTab]?.isTranslator === false
            }
            config={editorConfigurationCustom}
            editor={Editor ?? undefined}
            data={currentProduct?.translation?.[language]?.[nameOfField] ?? ""}
            onReady={(editor) => {
              editor.ui.view.element.setAttribute(
                "id",
                String(Math.random().toString(16).slice(2))
              );
              const wordsCount = editor.plugins.get("WordCount").words;
              const charactersCount =
                editor.plugins.get("WordCount").characters;
              setWordCount((prev) => ({
                ...prev,
                [language]: {
                  ...prev?.[language],
                  [nameOfField]: {
                    ...prev?.[language]?.[nameOfField],
                    words: wordsCount,
                    characters: charactersCount,
                  },
                },
              }));
            }}
            onChange={(event, editor) => {
              dispatch(changeLocationSlice.actions.setChangeLocation(true));
              if (!firstView) {
                const data = editor.getData();
                changeHandlerEditor(language, data, [nameOfField]);
              }
              const wordsCount = editor.plugins.get("WordCount").words;
              const charactersCount =
                editor.plugins.get("WordCount").characters;
              updateWordsCound(
                {
                  wordsCount,
                  charactersCount,
                  language,
                },
                [nameOfField]
              );
            }}
          />

          <div
            className="d-flex d-flex align-items-center justify-content-between mt-3"
            key={wordCount?.[language]?.[nameOfField]?.characters}
          >
            <div className="ck ck-word-count">
              <div className="ck-word-count__words">
                {t("global.words")}:{" "}
                {wordCount?.[language]?.[nameOfField]?.words}
              </div>
              <div className="ck-word-count__characters">
                {t("global.characters")}:{" "}
                {wordCount?.[language]?.[nameOfField]?.characters}
              </div>
            </div>
            <div>
              {" "}
              {!(
                firstView ||
                currentProduct?.translation?.[activeTab]?.isTranslator === false
              ) &&
                productStaticts?.contentRequirements?.[language]?.[nameOfField]
                  ?.wordCountHigh > 0 && (
                  <ContentWordsChart
                    words={wordCount?.[language]?.[nameOfField]?.words}
                    medium={
                      productStaticts?.contentRequirements?.[language]?.[
                        nameOfField
                      ]?.wordCountMedium
                    }
                    high={
                      productStaticts?.contentRequirements?.[language]?.[
                        nameOfField
                      ]?.wordCountHigh
                    }
                  />
                )}
            </div>
          </div>
        </div>
      );
    },
    [
      currentProduct?.id,
      currentProduct?.translation?.[activeTab]?.isTranslator,
      productStaticts,
    ]
  );
  //[currentProduct?.id, currentProduct?.translation?.[activeTab]?.isTranslator]);

  const CKBlock = useCallback(
    ({ language, currentProduct, wordCount, block, firstView }: any) => {
      return (
        <>
          <CKEditor
            disabled={firstView}
            config={editorConfigurationCustom}
            editor={Editor ?? undefined}
            // data={
            //   firstView
            //     ? (currentProduct?.translation?.[language]?.blocks?.[
            //         block?.id
            //       ] as string) ?? ""
            //     : (currentProduct?.translation?.[language]?.blocks?.[
            //         block?.id
            //       ] as string) ?? ""
            // }
            data={
              (currentProduct?.translation?.[language]?.blocks?.[
                block?.id
              ] as string) ?? ""
            }
            onReady={(editor) => {
              editor.ui.view.element.setAttribute(
                "id",
                String(Math.random().toString(16).slice(2))
              );
              const wordsCount = editor.plugins.get("WordCount").words;
              const charactersCount =
                editor.plugins.get("WordCount").characters;
              setWordCount((prev) => ({
                ...prev,
                [language]: {
                  ...prev?.[language],
                  [block.name]: {
                    ...prev?.[language]?.[block.name],
                    words: wordsCount,
                    characters: charactersCount,
                  },
                },
              }));
            }}
            onChange={(event, editor) => {
              const data = editor.getData();
              dispatch(changeLocationSlice.actions.setChangeLocation(true));
              changeHandlerEditorBlock(
                data,
                language as string,
                // "block",
                block?.id as number
              );
              const wordsCount = editor.plugins.get("WordCount").words;
              const charactersCount =
                editor.plugins.get("WordCount").characters;
              updateWordsCound(
                {
                  wordsCount,
                  charactersCount,
                  language,
                },
                block.name
              );
            }}
          />
          <div className="d-flex justify-content-start text-align-center mt-3">
            <div className="ck ck-word-count">
              <div className="ck-word-count__words">
                {t("global.words")}:{" "}
                {wordCount?.[language]?.[block.name]?.words}
              </div>
              <div className="ck-word-count__characters">
                {t("global.characters")}:{" "}
                {wordCount?.[language]?.[block.name]?.characters}
              </div>
            </div>
          </div>
        </>
      );
    },
    [currentProduct?.id]
  );
  //[currentProduct?.id, activeTab]
  useEffect(() => {
    EnumService.getEnums().then((data) => {
      dispatch(enumsSlice.actions.setEnums(data));
    });
  }, []);
  return (
    <>
      <div className="card">
        <div className="card-body">
          <ul
            className="nav nav-tabs-custom rounded card-header-tabs border-bottom-0"
            role="tablist"
          >
            {translationLanguageEnum?.map((language) => {
              return (
                <li
                  className="nav-item"
                  key={language}
                  onClick={() => changeActiveTab(language)}
                >
                  <a
                    className={`nav-link ${
                      activeTab === language ? "active" : ""
                    }`}
                    data-bs-toggle="tab"
                    href={`#${language}`}
                    role="tab"
                    aria-selected={language === currentLanguage}
                  >
                    {t(`products.content_${language}`)}
                  </a>
                </li>
              );
            })}
          </ul>
          <div className="tab-content">
            {translationLanguageEnum?.map((language) => {
              return (
                <div
                  className={`tab-pane  ${
                    activeTab === language ? "active" : ""
                  }`}
                  id={language}
                  key={language}
                  role="tabpanel"
                >
                  <div className="row mt-4">
                    <div className="col-md-12">
                      <div className="d-flex justify-content-between align-items-center my-1 translation-height">
                        <label className="m-0" htmlFor="description">
                          {t("products.description")}
                        </label>
                        <div className="d-flex justify-content-end align-items-center my-1">
                          {!firstView && checkShowTranslation() && (
                            <>
                              <Select
                                className="custom_zindex react-select-field me-2"
                                name="content"
                                theme={themeSelect}
                                placeholder={`${t(
                                  "formality.select_formality"
                                )}`}
                                options={deeplFormality?.map((formality) => ({
                                  value: formality,
                                  label: getCorrectFormality(formality, t),
                                }))}
                                // key={state?.id}
                                onChange={(e) => {
                                  handleSelectSingleFormality(e as any);
                                }}
                              />

                              <div className="">
                                <Select
                                  className="custom_zindex react-select-field"
                                  name="description"
                                  theme={themeSelect}
                                  placeholder="Translate from"
                                  options={translationLanguageEnum
                                    ?.filter((lang) => lang !== language)
                                    ?.map((lang) => ({
                                      value: lang,
                                      label: getCorrectLanguage(lang, t),
                                    }))}
                                  onChange={(e) => {
                                    handleSelectSingle(
                                      e as any,
                                      language as string,
                                      "description"
                                    );
                                  }}
                                />
                              </div>
                              <button
                                className="btn btn-secondary ms-3"
                                onClick={handleTranslate}
                              >
                                {t("global.translate")}
                              </button>
                            </>
                          )}
                        </div>
                      </div>
                      {/* {currentProduct?.id &&  */}
                      <div>
                        <>
                          <CKEditor
                            config={editorConfigurationCustom}
                            disabled={
                              firstView ||
                              currentProduct?.translation?.[activeTab]
                                ?.isTranslator === false
                            }
                            editor={Editor}
                            data={
                              currentProduct?.translation?.[language]
                                ?.description ?? ""
                            }
                            onReady={(editor) => {
                              setTimeout(() => {
                                editor.ui.view.element.setAttribute(
                                  "id",
                                  String(Math.random().toString(16).slice(2))
                                );
                                const wordsCount =
                                  editor.plugins.get("WordCount").words;
                                const charactersCount =
                                  editor.plugins.get("WordCount").characters;
                                setWordCount((prev) => ({
                                  ...prev,
                                  [language]: {
                                    ...prev?.[language],
                                    description: {
                                      ...prev?.[language]?.["description"],
                                      words: wordsCount,
                                      characters: charactersCount,
                                    },
                                  },
                                }));
                              }, 500);
                            }}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              changeHandlerEditor(
                                language,
                                data,
                                "description"
                              );
                              const wordsCount =
                                editor.plugins.get("WordCount").words;
                              const charactersCount =
                                editor.plugins.get("WordCount").characters;
                              updateWordsCound(
                                {
                                  wordsCount,
                                  charactersCount,
                                  language,
                                },
                                "description"
                              );
                            }}
                          />
                          <div className="d-flex align-items-center justify-content-between  mt-3">
                            <div className="ck ck-word-count">
                              <div className="ck-word-count__words">
                                {t("global.words")}:
                                {wordCount?.[language]?.["description"]?.words}
                              </div>
                              <div className="ck-word-count__characters">
                                Characters:{" "}
                                {
                                  wordCount?.[language]?.["description"]
                                    ?.characters
                                }
                              </div>
                            </div>
                            <div>
                              {productStaticts?.contentRequirements?.[
                                language
                              ]?.["description"]?.wordCountHigh > 0 && (
                                <ContentWordsChart
                                  words={
                                    wordCount?.[language]?.["description"]
                                      ?.words
                                  }
                                  medium={
                                    productStaticts?.contentRequirements?.[
                                      language
                                    ]?.["description"]?.wordCountMedium
                                  }
                                  high={
                                    productStaticts?.contentRequirements?.[
                                      language
                                    ]?.["description"]?.wordCountHigh
                                  }
                                />
                              )}
                            </div>
                          </div>
                        </>
                      </div>
                    </div>
                    <div className="col-md-12 mt-3">
                      <>
                        <div className="d-flex justify-content-between align-items-center my-1 translation-height">
                          <label className="m-0" htmlFor="introText">
                            {t("products.introText")}
                          </label>
                          <div className="d-flex justify-content-end align-items-center my-1">
                            {!firstView && checkShowTranslation() && (
                              <>
                                {" "}
                                <Select
                                  className="custom_zindex react-select-field me-2"
                                  name="content"
                                  theme={themeSelect}
                                  placeholder={`${t(
                                    "formality.select_formality"
                                  )}`}
                                  options={deeplFormality?.map((formality) => ({
                                    value: formality,
                                    label: getCorrectFormality(formality, t),
                                  }))}
                                  // key={state?.id}
                                  onChange={(e) => {
                                    handleSelectSingleFormality(e as any);
                                  }}
                                />
                                <div className="">
                                  <Select
                                    className="custom_zindex react-select-field"
                                    name="introText"
                                    theme={themeSelect}
                                    placeholder="Translate from"
                                    options={translationLanguageEnum
                                      ?.filter((lang) => lang !== language)
                                      ?.map((lang) => ({
                                        value: lang,
                                        label: getCorrectLanguage(lang, t),
                                      }))}
                                    onChange={(e) => {
                                      handleSelectSingle(
                                        e as any,
                                        language as string,
                                        "introText"
                                      );
                                    }}
                                  />
                                </div>
                                <button
                                  className="btn btn-secondary ms-3"
                                  onClick={handleTranslate}
                                >
                                  {t("global.translate")}
                                </button>
                              </>
                            )}
                          </div>
                        </div>
                      </>
                      <>
                        <CKEditor
                          config={editorConfigurationCustom}
                          editor={Editor}
                          disabled={
                            firstView ||
                            currentProduct?.translation?.[activeTab]
                              ?.isTranslator === false
                          }
                          data={
                            currentProduct?.translation?.[language]
                              ?.introText ?? ""
                          }
                          onReady={(editor) => {
                            setTimeout(() => {
                              editor.ui.view.element.setAttribute(
                                "id",
                                String(Math.random().toString(16).slice(2))
                              );
                              const wordsCount =
                                editor.plugins.get("WordCount").words;
                              const charactersCount =
                                editor.plugins.get("WordCount").characters;
                              setWordCount((prev) => ({
                                ...prev,
                                [language]: {
                                  ...prev?.[language],
                                  introText: {
                                    ...prev?.[language]?.["introText"],
                                    words: wordsCount,
                                    characters: charactersCount,
                                  },
                                },
                              }));
                            }, 500);
                          }}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            changeHandlerEditor(language, data, "introText");
                            const wordsCount =
                              editor.plugins.get("WordCount").words;
                            const charactersCount =
                              editor.plugins.get("WordCount").characters;
                            updateWordsCound(
                              {
                                wordsCount,
                                charactersCount,
                                language,
                              },
                              "introText"
                            );
                          }}
                        />
                        <div className="d-flex align-items-center justify-content-between  mt-3">
                          <div className="ck ck-word-count">
                            <div className="ck-word-count__words">
                              {t("global.words")}:
                              {wordCount?.[language]?.["introText"]?.words}
                            </div>
                            <div className="ck-word-count__characters">
                              Characters:{" "}
                              {wordCount?.[language]?.["introText"]?.characters}
                            </div>
                          </div>
                          <div>
                            {productStaticts?.contentRequirements?.[language]?.[
                              "introText"
                            ]?.wordCountHigh > 0 && (
                              <ContentWordsChart
                                words={
                                  wordCount?.[language]?.["introText"]?.words
                                }
                                medium={
                                  productStaticts?.contentRequirements?.[
                                    language
                                  ]?.["introText"]?.wordCountMedium
                                }
                                high={
                                  productStaticts?.contentRequirements?.[
                                    language
                                  ]?.["introText"]?.wordCountHigh
                                }
                              />
                            )}
                          </div>
                        </div>
                      </>
                      {/* } */}
                    </div>
                    <div className="col-md-12 mt-3">
                      <>
                        <div className="d-flex justify-content-between align-items-center my-1 translation-height">
                          <label className="m-0" htmlFor="bullets">
                            {t("products.bullets")}
                          </label>
                          <div className="d-flex justify-content-end align-items-center my-1">
                            {!firstView && checkShowTranslation() && (
                              <>
                                {" "}
                                <Select
                                  className="custom_zindex react-select-field me-2"
                                  name="content"
                                  theme={themeSelect}
                                  placeholder={`${t(
                                    "formality.select_formality"
                                  )}`}
                                  options={deeplFormality?.map((formality) => ({
                                    value: formality,
                                    label: getCorrectFormality(formality, t),
                                  }))}
                                  // key={state?.id}
                                  onChange={(e) => {
                                    handleSelectSingleFormality(e as any);
                                  }}
                                />
                                <div className="">
                                  <Select
                                    className="custom_zindex react-select-field"
                                    name="bullets"
                                    theme={themeSelect}
                                    placeholder="Translate from"
                                    options={translationLanguageEnum
                                      ?.filter((lang) => lang !== language)
                                      ?.map((lang) => ({
                                        value: lang,
                                        label: getCorrectLanguage(lang, t),
                                      }))}
                                    onChange={(e) => {
                                      handleSelectSingle(
                                        e as any,
                                        language as string,
                                        "bullets"
                                      );
                                    }}
                                  />
                                </div>
                                <button
                                  className="btn btn-secondary ms-3"
                                  onClick={handleTranslate}
                                >
                                  {t("global.translate")}
                                </button>
                              </>
                            )}
                          </div>
                        </div>
                      </>
                      <>
                        <CKEditor
                          data={
                            currentProduct?.translation?.[language]?.bullets ??
                            ""
                          }
                          config={editorConfigurationCustom}
                          editor={Editor}
                          disabled={
                            firstView ||
                            currentProduct?.translation?.[activeTab]
                              ?.isTranslator === false
                          }
                          onReady={(editor) => {
                            setTimeout(() => {
                              // Postavljanje ID-a za editor element
                              editor.ui.view.element.setAttribute(
                                "id",
                                String(Math.random().toString(16).slice(2))
                              );

                              // Provera i uzimanje broja reči i karaktera sa WordCount plugina
                              const wordCountPlugin =
                                editor.plugins.get("WordCount");
                              if (wordCountPlugin) {
                                const wordsCount = wordCountPlugin.words;
                                const charactersCount =
                                  wordCountPlugin.characters;

                                // Ažuriranje stanja sa novim brojem reči i karaktera
                                setWordCount((prev) => ({
                                  ...prev,
                                  [language]: {
                                    ...prev?.[language],
                                    bullets: {
                                      ...prev?.[language]?.["bullets"],
                                      words: wordsCount,
                                      characters: charactersCount,
                                    },
                                  },
                                }));
                              } else {
                                console.error("WordCount plugin nije učitan");
                              }
                            }, 500);
                          }}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            changeHandlerEditor(language, data, "bullets");
                            const wordsCount =
                              editor.plugins.get("WordCount").words;
                            const charactersCount =
                              editor.plugins.get("WordCount").characters;
                            updateWordsCound(
                              {
                                wordsCount,
                                charactersCount,
                                language,
                              },
                              "bullets"
                            );
                          }}
                        />
                        <div className="d-flex align-items-center justify-content-between  mt-3">
                          <div className="ck ck-word-count">
                            <div className="ck-word-count__words">
                              {t("global.words")}:
                              {wordCount?.[language]?.["bullets"]?.words}
                            </div>
                            <div className="ck-word-count__characters">
                              Characters:{" "}
                              {wordCount?.[language]?.["bullets"]?.characters}
                            </div>
                          </div>
                          <div>
                            {productStaticts?.contentRequirements?.[language]?.[
                              "bullets"
                            ]?.wordCountHigh > 0 && (
                              <ContentWordsChart
                                words={
                                  wordCount?.[language]?.["bullets"]?.words
                                }
                                medium={
                                  productStaticts?.contentRequirements?.[
                                    language
                                  ]?.["bullets"]?.wordCountMedium
                                }
                                high={
                                  productStaticts?.contentRequirements?.[
                                    language
                                  ]?.["bullets"]?.wordCountHigh
                                }
                              />
                            )}
                          </div>
                        </div>
                      </>
                      {/* // } */}
                    </div>
                    {!!certainBlocks?.length &&
                      certainBlocks?.map((block: any) => (
                        <div className="col-md-12 mt-3" key={block?.id}>
                          <div className="d-flex justify-content-between align-items-center my-1 translation-height">
                            <label htmlFor="value">{block?.name}</label>
                            <div className="d-flex justify-content-end align-items-center my-1">
                              {!firstView && checkShowTranslation() && (
                                <>
                                  <Select
                                    className="custom_zindex react-select-field me-2"
                                    name="content"
                                    theme={themeSelect}
                                    placeholder={`${t(
                                      "formality.select_formality"
                                    )}`}
                                    options={deeplFormality?.map(
                                      (formality) => ({
                                        value: formality,
                                        label: getCorrectFormality(
                                          formality,
                                          t
                                        ),
                                      })
                                    )}
                                    // key={state?.id}
                                    onChange={(e) => {
                                      handleSelectSingleFormality(e as any);
                                    }}
                                  />
                                  <div className="">
                                    <Select
                                      className="custom_zindex react-select-field"
                                      name={block?.name}
                                      theme={themeSelect}
                                      placeholder="Translate from"
                                      options={translationLanguageEnum
                                        .filter((lang) => lang !== language)
                                        ?.map((lang) => ({
                                          value: lang,
                                          label: getCorrectLanguage(lang, t),
                                        }))}
                                      // key={state?.id}
                                      onChange={(e) => {
                                        handleSelectSingle(
                                          e as any,
                                          language as string,
                                          block?.id
                                        );
                                      }}
                                    />
                                  </div>
                                  <button
                                    className="btn btn-secondary ms-3"
                                    onClick={handleTranslateBlock}
                                  >
                                    {t("global.translate")}
                                  </button>
                                </>
                              )}
                            </div>
                          </div>
                          <>
                            <CKBlock
                              currentProduct={currentProduct}
                              wordCount={wordCount}
                              language={language}
                              block={block}
                              firstView={firstView}
                            />
                          </>
                        </div>
                      ))}
                    {!firstView && (
                      <div className="d-flex justify-content-end mt-2">
                        {/* <button
                                                    className="btn btn-info"
                                                    onClick={() => backHandle()}
                                                >
                                                    {t('products.close')}
                                                </button> */}
                        {/* {!isTranslator && (
                          <>
                            <button
                              className="btn btn-secondary ms-2"
                              onClick={(e) => handleCopyVariation()}
                            >
                              {isLoadingCopy ? (
                                <Spinner
                                  animation="border"
                                  size="sm"
                                  role="status"
                                />
                              ) : (
                                // <span>{t("products.ai_variation")}</span>
                                <span>Copy As Variation</span>
                              )}
                            </button>
                            <button
                              className="btn btn-success ms-2"
                              onClick={(e) => handleAiVariation()}
                            >
                              {isLoading ? (
                                <Spinner
                                  animation="border"
                                  size="sm"
                                  role="status"
                                />
                              ) : (
                                <span>{t("products.ai_variation")}</span>
                              )}
                            </button>
                          </>
                        )} */}
                        {currentProduct?.translation?.[activeTab]
                          ?.isTranslator === false ? (
                          ""
                        ) : (
                          <>
                            {id && (
                              <button
                                className="btn btn-secondary ms-2"
                                onClick={(e) => handleSubmit(e, language)}
                              >
                                {t("products.save")}
                              </button>
                            )}{" "}
                            {id && isWorkflow && (
                              <button
                                className="btn btn-primary ms-2"
                                onClick={(e) => handleSubmit(e, language, true)}
                              >
                                {t("products.next")}
                              </button>
                            )}
                          </>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      {!isTranslator && (
        <div className="card">
          <div className="card-body">
            <div className="row">
              <>
                {!!allBlocks?.length && (
                  <div className="col-md-12 my-3">
                    <label htmlFor="blocks">{t("products.blocks")}</label>
                    <Select
                      isDisabled={firstView}
                      className="react-select-field"
                      theme={themeSelect}
                      value={certainBlocks?.map(
                        (certainValue: { name: string; id: number }) => ({
                          label: certainValue?.name,
                          value: certainValue?.id,
                        })
                      )}
                      options={allBlocks?.map(
                        (value: { name: string; id: number }) => ({
                          label: value?.name,
                          value: value?.id,
                        })
                      )}
                      isMulti
                      onChange={(e) => {
                        handleSelectBlock(
                          e as MultiValue<{
                            label: string;
                            value: number;
                            isAdd: boolean;
                          }>
                        );
                      }}
                      key={currentProduct?.id}
                    />
                  </div>
                )}
              </>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
