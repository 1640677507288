import { BaseModel } from "../../model/shared/BaseModel";
import { requests } from "../../utils/helpers/api.services";
import { DATA_ENRICHMENT } from "../../utils/helpers/api.routes";

export const DataEnrichmentService = {
  getDataEnrichment: (): Promise<BaseModel<any>> =>
    requests.get(`${DATA_ENRICHMENT}`),
  updateDataEnrichment: (params: any) =>
    requests.put(`${DATA_ENRICHMENT}`, params),
};
