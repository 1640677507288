import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { WorkflowList } from "./components/WorkflowList";
import { WorkflowStep1 } from "./components/WorkflowStep1";
import { useTranslation } from "react-i18next";
import { WorkflowCreate } from "./components/WorkflowCreate";
import { ErrorToast, SuccessToast } from "../../shared/toasters/toasters";
import { WorkflowService } from "./service";
import { IWorkflow } from "./dto/IWorkflow";
import { UserService } from "../users/service";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { IAllStates } from "../../store/rootReducer";
import { handleStepNavigate } from "./components/WorkflowStep2";
import { WorkflowUnfinishedList } from "./components/WorkflowUnfinishedList";
import { CategoryServicee } from "../../store/category/categoryService";
import { useDispatch } from "react-redux";
import categorySlice from "../../store/category/categoryRedux";
import step3PaginationSlice from "../../store/workflow/step3PaginationSlice";
import step4PaginationSlice from "../../store/workflow/step4PaginationSlice";
import step6PaginationSlice from "../../store/workflow/step6PaginationSlice";
import step5PaginationSlice from "../../store/workflow/step5PaginationSlice";
import axios from "axios";
const initialPagination = {
  page: 1,
  perPage: 10,
  client: null,
  name: null,
  workflowStatus: undefined,
  fromDeadline: null,
  toDeadline: null,
};
export const Workflow = () => {
  const navigate = useNavigate();
  const [createField, setCreateField] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  dispatch(step3PaginationSlice.actions.setStep3Pagination({}));
  dispatch(step4PaginationSlice.actions.setStep4Pagination({}));
  dispatch(step5PaginationSlice.actions.setStep5Pagination({}));
  dispatch(step6PaginationSlice.actions.setStep6Pagination({}));
  const [completeTab, setCompleteTab] = useState(false);
  const { workflowStatus } = useSelector((state: IAllStates) => state.enums);
  const [firstStap, setFirstStap] = useState<IWorkflow | undefined>();
  const [workflowList, setWorkflowList] = useState<IWorkflow[] | undefined>();
  const { accessRights } = useSelector((state: IAllStates) => state.auth);
  const [completedList, setCompletedList] = useState<IWorkflow[] | undefined>();
  const [currentWorkflow, setCurrentWorkflow] = useState<
    IWorkflow | undefined
  >();
  const currentUser = JSON.parse(localStorage.getItem("currentUser") as string);
  const isManager = currentUser?.roles?.includes("ROLE_MANAGER");
  const isAdmin = currentUser?.roles?.includes("ROLE_ADMIN");
  const isCompanyAdmin = currentUser?.roles?.includes("ROLE_COMPANY_ADMIN");
  const [pagination, setPagination] = useState(initialPagination);
  const [name, setName] = useState("");
  const [completeName, setCompleteName] = useState("");
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const handlePages = (updatePage: number) => {
    setPagination({ ...pagination, page: updatePage });
    setPage(updatePage);
  };
  const [paginationCompleted, setPaginationCompleted] = useState({
    page: 1,
    perPage: 10,
    client: null,
    name: null,
    workflowStatus: undefined,
    fromDeadline: null,
    toDeadline: null,
  });
  const [pageCompleted, setPageCompleted] = useState(1);
  const [totalPagesCompleted, setTotalPagesCompleted] = useState(0);

  const handlePagesCompleted = (updatePage: number) => {
    setPaginationCompleted({ ...paginationCompleted, page: updatePage });
    setPageCompleted(updatePage);
  };

  const [triggerUpdate, setTriggerUpdate] = useState(false);

  const handlePaginationStatus = (obj: any) => {
    if (!completeTab) {
      setPagination((prev) => ({
        ...prev,
        page: 1,
        workflowStatus: obj?.value,
      }));
    } else {
      setPaginationCompleted((prev) => ({
        ...prev,
        page: 1,
        workflowStatus: obj?.value,
      }));
    }
  };

  const handlePaginationClient = (obj: any) => {
    if (!completeTab) {
      setPagination(
        (prev) =>
          ({
            ...prev,
            page: 1,
            client: {
              id: obj?.value as number,
              name: obj?.label as string,
            },
          } as any)
      );
    } else {
      setPaginationCompleted(
        (prev) =>
          ({
            ...prev,
            page: 1,
            client: {
              id: obj?.value as number,
              name: obj?.label as string,
            },
          } as any)
      );
    }
  };

  const handlePaginationName = (e: any) => {
    const { value, name } = e.target;

    if (!completeTab) {
      setName((prev: any) => ({
        ...prev,
        value,
      }));
    } else {
      setCompleteName((prev: any) => ({
        ...prev,
        value,
      }));
    }
  };

  const handlePaginationFromDate = (e: any) => {
    if (!completeTab) {
      setPagination((prev) => ({
        ...prev,
        page: 1,
        fromDeadline: e,
      }));
    } else {
      setPaginationCompleted((prev) => ({
        ...prev,
        page: 1,
        fromDeadline: e,
      }));
    }
  };

  const handlePaginationToDate = (e: any) => {
    if (!completeTab) {
      setPagination((prev) => ({
        ...prev,
        page: 1,
        toDeadline: e,
      }));
    } else {
      setPaginationCompleted((prev) => ({
        ...prev,
        page: 1,
        toDeadline: e,
      }));
    }
  };

  const submitHandler = (event: React.FormEvent) => {
    event.preventDefault();

    if (currentWorkflow?.id) {
      WorkflowService.updateWorkflowById(
        currentWorkflow?.id,
        currentWorkflow as unknown as IWorkflow
      )
        .then((response) => {
          if (response?.id) {
            SuccessToast(
              `${t("toasts.successfully_updated")} ${response.name}`
            );
          }
        })
        .catch((error) => ErrorToast(error));
    } else {
      WorkflowService.createWorkflow({
        ...(firstStap as any),
        deadlineDate: (firstStap?.deadlineData as any)
          ?.toISOString()
          ?.split("T")[0],
      })
        .then((response) => {
          if (response?.id) {
            SuccessToast("Success create workflow");
            setCreateField(false);
            setFirstStap(undefined);
          }
        })
        .catch((error) => ErrorToast(error));
    }
  };

  const proceedHandler = (
    workflowId: number,
    status: string,
    tabs: any,
    e: any
  ) => {
    const linkStatus = tabs?.some((tab: string) => tab === status);
    if (e.type === "click") {
      handleStepNavigate(
        linkStatus === true ? status : tabs[0],
        Number(workflowId),
        navigate
      );
    } else {
      switch (status) {
        case "SETTING_UP":
          return window.open(`/app/workflow/step1/${workflowId}`, "_blank");
        case "IMPORT":
          return window.open(`/app/workflow/step2/${workflowId}`, "_blank");
        case "BASE_DATA":
          return window.open(`/app/workflow/step3/${workflowId}`, "_blank");
        case "ATTRIBUTES":
          return window.open(`/app/workflow/step4/${workflowId}`, "_blank");
        case "COPY_WRITING":
          return window.open(`/app/workflow/step5/${workflowId}`, "_blank");
        case "TRANSLATION":
          return window.open(`/app/workflow/step6/${workflowId}`, "_blank");
        case "OVERVIEW":
          return window.open(`/app/workflow/step7/${workflowId}`, "_blank");
        case "EXPORT":
          return window.open(`/app/workflow/step8/${workflowId}`, "_blank");
        default:
          return window.open(`/app/workflow/step1/${workflowId}`, "_blank");
      }
    }
  };

  useEffect(() => {
    // WorkflowService.getAllWorkflows({
    //   ...pagination,
    //   client: (pagination as any)?.client?.id,
    // })
    //   .then((response) => {
    //     console.log(completeTab);
    //     const { data } = response;
    //     !completeTab && setPaginationCompleted(initialPagination);
    //     setTotalPages(Math.ceil(response?.count / response?.perPage));
    //     setPage(response?.page);
    //     setWorkflowList(data);
    //   })
    //   .catch((error) => ErrorToast(error));
  }, [pagination, triggerUpdate, createField, completeTab]);

  useEffect(() => {
    setName("");
    setCompleteName("");
  }, [completeTab]);
  useEffect(() => {
    if (completeTab) {
      WorkflowService.getAllWorkflows({
        ...paginationCompleted,
        client: (paginationCompleted as any)?.client?.id,
        workflowStatus: "COMPLETED",
        name: (completeName as any)?.value ?? "",
      })
        .then((response) => {
          setPagination(initialPagination);
          const { data } = response;
          setTotalPagesCompleted(
            Math.ceil(response?.count / response?.perPage)
          );
          setCompletedList(data);
          setPageCompleted(response?.page);
        })
        .catch((error) => ErrorToast(error));
    } else {
      WorkflowService.getAllWorkflows({
        ...pagination,
        client: (pagination as any)?.client?.id,
        name: (name as any)?.value ?? "",
      })
        .then((response) => {
          const { data } = response;
          !completeTab && setPaginationCompleted(initialPagination);
          setTotalPages(Math.ceil(response?.count / response?.perPage));
          setPage(response?.page);
          setWorkflowList(data);
        })
        .catch((error) => ErrorToast(error));
    }
  }, [
    pagination,
    paginationCompleted,
    triggerUpdate,
    createField,
    completeTab,
    name,
    completeName,
  ]);
  useEffect(() => {
    CategoryServicee.getAllCategories()
      .then((data) => {
        dispatch(categorySlice.actions.setCategory(data));
      })
      .catch((error) => ErrorToast(error));
  }, []);
  const [eanCode, setEanCode] = useState("");
  const [results, setResults] = useState("");

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    try {
      const params = JSON.stringify({
        query: "HP Laptop 250 G9 15 N4500 8G256, 6S798EA na stranici ananas.ba",
        pages: 2,
      });

      const response = await axios.get(
        `http://localhost:4000/api/admin/google-search/crawl`,
        {
          params: { params }, // Prosledi ceo objekat unutar jednog parametra
        }
      );

      console.log("Response data:", response.data); // Loguj odgovor
      setResults(response.data);
    } catch (error) {
      console.error("Error fetching data:", error); // Loguj grešku
    }
  };
  return (
    <>
      {accessRights?.includes("workflow_can_create") && !createField && (
        <div className="card ">
          <div className="card-body d-flex align-items-center justify-content-between">
            <h4 className="card-title mg-b-0">
              {/* {t("clients.createClient")} */}
              {t("workflow.new_workflow")}
            </h4>
            <button
              className="btn btn-primary"
              onClick={() => setCreateField(true)}
            >
              {t("global.add")}
            </button>
          </div>
        </div>
      )}
      {/* <div>
        <form onSubmit={handleSubmit} className="my-3">
          <div className="col-md-4 d-flex justify-content-start">
            <input
              className="form-control"
              type="text"
              value={eanCode}
              onChange={(e) => setEanCode(e.target.value)}
              placeholder="Type Title or EAN code"
            />
            <button className="ms-2 btn btn-primary" type="submit">
              Submit
            </button>
          </div>
        </form>
        <div> */}
      {/* <h3>Rezultati:</h3>
          {results && (
            <div
              dangerouslySetInnerHTML={{
                __html: Object.values(results) as unknown as string,
              }}
            />
          )} */}
      {/* </div>
      </div> */}
      {createField && (
        <Form onSubmit={submitHandler}>
          <div className="card ">
            <div className="card-body">
              <WorkflowCreate
                firstStap={firstStap}
                setFirstStap={setFirstStap}
                workflowStatus={workflowStatus}
              />
              <div className="mt-2 d-flex justify-content-end">
                <button
                  className="btn btn-info me-2"
                  onClick={() => {
                    setCreateField(false);
                    // setCurrentClient({} as any);
                  }}
                >
                  {t("global.cancel")}
                </button>
                <button className="btn btn-primary" type="submit">
                  {/* {currentClient?.id
                    ? `${t("global.update")}`
                    : `${t("global.create")}`} */}
                  {t("global.create")}
                </button>
              </div>
            </div>
          </div>
        </Form>
      )}
      {(isAdmin || isCompanyAdmin || isManager) && <WorkflowUnfinishedList />}
      <WorkflowList
        completeTab={completeTab}
        setCompleteTab={setCompleteTab}
        isAdmin={isAdmin}
        isCompanyAdmin={isCompanyAdmin}
        isManager={isManager}
        accessRights={accessRights}
        list={workflowList as IWorkflow[] | undefined}
        //  changeFilterHandler={changeFilterHandler}
        proceedHandler={proceedHandler}
        setPagination={setPagination}
        page={page}
        totalPages={totalPages}
        setPaginationCompleted={setPaginationCompleted}
        pageCompleted={pageCompleted}
        totalPagesCompleted={totalPagesCompleted}
        handlePaginationStatus={handlePaginationStatus}
        handlePages={handlePages}
        handlePagesCompleted={handlePagesCompleted}
        setTriggerUpdate={setTriggerUpdate}
        havePagination={true}
        pagination={pagination}
        paginationCompleted={paginationCompleted}
        handlePaginationClient={handlePaginationClient}
        handlePaginationFromDate={handlePaginationFromDate}
        handlePaginationToDate={handlePaginationToDate}
        handlePaginationName={handlePaginationName}
        haveFilters={true}
        haveTabs={true}
        completedList={completedList}
        completeName={completeName}
        name={name}
      />
    </>
  );
};
