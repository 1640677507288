import React, { useEffect, useState } from "react";
import { EditCopywrite } from "./EditCopywrite";
import { useParams } from "react-router";
import { ContentWorkflowService } from "../service";
import { ErrorToast } from "../../../shared/toasters/toasters";
import { ContentStepper } from "./ContentStepper";
import { t } from "i18next";
import { IWorkflow } from "../../workflow/dto/IWorkflow";
interface IProgressData {
  done: number;
  total: number;
  percent: number;
}
interface IProgressBar {
  assigment: IProgressData;
  translation: IProgressData;
}
export const ContentStep4Edit = () => {
  const [currentContent, setCurrentContent] = useState({});
  const [contentCopywrite, setContentCopywrite] = useState({} as any);
  const [updateCopywriteList, setUpdateCopywriteList] = useState(false);
  const [currentSourceLang, setCurrentSourceLang] = useState("");
  const [defaultTabDisabled, setDefaultTabDisabled] = useState("");
  const { id, contentId } = useParams();
  const [isTranslator, setIsTranslator] = useState(false);
  const [isTranslationDone, setIsTranslationDone] = useState(false);
  const [triggerUpdateData, setTriggerUpdateData] = useState(false);
  const [progressBarData, setProgressBarData] = useState<any | undefined>(
    undefined
  );
  const [staticsView2, setStaticsView2] = useState<any[]>([]);
  const currentUser = JSON.parse(localStorage.getItem("currentUser") as string);
  const isManager = currentUser?.roles.includes("ROLE_MANAGER");
  const isAdmin = currentUser?.roles.includes("ROLE_ADMIN");
  const isCompanyAdmin = currentUser?.roles.includes("ROLE_COMPANY_ADMIN");
  const [currentWorkflow, setCurrentWorkflow] = useState<any | null>(null);
  const progressArr = ["assigment", "translated"];
  useEffect(() => {
    id &&
      contentId &&
      ContentWorkflowService.getTranslationById(+id!, +contentId!)
        .then((res) => {
          setContentCopywrite(res);
        })
        .catch((err) => ErrorToast(err));
  }, []);
  useEffect(() => {
    ContentWorkflowService.getAllTranslation(
      {
        page: 1,
        perPage: 0,
        lang: localStorage.getItem("contentTranslationLanguage"),
      },
      +id!
    )
      .then((response: any) => {
        const {
          currentLanguage,
          workflow,
          isTranslationDone,
          statistics,
          view,
        }: any = response;
        // console.log(response, "workflow?.sourceLang");
        setDefaultTabDisabled(workflow?.sourceLang);
        setCurrentSourceLang(currentLanguage);
        setIsTranslationDone(isTranslationDone);
        setProgressBarData(statistics);
        setCurrentWorkflow(workflow as IWorkflow);
        setStaticsView2(response?.statistics ?? []);
        setIsTranslator(+view !== 1);
      })
      .catch((error) => ErrorToast(error));
  }, [triggerUpdateData, localStorage.getItem("contentTranslationLanguage")]);

  return (
    <div className="col-12">
      <div className="card">
        <div className="card-body">
          <ContentStepper
            isDisabledComplete={isTranslationDone}
            step={"TRANSLATION"}
            // handleFilterCopywritingStatus={
            //   handleFilterCopywritingStatus
            // }
            id={id}
            copywriteId={contentId}
            // changeFilterHandler={changeFilterHandler}
            precent={progressBarData?.["translation"]?.percent}
            showNextButton={isTranslator}
            showMarkAsComplete={
              currentWorkflow?.status === "TRANSLATION" &&
              (isAdmin || isCompanyAdmin || isManager)
            }
            // status={pagination?.status}
            assigned={!isTranslator}
            // setSelectedProductId={
            //   !contentId && setSelectedProductId
            // }
            //   handlePerPage={handlePerPage}
          />
          {progressArr?.map((data: any) => {
            if (data === "assigment" && isTranslator) {
              return null;
            }
            if (!isTranslator) {
              return (
                <div
                  className="card bg-light overflow-hidden shadow-none"
                  key={data}
                >
                  <div className="card-body">
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <h6 className="mb-0">
                          {t("workflow.current")} {data}:
                          <b className="text-success">
                            {" "}
                            {
                              progressBarData?.[data as keyof IProgressBar]
                                ?.percent
                            }
                            %
                          </b>
                        </h6>
                      </div>
                      <div className="flex-shrink-0">
                        <h6 className="mb-0">
                          {progressBarData?.[data as keyof IProgressBar]?.done}/
                          {progressBarData?.[data as keyof IProgressBar]?.total}{" "}
                          {t("workflow.finished")}
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div className="progress">
                    {/*//@ts-ignore*/}
                    <div
                      className="progress-bar bg-success"
                      style={{
                        width: `${
                          progressBarData?.[data as keyof IProgressBar]
                            ?.percent ?? 0
                        }%`,
                      }}
                      aria-valuenow={
                        progressBarData?.[data as keyof IProgressBar]
                          ?.percent as number
                      }
                      aria-valuemin="0"
                      aria-valuemax="100"
                    />
                  </div>
                </div>
              );
            }
          })}
          {isTranslator &&
            progressBarData?.map((data: any) => (
              <div
                className="card bg-light overflow-hidden shadow-none"
                key={data?.lang}
              >
                <div className="card-body">
                  <div className="d-flex">
                    <div className="flex-grow-1">
                      <h6 className="mb-0">
                        {t("workflow.current")} transleted {data?.lang}:
                        <b className="text-success"> {data?.percent}%</b>
                      </h6>
                    </div>
                    <div className="flex-shrink-0">
                      <h6 className="mb-0">
                        {data?.done}/{data?.total} {t("workflow.finished")}
                      </h6>
                    </div>
                  </div>
                </div>
                <div className="progress">
                  {/*//@ts-ignore*/}
                  <div
                    className="progress-bar bg-success"
                    style={{
                      width: `${data?.percent ?? 0}%`,
                    }}
                    aria-valuenow={data?.percent as number}
                    aria-valuemin="0"
                    aria-valuemax="100"
                  />
                </div>
              </div>
            ))}
          <EditCopywrite
            defaultTabDisabled={defaultTabDisabled}
            // langTab={currentSourceLang}
            step={"TRANSLATION"}
            copywriteId={contentId}
            setCurrentContent={setContentCopywrite}
            currentContent={contentCopywrite}
            // setCopywriteId={setSelectedProductId}
            setUpdateCopywriteList={setUpdateCopywriteList}
            setTriggerUpdateData={setTriggerUpdateData}
          />
        </div>
      </div>
    </div>
  );
};
