import React, { ChangeEvent, useEffect, useState } from "react";
import { MappingStepper } from "./MappingStepper";
import { ClientMappingService } from "../service";
import { useNavigate, useParams } from "react-router";
import { ErrorToast, SuccessToast } from "../../../shared/toasters/toasters";
import { Button, Col, Row } from "react-bootstrap";
import { t } from "i18next";
import { useSelector } from "react-redux";
import { IAllStates } from "../../../store/rootReducer";

export const InfoStep = () => {
  const { id } = useParams();
  const clientId = useSelector((state: IAllStates) => state.clientId);
  const navigate = useNavigate();
  const [triggerUpdate, setTriggerUpdate] = useState(false);
  const [infoList, setInfoList] = useState<any[] | undefined>();
  const changeHandler = (
    event: ChangeEvent<HTMLInputElement>,
    idObject: number
  ) => {
    const { value } = event.target;
    setInfoList((prev: any) =>
      prev?.map((item: any, index: number) => {
        if (index === idObject) {
          return { ...item, ["text"]: value };
        } else {
          return item;
        }
      })
    );
  };
  const backNavigate = (e: any) => {
    e.preventDefault();
    navigate(`/app/clients/mapping-list/${clientId}`);
  };
  const handleSave = (event: React.FormEvent) => {
    event.preventDefault();
    ClientMappingService.updateMappingInfoById(Number(id), infoList)
      .then((response) => {
        SuccessToast(`${t("toasts.successfully_updated")}`);
      })
      .catch((error) => ErrorToast(error));
  };
  useEffect(() => {
    ClientMappingService.getMappingInfoById(Number(id))
      .then((response) => {
        setInfoList(response);
      })
      .catch((error) => ErrorToast(error));
  }, [triggerUpdate]);

  return (
    <div className="card">
      <div className="card-body">
        <MappingStepper step={"INFO"} />
        <Row>
          {infoList?.map((item: any, index: number) => (
            <Col md={4} id={item?.fieldName} className="my-2">
              <label htmlFor={item?.fieldName} className="">
                {`${t(`products.${item?.fieldName}`)}`}
              </label>
              <input
                type="text"
                name={item?.fieldName}
                placeholder="Type..."
                className="form-control"
                value={(item?.text as string) ?? ""}
                onChange={(e) => changeHandler(e, index)}
              />
            </Col>
          ))}
          <div className="d-flex justify-content-between mt-2">
            <Button variant="info" onClick={(e) => backNavigate(e)}>
              <i className="ri-arrow-left-line me-1" />
              {t("global.cancel")}
            </Button>
            <Button variant="primary" onClick={(e) => handleSave(e)}>
              {t("category.save")}
            </Button>
          </div>
        </Row>
      </div>
    </div>
  );
};
