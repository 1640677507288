import React, { useEffect, useState } from "react";
import { ProductsAttributesUpdate } from "../../products/attributes/components/ProductsAttributesUpdate";
import { Col, Card } from "react-bootstrap";
import { useParams } from "react-router";
import { WorkflowStepper } from "./WorkflowStepper";
import { useDispatch, useSelector } from "react-redux";
import { IAllStates } from "../../../store/rootReducer";
import { IBaseData, IWorkflow, IWorkflowOtherData } from "../dto/IWorkflow";
import step4PaginationSlice from "../../../store/workflow/step4PaginationSlice";
import { WorkflowService } from "../service";
import { ErrorToast } from "../../../shared/toasters/toasters";
import { t } from "i18next";
import { ProfileDetailsService } from "../../profile/service";
import authSlice from "../../../store/auth/authRedux";
const WorkflowStep4Edit = () => {
  const { id, productId } = useParams();
  const dispatch = useDispatch();
  const currentUser = JSON.parse(localStorage.getItem("currentUser") as string);
  const isManager = currentUser?.roles.includes("ROLE_MANAGER");
  const isAdmin = currentUser?.roles.includes("ROLE_ADMIN");
  const isCompanyAdmin = currentUser?.roles.includes("ROLE_COMPANY_ADMIN");
  const [baseData, setBaseData] = useState<IBaseData | undefined>(undefined);

  const stepPagination = useSelector(
    (state: IAllStates) => state.step4Pagination as any
  );
  const [currentWorkflow, setCurrentWorkflow] = useState<IWorkflow | null>(
    null
  );

  const handlePerPage = (e: any) => {
    dispatch(
      step4PaginationSlice.actions.setStep4Pagination({
        ...(stepPagination as any),
        page: 1,
        perPage: e.value,
      })
    );
  };
  const changeFilterHandler = (name: string, value: string) => {
    dispatch(
      step4PaginationSlice.actions.setStep4Pagination({
        ...(stepPagination as any),
        page: 1,
        [name]: value,
      })
    );
  };
  useEffect(() => {
    WorkflowService.getWorkflowAttributesProducts(stepPagination, +id!)
      .then((response) => {
        const { attribute, workflow } =
          (response as unknown as IWorkflowOtherData)?.otherData ?? {};
        setBaseData(attribute);
        setCurrentWorkflow(workflow as IWorkflow);
      })
      .catch((error) => ErrorToast(error));
  }, []);
  useEffect(() => {
    ProfileDetailsService.getProfileDetails()
      .then((data) => {
        dispatch(authSlice.actions.setAuth(data));
      })
      .catch((error) => ErrorToast(error));
  }, []);
  return (
    <>
      <Card>
        <Card.Body>
          <Col xs={12}>
            <div className="row">
              <WorkflowStepper
                stepPagination={stepPagination}
                step={"ATTRIBUTES"}
                precent={baseData?.percent}
                // setSelectedProductId={setSelectedProductId}
                status={stepPagination?.status}
                showNextButton
                showMarkAsComplete={
                  currentWorkflow?.status === "ATTRIBUTES" &&
                  (isAdmin || isCompanyAdmin || isManager)
                }
                changeFilterHandler={changeFilterHandler}
                id={id}
                handlePerPage={handlePerPage}
                showFilters={false}
              />
              <div className="card bg-light overflow-hidden shadow-none">
                <div className="card-body">
                  <div className="d-flex">
                    <div className="flex-grow-1">
                      <h6 className="mb-0">
                        {`${t("workflow.current")}`}:{" "}
                        <b className="text-success">
                          {baseData?.percent ?? 0}%
                        </b>
                      </h6>
                    </div>
                    <div className="flex-shrink-0">
                      <h6 className="mb-0">
                        {baseData?.done ?? 0}/{baseData?.total ?? 0}{" "}
                        {`${t("workflow.finished")}`}
                      </h6>
                    </div>
                  </div>
                </div>
                <div className="progress">
                  {/*//@ts-ignore*/}
                  <div
                    className="progress-bar bg-success"
                    style={{ width: `${baseData?.percent ?? 0}%` }}
                    aria-valuenow={baseData?.percent as number}
                    aria-valuemin="0"
                    aria-valuemax="100"
                  />
                </div>
              </div>
              <ProductsAttributesUpdate
                selectedProductId={productId as any}
                // setSelectedProductId={setSelectedProductId}
                isWorkflowUpdate={true}
              />
            </div>
          </Col>
        </Card.Body>
      </Card>
    </>
  );
};

export default WorkflowStep4Edit;
