import { ACCESS_RIGHTS_URL } from "../../utils/helpers/api.routes";
import { requests } from "../../utils/helpers/api.services";

export const AccessService = {
  getAllAccessRights: (params: any): Promise<any> =>
    requests.get(`${ACCESS_RIGHTS_URL}`, params),
  getRightsById: (id: number) => requests.get(`${ACCESS_RIGHTS_URL}/${id}`),
  updateRightsById: (params: any, id: number) =>
    requests.put(`${ACCESS_RIGHTS_URL}/${id}`, params),
  createRight: (params: any) => requests.post(`${ACCESS_RIGHTS_URL}`, params),
  deleteRightById: (id: number) =>
    requests.delete(`${ACCESS_RIGHTS_URL}/${id}`),
};
