import { createSlice } from "@reduxjs/toolkit";

const open423ErrModalSlice = createSlice({
  name: "open423ErrModal",
  initialState: false,

  reducers: {
    setOpen423ErrModal: (state, action) => {
      state = action.payload;
      return state;
    },
  },
});

export default open423ErrModalSlice;
