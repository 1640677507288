import React from "react";
import Chart from "react-apexcharts";

interface IContentWordsChart {
  words: number;
  medium: number;
  high: number;
}
export const ContentWordsChart = ({
  words,
  medium,
  high,
}: IContentWordsChart) => {
  const funcForPrecent = (words: number, medium: number, high: number) => {
    const dividend = words - medium;
    const divisor = high - medium;
    if (words <= medium) {
      return 0;
    } else if (words >= high) {
      return 100;
    } else {
      return Math.floor((dividend / divisor) * 100);
    }
  };
  return (
    <div className="chart-container">
      <Chart
        options={{
          plotOptions: {
            radialBar: {
              startAngle: -135,
              endAngle: 225,
              hollow: {
                margin: 0,
                size: "70%",
                background: "#fff",
                image: undefined,
                imageOffsetX: 0,
                imageOffsetY: 0,
                position: "front",
                dropShadow: {
                  enabled: true,
                  top: 3,
                  left: 0,
                  blur: 4,
                  opacity: 0.24,
                },
              },
              track: {
                background: "#fff",
                strokeWidth: "67%",
                margin: 0, // margin is in pixels
                dropShadow: {
                  enabled: true,
                  top: -3,
                  left: 0,
                  blur: 4,
                  opacity: 0.35,
                },
              },

              dataLabels: {
                showOn: "always",
                name: {
                  offsetY: 20,
                  show: true,
                  color: "#888",
                  fontSize: "13px",
                },
                value: {
                  formatter: function (val: any) {
                    return val + "%";
                  },
                  color: "#111",
                  fontSize: "14px",
                  show: true,
                  offsetY: -10,
                },
              },
            },
          },
          fill: {
            type: "gradient",
            gradient: {
              shade: "dark",
              type: "horizontal",
              shadeIntensity: 0.5,
              gradientToColors: ["#ABE5A1"],
              inverseColors: true,
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100],
            },
          },
          stroke: {
            lineCap: "round",
          },
          labels: [""],
        }}
        series={[Number(funcForPrecent(words, medium, high))]}
        type="radialBar"
        // width="90px" // Set the width to 70px
        // height="90px" // Set the height to 70px
      />
    </div>
  );
};
