import { useTranslation } from "react-i18next";
import { FilePond } from "react-filepond";
import { FilePondFile } from "filepond";
import { IProducts, IProductsList } from "../../dto/IProducts";
import { CustomImageHandler } from "../../../../shared/components/CustomImageHandler";
import { Carousel, Table } from "react-bootstrap";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { themeSelect } from "../../../../shared/components/Select2";
import Select, { SingleValue } from "react-select";
import React, { SetStateAction, useCallback, useEffect, useState } from "react";
import { ErrorToast, SuccessToast } from "../../../../shared/toasters/toasters";
import {
  ICategoryChildren,
  ICategoryDTO,
} from "../../../category/values/dto/ICategory";
import { RemoveImageButton } from "../../../../shared/components/RemoveImageButton";
import { IPicture } from "../../../../model/shared/IPicture";
import { ProductsService } from "../../service";
import { BrandService } from "../../../brand/service";
import { IBrand } from "../../../brand/dto/IBrand";
import { ContentEditableEvent } from "react-simple-wysiwyg";
import { ConfirmDelete } from "../../../../shared/components/ConfirmDelete";
import { useDispatch, useSelector } from "react-redux";
import { IAllStates } from "../../../../store/rootReducer";
import { getWebsiteTranslation } from "../../../../shared/functions/Functions";
import { CategoryServicee } from "../../../../store/category/categoryService";
import categorySlice from "../../../../store/category/categoryRedux";
import { Button, Modal } from "react-bootstrap";
import { DocumentBlockService } from "../../../blockDocument/service";
interface IProductsActionsProps {
  categories: any;
  files: any;
  setFiles: any;
  handleClose?: (() => void | undefined) | undefined;
  currentProduct: IProductsList | IProducts;
  setCurrentProduct: React.Dispatch<SetStateAction<IProductsList | IProducts>>;
  currentTranslations: any;
  setCurrentTranslations: React.Dispatch<SetStateAction<any>>;
  changeHandler: (
    event: React.ChangeEvent<
      HTMLInputElement | ContentEditableEvent | HTMLTextAreaElement
    >
  ) => void;
  handleSelectMainCategory: (
    selectedSingle: SingleValue<{ label: string; value: number }>,
    inputKey: string
  ) => void;
  handleSubmit: (event: React.FormEvent, checkNext?: boolean) => void;
  isEdit?: boolean;
  isImported?: boolean;
  isCrawled?: boolean;
}

export const BaseDataActions = ({
  categories,
  files,
  setFiles,
  handleClose,
  currentTranslations,
  setCurrentTranslations,
  currentProduct,
  setCurrentProduct,
  changeHandler,
  handleSubmit,
  handleSelectMainCategory,
  isEdit = false,
  isImported = false,
  isCrawled = false,
}: IProductsActionsProps) => {
  const mainLangBlock = {
    en: "Data sheet",
    de: "Datenblatt",
    fr: "Fiche technique",
    it: "Scheda tecnica",
  };

  const { id } = useParams();
  const [showModalBlock, setShowModalBlock] = useState(false);
  const [blockIndex, setBlockIndex] = useState(null);
  const [blockLanguage, setBlockLanguage] = useState("");
  const firstTable = ["title", "subtitle", "links"];
  const secondTable = ["photos", "documents", "videos"];
  const [photosTableAcordion, setPhotosTableAcordion] = useState(false);
  const [documentsTableAcordion, setDocumentsTableAcordion] = useState(false);
  const [videoTableAcordion, setVideoTableAcordion] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const isWorkflow = location?.pathname?.includes("workflow");
  const [imgFancyBox, setImgFancyBox] = useState<string>("");
  const [currentBlock, setCurrentBlock] = useState({ id: null, name: "" });
  const [open, setOpen] = useState(false);
  const toggle = () => {
    setOpen((prev: boolean) => !prev);
  };
  const { accessRights } = useSelector((state: IAllStates) => state.auth);
  const showCrawlerUrl = accessRights?.includes(
    "product_base_data_crawler_can_view"
  );
  const { state: close }: any = useLocation();
  const [openModal, setOpenModal] = useState(false);
  const dispatch = useDispatch();
  const [triggerMainCategoryClear, setTriggerMainCategoryClear] =
    useState(false);
  const [selectedImage, setSelectedImage] = useState<IPicture>();
  const [selectedMainCategory, setSelectedMainCategory] = useState<
    ICategoryChildren[]
  >([]);
  const [brandList, setBrandList] = useState<IBrand[]>([]);
  const [documentBlockList, setDocumentBlockList] = useState<any[]>([]);
  useEffect(() => {
    CategoryServicee.getAllCategories()
      .then((data) => {
        dispatch(categorySlice.actions.setCategory(data));
      })
      .catch((error) => ErrorToast(error));
  }, []);
  const goBack = () => {
    if (
      location?.pathname?.includes("/app/pending-products/imported/base-data/")
    ) {
      // navigate('/app/pending-products/imported');
      navigate(-1);
    } else {
      navigate(-1);
    }
  };
  const handleSave = () => {
    const newArray = {
      block: currentBlock?.id,
      blockName: currentBlock?.name,
      link: "",
    };
    setShowModalBlock((prev: boolean) => !prev);
    setCurrentTranslations((prevData: any) => ({
      ...prevData,
      [blockLanguage]: {
        ...prevData?.[blockLanguage],
        ["documents"]: prevData?.[blockLanguage]?.documents?.concat(newArray),
      },
    }));
    //ovdje
  };
  const changeHandlerSecond = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | ContentEditableEvent
    >,
    field: string,
    index: number,
    language: string
  ) => {
    // @ts-ignore
    const { name, value } = event.target;

    let newArray = (currentTranslations as any)?.[language]?.[field];
    let indexArray = 0;
    if (language === "de") {
      indexArray = index;
    } else if (language === "en") {
      const arrayDe = (currentTranslations as any)?.["de"]?.[field]?.length;
      indexArray = index;
    } else if (language === "fr") {
      const arrayDe = (currentTranslations as any)?.["de"]?.[field]?.length;
      const arrayEn = (currentTranslations as any)?.["en"]?.[field]?.length;
      indexArray = index;
    } else {
      const arrayDe = (currentTranslations as any)?.["de"]?.[field]?.length;
      const arrayEn = (currentTranslations as any)?.["en"]?.[field]?.length;
      const arrayFr = (currentTranslations as any)?.["fr"]?.[field]?.length;
      indexArray = index;
    }

    if (field === "documents") {
      setCurrentTranslations((prevData: any) => ({
        ...prevData,

        [language]: {
          ...prevData?.[language],
          ["documents"]: prevData?.[language]?.documents?.map(
            (doc: any, idx: number) => {
              if (idx !== index) return doc;
              return { ...doc, link: value };
            }
          ),
        },
      }));
    } else {
      newArray[indexArray] = value;
      setCurrentTranslations((prevData: any) => ({
        ...prevData,
        [language]: {
          ...prevData?.[language],
          [field]: newArray,
        },
      }));
    }
  };
  const changeHandlerFirstRow = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | ContentEditableEvent
    >,
    field: string,
    index: number,
    language: string
  ) => {
    // @ts-ignore
    const { name, value } = event.target;

    let newArray = (currentProduct as any)?.translation?.[language]?.[field];
    let indexArray = 0;
    if (field === "documents") {
      newArray[indexArray] = {
        block: null,
        link: value,
      };
      setCurrentProduct((prevData: any) => ({
        ...prevData,
        translation: {
          ...prevData?.translation,
          [language]: {
            ...prevData?.translation?.[language],
            ["documents"]: newArray,
          },
        },
      }));
    } else {
      newArray[indexArray] = value;
      setCurrentProduct((prevData: any) => ({
        ...prevData,
        translation: {
          ...prevData?.translation,
          [language]: {
            ...prevData?.translation?.[language],
            [field]: newArray,
          },
        },
      }));
    }
  };
  const handleSelectBlock = (
    selectedSingle: SingleValue<{ label: string; value: any }>
  ) => {
    setCurrentBlock({
      id: selectedSingle?.value as any,
      name: selectedSingle?.label as string,
    });
  };
  const changeHandlerFirstTable = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | ContentEditableEvent
    >,
    field: string,
    language: string
  ) => {
    // @ts-ignore
    const { name, value } = event.target;
    setCurrentProduct((prevData: any) => ({
      ...prevData,
      translation: {
        ...prevData?.translation,
        [language]: {
          ...prevData?.translation?.[language],
          [field]: value,
        },
      },
    }));
  };
  const removeField = (
    e: React.MouseEvent<HTMLSpanElement, MouseEvent>,
    indexElement: number,
    language: string,
    table: string
  ) => {
    e.preventDefault();
    setCurrentTranslations((prevData: any) => ({
      ...prevData,
      [language]: {
        ...prevData?.[language],
        [table]: currentTranslations?.[language]?.[table]?.filter(
          (item: string, index: number) => index !== indexElement
        ),
      },
    }));
  };
  const currentUser = JSON.parse(localStorage.getItem("currentUser") as string);
  const isCompanyAdmin = currentUser?.roles?.some(
    (role: string) => role === "ROLE_COMPANY_ADMIN"
  );
  const disabled = () => {
    if (
      accessRights?.includes("product_base_data_can_edit") ||
      isCompanyAdmin ||
      isImported
    ) {
      return false;
    } else if (currentProduct?.id === undefined) {
      return false;
    } else {
      return true;
    }
  };
  const { translationLanguageEnum } = useSelector(
    (state: IAllStates) => state.enums
  );
  const tableAcordion = (e: any, title: string) => {
    e.preventDefault();
    if (title === "photos") {
      setPhotosTableAcordion((prev: boolean) => !prev);
    } else if (title === "documents") {
      setDocumentsTableAcordion((prev: boolean) => !prev);
    } else {
      setVideoTableAcordion((prev: boolean) => !prev);
    }
  };
  const addFormFields = (
    e: any,
    lang: string,
    table: string,
    index: number
  ) => {
    e.preventDefault();
    if (table === "documents") {
      setShowModalBlock((prev: boolean) => !prev);
      setBlockIndex(index as any);
      setBlockLanguage(lang);
    } else {
      const newItem = [""];
      let newArrayAddField = [
        ...(currentTranslations as any)?.[lang]?.[table],
        ...newItem,
      ];
      setCurrentTranslations((prevData: any) => ({
        ...prevData,
        [lang]: {
          ...prevData?.[lang],
          [table]: newArrayAddField,
        },
      }));
    }
  };
  function handleSelectSingle2(
    selectedSingle: SingleValue<{ label: string; value: number }>
  ) {
    setTriggerMainCategoryClear((prev: boolean) => !prev);
    const selected = categories?.find(
      (category: ICategoryDTO) => category?.id === selectedSingle?.value
    )?.children;
    selected && setSelectedMainCategory(selected);
    setCurrentProduct((prev: any) => ({
      ...prev,
      category: null,
    }));
  }
  const { websiteEnum } = useSelector((state: IAllStates) => state.enums);
  const deleteImageHandler = () => {
    selectedImage?.id &&
      ProductsService.deleteImageById(selectedImage?.id)
        .then((response) => {
          if (response === "") {
            // setOpenModal(false);
            navigate(0);
            SuccessToast(
              `${t("toasts.successfully_deleted")} ${
                selectedImage?.file?.nameOriginal
              }`
            );
          }
        })
        .catch((error) => ErrorToast(error));
  };
  const openInNewTab = (url: string) => {
    if (url) {
      const newWindow = window.open(url, "_blank", "noopener,noreferrer");
      if (newWindow) newWindow.opener = null;
    } else {
      ErrorToast({
        response: {
          data: {
            error: "Bad Request",
            message: ["Field is empty"],
            statusCode: 400,
          },
        },
      });
    }
  };
  const changeWithLanguage = (
    event: React.ChangeEvent<HTMLInputElement>,
    language: string,
    key: string
  ) => {
    setCurrentProduct((prev) => ({
      ...prev,
      translation: {
        ...prev.translation,
        [language]: {
          ...prev?.translation?.[language],
          [key]: event.target.value,
        },
      },
    }));
  };

  function handleSelectSingle(
    selectedSingle: SingleValue<{ label: string; value: number }>
  ) {
    setCurrentProduct((prev: any) => ({
      ...prev,
      website: selectedSingle?.value,
    }));
  }

  useEffect(() => {
    BrandService.getAllBrands({ page: 10, perPage: 0 })
      .then((response) => {
        const { data } = response;
        setBrandList(data);
      })
      .catch((error) => ErrorToast(error));
  }, []);
  useEffect(() => {
    DocumentBlockService.getAllBlocks({ page: 10, perPage: 0 })
      .then((response) => {
        const { data } = response;
        setDocumentBlockList(data);
      })
      .catch((error) => ErrorToast(error));
  }, []);

  useEffect(() => {
    if (currentProduct?.id) {
      const parentId = currentProduct?.category?.parents?.find(
        (category: { level: number }) => category.level === 1
      )?.id;

      const correctCategory = categories.find(
        (category: { id: number }) => category.id === parentId
      );
      setSelectedMainCategory(correctCategory?.children);
    }
  }, [currentProduct?.id]);
  const backHandle = () => {
    if (close?.close === "export") {
      navigate(`/app/export/list/${close?.idExport}`, {
        state: (location.state as any)?.headerName,
      });
      // goBack()
    } else if (close?.close === "attributes") {
      navigate(`/admin/attributes/list/${close?.idExport}`, {
        state: (location.state as any)?.headerName,
      });
    } else if (handleClose) {
      handleClose();
    } else {
      goBack();
    }
  };
  const EditorWithContent = useCallback(() => {
    return (
      <MainCategoryFilter
        selectedMainCategory={selectedMainCategory}
        t={t}
        disabled={disabled}
        currentProduct={currentProduct}
        handleSelectMainCategory={handleSelectMainCategory}
      />
    );
  }, [
    triggerMainCategoryClear,
    currentProduct?.id,
    selectedMainCategory,
    // (currentProduct as any)?.category?.parents,
  ]);
  return (
    <React.Fragment key={currentProduct?.id}>
      <div className="col-md-9">
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className={`col-md-3 my-1`}>
                <label htmlFor="eanCode">{t("products.ean_code")}</label>
                <input
                  type="text"
                  className="form-control"
                  name="eanCode"
                  onChange={changeHandler}
                  value={currentProduct?.eanCode}
                  placeholder={t("products.enter_ean_code")}
                />
              </div>
              <div className="col-md-3 my-1">
                <label htmlFor="articleNumber">
                  {t("products.articleNumber")}
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="articleNumber"
                  onChange={changeHandler}
                  value={currentProduct?.articleNumber}
                  placeholder={t("products.enter_articleNumber")}
                />
              </div>
              <div className="col-md-3 my-1">
                <label htmlFor="sapId">{t("products.sapId")}</label>
                <input
                  type="text"
                  className="form-control"
                  name="sapId"
                  onChange={changeHandler}
                  value={currentProduct?.sapId}
                  placeholder={t("products.enter_sap_id")}
                />
              </div>
              <div className="col-md-3 my-1">
                <label htmlFor="pharmaCode">{t("products.pharmaCode")}</label>
                <input
                  type="text"
                  className="form-control"
                  name="pharmaCode"
                  onChange={changeHandler}
                  value={currentProduct?.pharmaCode}
                  placeholder={t("products.enter_pharma_code")}
                />
              </div>
              <div
                className={`col-md-${
                  !!selectedMainCategory?.length ? 4 : 6
                } my-1`}
              >
                <label htmlFor="category" className="required-field">
                  {t("products.select_main_category")}
                </label>
                <Select
                  className="react-select-field"
                  isDisabled={disabled() as unknown as boolean | undefined}
                  theme={themeSelect}
                  options={categories
                    ?.filter(
                      (category: { children: [] }) =>
                        !!category?.children?.length
                    )
                    ?.map((cat: { id: any; title: any }) => ({
                      value: cat?.id,
                      label: cat?.title ?? "-",
                    }))}
                  key={currentProduct?.id}
                  defaultValue={
                    (currentProduct as any)?.category?.parents && {
                      label: (currentProduct as any)?.category?.parents?.find(
                        (parent: { level: number }) => parent?.level === 1
                      )?.title,
                      value: (currentProduct as any)?.category?.parents?.find(
                        (parent: { level: number }) => parent?.level === 1
                      )?.id,
                    }
                  }
                  onChange={(e) => {
                    handleSelectSingle2(e);
                  }}
                />
              </div>

              {!!selectedMainCategory?.length && <EditorWithContent />}

              <div
                className={`col-md-${
                  !!selectedMainCategory?.length ? 4 : 6
                } my-1`}
              >
                <label htmlFor="category" className="required-field">
                  {t("products.brand")}
                </label>
                <Select
                  className="react-select-field"
                  theme={themeSelect}
                  options={brandList?.map((brand) => ({
                    label: brand?.name ?? "-",
                    value: brand?.id,
                  }))}
                  defaultValue={
                    (currentProduct as IProductsList)?.brand?.id && {
                      label: (currentProduct as IProductsList)?.brand?.name,
                      value: (currentProduct as IProductsList)?.brand?.id,
                    }
                  }
                  onChange={(e) => {
                    handleSelectMainCategory(
                      e as SingleValue<{ label: string; value: number }>,
                      "brand"
                    );
                  }}
                  key={currentProduct?.id}
                />
              </div>
              {isImported || isCrawled ? (
                ""
              ) : (
                <>
                  <div className={`col-md-${showCrawlerUrl ? "4" : "6"} my-1`}>
                    <label htmlFor="sourceUrl">{`${t(
                      "products.source_url"
                    )}`}</label>
                    <input
                      type="text"
                      name="sourceUrl"
                      required
                      value={(currentProduct as any)?.sourceUrl}
                      onChange={changeHandler}
                      className="form-control"
                    />
                  </div>
                  <div className={`col-md-${showCrawlerUrl ? "4" : "6"} my-1`}>
                    <label className="required-field" htmlFor="website">{`${t(
                      "crawlers.website"
                    )}`}</label>
                    <Select
                      className="react-select-field"
                      options={websiteEnum?.map((website) => ({
                        value: website,
                        label: getWebsiteTranslation(website, t),
                      }))}
                      theme={themeSelect}
                      onChange={(e) => {
                        handleSelectSingle(e);
                      }}
                      defaultValue={
                        currentProduct?.website && {
                          label: getWebsiteTranslation(
                            currentProduct?.website,
                            t
                          ),
                          value: currentProduct?.website,
                        }
                      }
                      key={currentProduct?.id}
                    />
                  </div>
                  {showCrawlerUrl && (
                    <div className={`col-md-4 my-1`}>
                      <label htmlFor="url">{`${t(
                        "products.crawlerUrl"
                      )}`}</label>
                      <input
                        type="text"
                        name="crawlerUrl"
                        required
                        value={currentProduct?.crawlerUrl}
                        onChange={changeHandler}
                        className="form-control"
                      />
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <div
        className="col-md-3"
        style={id ? { maxHeight: "290px" } : { maxHeight: "243px" }}
      >
        <div className="card h-100">
          <div
            className="card-body"
            style={id ? { maxHeight: "290px" } : { maxHeight: "243px" }}
          >
            <FilePond
              files={files}
              allowReorder={true}
              allowMultiple={true}
              acceptedFileTypes={["image/*"]}
              onupdatefiles={(filesArr: FilePondFile[]) => {
                setFiles(filesArr?.map((fileItem) => fileItem?.file));
              }}
              labelIdle={`${t("products.drag_and_drop")}`}
            />
            {isEdit && (
              <>
                <Carousel
                  style={id ? { maxHeight: "170px" } : { maxHeight: "243px" }}
                >
                  {(currentProduct as IProductsList)?.productPictures?.map(
                    (productImage) => (
                      <Carousel.Item
                        key={productImage?.id}
                        className="position-relative text-center"
                        style={
                          id ? { maxHeight: "170px" } : { maxHeight: "243px" }
                        }
                      >
                        <RemoveImageButton
                          onClick={() => {
                            setOpenModal(true);
                            setSelectedImage(productImage);
                          }}
                        />
                        <CustomImageHandler
                          path={`${productImage?.path}`}
                          classes="figure-img img-fluid rounded"
                          alt="Product"
                        />
                      </Carousel.Item>
                    )
                  )}
                </Carousel>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="col-md-12">
        <div className="card">
          <div className="card-body">
            <div className="accordion" id="accordionPanelsStayOpenExample">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#panelsStayOpen-collapseOne"
                    aria-expanded="true"
                    aria-controls="panelsStayOpen-collapseOne"
                  >
                    Title, Subtitle and Links
                  </button>
                </h2>
                <div
                  id="panelsStayOpen-collapseOne"
                  className="accordion-collapse collapse show"
                >
                  <div className="accordion-body">
                    <div className="row">
                      {firstTable?.map((item: string, index: number) => (
                        <>
                          {true && (
                            <div className="col-md-4">
                              <div className="table-responsive">
                                <Table className="align-middle table-nowrap mb-0">
                                  <thead className="table-light">
                                    <tr>
                                      <th
                                        scope="col"
                                        className="flag-field"
                                      ></th>
                                      <th
                                        scope="col"
                                        className="ps-0 text-start"
                                      >
                                        {`${t(`mapping.${item}`)}`}
                                      </th>
                                    </tr>
                                  </thead>
                                  {item === "title" || item === "subtitle" ? (
                                    <tbody>
                                      {translationLanguageEnum?.map(
                                        (language: any) => {
                                          return (
                                            <tr>
                                              <td className="ps-0">
                                                {" "}
                                                <img
                                                  src={`/images/flags/${language}.svg`}
                                                  alt="country-image"
                                                  className="me-2 rounded"
                                                  height="18"
                                                />
                                                <span className="text-uppercase">
                                                  {language}
                                                </span>
                                              </td>
                                              <td className="ps-0 d-flex justify-content-between align-items-center">
                                                {" "}
                                                <input
                                                  onChange={(e) =>
                                                    changeHandlerFirstTable(
                                                      e,
                                                      item,
                                                      language
                                                    )
                                                  }
                                                  value={
                                                    currentProduct
                                                      ?.translation?.[
                                                      language
                                                    ]?.[item] ?? ""
                                                  }
                                                  id="client"
                                                  className="form-control w-100"
                                                  name="name"
                                                  placeholder={`${t(
                                                    "mapping.type"
                                                  )}...`}
                                                  type="text"
                                                />
                                                {/* {item === "link" && (
                                                <span
                                                  onClick={() => {
                                                    openInNewTab(
                                                      currentProduct
                                                        ?.translation?.[
                                                        language
                                                      ]?.[item]
                                                    );
                                                  }}
                                                  className="cursor-pointer"
                                                  style={{
                                                    color: "#3577f1",
                                                    fontSize: "18px",
                                                  }}
                                                >
                                                  <i className="ri-eye-fill ms-1"></i>
                                                </span>
                                              )} */}
                                              </td>
                                            </tr>
                                          );
                                        }
                                      )}
                                    </tbody>
                                  ) : (
                                    <tbody>
                                      {translationLanguageEnum?.map(
                                        (language: any) => {
                                          return (
                                            <>
                                              <tr>
                                                <td className="ps-0">
                                                  {" "}
                                                  <img
                                                    src={`/images/flags/${language}.svg`}
                                                    alt="country-image"
                                                    className="me-2 rounded"
                                                    height="18"
                                                  />
                                                  <span className="text-uppercase">
                                                    {language}
                                                  </span>
                                                </td>
                                                <td className="ps-0 d-flex justify-content-between align-items-center">
                                                  {" "}
                                                  <input
                                                    onChange={(e: any) =>
                                                      changeHandlerFirstRow(
                                                        e,
                                                        item as string,
                                                        index,
                                                        language as string
                                                      )
                                                    }
                                                    value={
                                                      (!!currentProduct
                                                        ?.translation?.[
                                                        language
                                                      ]?.[item]?.length &&
                                                        currentProduct
                                                          ?.translation?.[
                                                          language
                                                        ]?.[item][0]) ??
                                                      ""
                                                    }
                                                    id="client"
                                                    className="form-control w-100"
                                                    name={item}
                                                    placeholder={`${t(
                                                      "mapping.type"
                                                    )}...`}
                                                    type="text"
                                                  />
                                                  <div className="ms-1 d-flex align-items-center">
                                                    <span
                                                      style={{
                                                        color: "green",
                                                        fontSize: "18px",
                                                      }}
                                                      onClick={(e: any) => {
                                                        addFormFields(
                                                          e,
                                                          language as string,
                                                          item as string,
                                                          index as number
                                                        );
                                                      }}
                                                      className="cursor-pointer"
                                                    >
                                                      <i className="ri-add-circle-fill me-1"></i>
                                                    </span>
                                                    <span
                                                      onClick={() => {
                                                        openInNewTab(
                                                          currentProduct
                                                            ?.translation?.[
                                                            language
                                                          ]?.[item]
                                                        );
                                                      }}
                                                      className="cursor-pointer"
                                                      style={{
                                                        color: "#3577f1",
                                                        fontSize: "18px",
                                                      }}
                                                    >
                                                      <i className="ri-eye-fill ms-1"></i>
                                                    </span>
                                                  </div>
                                                </td>
                                              </tr>
                                              {true &&
                                                currentTranslations?.[
                                                  language
                                                ]?.[item]?.map(
                                                  (
                                                    itemArray: any,
                                                    index: number
                                                  ) => {
                                                    return (
                                                      <tr>
                                                        <td
                                                          className={`ps-0 ${
                                                            item ===
                                                              "documents" &&
                                                            "py-doc"
                                                          }`}
                                                        >
                                                          <img
                                                            src={`/images/flags/${language}.svg`}
                                                            alt="country-image"
                                                            className="me-2 rounded"
                                                            height="18"
                                                          />
                                                          <span className="text-uppercase">
                                                            {language}
                                                          </span>
                                                        </td>
                                                        <td
                                                          className={`ps-0 d-flex justify-content-between align-items-center ${
                                                            item ===
                                                              "documents" &&
                                                            "py-doc"
                                                          }`}
                                                        >
                                                          <input
                                                            onChange={(
                                                              e: any
                                                            ) =>
                                                              changeHandlerSecond(
                                                                e,
                                                                item as string,
                                                                index,
                                                                language as string
                                                              )
                                                            }
                                                            value={
                                                              currentTranslations?.[
                                                                language
                                                              ]?.[item][
                                                                index
                                                              ] ?? ""
                                                            }
                                                            className="form-control w-100"
                                                            name="name"
                                                            placeholder={`${t(
                                                              "mapping.type"
                                                            )}...`}
                                                            type="text"
                                                          />

                                                          <span className="ms-1 d-flex align-items-center">
                                                            <span
                                                              className="cursor-pointer"
                                                              style={{
                                                                color: "red",
                                                                fontSize:
                                                                  "18px",
                                                              }}
                                                              onClick={(e) =>
                                                                removeField(
                                                                  e,
                                                                  index,
                                                                  language,
                                                                  item
                                                                )
                                                              }
                                                            >
                                                              <i className="ri-delete-bin-fill me-1"></i>
                                                            </span>
                                                            <span
                                                              onClick={() => {
                                                                openInNewTab(
                                                                  itemArray?.link
                                                                );
                                                              }}
                                                              className="cursor-pointer"
                                                              style={{
                                                                color:
                                                                  "#3577f1",
                                                                fontSize:
                                                                  "18px",
                                                              }}
                                                            >
                                                              <i className="ri-eye-fill ms-1"></i>
                                                            </span>
                                                          </span>
                                                        </td>
                                                      </tr>
                                                    );
                                                  }
                                                )}
                                            </>
                                          );
                                        }
                                      )}
                                    </tbody>
                                  )}
                                </Table>
                              </div>
                            </div>
                          )}
                        </>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="accordion-item mt-1">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#panelsStayOpen-collapseTwo"
                    aria-controls="panelsStayOpen-collapseTwo"
                    aria-expanded="true"
                  >
                    Photos, Documents and Videos
                  </button>
                </h2>
                <div
                  id="panelsStayOpen-collapseTwo"
                  className="accordion-collapse collapse show"
                >
                  <div className="accordion-body">
                    <div className="row">
                      {secondTable?.map((item: string) => (
                        <div className="col-md-4">
                          <div className="table-responsive">
                            <Table className="align-middle table-nowrap mb-0">
                              <thead className="table-light">
                                <tr>
                                  <th
                                    scope="col"
                                    className="ps-0 flag-field"
                                  ></th>
                                  <th
                                    scope="col"
                                    className="ps-0 d-flex justify-content-between"
                                  >
                                    <span> {`${t(`mapping.${item}`)}`}</span>

                                    <span
                                      className="cursor-pointer"
                                      onClick={(e) => tableAcordion(e, item)}
                                    >
                                      {/* <i className="ri-arrow-up-s-line"></i> */}
                                      <i
                                        className={`${
                                          item === "photos"
                                            ? photosTableAcordion
                                              ? "ri-arrow-down-s-line"
                                              : "ri-arrow-up-s-line"
                                            : item === "documents"
                                            ? documentsTableAcordion
                                              ? "ri-arrow-down-s-line"
                                              : "ri-arrow-up-s-line"
                                            : videoTableAcordion
                                            ? "ri-arrow-down-s-line"
                                            : "ri-arrow-up-s-line"
                                        }`}
                                      ></i>
                                    </span>
                                  </th>
                                </tr>
                              </thead>
                              <tbody
                                className={`${
                                  item === "photos"
                                    ? photosTableAcordion
                                      ? "d-none"
                                      : "table-row-group"
                                    : item === "documents"
                                    ? documentsTableAcordion
                                      ? "d-none"
                                      : "table-row-group"
                                    : videoTableAcordion
                                    ? "d-none"
                                    : "table-row-group"
                                }`}
                              >
                                {translationLanguageEnum?.map(
                                  (language: any, index: number) => {
                                    return (
                                      <>
                                        <tr>
                                          <td
                                            className={`ps-0 ${
                                              item === "documents" && " py-doc"
                                            }`}
                                          >
                                            <img
                                              src={`/images/flags/${language}.svg`}
                                              alt="country-image"
                                              className="me-2 rounded"
                                              height="18"
                                            />
                                            <span className="text-uppercase">
                                              {language}
                                            </span>
                                          </td>
                                          <td
                                            className={`ps-0 d-flex justify-content-between align-items-center${
                                              item === "documents" && " py-doc"
                                            }`}
                                          >
                                            {item === "documents" ? (
                                              <div className="d-flex flex-column w-100">
                                                <span className="text-start fw-500">
                                                  {
                                                    (mainLangBlock as any)?.[
                                                      language
                                                    ]
                                                  }
                                                </span>
                                                <input
                                                  style={{
                                                    height: "32px",
                                                  }}
                                                  onChange={(e: any) =>
                                                    changeHandlerFirstRow(
                                                      e,
                                                      item as string,
                                                      index,
                                                      language as string
                                                    )
                                                  }
                                                  value={
                                                    !!currentProduct
                                                      ?.translation?.[
                                                      language
                                                    ]?.[item]?.length &&
                                                    currentProduct
                                                      ?.translation?.[
                                                      language
                                                    ]?.[item][0]?.link
                                                  }
                                                  className="form-control w-100"
                                                  name="name"
                                                  placeholder={`${t(
                                                    "mapping.type"
                                                  )}...`}
                                                  type="text"
                                                />
                                              </div>
                                            ) : (
                                              <input
                                                onChange={(e: any) =>
                                                  changeHandlerFirstRow(
                                                    e,
                                                    item as string,
                                                    index,
                                                    language as string
                                                  )
                                                }
                                                value={
                                                  (!!currentProduct
                                                    ?.translation?.[language]?.[
                                                    item
                                                  ]?.length &&
                                                    currentProduct
                                                      ?.translation?.[
                                                      language
                                                    ]?.[item][0]) ??
                                                  ""
                                                }
                                                id="client"
                                                className="form-control w-100"
                                                name={item}
                                                placeholder={`${t(
                                                  "mapping.type"
                                                )}...`}
                                                type="text"
                                              />
                                            )}

                                            <div className="ms-1 d-flex align-items-center">
                                              {true && (
                                                <span
                                                  style={{
                                                    color: "green",
                                                    fontSize: "18px",
                                                  }}
                                                  onClick={(e: any) => {
                                                    addFormFields(
                                                      e,
                                                      language as string,
                                                      item as string,
                                                      index as number
                                                    );
                                                  }}
                                                  className="cursor-pointer"
                                                >
                                                  <i className="ri-add-circle-fill me-1"></i>
                                                </span>
                                              )}

                                              <span
                                                onClick={
                                                  item === "videos"
                                                    ? () => {
                                                        currentProduct
                                                          ?.translation?.[
                                                          language
                                                        ]?.[item][0] &&
                                                          openInNewTab(
                                                            !!currentProduct
                                                              ?.translation?.[
                                                              language
                                                            ]?.[item]?.length &&
                                                              currentProduct
                                                                ?.translation?.[
                                                                language
                                                              ]?.[item][0]
                                                          );
                                                      }
                                                    : item === "photos"
                                                    ? () => {
                                                        !!currentProduct
                                                          ?.translation?.[
                                                          language
                                                        ]?.[item][0] &&
                                                          setImgFancyBox(
                                                            !!currentProduct
                                                              ?.translation?.[
                                                              language
                                                            ]?.[item]?.length &&
                                                              currentProduct
                                                                ?.translation?.[
                                                                language
                                                              ]?.[item][0]
                                                          );
                                                        currentProduct
                                                          ?.translation?.[
                                                          language
                                                        ]?.[item][0]
                                                          ? toggle()
                                                          : ErrorToast({
                                                              response: {
                                                                data: {
                                                                  error:
                                                                    "Bad Request",
                                                                  message: [
                                                                    "Field is empty",
                                                                  ],
                                                                  statusCode: 400,
                                                                },
                                                              },
                                                            });
                                                      }
                                                    : () => {
                                                        openInNewTab(
                                                          !!currentProduct
                                                            ?.translation?.[
                                                            language
                                                          ]?.[item]?.length &&
                                                            currentProduct
                                                              ?.translation?.[
                                                              language
                                                            ]?.[item][0]?.link
                                                        );
                                                      }
                                                }
                                                className="cursor-pointer"
                                                style={{
                                                  color: "#3577f1",
                                                  fontSize: "18px",
                                                }}
                                              >
                                                <i className="ri-eye-fill ms-1"></i>
                                              </span>
                                            </div>
                                          </td>
                                        </tr>
                                        {true &&
                                          currentTranslations?.[language]?.[
                                            item
                                          ]?.map(
                                            (itemArray: any, index: number) => {
                                              return (
                                                <tr>
                                                  <td
                                                    className={`ps-0 ${
                                                      item === "documents" &&
                                                      "py-doc"
                                                    }`}
                                                  >
                                                    <img
                                                      src={`/images/flags/${language}.svg`}
                                                      alt="country-image"
                                                      className="me-2 rounded"
                                                      height="18"
                                                    />
                                                    <span className="text-uppercase">
                                                      {language}
                                                    </span>
                                                  </td>
                                                  <td
                                                    className={`ps-0 d-flex justify-content-between align-items-center ${
                                                      item === "documents" &&
                                                      "py-doc"
                                                    }`}
                                                  >
                                                    {item === "documents" ? (
                                                      <>
                                                        <div className="d-flex flex-column  w-100">
                                                          <span className="text-start fw-500">
                                                            {itemArray?.blockName ??
                                                              null}
                                                          </span>
                                                          <input
                                                            style={{
                                                              height: "32px",
                                                            }}
                                                            onChange={(
                                                              e: any
                                                            ) =>
                                                              changeHandlerSecond(
                                                                e,
                                                                item as string,
                                                                index,
                                                                language as string
                                                              )
                                                            }
                                                            value={
                                                              item !==
                                                              "documents"
                                                                ? itemArray ??
                                                                  ""
                                                                : currentTranslations?.[
                                                                    language
                                                                  ]?.[item][
                                                                    index
                                                                  ]?.link ?? ""
                                                            }
                                                            className="form-control w-100"
                                                            name="name"
                                                            placeholder={`${t(
                                                              "mapping.type"
                                                            )}...`}
                                                            type="text"
                                                          />
                                                        </div>
                                                      </>
                                                    ) : (
                                                      <input
                                                        onChange={(e: any) =>
                                                          changeHandlerSecond(
                                                            e,
                                                            item as string,
                                                            index,
                                                            language as string
                                                          )
                                                        }
                                                        value={
                                                          item !== "documents"
                                                            ? itemArray ?? ""
                                                            : currentProduct
                                                                ?.translation?.[
                                                                language
                                                              ]?.[item][index]
                                                                ?.link ?? ""
                                                        }
                                                        className="form-control w-100"
                                                        name="name"
                                                        placeholder={`${t(
                                                          "mapping.type"
                                                        )}...`}
                                                        type="text"
                                                      />
                                                    )}

                                                    <span className="ms-1 d-flex align-items-center">
                                                      <span
                                                        className="cursor-pointer"
                                                        style={{
                                                          color: "red",
                                                          fontSize: "18px",
                                                        }}
                                                        onClick={(e) =>
                                                          removeField(
                                                            e,
                                                            index,
                                                            language,
                                                            item
                                                          )
                                                        }
                                                      >
                                                        <i className="ri-delete-bin-fill me-1"></i>
                                                      </span>
                                                      <span
                                                        onClick={
                                                          item === "photos"
                                                            ? () => {
                                                                setImgFancyBox(
                                                                  itemArray as unknown as string
                                                                );
                                                                itemArray
                                                                  ? toggle()
                                                                  : ErrorToast({
                                                                      response:
                                                                        {
                                                                          data: {
                                                                            error:
                                                                              "Bad Request",
                                                                            message:
                                                                              [
                                                                                "Field is empty",
                                                                              ],
                                                                            statusCode: 400,
                                                                          },
                                                                        },
                                                                    });
                                                              }
                                                            : () => {
                                                                openInNewTab(
                                                                  item ===
                                                                    "videos"
                                                                    ? itemArray
                                                                    : itemArray?.link
                                                                );
                                                              }
                                                        }
                                                        className="cursor-pointer"
                                                        style={{
                                                          color: "#3577f1",
                                                          fontSize: "18px",
                                                        }}
                                                      >
                                                        <i className="ri-eye-fill ms-1"></i>
                                                      </span>
                                                    </span>
                                                  </td>
                                                </tr>
                                              );
                                            }
                                          )}
                                      </>
                                    );
                                  }
                                )}
                              </tbody>
                            </Table>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-end mt-3">
                {location?.pathname?.includes("workflow/") && (
                  <button className="btn btn-info" onClick={() => backHandle()}>
                    {t("products.close")}
                  </button>
                )}

                <button
                  className="btn btn-secondary ms-2"
                  onClick={(e) => handleSubmit(e)}
                >
                  {id ? t("products.save") : t("global.create")}
                </button>
                {id && isWorkflow && (
                  <button
                    className="btn btn-primary ms-2"
                    onClick={(e) => handleSubmit(e, true)}
                  >
                    {t("products.next")}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ConfirmDelete
        show={openModal}
        setShow={setOpenModal}
        itemName={selectedImage?.file?.nameOriginal}
        deleteHandler={deleteImageHandler}
        selectedProduct={selectedImage}
      />
      <Modal show={open} toggle={toggle}>
        <button type="button" className="btn fs-3" onClick={toggle}>
          ×
        </button>
        <img className="d-block w-100" src={imgFancyBox} alt="Image One" />
      </Modal>
      <Modal show={showModalBlock} size="xl" style={{ height: "500px" }}>
        <Modal.Body
          className="d-flex align-items-center flex-column"
          style={{ height: "420px" }}
        >
          <label htmlFor="category" className="">
            Documents block
          </label>
          <Select
            className="react-select-field w-400"
            theme={themeSelect}
            options={documentBlockList?.map((brand) => ({
              label: brand?.name ?? "-",
              value: brand?.id,
            }))}
            onChange={(e) => {
              handleSelectBlock(
                e as SingleValue<{ label: string; value: number }>
              );
            }}
            key={currentProduct?.id}
          />
        </Modal.Body>
        <Modal.Footer className="d-flex align-items-center justify-content-center">
          <Button
            variant="primary"
            size="sm"
            onClick={() => {
              handleSave();
            }}
          >
            {t(`global.save`)}
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

const MainCategoryFilter = ({
  selectedMainCategory,
  t,
  disabled,
  currentProduct,
  handleSelectMainCategory,
}: any) => {
  return (
    <>
      <div className="col-md-4 my-1">
        <label htmlFor="category" className="required-field">
          {t("products.select_category")}
        </label>
        <Select
          className="react-select-field"
          isDisabled={disabled() as unknown as boolean | undefined}
          theme={themeSelect}
          options={selectedMainCategory?.map((mainCat: any) => ({
            label: mainCat?.title ?? "-",
            options: mainCat?.children?.map((subCat: any) => ({
              label: subCat?.title ?? "-",
              value: subCat?.id,
            })),
          }))}
          defaultValue={
            (currentProduct as any)?.category?.id && {
              label: (currentProduct as any)?.category?.title,
              value: (currentProduct as any)?.category?.id,
            }
          }
          key={currentProduct?.id}
          onChange={(e) => {
            handleSelectMainCategory(
              e as SingleValue<{ label: string; value: number }>,
              "category"
            );
          }}
        />
        {currentProduct?.id && (
          <div
            className="alert alert-warning alert-borderless mt-2 mb-0 p-0"
            role="alert"
          >
            <p
              className="mb-0"
              dangerouslySetInnerHTML={{
                __html: t(`products.affect_attributes`),
              }}
            />
          </div>
        )}
      </div>
    </>
  );
};
