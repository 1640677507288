import { t } from "i18next";
import { Button, Col, Row } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { ErrorToast } from "../../../shared/toasters/toasters";
import { IAllStates } from "../../../store/rootReducer";
import workflowSlice from "../../../store/workflow/workflowSlice";
import Select from "react-select";
import { ContentWorkflowService } from "../service";
import contentWorkflowSlice from "../../../store/contentWorkflow/contentWorkflowSlice";
import { nanoid } from "nanoid";
export const handleContentStepNavigate = (
  status: string,
  id: number,
  navigate: any
) => {
  switch (status) {
    case "SETTING_UP":
      return navigate(`/app/content-workflow/setting-up/${id}`);
    case "BASE":
      return navigate(`/app/content-workflow/base/${id}`);
    case "COPY_WRITING":
      return navigate(`/app/content-workflow/copywriting/${id}`);
    case "TRANSLATION":
      return navigate(`/app/content-workflow/translation/${id}`);
    case "OVERVIEW":
      return navigate(`/app/content-workflow/overview/${id}`);
    case "EXPORT":
      return navigate(`/app/content-workflow/export/${id}`);
    default:
      return navigate(`/app/content-workflow/setting-up/${id}`);
  }
};
const perPageOptions = [
  {
    label: 10,
    value: 10,
  },
  {
    label: 50,
    value: 50,
  },
  {
    label: 100,
    value: 100,
  },
  {
    label: 500,
    value: 500,
  },
];
export const ContentStepper = ({
  copywriteId,
  setCopywriteId,
  setCurrentContent,
  pagination,
  isDisabledComplete = true,
  precent,
  step,
  id,
  handleFilterCopywritingStatus,
  setSelectedProductId,
  changeFilterHandler,
  status,
  assigned,
  showMarkAsComplete = true,
  showNextButton = false,
  handlePerPage,
  handleFilterUser,
  userList,
}: any) => {
  const currentUser = JSON.parse(localStorage.getItem("currentUser") as string);
  const isManager = currentUser?.roles.includes("ROLE_MANAGER");
  const isAdmin = currentUser?.roles.includes("ROLE_ADMIN");
  const isCompanyAdmin = currentUser?.roles.includes("ROLE_COMPANY_ADMIN");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const copywritingStatusOptions = [
    {
      label: `${t("global.all")}`,
      value: null,
    },
    {
      label: `${t("global.finished")}`,
      value: "finished",
    },
    {
      label: `${t("global.unfinished")}`,
      value: "unfinished",
    },
  ];
  const { workflowStatus } = useSelector((state: IAllStates) => state.enums);
  const { baseData, attributes } = useSelector(
    (state: IAllStates) => state.workflow
  );
  const [tabsList, setTabsList] = useState([]);
  const handleNextProduct = () => {
    ContentWorkflowService.getNextCopywriteContentWorkflow(
      Number(id),
      step as string
    )
      .then((response) => {
        const { next } = response;
        // if (!response.nextProduct?.id) {
        //     setCopywriteId(0);
        // }
        setCopywriteId(next?.id);
        window.scrollTo(0, 0);
      })
      .catch((error) => ErrorToast(error));
  };
  const handleMarkAsComplete = () => {
    if (step === "BASE") {
      ContentWorkflowService.saveWorkflowBaseById(Number(id))
        .then((response) => {
          handleContentStepNavigate(response?.status, Number(id), navigate);
        })
        .catch((error) => ErrorToast(error));
    } else if (step === "COPY_WRITING") {
      ContentWorkflowService.saveWorkflowCopywritingById(Number(id))
        .then((response) => {
          handleContentStepNavigate(response?.status, Number(id), navigate);
        })
        .catch((error) => ErrorToast(error));
    } else if (step === "TRANSLATION") {
      ContentWorkflowService.saveWorkflowTranslationById(Number(id))
        .then((response) => {
          handleContentStepNavigate(response?.status, Number(id), navigate);
        })
        .catch((error) => ErrorToast(error));
    } else {
      return;
    }
  };
  const handleStepSteper = (status: string, id: number) => {
    switch (status) {
      case "SETTING_UP":
        return `/app/content-workflow/setting-up/${id}`;
      case "BASE":
        return `/app/content-workflow/base/${id}`;
      case "COPY_WRITING":
        return `/app/content-workflow/copywriting/${id}`;
      case "TRANSLATION":
        return `/app/content-workflow/translation/${id}`;
      case "EXPORT":
        return `/app/content-workflow/export/${id}`;
      case "OVERVIEW":
        return `/app/content-workflow/overview/${id}`;
      default:
        return `/app/content-workflow/setting-up/${id}`;
    }
  };

  useEffect(() => {
    Boolean(id) &&
      ContentWorkflowService.getWorkflowById(id as any)
        .then((response: any) => {
          setTabsList(response?.tabs);
          dispatch(contentWorkflowSlice.actions.setContentWorkflow(response));
          window.scrollTo(0, 0);
        })
        .catch((error) => ErrorToast(error));
  }, [id]);

  return (
    <Row>
      <Col md={12}>
        <ul className="nav nav-tabs nav-tabs-custom nav-success nav-justified mb-3">
          {tabsList?.map((stepName: string) => {
            const removeBaseData = !baseData && stepName === "BASE_DATA"; // if we don't have base data and step is 3, don't render
            const removeAttributes = !attributes && stepName === "ATTRIBUTES"; // same logic as above
            if (removeBaseData || removeAttributes) {
              return;
            }
            return (
              <li className="nav-item" key={stepName}>
                <Link
                  className={`nav-link ${step === stepName ? "active" : ""}`}
                  to={handleStepSteper(stepName, Number(id))}
                >
                  {t(`content_workflow.${stepName}`)}
                </Link>
              </li>
            );
          })}
        </ul>
      </Col>
      {["BASE", "ATTRIBUTES", "COPY_WRITING", "TRANSLATION"].includes(step) &&
        // setSelectedProductId
        !copywriteId && (
          // true
          <Col md={12} className="d-flex justify-content-between mb-2">
            {
              // Boolean(changeFilterHandler)
              true && (
                <div className="d-flex align-items-center">
                  {step !== "BASE" && (
                    <>
                      <span className="me-1">{t(`workflow.filter`)}:</span>
                      <Button
                        variant={`${
                          pagination?.status === "" ? "" : "outline-"
                        }primary`}
                        onClick={() => changeFilterHandler("status", "", true)}
                        className="d-flex align-items-center"
                        size="sm"
                      >
                        {t("global.all")}
                      </Button>
                      <Button
                        variant={`${
                          pagination?.status === "finished" ? "" : "outline-"
                        }success`}
                        onClick={() =>
                          changeFilterHandler("status", "finished")
                        }
                        className="d-flex mx-2 align-items-center"
                        size="sm"
                      >
                        {t("global.finished")}
                      </Button>
                      <Button
                        variant={`${
                          pagination?.status === "unfinished" ? "" : "outline-"
                        }danger`}
                        onClick={() =>
                          changeFilterHandler("status", "unfinished")
                        }
                        className="d-flex align-items-center"
                        size="sm"
                      >
                        {t("global.unfinished")}
                      </Button>
                      {(isManager || isAdmin || isCompanyAdmin) &&
                        (assigned === "" || assigned) && (
                          <>
                            <Button
                              variant={`${
                                pagination?.assigned === "yes" ? "" : "outline-"
                              }warning mx-2`}
                              onClick={() =>
                                changeFilterHandler("assigned", "yes")
                              }
                              className="d-flex align-items-center"
                              size="sm"
                            >
                              {t("global.assigned")}
                            </Button>
                            <Button
                              variant={`${
                                pagination?.assigned === "no" ? "" : "outline-"
                              }info`}
                              onClick={() =>
                                changeFilterHandler("assigned", "no")
                              }
                              className="d-flex align-items-center"
                              size="sm"
                            >
                              {t("global.unassigned")}
                            </Button>
                          </>
                        )}
                      {(isManager || isAdmin || isCompanyAdmin) && (
                        <>
                          <Select
                            key={nanoid()}
                            styles={{
                              control: (baseStyles, state) => ({
                                ...baseStyles,
                                width: "200px",
                              }),
                            }}
                            className="react-select-field ms-2"
                            onChange={(e: any) => handleFilterUser(e)}
                            placeholder={`User:`}
                            defaultValue={
                              step !== "TRANSLATION"
                                ? (pagination as any)?.copywriter > 0
                                  ? userList
                                      ?.map((user: any) => ({
                                        label: `${user?.firstName} ${user?.lastName}`,
                                        value: user?.id,
                                      }))
                                      ?.find(
                                        (user: any) =>
                                          user?.value ==
                                          (pagination as any)?.copywriter
                                      )
                                  : null
                                : (pagination as any)?.translator > 0
                                ? userList
                                    ?.map((user: any) => ({
                                      label: `${user?.firstName} ${user?.lastName}`,
                                      value: user?.id,
                                    }))
                                    ?.find(
                                      (user: any) =>
                                        user?.value ==
                                        (pagination as any)?.translator
                                    )
                                : null
                            }
                            options={userList?.map((user: any) => ({
                              label: `${user?.firstName} ${user?.lastName}`,
                              value: user?.id,
                            }))}
                            isClearable={true}
                          />
                        </>
                      )}
                    </>
                  )}

                  {step !== "BASE" && (
                    <>
                      <h6 className="m-0 p-0 ms-2">
                        {t("workflow.per_page")}:
                      </h6>
                      <Select
                        className="react-select-field ms-2"
                        onChange={handlePerPage}
                        placeholder={`${t("workflow.per_page")}:`}
                        options={perPageOptions}
                        defaultValue={
                          pagination?.perPage
                            ? {
                                label: pagination?.perPage,
                                value: pagination?.perPage,
                              }
                            : perPageOptions[0]
                        }
                      />
                    </>
                  )}
                  {step === "TRANSLATION" &&
                    (isManager || isAdmin || isCompanyAdmin) && (
                      <>
                        <h6 className="m-0 p-0 ms-2">
                          {t("workflow.copywriting_status")}:
                        </h6>
                        <Select
                          styles={{
                            control: (baseStyles, state) => ({
                              ...baseStyles,
                              minWidth: "150px",
                            }),
                          }}
                          className="react-select-field ms-2"
                          onChange={handleFilterCopywritingStatus}
                          placeholder={`${t("workflow.copywriting_status")}:`}
                          options={copywritingStatusOptions}
                          defaultValue={
                            pagination?.copywritingStatus
                              ? copywritingStatusOptions?.find(
                                  (item: any) =>
                                    item?.value ===
                                    pagination?.copywritingStatus
                                )
                              : copywritingStatusOptions[0]
                          }
                        />
                      </>
                    )}
                </div>
              )
            }
            <div className="d-flex align-items-center">
              {showMarkAsComplete && (
                <Button
                  variant="success"
                  onClick={
                    () => handleMarkAsComplete()
                    // navigate(`/app/workflow/step${step + 1}/${id}`)
                  }
                  className="d-flex align-items-center"
                  disabled={
                    step === "TRANSLATION"
                      ? !isDisabledComplete
                      : precent !== 100
                  }
                  size="sm"
                >
                  {t("global.markAsComplete")}
                </Button>
              )}
              {step !== "BASE" && showNextButton && (
                <Button
                  variant="outline-success"
                  onClick={handleNextProduct}
                  className="ms-2"
                  disabled={precent === 100}
                  size="sm"
                >
                  {t("global.next")}
                  <i className="ri-arrow-right-line ms-1" />
                </Button>
              )}
            </div>
          </Col>
        )}
    </Row>
  );
};
