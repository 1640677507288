import { useTranslation } from "react-i18next";
import { IUserDTO } from "../dto/IUser";
import { formatDate, formatRoles } from "../../../shared/functions/Functions";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { CustomImageHandler } from "../../../shared/components/CustomImageHandler";
import { Pagination } from "../../../shared/paginations/Paginations";
import React, { SetStateAction, useEffect, useState } from "react";
import { IUser } from "../../../model/authentification/IRegister";
import { UserService } from "../service";
import { ErrorToast, SuccessToast } from "../../../shared/toasters/toasters";
import { ConfirmDelete } from "../../../shared/components/ConfirmDelete";
import { EmptyState } from "../../../shared/components/EmptyState";
import Select, { SingleValue } from "react-select";
import { themeSelect } from "../../../shared/components/Select2";
import { LoadingAnimation } from "../../../shared/components/Loading";
import { useSelector } from "react-redux";
import { IAllStates } from "../../../store/rootReducer";
import { CompanyService } from "../../companies/service";
import { ICompaniesDTO } from "../../companies/dto/ICompanies";

interface IUserListProps {
  accessRights: string[];
  userList: IUserDTO[];
  page: number;
  setPagination: any;
  totalPages: number;
  handlePages: (updateNumber: number) => void;
  editHandler: (userId: number) => void;
  changeFilterHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
  setTriggerUpdate: React.Dispatch<SetStateAction<boolean>>;
  companiesList: ICompaniesDTO[];
}

export const UserList = ({
  accessRights,
  userList,
  page,
  setPagination,
  totalPages,
  handlePages,
  changeFilterHandler,
  setTriggerUpdate,
  editHandler,
  companiesList,
}: IUserListProps) => {
  const [selectedUser, setSelectedUser] = useState<IUser>();
  const { t } = useTranslation();
  const options = ["Yes", "No"];
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const [windowWidth, setWindowWidth] = useState(getWindowDimensions());

  const isAdmin = useSelector((state: IAllStates) =>
    state?.auth?.roles?.some((role) => role === "ROLE_ADMIN")
  );
  const deleteHandler = () => {
    selectedUser?.id &&
      UserService.deleteUserById(selectedUser?.id)
        .then((response) => {
          if (response === "") {
            SuccessToast(
              `${t("toasts.successfully_deleted")} ${selectedUser?.firstName} ${
                selectedUser?.lastName
              }`
            );
            setTriggerUpdate((prev) => !prev);
          }
        })
        .catch((error) => ErrorToast(error));
  };
  function checkDate(date: string) {
    const firstDate = new Date(date);
    const todayDate = new Date();
    todayDate.setHours(0, 0, 0, 0);
    return firstDate < todayDate;
  }
  const handleSelectCopywriter = (
    selectedSingle: SingleValue<{ label: string; value: string }>
  ) => {
    let result: boolean | null;
    if (selectedSingle?.value) {
      switch (selectedSingle?.value as string) {
        case "Yes":
          result = true;
          break;
        case "No":
          result = false;
          break;
        default:
          result = null;
      }
    } else {
      result = null;
    }
    setPagination((prev: any) => ({ ...prev, isCopywriter: result }));
  };

  const handleSelectCompany = (
    selectedSingle: SingleValue<{ label: string; value: string }>
  ) => {
    setPagination((prev: any) => ({ ...prev, company: selectedSingle?.value }));
  };

  const showAllRoles = (user: any) => {
    const roles = (user?.roles as any[]) ?? [];
    const customRolesArray =
      user?.customRoles?.map((role: any) => role?.name as string) ?? [];
    const allRoles = roles.concat(customRolesArray);

    return formatRoles(allRoles);
    //  roles.concat(customRolesArray).join(", ");
  };

  useEffect(() => {
    function handleResize() {
      setWindowWidth(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  function getWindowDimensions() {
    const { innerWidth: width } = window;
    return {
      width,
    };
  }
  const correctFormatExpires = (date: any) => {
    if (date) {
      const datum = new Date(date);
      return datum.toLocaleDateString("sr-RS");
    } else {
      return null;
    }
  };
  return (
    <div className="card">
      <div className="card-header pb-0">
        <div className="d-flex justify-content-between">
          <h4 className="card-title mg-b-0">{`${t("users.listOfUsers")}`}</h4>
        </div>
      </div>
      <div className="card-body">
        <div className="d-flex justify-content-start mb-3">
          <input
            className="form-control  me-2"
            name={"name"}
            placeholder={`${t("users.searchName")}...`}
            type="text"
            style={{ width: `${windowWidth?.width > 767 ? `25%` : `50%`}` }}
            onChange={changeFilterHandler}
            maxLength={30}
          />

          <input
            className="form-control  me-2"
            name={"email"}
            onChange={changeFilterHandler}
            placeholder={`${t("users.searchEmail")}...`}
            type="email"
            style={{ width: `${windowWidth?.width > 767 ? `25%` : `50%`}` }}
            maxLength={30}
          />
          <Select
            className="react-select-field   me-2"
            theme={themeSelect}
            isClearable={true}
            placeholder={"Copywriter"}
            options={options?.map((option: string) => ({
              label: option ?? "-",
              value: option,
            }))}
            onChange={(e) => {
              handleSelectCopywriter(e as any);
            }}
          />
          {isAdmin && (
            <Select
              className="react-select-field "
              theme={themeSelect}
              isClearable={true}
              placeholder={"Company"}
              options={companiesList?.map((option) => ({
                label: option.title ?? "-",
                value: option.id,
              }))}
              onChange={(e) => {
                handleSelectCompany(e as any);
              }}
            />
          )}
        </div>
        {userList == undefined ? (
          <LoadingAnimation />
        ) : userList.length === 0 ? (
          <EmptyState />
        ) : (
          <div className="table-responsive">
            <Table className="align-middle table-nowrap mb-0">
              <thead className="table-light">
                <tr>
                  <th scope="col">{`${t("global.id")}`}</th>
                  <th scope="col">{`${t("users.status")}`}</th>
                  <th scope="col">{`${t("users.user")}`}</th>
                  {isAdmin && <th scope="col">{`${t("users.company")}`}</th>}
                  <th scope="col">{`${t("users.roles")}`}</th>
                  <th scope="col">{`${t("global.created_at")}`}</th>
                  <th scope="col">{`${t("global.expires_at")}`}</th>

                  {(accessRights?.includes("user_can_edit") ||
                    accessRights?.includes("user_can_delete")) && (
                    <th scope="col">{`${t("global.actions")}`}</th>
                  )}
                </tr>
              </thead>
              <tbody>
                {userList?.map((user) => {
                  const isSuperAdmin = user?.roles?.some(
                    (role: string) => role === "ROLE_SUPER_ADMIN"
                  );
                  return (
                    <tr key={user?.id}>
                      <td>
                        <Link to="#" className="fw-medium">
                          #{user?.id}
                        </Link>
                      </td>
                      <td
                        className={`text-${
                          user?.isActive ? "success" : "danger"
                        }`}
                      >
                        {user?.isActive ? (
                          <span>
                            <i className="ri-checkbox-circle-line fs-17 align-middle" />{" "}
                            Active
                          </span>
                        ) : (
                          <span>
                            <i className="ri-close-circle-line fs-17 align-middle" />
                            Inactive
                          </span>
                        )}
                      </td>
                      <td className="text-start">
                        <CustomImageHandler
                          classes="avatar-xs rounded-circle me-2"
                          path={user?.profilePicture?.path}
                          alt="User logo"
                        />
                        {user?.firstName} {user?.lastName}
                      </td>
                      {/* <td>{formatRoles(user?.roles as string[])}</td> */}
                      {isAdmin && <td>{user?.company?.title ?? "-"}</td>}
                      <td>
                        {
                          showAllRoles(user)
                          // user?.customRoles
                          //   .map((role: any) => {
                          //     return role.name;
                          //   })
                          //   .join(", ")
                        }
                      </td>

                      <td>{formatDate(user?.createdAt)}</td>
                      <td
                        style={{
                          color: `${
                            checkDate((user as any)?.expiresAt) ? "#f20c46" : ""
                          }`,
                        }}
                      >
                        {correctFormatExpires((user as any)?.expiresAt)}
                      </td>
                      {(accessRights?.includes("user_can_edit") ||
                        accessRights?.includes("user_can_delete")) && (
                        <td>
                          {!isSuperAdmin ? (
                            <div className="d-flex align-items-center justify-content-center">
                              {accessRights?.includes("user_can_edit") && (
                                <button
                                  className="btn btn-sm btn-warning d-flex align-items-center me-2"
                                  onClick={() => editHandler(user?.id)}
                                >
                                  <i className="ri-edit-2-fill me-1" />
                                  {`${t("global.edit")}`}
                                </button>
                              )}
                              {accessRights?.includes("user_can_delete") && (
                                <button
                                  className="btn btn-sm btn-danger d-flex align-items-center"
                                  onClick={() => {
                                    setSelectedUser(user);
                                    handleShow();
                                  }}
                                >
                                  <i className="ri-delete-bin-fill me-1" />
                                  {`${t("global.delete")}`}
                                </button>
                              )}
                            </div>
                          ) : (
                            "-"
                          )}
                        </td>
                      )}
                      {/* }
                       */}
                      {/* {!isAdmin ? (
                        bilo ovako
                          <div className="d-flex align-items-center justify-content-center">
                            <button
                              className="btn btn-sm btn-warning d-flex align-items-center me-2"
                              onClick={() => editHandler(user?.id)}
                            >
                              <i className="ri-edit-2-fill me-1" />
                              {`${t("global.edit")}`}
                            </button>

                            <button
                              className="btn btn-sm btn-danger d-flex align-items-center"
                              onClick={() => {
                                setSelectedUser(user);
                                handleShow();
                              }}
                            >
                              <i className="ri-delete-bin-fill me-1" />
                              {`${t("global.delete")}`}
                            </button>
                          </div>
                        ) : (
                          "-"
                        )} */}
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        )}
        <div className="d-flex justify-content-end ">
          {totalPages <= 1 ? (
            ""
          ) : (
            <Pagination
              page={page}
              totalPages={totalPages}
              handlePagination={handlePages}
            />
          )}
        </div>
        <ConfirmDelete
          show={show}
          setShow={setShow}
          itemName={`${selectedUser?.firstName} ${selectedUser?.lastName}`}
          deleteHandler={deleteHandler}
          selectedProduct={selectedUser}
        />
      </div>
    </div>
  );
};
