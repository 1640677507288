// @ts-nocheck
import React, { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import Select from "react-select";
import { themeSelect } from "../../../../shared/components/Select2";
import { getCorrectLanguage } from "../../../../shared/functions/Functions";
import { Spinner } from "react-bootstrap";
import { IProducts, IProductsList } from "../../dto/IProducts";
import { ProductsService } from "../../service";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { IAllStates } from "../../../../store/rootReducer";
import { getCorrectFormality } from "../../.../../../../shared/functions/Functions";
import _debounce from "lodash/debounce";
import "../../../../ckeditor.css";
import Chart from "react-apexcharts";
import { ContentWordsChart } from "./ContentWordsChart";
const editorConfigurationCustom = {
  toolbar: {
    items: [
      "undo",
      "redo",
      "findAndReplace",
      "alignment",
      "heading",
      "textPartLanguage",
      "|",
      "bold",
      "italic",
      "underline",
      "link",
      "|",
      "horizontalLine",
      "fontColor",
      "fontBackgroundColor",
      "fontSize",
      "highlight",
      "selectAll",
      "pageBreak",
      "strikethrough",
      "specialCharacters",
      "restrictedEditingException",
      "numberedList",
      "bulletedList",
      "indent",
      "outdent",
      "removeFormat",
      "subscript",
      "superscript",
      "HtmlEmbed",
      "sourceEditing",
      "insertTable",
      "|",
      "glossary-scan",
      // "glossary-ignore",
      "glossary-change",
    ],
    shouldNotGroupWhenFull: true, // optional, to prevent grouping when the toolbar is full
  },
  table: {
    contentToolbar: [
      "tableColumn",
      "tableRow",
      "mergeTableCells",
      "tableProperties",
      "tableCellProperties",
      "toggleTableCaption",
    ],
  },
  removePlugins: ["Title", "Markdown"], // remove the title plugin from the editor
};

interface IEditLanguage {
  isTranslator?: boolean;
  handleCloseContent?: (() => void | undefined) | undefined;
  language?: string;
  setTriggerUpdate?: any;
  handleTranslateBlock?: () => void;
  handleSelectSingle?: () => void;
  handleSelectSingleFormality?: () => void;
  handleTranslate?: () => void;
  currentProduct?: IProductsList | IProducts;
  certainBlocks?: any;
  handleAiVariation?: () => void;
  changeHandlerEditor?: () => void;
  changeHandlerEditorBlock?: () => void;
  isLoading?: any;
  handleSubmit: (event: React.FormEvent, language: string) => void;
  allBlocks: any;
  productStaticts?: any;
}

export const ContentEditLanguage = ({
  productStaticts = {},
  isTranslator = false,
  handleCloseContent,
  language,
  handleTranslateBlock,
  handleSelectSingle,
  handleSelectSingleFormality,
  handleTranslate,
  currentProduct,
  certainBlocks,
  handleAiVariation,
  isLoading,
  changeHandlerEditor,
  changeHandlerEditorBlock,
  handleSubmit,
  setTriggerUpdate,
  allBlocks,
}: IEditLanguage) => {
  const { state: pagination }: any = useLocation();
  const { state: content }: any = useLocation();
  const location = useLocation();
  const isWorkflow = location?.pathname?.includes("workflow");
  const { t } = useTranslation();
  const { deeplFormality } = useSelector((state: IAllStates) => state.enums);
  const { id } = useParams();
  const navigate = useNavigate();
  const [isLoadingCopy, setIsLoadingCopy] = useState(false);
  const [wordCount, setWordCount] = useState<IEditorCounter>();
  const { translationLanguageEnum } = useSelector(
    (state: IAllStates) => state.enums
  );
  const updateWordsCound = _debounce(
    ({ wordsCount, charactersCount, language }, nameField) => {
      setWordCount((prev) => ({
        ...prev,
        [language]: {
          ...prev?.[language],
          [nameField]: {
            ...prev?.[language]?.[nameField],
            words: wordsCount,
            characters: charactersCount,
          },
        },
      }));
    },
    500
  );
  const handleCopyVariation = () => {
    setIsLoadingCopy(true);
    id &&
      ProductsService.cloneProductContent(+id)
        .then((response) => {
          setTriggerUpdate((prev) => !prev);
        })
        .catch((error) => ErrorToast(error))
        .finally(() => setIsLoadingCopy(false));
  };
  return (
    <>
      <div className="row mt-4">
        <div className="col-md-12">
          <div className="d-flex justify-content-between align-items-center my-1">
            <label className="m-0" htmlFor="description">
              {t("products.description")}
            </label>
            <div className="d-flex justify-content-end align-items-center my-1">
              <>
                <Select
                  className="custom_zindex react-select-field me-2"
                  name="content"
                  theme={themeSelect}
                  placeholder={`${t("formality.select_formality")}`}
                  options={deeplFormality?.map((formality) => ({
                    value: formality,
                    label: getCorrectFormality(formality, t),
                  }))}
                  // key={state?.id}
                  onChange={(e) => {
                    handleSelectSingleFormality(e as any);
                  }}
                />
                <div className="">
                  <Select
                    className="custom_zindex react-select-field"
                    name="description"
                    theme={themeSelect}
                    placeholder="Translate from"
                    options={translationLanguageEnum
                      ?.filter((lang) => lang !== language)
                      ?.map((lang) => ({
                        value: lang,
                        label: getCorrectLanguage(lang, t),
                      }))}
                    onChange={(e) => {
                      handleSelectSingle(
                        e as any,
                        language as string,
                        "description"
                      );
                    }}
                  />
                </div>
                <button
                  className="btn btn-secondary ms-3"
                  onClick={handleTranslate}
                >
                  {t("global.translate")}
                </button>
              </>
            </div>
          </div>
          <>
            <CKEditor
              config={editorConfigurationCustom}
              editor={Editor}
              data={currentProduct?.translation?.[language]?.description ?? ""}
              onReady={(editor) => {
                editor.ui.view.element.setAttribute(
                  "id",
                  String(Math.random().toString(16).slice(2))
                );
                const wordsCount = editor.plugins.get("WordCount").words;
                const charactersCount =
                  editor.plugins.get("WordCount").characters;
                setWordCount((prev) => ({
                  ...prev,
                  [language]: {
                    ...prev?.[language],
                    description: {
                      ...prev?.[language]?.["description"],
                      words: wordsCount,
                      characters: charactersCount,
                    },
                  },
                }));
              }}
              onChange={(event, editor) => {
                const data = editor.getData();
                changeHandlerEditor(language, data, "description");
                const wordsCount = editor.plugins.get("WordCount").words;
                const charactersCount =
                  editor.plugins.get("WordCount").characters;
                updateWordsCound(
                  {
                    wordsCount,
                    charactersCount,
                    language,
                  },
                  "description"
                );
              }}
            />
            <div className="d-flex align-items-center justify-content-between mt-3">
              <div className="ck ck-word-count">
                <div className="ck-word-count__words">
                  {t("global.words")}:
                  {wordCount?.[language]?.["description"]?.words}
                </div>
                <div className="ck-word-count__characters">
                  Characters:{" "}
                  {wordCount?.[language]?.["description"]?.characters}
                </div>
              </div>
              <div>
                {productStaticts?.contentRequirements?.[language]?.[
                  "description"
                ]?.wordCountHigh > 0 && (
                  <ContentWordsChart
                    words={wordCount?.[language]?.["description"]?.words}
                    medium={
                      productStaticts?.contentRequirements?.[language]?.[
                        "description"
                      ]?.wordCountMedium
                    }
                    high={
                      productStaticts?.contentRequirements?.[language]?.[
                        "description"
                      ]?.wordCountHigh
                    }
                  />
                )}
              </div>
            </div>
          </>
        </div>
        <div className="col-md-12 mt-3">
          <div className="d-flex justify-content-between align-items-center my-1">
            <label className="m-0" htmlFor="introText">
              {t("products.introText")}
            </label>
            <div className="d-flex justify-content-end align-items-center my-1">
              <>
                <Select
                  className="custom_zindex react-select-field me-2"
                  name="content"
                  theme={themeSelect}
                  placeholder={`${t("formality.select_formality")}`}
                  options={deeplFormality?.map((formality) => ({
                    value: formality,
                    label: getCorrectFormality(formality, t),
                  }))}
                  // key={state?.id}
                  onChange={(e) => {
                    handleSelectSingleFormality(e as any);
                  }}
                />
                <div>
                  <Select
                    className="custom_zindex react-select-field"
                    theme={themeSelect}
                    placeholder="Translate from"
                    isClearable={true}
                    options={translationLanguageEnum
                      ?.filter((lang) => lang !== language)
                      ?.map((lang) => ({
                        value: lang,
                        label: getCorrectLanguage(lang, t),
                      }))}
                    onChange={(e) => {
                      handleSelectSingle(
                        e as any,
                        language as string,
                        "introText"
                      );
                    }}
                  />
                </div>
                <button
                  className="btn btn-secondary ms-3"
                  onClick={handleTranslate}
                >
                  {t("global.translate")}
                </button>
              </>
            </div>
          </div>
          <>
            <CKEditor
              config={editorConfigurationCustom}
              editor={Editor}
              data={currentProduct?.translation?.[language]?.introText ?? ""}
              onReady={(editor) => {
                editor.ui.view.element.setAttribute(
                  "id",
                  String(Math.random().toString(16).slice(2))
                );
                const wordsCount = editor.plugins.get("WordCount").words;
                const charactersCount =
                  editor.plugins.get("WordCount").characters;
                setWordCount((prev) => ({
                  ...prev,
                  [language]: {
                    ...prev?.[language],
                    introText: {
                      ...prev?.[language]?.["introText"],
                      words: wordsCount,
                      characters: charactersCount,
                    },
                  },
                }));
              }}
              onChange={(event, editor) => {
                const data = editor.getData();
                changeHandlerEditor(language, data, "introText");
                const wordsCount = editor.plugins.get("WordCount").words;
                const charactersCount =
                  editor.plugins.get("WordCount").characters;
                updateWordsCound(
                  {
                    wordsCount,
                    charactersCount,
                    language,
                  },
                  "introText"
                );
              }}
            />
            <div className="d-flex align-items-center justify-content-between mt-3">
              <div className="ck ck-word-count">
                <div className="ck-word-count__words">
                  {t("global.words")}:
                  {wordCount?.[language]?.["introText"]?.words}
                </div>
                <div className="ck-word-count__characters">
                  Characters: {wordCount?.[language]?.["introText"]?.characters}
                </div>
              </div>
              <div>
                {productStaticts?.contentRequirements?.[language]?.[
                  "intro_text"
                ]?.wordCountHigh > 0 && (
                  <ContentWordsChart
                    words={wordCount?.[language]?.["introText"]?.words}
                    medium={
                      productStaticts?.contentRequirements?.[language]?.[
                        "intro_text"
                      ]?.wordCountMedium
                    }
                    high={
                      productStaticts?.contentRequirements?.[language]?.[
                        "intro_text"
                      ]?.wordCountHigh
                    }
                  />
                )}
              </div>
            </div>
            {/* <div className="d-flex justify-content-start text-align-center mt-3">
              <div className="ck ck-word-count">
                <div className="ck-word-count__words">
                  {t("global.words")}:{" "}
                  {wordCount?.[language]?.["introText"]?.words}
                </div>
                <div className="ck-word-count__characters">
                  {t("global.characters")}:{" "}
                  {wordCount?.[language]?.["introText"]?.characters}
                </div>
              </div>
            </div> */}
          </>
        </div>
        <div className="col-md-12 mt-3">
          <div className="d-flex justify-content-between align-items-center my-1">
            <label className="m-0" htmlFor="bullets">
              {t("products.bullets")}
            </label>
            <div className="d-flex justify-content-end align-items-center my-1">
              <>
                <Select
                  className="custom_zindex react-select-field me-2"
                  name="content"
                  theme={themeSelect}
                  placeholder={`${t("formality.select_formality")}`}
                  options={deeplFormality?.map((formality) => ({
                    value: formality,
                    label: getCorrectFormality(formality, t),
                  }))}
                  // key={state?.id}
                  onChange={(e) => {
                    handleSelectSingleFormality(e as any);
                  }}
                />

                <div className="">
                  <Select
                    className="custom_zindex react-select-field"
                    theme={themeSelect}
                    placeholder="Translate from"
                    isClearable={true}
                    options={translationLanguageEnum
                      ?.filter((lang) => lang !== language)
                      ?.map((lang) => ({
                        value: lang,
                        label: getCorrectLanguage(lang, t),
                      }))}
                    //   key={state?.id}
                    onChange={(e) => {
                      handleSelectSingle(
                        e as any,
                        language as string,
                        "bullets"
                      );
                    }}
                  />
                </div>
                <button
                  className="btn btn-secondary ms-3"
                  onClick={handleTranslate}
                >
                  {t("global.translate")}
                </button>
              </>
            </div>
          </div>
          <>
            <CKEditor
              config={editorConfigurationCustom}
              editor={Editor}
              data={currentProduct?.translation?.[language]?.bullets ?? ""}
              onReady={(editor) => {
                editor.ui.view.element.setAttribute(
                  "id",
                  String(Math.random().toString(16).slice(2))
                );
                const wordsCount = editor.plugins.get("WordCount").words;
                const charactersCount =
                  editor.plugins.get("WordCount").characters;
                setWordCount((prev) => ({
                  ...prev,
                  [language]: {
                    ...prev?.[language],
                    bullets: {
                      ...prev?.[language]?.["bullets"],
                      words: wordsCount,
                      characters: charactersCount,
                    },
                  },
                }));
              }}
              onChange={(event, editor) => {
                const data = editor.getData();
                changeHandlerEditor(language, data, "bullets");
                const wordsCount = editor.plugins.get("WordCount").words;
                const charactersCount =
                  editor.plugins.get("WordCount").characters;
                updateWordsCound(
                  {
                    wordsCount,
                    charactersCount,
                    language,
                  },
                  "bullets"
                );
              }}
            />
            <div className="d-flex align-items-center justify-content-between  mt-3">
              <div className="ck ck-word-count">
                <div className="ck-word-count__words">
                  {t("global.words")}:
                  {wordCount?.[language]?.["bullets"]?.words}
                </div>
                <div className="ck-word-count__characters">
                  Characters: {wordCount?.[language]?.["bullets"]?.characters}
                </div>
              </div>
              <div>
                {productStaticts?.contentRequirements?.[language]?.["bullets"]
                  ?.wordCountHigh > 0 && (
                  <ContentWordsChart
                    words={wordCount?.[language]?.["bullets"]?.words}
                    medium={
                      productStaticts?.contentRequirements?.[language]?.[
                        "bullets"
                      ]?.wordCountMedium
                    }
                    high={
                      productStaticts?.contentRequirements?.[language]?.[
                        "bullets"
                      ]?.wordCountHigh
                    }
                  />
                )}
              </div>
            </div>
          </>
        </div>
        {/* ispod */}
        {!!certainBlocks?.length &&
          certainBlocks?.map((block: any) => (
            <div className="col-md-12 mt-3">
              <div className="d-flex justify-content-between align-items-center my-1">
                <label htmlFor="value">{block?.name}</label>
                <div className="d-flex justify-content-end align-items-center my-1">
                  <>
                    {" "}
                    <Select
                      className="custom_zindex react-select-field me-2"
                      name="content"
                      theme={themeSelect}
                      placeholder={`${t("formality.select_formality")}`}
                      options={deeplFormality?.map((formality) => ({
                        value: formality,
                        label: getCorrectFormality(formality, t),
                      }))}
                      // key={state?.id}
                      onChange={(e) => {
                        handleSelectSingleFormality(e as any);
                      }}
                    />
                    <div className="">
                      <Select
                        className="custom_zindex react-select-field"
                        name={block?.name}
                        theme={themeSelect}
                        placeholder="Translate from"
                        options={translationLanguageEnum
                          .filter((lang) => lang !== language)
                          ?.map((lang) => ({
                            value: lang,
                            label: getCorrectLanguage(lang, t),
                          }))}
                        // key={state?.id}
                        onChange={(e) => {
                          handleSelectSingle(
                            e as any,
                            language as string,
                            block?.id
                          );
                        }}
                      />
                    </div>
                    <button
                      className="btn btn-secondary ms-3"
                      onClick={handleTranslateBlock}
                    >
                      {t("global.translate")}
                    </button>
                  </>
                </div>
              </div>
              <CKEditor
                config={editorConfigurationCustom}
                editor={Editor}
                data={
                  (currentProduct?.translation?.[language]?.blocks?.[
                    block?.id
                  ] as string) ?? ""
                }
                onReady={(editor) => {
                  editor.ui.view.element.setAttribute(
                    "id",
                    String(Math.random().toString(16).slice(2))
                  );
                  const wordsCount = editor.plugins.get("WordCount").words;
                  const charactersCount =
                    editor.plugins.get("WordCount").characters;
                  setWordCount((prev) => ({
                    ...prev,
                    [language]: {
                      ...prev?.[language],
                      [block.name]: {
                        ...prev?.[language]?.[block.name],
                        words: wordsCount,
                        characters: charactersCount,
                      },
                    },
                  }));
                }}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  changeHandlerEditorBlock(
                    data,
                    language as string,
                    // "block",
                    block?.id as number
                  );
                  const wordsCount = editor.plugins.get("WordCount").words;
                  const charactersCount =
                    editor.plugins.get("WordCount").characters;
                  updateWordsCound(
                    {
                      wordsCount,
                      charactersCount,
                      language,
                    },
                    block.name
                  );
                }}
              />
              <div className="d-flex justify-content-start text-align-center mt-3">
                <div className="ck ck-word-count">
                  <div className="ck-word-count__words">
                    {t("global.words")}:{" "}
                    {wordCount?.[language]?.[block.name]?.words}
                  </div>
                  <div className="ck-word-count__characters">
                    Characters:{" "}
                    {wordCount?.[language]?.[block.name]?.characters}
                  </div>
                </div>
              </div>
            </div>
          ))}

        <div className="d-flex justify-content-end mt-2">
          {!isTranslator && (
            <>
              <button
                className="btn btn-info"
                onClick={() => handleCloseContent()}
              >
                {t("products.close")}
              </button>
              <button
                className="btn btn-secondary ms-2"
                onClick={(e) => handleCopyVariation()}
              >
                {isLoadingCopy ? (
                  <Spinner animation="border" size="sm" role="status" />
                ) : (
                  // <span>{t("products.ai_variation")}</span>
                  <span>Copy As Variation</span>
                )}
              </button>
              <button
                className="btn btn-success ms-2"
                onClick={(e) => handleAiVariation()}
              >
                {isLoading ? (
                  <Spinner animation="border" size="sm" role="status" />
                ) : (
                  <span>{t("products.ai_variation")}</span>
                )}
              </button>
            </>
          )}
          <button
            className="btn btn-secondary ms-2"
            onClick={(e) => {
              handleSubmit(e, language);
              // localStorage.setItem("workflowLang", "");
            }}
          >
            {t("products.save")}
          </button>
          {id && isWorkflow && (
            <button
              className="btn btn-primary ms-2"
              onClick={(e) => {
                handleSubmit(e, language, true);
                // localStorage.setItem("workflowLang", "");
              }}
            >
              {t("products.next")}
            </button>
          )}
        </div>
      </div>
    </>
  );
};
