import React, { useEffect, useState } from "react";
import { Card, Col, Spinner, Table } from "react-bootstrap";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { WorkflowStepper } from "./WorkflowStepper";
import { useDispatch, useSelector } from "react-redux";
import { IAllStates } from "../../../store/rootReducer";
import Select, { SingleValue } from "react-select";
import { useTranslation } from "react-i18next";
import { ICopywriterData } from "./WorkflowStep5";
import { WorkflowService } from "../service";
import { ErrorToast, SuccessToast } from "../../../shared/toasters/toasters";
import { IWorkflow } from "../dto/IWorkflow";
import { ProductsListView } from "../../products/components/ProductsListView";
import { ProductsService } from "../../products/service";
import { IProductsList } from "../../products/dto/IProducts";
import { ContentUpdate } from "../../products/content/components/ContentUpdate";
import { EmptyState } from "../../../shared/components/EmptyState";
import { Pagination } from "../../../shared/paginations/Paginations";
import workflowLangSlice from "../../../store/workflow/workflowLangSlice";
import step6PaginationSlice from "../../../store/workflow/step6PaginationSlice";
import { themeSelect } from "../../../shared/components/Select2";
import { getCorrectFormality } from "../../../shared/functions/Functions";
import { ModalTranslateAll } from "../../../shared/components/ModalTranslateAll";
import { GoogleSearchPromptService } from "../../googleSearchPrompt/service";
const progressArr = ["assigment", "translation"];
interface IProgressData {
  done: number;
  total: number;
  percent: number;
}

interface IProgressBar {
  assigment: IProgressData;
  translation: IProgressData;
}

const WorkflowStep6 = () => {
  const { search } = useLocation();
  const mainTable = true;
  localStorage.setItem("translationNextLang", "");
  const dispatch = useDispatch();
  const [workflowLanguage, setWorkflowLanguage] = useState<string[]>([]);
  const [currentLanguage, setCurrentLanguage] = useState<string>();
  const stepPagination = useSelector(
    (state: IAllStates) => state.step6Pagination as any
  );
  const { accessRights } = useSelector((state: IAllStates) => state.auth);
  const [activeTab, setActiveTab] = useState(currentLanguage ?? "");
  const [updateList, setUpdateList] = useState<boolean>(false);
  const [translatorList, setTranslatorList] = useState<any[]>([]);
  const [formality, setFormality] = useState(null);
  const { deeplFormality } = useSelector((state: IAllStates) => state.enums);
  const [staticsView2, setStaticsView2] = useState<any[]>([]);
  const [checkedValues, setCheckedValues] = useState<number[]>([]);
  const [isTranslator, setIsTranslator] = useState(false);
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const [triggerUpdate, setTriggerUpdate] = useState(false);
  const [showTranslateDialog, setShowTranslateDialog] = useState(false);
  const [isTranslationDone, setIsTranslationDone] = useState(false);
  const [selectedProductId, setSelectedProductId] = useState(0);
  const currentUser = JSON.parse(localStorage.getItem("currentUser") as string);
  const [currentWorkflow, setCurrentWorkflow] = useState<IWorkflow | null>(
    null
  );
  const [googleSearchList, setGoogleSearchList] = useState<any[]>([]);
  const [googleSearchPrompt, setGoogleSearchPrompt] = useState<number>(0);
  const [progressBarData, setProgressBarData] = useState<
    IProgressBar | undefined
  >(undefined);
  const lightOrDarkMode = useSelector(
    (state: IAllStates) => state.lightOrDarkMode
  );
  const [userList, setUserList] = useState([]);
  const [triggerFiltersFirstLang, setTriggerFiltersFirstLang] = useState(false);
  const [translatorData, setTranslatorData] = useState<any>({ products: [] });
  const { t } = useTranslation();
  const [productsList, setProductsList] = useState<any[]>([]);
  const currentPage = Number(
    +(search.split("=").at(-1) ?? 0) !== 0 ? search.split("=").at(-1) : 1
  );
  const [pagination, setPagination] = useState({
    ...(stepPagination as unknown as any),
  });
  const [page, setPage] = useState(currentPage);
  const [totalPages, setTotalPages] = useState(0);
  const { translationLanguageEnum } = useSelector(
    (state: IAllStates) => state.enums
  );
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const handlePages = (updatePage: number) => {
    // navigate(`?page=${updatePage}`);
    setPagination({ ...pagination, page: updatePage });
    dispatch(
      step6PaginationSlice.actions.setStep6Pagination({
        ...(stepPagination as any),
        page: updatePage,
      })
    );
    setPage(updatePage);
  };

  const isManager = currentUser?.roles.includes("ROLE_MANAGER");
  const isAdmin = currentUser?.roles.includes("ROLE_ADMIN");
  const isCompanyAdmin = currentUser?.roles.includes("ROLE_COMPANY_ADMIN");
  // const isAdmin = currentUser.roles.includes(
  //   "ROLE_ADMIN" || "ROLE_COMPANY_ADMIN" || "ROLE_MANAGER"
  // );
  const selectTranslatorHandler = (event: any) => {
    setTranslatorData((prev: any) => ({ ...prev, user: +event?.value }));
  };
  function handleSelectSingleFormality(
    selectedSingle: SingleValue<{ label: string; value: string }>
  ) {
    setFormality(selectedSingle?.value as any);
  }
  const viewClickHandler = (id: number) => {
    navigate(`/app/products/view/base-data/${id}`);
  };

  const toForLinkFunc = (productId: number) => {
    return `/app/workflow/step6/${productId}/${id}`;
  };
  const submitFillWorkflow = (e: any) => {
    e.preventDefault();
    GoogleSearchPromptService.fillProductWorkflow({
      workflowId: Number(id),
      promptId: googleSearchPrompt,
      titleLanguage: currentWorkflow?.sourceLang,
    })
      .then((response: any) => {
        SuccessToast(`${response?.message}`);
      })
      .catch((error) => ErrorToast(error));
  };
  const handleFilterUser = (e: any) => {
    dispatch(
      step6PaginationSlice.actions.setStep6Pagination({
        ...(stepPagination as any),
        translator: e?.value,
        assigned: "",
        page: 1,
      })
    );
    setPagination((prev: any) => ({
      ...prev,
      translator: e?.value,
      assigned: "",
      page: 1,
    }));
  };
  const editClickHandler = (e: any, productId: number) => {
    if (e.type === "click") {
      //ovdje setujemo jezik za prevodioca
      localStorage.setItem("tabTranslateLanguage", activeTab ?? "");
      navigate(`/app/workflow/step6/${productId}/${id}`);
      setSelectedProductId(productId);
    } else if (e.type === "contextmenu") {
      window.open(`/app/workflow/step6/${productId}/${id}`, "_blank");
      setSelectedProductId(productId);
    }
  };
  const translateAll = () => {
    setIsLoading(true);
    WorkflowService.workflowTranslateAll(Number(id), {
      formality: formality,
    })
      .then((response) => {
        SuccessToast(`${t("toasts.successfully_translated")}`);
        setTriggerUpdate((prev) => !prev);
      })
      .catch((error) => {
        ErrorToast(error);
      })
      .finally(() => setIsLoading(false));
  };
  const submitGoogleSearch = (e: any) => {
    e.preventDefault();
    GoogleSearchPromptService.enrichProductWorkflow({
      workflowId: Number(id),
      promptId: googleSearchPrompt,
      titleLanguage: currentWorkflow?.sourceLang,
    })
      .then((response: any) => {
        SuccessToast(`${response?.message}`);
      })
      .catch((error) => ErrorToast(error));
  };
  const funcForFirstLoading = (firstLang: string) => {
    if (!pagination?.lang) {
      setPagination({ ...(stepPagination as unknown as any), lang: firstLang });
    } else {
      return;
    }
  };
  // const changeFilterHandler = (

  //   name: string,
  //   value: string,
  //   resetFilters?: boolean
  // ) => {
  //   if (resetFilters) {
  //     setPagination((prev: any) => ({
  //       ...prev,
  //       status: "",
  //       assigned: "",
  //       translator: null,
  //       page: 1,
  //     }));
  //     dispatch(
  //       step6PaginationSlice.actions.setStep6Pagination({
  //         ...(stepPagination as any),
  //         status: "",
  //         assigned: "",
  //         translator: null,
  //         page: 1,
  //       })
  //     );
  //   } else if (
  //     pagination?.status === "" &&
  //     (value === "yes" || value === "no")
  //   ) {
  //     setPagination((prev: any) => ({
  //       ...prev,
  //       [name]: value,
  //       status: null,
  //       page: 1,
  //     }));
  //     dispatch(
  //       step6PaginationSlice.actions.setStep6Pagination({
  //         ...(stepPagination as any),
  //         [name]: value,
  //         status: null,
  //         page: 1,
  //       })
  //     );
  //   } else {
  //     setPagination((prev: any) => ({
  //       ...prev,
  //       [name]: value,
  //       page: 1,
  //     }));
  //     dispatch(
  //       step6PaginationSlice.actions.setStep6Pagination({
  //         ...(stepPagination as any),
  //         [name]: value,
  //         page: 1,
  //       })
  //     );
  //   }
  // };
  const changeFilterHandler = (name: string, value: string) => {
    if (value === "") {
      dispatch(
        step6PaginationSlice.actions.setStep6Pagination({
          ...(stepPagination as any),
          page: 1,
          [name]: value,
          translator: null,
          assigned: "",
        })
      );
      setPagination((prev: any) => ({
        ...prev,
        [name]: value,
        translator: null,
        assigned: "",
      }));
    } else if (name === "assigned") {
      dispatch(
        step6PaginationSlice.actions.setStep6Pagination({
          ...(stepPagination as any),
          page: 1,
          [name]: value,
          translator: null,
        })
      );
      setPagination((prev: any) => ({
        ...prev,
        [name]: value,
        translator: null,
      }));
    } else {
      dispatch(
        step6PaginationSlice.actions.setStep6Pagination({
          ...(stepPagination as any),
          page: 1,
          [name]: value,
        })
      );
      setPagination((prev: any) => ({ ...prev, [name]: value }));
    }
  };
  const selectGoogleSearchHandler = (e: any) => {
    setGoogleSearchPrompt(+e.value);
  };
  const changeFinishHandler = async (finishedOrUnfinished: boolean) => {
    id &&
      (await WorkflowService.changeFinishedStatusTranslation(
        Number(id),
        {
          isDone: finishedOrUnfinished,
          products: checkedValues,
          language: activeTab,
        },
        "translation"
      )
        .then((response) => {
          if (response) {
            setUpdateList((prev) => !prev);
            setCheckedValues([]);
            SuccessToast(t("toasts.successfully_updated_status"));
          }
        })
        .catch((error) => ErrorToast(error)));
  };
  const deleteHandler = (product: IProductsList) => {
    product?.id &&
      ProductsService.deleteProductById(product?.id)
        .then((response) => {
          setPagination((prev: any) => ({ ...prev, page: 1 }));
          dispatch(
            step6PaginationSlice.actions.setStep6Pagination({
              ...(stepPagination as any),
              page: 1,
            })
          );
          SuccessToast(`${t("toasts.successfully_deleted")} ${product?.title}`);
          setUpdateList((prev) => !prev);
        })
        .catch((error) => ErrorToast(error));
  };
  const checkboxHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setCheckedValues((prev) => [...prev, +e.target.value]);
    } else {
      setCheckedValues((prev) =>
        prev.filter((value) => value !== +e.target.value)
      );
    }
  };

  const checkAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setCheckedValues(productsList?.map((product) => product.id));
    } else {
      setCheckedValues([]);
    }
  };
  const handlePerPage = (e: any) => {
    setPagination((prev: any) => ({ ...prev, page: 1, perPage: e.value }));
    dispatch(
      step6PaginationSlice.actions.setStep6Pagination({
        ...(stepPagination as any),
        page: 1,
        perPage: e.value,
      })
    );
  };
  const handleFilterCopywritingStatus = (e: any) => {
    setPagination((prev: any) => ({
      ...prev,
      page: 1,
      copywritingStatus: e.value,
    }));
    dispatch(
      step6PaginationSlice.actions.setStep6Pagination({
        ...(stepPagination as any),
        page: 1,
        copywritingStatus: e.value,
      })
    );
  };

  const assignTranslatorHandler = async () => {
    id &&
      (await WorkflowService.assignTranslator(Number(id), {
        ...translatorData,
        products: checkedValues,
        lang: activeTab,
      })
        .then((response) => {
          if (response === "") {
            setUpdateList((prev) => !prev);
            setCheckedValues([]);
            SuccessToast(t("toasts.successfully_assigned"));
          }
        })
        .catch((error) => ErrorToast(error)));
  };

  useEffect(() => {
    pagination?.lang &&
      WorkflowService.getTranslationProducts(pagination, +id!)
        .then((response: any) => {
          const {
            list,
            data,
            count,
            perPage,
            currentLanguage,
            view,
            workflow,
            translators,
            assigment,
            translation,
            isTranslationDone,
            page,
            assignedTranslators,
          } = (response as any) ?? {};
          setCurrentLanguage(currentLanguage);
          setActiveTab(currentLanguage);
          // dispatch(workflowLangSlice.actions.setWorkflowLang(currentLanguage));
          // localStorage.setItem("workflowLang", currentLanguage);
          localStorage.setItem("workflowLang", currentLanguage ?? "");
          setWorkflowLanguage(workflow?.language ?? []);
          setProductsList(data);
          setIsTranslationDone(isTranslationDone);
          setCurrentWorkflow(workflow as IWorkflow);
          setStaticsView2(response?.statistics ?? []);
          setProgressBarData({
            assigment,
            translation,
          });
          window.scrollTo(0, 0);
          setUserList(assignedTranslators as any);
          setIsTranslator(+view !== 1);
          setTranslatorList(translators);
          setTotalPages(Math.ceil(count / perPage));
          setPage(page);
        })
        .catch((error) => ErrorToast(error));
  }, [pagination, currentPage, updateList, selectedProductId, activeTab]);
  // useEffect(() => {
  //   if (currentPage) {
  //     setPagination((prev: any) => ({ ...prev, page: currentPage }));
  //     dispatch(
  //       step6PaginationSlice.actions.setStep6Pagination({
  //         ...(stepPagination as any),
  //         page: currentPage,
  //       })
  //     );
  //     setPage(currentPage);
  //   }
  // }, [currentPage]);
  useEffect(() => {
    GoogleSearchPromptService.getAllPrompts({ page: 1, perPage: 0 })
      .then((response) => {
        const { data } = response;
        setGoogleSearchList(data);
      })
      .catch((error) => ErrorToast(error));
  }, []);
  const changeActiveTab = (lng: string) => {
    setActiveTab(lng);
    dispatch(workflowLangSlice.actions.setWorkflowLang(lng));
    localStorage.setItem("workflowLang", lng ?? "");
    setPagination({
      ...pagination,
      lang: lng as any,
    });
    dispatch(
      step6PaginationSlice.actions.setStep6Pagination({
        ...(stepPagination as any),
        lang: lng as any,
      })
    );
  };
  return (
    <>
      {!isTranslator ? (
        <>
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <WorkflowStepper
                  pagination={pagination}
                  userList={userList}
                  handleFilterUser={handleFilterUser}
                  funcForFirstLoading={funcForFirstLoading}
                  stepPagination={stepPagination}
                  isDisabledComplete={isTranslationDone}
                  step={"TRANSLATION"}
                  id={id}
                  changeFilterHandler={changeFilterHandler}
                  precent={progressBarData?.["translation"]?.percent}
                  showNextButton={isTranslator}
                  showMarkAsComplete={
                    currentWorkflow?.status === "TRANSLATION" &&
                    (isAdmin || isCompanyAdmin || isManager)
                  }
                  status={pagination?.status}
                  assigned={!isTranslator && (pagination?.assigned ?? "")}
                  setSelectedProductId={
                    !selectedProductId && setSelectedProductId
                  }
                  handlePerPage={handlePerPage}
                  handleFilterCopywritingStatus={handleFilterCopywritingStatus}
                />
                {
                  // !selectedProductId
                  true && (
                    <ul className="nav nav-tabs-custom rounded card-header-tabs border-bottom-0 mt-2">
                      {workflowLanguage?.map((language: string) => {
                        return (
                          <li
                            className="nav-item custom-basis"
                            key={language}
                            onClick={() => changeActiveTab(language as string)}
                          >
                            <a
                              className={`nav-link ${
                                activeTab === language ? "active" : ""
                              }`}
                              data-bs-toggle="tab"
                              href={`#${language}`}
                              role="tab"
                              aria-selected={language === currentLanguage}
                            >
                              {t(`products.content_${language}`)}
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  )
                }
                <div className="tab-content">
                  {workflowLanguage?.map((language, index) => {
                    return (
                      <div
                        key={index}
                        className={`tab-pane  ${
                          activeTab === language ? "active" : ""
                        }`}
                        id={language}
                        role="tabpanel"
                      >
                        <div className="mt-3">
                          <Card>
                            <Card.Body>
                              {progressArr?.map((data) => {
                                if (data === "assigment" && isTranslator) {
                                  return null;
                                }
                                return (
                                  <div
                                    className="card bg-light overflow-hidden shadow-none"
                                    key={data}
                                  >
                                    <div className="card-body">
                                      <div className="d-flex">
                                        <div className="flex-grow-1">
                                          <h6 className="mb-0">
                                            {t("workflow.current")} {data}:
                                            <b className="text-success">
                                              {" "}
                                              {
                                                progressBarData?.[
                                                  data as keyof IProgressBar
                                                ]?.percent
                                              }
                                              %
                                            </b>
                                          </h6>
                                        </div>
                                        <div className="flex-shrink-0">
                                          <h6 className="mb-0">
                                            {
                                              progressBarData?.[
                                                data as keyof IProgressBar
                                              ]?.done
                                            }
                                            /
                                            {
                                              progressBarData?.[
                                                data as keyof IProgressBar
                                              ]?.total
                                            }{" "}
                                            {t("workflow.finished")}
                                          </h6>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="progress">
                                      {/*//@ts-ignore*/}
                                      <div
                                        className="progress-bar bg-success"
                                        style={{
                                          width: `${
                                            progressBarData?.[
                                              data as keyof IProgressBar
                                            ]?.percent ?? 0
                                          }%`,
                                        }}
                                        aria-valuenow={
                                          progressBarData?.[
                                            data as keyof IProgressBar
                                          ]?.percent as number
                                        }
                                        aria-valuemin="0"
                                        aria-valuemax="100"
                                      />
                                    </div>
                                  </div>
                                );
                              })}
                              <Col xs={12}>
                                <div className="table-responsive">
                                  {!isTranslator && (
                                    <>
                                      <h6 className="mb-1 p-0 d-block">
                                        {t("workflow.translator")}:
                                      </h6>
                                      <div className="d-flex align-items-center gap-2 mb-1">
                                        {lightOrDarkMode === "dark" ? (
                                          <Select
                                            className="react-select-field"
                                            styles={{
                                              option: (base, state) => ({
                                                ...base,
                                                backgroundColor:
                                                  state.isSelected
                                                    ? "#007aff"
                                                    : "#262a2f",
                                                ":hover": {
                                                  backgroundColor:
                                                    state.isSelected
                                                      ? "#007aff"
                                                      : "black",
                                                },
                                              }),
                                              control: (baseStyles, state) => ({
                                                ...baseStyles,
                                                width: 250,
                                                zIndex: showTranslateDialog
                                                  ? 0
                                                  : 9999,
                                                ":hover": {
                                                  backgroundColor: "black",
                                                },
                                              }),

                                              menu: (base) => ({
                                                ...base,
                                                backgroundColor: "#262a2f",
                                              }),
                                              menuPortal: (base) => ({
                                                ...base,
                                                zIndex: showTranslateDialog
                                                  ? 0
                                                  : 9999,
                                              }),
                                            }}
                                            menuPortalTarget={document.body}
                                            onChange={selectTranslatorHandler}
                                            isClearable={true}
                                            options={translatorList?.map(
                                              (translator) => ({
                                                label: `${
                                                  translator?.firstName
                                                } ${translator?.lastName} (${
                                                  translator.translatorAssignment
                                                }  ${t(
                                                  "products.products"
                                                ).toLowerCase()})`,
                                                value: translator.id,
                                              })
                                            )}
                                          />
                                        ) : (
                                          <Select
                                            className="react-select-field"
                                            styles={{
                                              control: (baseStyles, state) => ({
                                                ...baseStyles,
                                                width: 250,
                                                zIndex: showTranslateDialog
                                                  ? 0
                                                  : 9999,
                                              }),
                                              menuPortal: (base) => ({
                                                ...base,
                                                zIndex: showTranslateDialog
                                                  ? 0
                                                  : 9999,
                                              }),
                                            }}
                                            menuPortalTarget={document.body}
                                            onChange={selectTranslatorHandler}
                                            isClearable={true}
                                            options={translatorList?.map(
                                              (translator) => ({
                                                label: `${
                                                  translator?.firstName
                                                } ${translator?.lastName} (${
                                                  translator.translatorAssignment
                                                }  ${t(
                                                  "products.products"
                                                ).toLowerCase()})`,
                                                value: translator.id,
                                              })
                                            )}
                                          />
                                        )}
                                        <button
                                          onClick={assignTranslatorHandler}
                                          className="btn  btn-secondary d-flex align-items-center me-2"
                                          disabled={
                                            !translatorData?.user ||
                                            !checkedValues?.length
                                          }
                                        >
                                          <i className="ri-share-box-fill me-1" />
                                          {t("global.assign")}
                                        </button>
                                        <button
                                          onClick={() =>
                                            changeFinishHandler(true)
                                          }
                                          className="btn  btn-success d-flex align-items-center me-2"
                                          disabled={
                                            translatorData?.user ||
                                            !checkedValues?.length
                                          }
                                        >
                                          {/* <i className="ri-share-box-fill me-1" /> */}
                                          {t("global.finished")}
                                        </button>
                                        <button
                                          onClick={() =>
                                            changeFinishHandler(false)
                                          }
                                          className="btn  btn-danger d-flex align-items-center me-2"
                                          disabled={
                                            translatorData?.user ||
                                            !checkedValues?.length
                                          }
                                        >
                                          {/* <i className="ri-share-box-fill me-1" /> */}
                                          {t("global.unfinished")}
                                        </button>
                                        <div className="d-flex ms-auto">
                                          {accessRights?.includes(
                                            "data_enrichment_workflow_all_products"
                                          ) && (
                                            <>
                                              <Select
                                                className="react-select-field w-210"
                                                placeholder={
                                                  "Google search prompt"
                                                }
                                                onChange={
                                                  selectGoogleSearchHandler
                                                }
                                                // isClearable={true}
                                                options={googleSearchList.map(
                                                  (exportItem) => ({
                                                    label: exportItem.title,
                                                    value: exportItem.id,
                                                  })
                                                )}
                                              />
                                              <button
                                                style={{ minWidth: "141px" }}
                                                className="btn btn-success ms-1 me-2"
                                                disabled={
                                                  googleSearchPrompt === 0
                                                }
                                                onClick={(e: any) =>
                                                  submitGoogleSearch(e)
                                                }
                                              >
                                                <span>
                                                  {" "}
                                                  {t(
                                                    "data_enrichment.enrichment"
                                                  )}
                                                </span>
                                              </button>
                                              <button
                                                // style={{ minWidth: "141px" }}
                                                className="btn btn-info ms-1 me-2"
                                                // disabled={
                                                //   googleSearchPrompt === 0
                                                // }
                                                onClick={(e: any) =>
                                                  submitFillWorkflow(e)
                                                }
                                              >
                                                {/* {isLoading ? (
                    <Spinner animation="border" size="sm" role="status" />
                  ) : ( */}
                                                <span> {t("global.fill")}</span>
                                                {/* )} */}
                                              </button>
                                            </>
                                          )}
                                        </div>
                                        {accessRights?.includes(
                                          "workflow_can_translate_all"
                                        ) && (
                                          <div className="d-flex ms-auto">
                                            {lightOrDarkMode === "dark" ? (
                                              <Select
                                                className="custom_zindex react-select-field me-1"
                                                styles={{
                                                  option: (base, state) => ({
                                                    ...base,
                                                    backgroundColor:
                                                      state.isSelected
                                                        ? "#007aff"
                                                        : "#262a2f",
                                                    ":hover": {
                                                      backgroundColor:
                                                        state.isSelected
                                                          ? "#007aff"
                                                          : "black",
                                                    },
                                                  }),
                                                  control: (
                                                    baseStyles,
                                                    state
                                                  ) => ({
                                                    ...baseStyles,
                                                    width: 120,
                                                    zIndex: showTranslateDialog
                                                      ? 0
                                                      : 9999,
                                                    ":hover": {
                                                      backgroundColor: "black",
                                                    },
                                                  }),

                                                  menu: (base) => ({
                                                    ...base,
                                                    backgroundColor: "#262a2f",
                                                  }),
                                                  menuPortal: (base) => ({
                                                    ...base,
                                                    zIndex: showTranslateDialog
                                                      ? 0
                                                      : 9999,
                                                  }),
                                                }}
                                                menuPortalTarget={document.body}
                                                name="content"
                                                theme={themeSelect}
                                                placeholder={`${t(
                                                  "formality.select_formality"
                                                )}`}
                                                options={deeplFormality?.map(
                                                  (formality) => ({
                                                    value: formality,
                                                    label: getCorrectFormality(
                                                      formality,
                                                      t
                                                    ),
                                                  })
                                                )}
                                                // key={state?.id}
                                                onChange={(e) => {
                                                  handleSelectSingleFormality(
                                                    e as any
                                                  );
                                                }}
                                              />
                                            ) : (
                                              <Select
                                                styles={{
                                                  control: (
                                                    baseStyles,
                                                    state
                                                  ) => ({
                                                    ...baseStyles,
                                                    width: 120,
                                                    zIndex: showTranslateDialog
                                                      ? 0
                                                      : 9999,
                                                  }),
                                                  menuPortal: (base) => ({
                                                    ...base,
                                                    zIndex: showTranslateDialog
                                                      ? 0
                                                      : 9999,
                                                  }),
                                                }}
                                                menuPortalTarget={document.body}
                                                className="custom_zindex react-select-field me-1"
                                                name="content"
                                                theme={themeSelect}
                                                placeholder={`${t(
                                                  "formality.select_formality"
                                                )}`}
                                                options={deeplFormality?.map(
                                                  (formality) => ({
                                                    value: formality,
                                                    label: getCorrectFormality(
                                                      formality,
                                                      t
                                                    ),
                                                  })
                                                )}
                                                // key={state?.id}
                                                onChange={(e) => {
                                                  handleSelectSingleFormality(
                                                    e as any
                                                  );
                                                }}
                                              />
                                            )}

                                            <button
                                              onClick={(e) =>
                                                setShowTranslateDialog(true)
                                              }
                                              className="btn btn-secondary d-flex align-items-center"
                                            >
                                              {isLoading ? (
                                                <Spinner
                                                  animation="border"
                                                  size="sm"
                                                  role="status"
                                                />
                                              ) : (
                                                <span>
                                                  {t("global.translateAll")}
                                                </span>
                                              )}
                                            </button>
                                          </div>
                                        )}
                                      </div>
                                    </>
                                  )}

                                  {!!productsList?.length ? (
                                    <Table className="align-middle table-nowrap mb-0">
                                      <thead className="table-light">
                                        <tr>
                                          {!isTranslator && (
                                            <th scope="col">
                                              <input
                                                className="form-check-input m-0 cursor-pointer"
                                                checked={
                                                  checkedValues.length ===
                                                  productsList?.length
                                                }
                                                type="checkbox"
                                                onChange={checkAll}
                                              />
                                            </th>
                                          )}
                                          <th scope="col">{`${t(
                                            "global.id"
                                          )}`}</th>
                                          <th scope="col">{`${t(
                                            "global.aenCode"
                                          )}`}</th>
                                          <th scope="col">{`${t(
                                            "global.finished"
                                          )}`}</th>
                                          <th scope="col">{`${t(
                                            "global.similar"
                                          )}`}</th>

                                          {!isTranslator && (
                                            <th scope="col">{`${t(
                                              "global.assign"
                                            )}`}</th>
                                          )}
                                          <th scope="col">{`${t(
                                            "products.product"
                                          )}`}</th>
                                          <th scope="col">{`${t(
                                            "products.brand"
                                          )}`}</th>
                                          <th scope="col">{`${t(
                                            "products.category"
                                          )}`}</th>
                                          <th scope="col">{`${t(
                                            "products.createdAt"
                                          )}`}</th>

                                          <th scope="col">{`${t(
                                            "global.actions"
                                          )}`}</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {productsList?.map((product) => (
                                          <ProductsListView
                                            toForLinkFunc={toForLinkFunc}
                                            workflow={true}
                                            nameOfTable={
                                              !isTranslator
                                                ? "content"
                                                : "translator"
                                            }
                                            key={product?.id}
                                            showViewButton={false}
                                            showAssignColumn={!isTranslator}
                                            showDeleteButton={false}
                                            showActionButtons={isTranslator}
                                            onEdit={editClickHandler}
                                            checkedValues={checkedValues}
                                            handleCheck={checkboxHandler}
                                            onView={viewClickHandler}
                                            product={product}
                                            mainTable={mainTable}
                                            deleteHandler={deleteHandler}
                                            firstView={true}
                                          />
                                        ))}
                                      </tbody>
                                    </Table>
                                  ) : (
                                    <EmptyState />
                                  )}
                                </div>
                                <div className="row">
                                  <div className="col-12">
                                    <div className="d-flex justify-content-end ">
                                      {totalPages <= 1 ? (
                                        ""
                                      ) : (
                                        <Pagination
                                          page={page}
                                          totalPages={totalPages}
                                          handlePagination={handlePages}
                                        />
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </Col>
                              {/* )} */}
                            </Card.Body>
                          </Card>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <WorkflowStepper
                  pagination={pagination}
                  userList={userList}
                  handleFilterUser={handleFilterUser}
                  step={"TRANSLATION"}
                  handlePerPage={handlePerPage}
                  handleFilterCopywritingStatus={handleFilterCopywritingStatus}
                  id={id}
                  changeFilterHandler={changeFilterHandler}
                  precent={progressBarData?.["translation"]?.percent}
                  showNextButton={isTranslator}
                  showMarkAsComplete={
                    currentWorkflow?.status === "TRANSLATION" &&
                    (isAdmin || isCompanyAdmin || isManager)
                  }
                  status={pagination?.status}
                  assigned={!isTranslator && pagination?.assigned}
                  setSelectedProductId={
                    !selectedProductId && setSelectedProductId
                  }
                />
                {staticsView2?.map((data) => {
                  return (
                    <div
                      className="card bg-light overflow-hidden shadow-none"
                      key={data?.lang}
                    >
                      <div className="card-body">
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <h6 className="mb-0">
                              {t("workflow.current")} {data?.lang}:
                              <b className="text-success"> {data?.percent}%</b>
                            </h6>
                          </div>
                          <div className="flex-shrink-0">
                            <h6 className="mb-0">
                              {data?.done} {t("workflow.finished")}
                            </h6>
                          </div>
                        </div>
                      </div>
                      <div className="progress">
                        {/*//@ts-ignore*/}
                        <div
                          className="progress-bar bg-success"
                          style={{ width: `${data?.percent ?? 0}%` }}
                          aria-valuenow={data?.percent as number}
                          aria-valuemin="0"
                          aria-valuemax="100"
                        />
                      </div>
                    </div>
                  );
                })}
                <>
                  <div className="table-responsive">
                    {!!productsList?.length ? (
                      <Table className="align-middle table-nowrap mb-0 ">
                        <thead className="table-light">
                          <tr>
                            {!isTranslator && (
                              <th scope="col">
                                <input
                                  className="form-check-input m-0 cursor-pointer"
                                  checked={
                                    checkedValues.length ===
                                    productsList?.length
                                  }
                                  type="checkbox"
                                  onChange={checkAll}
                                />
                              </th>
                            )}
                            <th scope="col">{`${t("global.id")}`}</th>
                            <th scope="col">{`${t("global.aenCode")}`}</th>
                            {isTranslator && (
                              <th scope="col">{`${t(
                                "export_client.languages"
                              )}`}</th>
                            )}
                            {/* <th scope="col">{`${t('global.finished')}`}</th> */}
                            <th scope="col">{`${t("global.similar")}`}</th>
                            {staticsView2?.map((lang: any) => (
                              <th scope="col" key={lang?.lang}>{`Finsihed ${
                                lang?.lang as string
                              }`}</th>
                            ))}

                            {!isTranslator && (
                              <th scope="col">{`${t("global.assign")}`}</th>
                            )}
                            <th scope="col">{`${t("products.product")}`}</th>
                            <th scope="col">{`${t("products.brand")}`}</th>
                            <th scope="col">{`${t("products.category")}`}</th>
                            <th scope="col">{`${t("products.createdAt")}`}</th>
                            {isTranslator && (
                              <th scope="col">{`${t("global.actions")}`}</th>
                            )}
                          </tr>
                        </thead>
                        <tbody>
                          {productsList?.map((product) => (
                            <ProductsListView
                              toForLinkFunc={toForLinkFunc}
                              workflow={true}
                              nameOfTable={"translator"}
                              key={product?.id}
                              showViewButton={false}
                              showAssignColumn={!isTranslator}
                              showDeleteButton={false}
                              showActionButtons={isTranslator}
                              onEdit={editClickHandler}
                              checkedValues={checkedValues}
                              handleCheck={checkboxHandler}
                              onView={viewClickHandler}
                              product={product}
                              mainTable={mainTable}
                              deleteHandler={deleteHandler}
                              staticsView2={staticsView2}
                            />
                          ))}
                        </tbody>
                      </Table>
                    ) : (
                      <EmptyState />
                    )}
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className="d-flex justify-content-end ">
                        {totalPages <= 1 ? (
                          ""
                        ) : (
                          <Pagination
                            page={page}
                            totalPages={totalPages}
                            handlePagination={handlePages}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </>
                {/* )} */}
              </div>
            </div>
          </div>
        </>
      )}
      <ModalTranslateAll
        show={showTranslateDialog}
        setShow={setShowTranslateDialog}
        translateHandler={translateAll}
      />
    </>
  );
};

export default WorkflowStep6;
