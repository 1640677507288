import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { ClientMappingService } from "./service";
import { ErrorToast } from "../../shared/toasters/toasters";
import _debounce from "lodash/debounce";
import { ListMapping } from "./components/ListMapping";
import { useNavigate, useParams } from "react-router";
import { useDispatch } from "react-redux";
import clientIdSlice from "../../store/clientMapping/clientId";
import { ClientService } from "../clients/service";

export const MappingClients = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { t } = useTranslation();
  const [clientName, setClientName] = useState({});
  const dispatch = useDispatch();
  const [triggerUpdate, setTriggerUpdate] = useState(false);
  const [companiesList, setCompaniesList] = useState<any[] | undefined>();
  const [paginate, setPaginate] = useState({
    page: 1,
    perPage: 10,
  });
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const handlePages = (updatePage: number) => {
    setPaginate({ ...paginate, page: updatePage });
    setPage(updatePage);
  };

  const changeFilterHandler = _debounce(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value, name } = event.target;
      setPaginate((prev) => ({ ...prev, [name]: value }));
    },
    200
  );

  useEffect(() => {
    ClientMappingService.getClientMappingById(Number(id), paginate)
      .then((response) => {
        const { data } = response;
        setCompaniesList(data);
        setTotalPages(Math.ceil(response?.count / response?.perPage));
      })
      .catch((error) => ErrorToast(error));
  }, [JSON.stringify(paginate), triggerUpdate]);
  useEffect(() => {
    dispatch(clientIdSlice.actions.setClientId(id));
  }, [id]);
  useEffect(() => {
    ClientService.getAllClients({ page: 1, perPage: 0 })
      .then((response) => {
        const { data } = response;
        setClientName(
          data?.length > 0
            ? (data as any)
                ?.map((user: any) => ({
                  name: user?.name,
                  value: user?.id,
                }))
                ?.find((user: any) => user?.value == id)
            : {}
        );
      })
      .catch((error) => ErrorToast(error));
  }, []);
  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="d-flex justify-content-between align-items-center">
            <button
              className="btn btn-info mb-3 d-flex align-items-center"
              onClick={() => navigate("/app/clients")}
            >
              <i className="ri-arrow-left-line"></i>
              <span className="ms-1">Back</span>
            </button>
            <h4>{(clientName as any)?.name ?? ""}</h4>
          </div>
        </div>
      </div>
      <div className="col-xl-12 mb-3">
        <div className="card ">
          <div className="card-body d-flex align-items-center justify-content-between">
            <h4 className="card-title mg-b-0">{t("mapping.create_mapping")}</h4>
            <button
              className="btn btn-primary"
              onClick={() => navigate(`/app/clients/mapping-create/${id}`)}
            >
              {t("global.create")}
            </button>
          </div>
        </div>
      </div>
      <div className="col-xl-12">
        <ListMapping
          companiesList={companiesList}
          changeFilterHandler={changeFilterHandler}
          page={page}
          totalPages={totalPages}
          handlePages={handlePages}
          setTriggerUpdate={setTriggerUpdate}
        />
      </div>
    </>
  );
};
