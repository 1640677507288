import { BaseModel } from "../../model/shared/BaseModel";
import { CLIENT_MAPPING_URL } from "../../utils/helpers/api.routes";
import { requests } from "../../utils/helpers/api.services";

export const ClientMappingService = {
  createClientMapping: (params: any) =>
    requests.post(`${CLIENT_MAPPING_URL}/base/${params?.id}`, params),
  getClientMappingById: (id: number, params: any) =>
    requests.get(`${CLIENT_MAPPING_URL}/base/${id}`, params),
  getMappingInfoById: (id: number) =>
    requests.get(`${CLIENT_MAPPING_URL}/info/list/${id}`),
  updateMappingInfoById: (id: number, params: any) =>
    requests.put(`${CLIENT_MAPPING_URL}/info/field/${id}`, params),
  getClientMappingPerProfileById: (idClient: number, idProfile: number) =>
    requests.get(`${CLIENT_MAPPING_URL}/base/${idClient}/${idProfile}`),

  updateClientMappingById: (id: number, clientId: number, params: any) =>
    requests.put(`${CLIENT_MAPPING_URL}/base/${id}/${clientId}`, params),
  getClientMappingCategoryById: (id: number, params: any) =>
    requests.get(`${CLIENT_MAPPING_URL}/category/list/${id}`, params),
  getMappingCategoryById: (id: number) =>
    requests.get(`${CLIENT_MAPPING_URL}/category/${id}`),
  updateMappingCategoryById: (id: number, params: any) =>
    requests.put(`${CLIENT_MAPPING_URL}/category/${id}`, params),
  getClientMappingAttributeById: (id: number, params: any) =>
    requests.get(`${CLIENT_MAPPING_URL}/attribute/list/${id}`, params),
  getMappingAttributeById: (id: number) =>
    requests.get(`${CLIENT_MAPPING_URL}/attribute/${id}`),
  updateMappingAttributeById: (id: number, params: any) =>
    requests.put(`${CLIENT_MAPPING_URL}/attribute/${id}`, params),
  getClientMappingValueById: (id: number, params: any) =>
    requests.get(`${CLIENT_MAPPING_URL}/value/list/${id}`, params),
  getMappingValueById: (id: number) =>
    requests.get(`${CLIENT_MAPPING_URL}/value/${id}`),
  updateMappingValueById: (id: number, params: any) =>
    requests.put(`${CLIENT_MAPPING_URL}/value/${id}`, params),
  getClientMappingFieldById: (id: number, params: any) =>
    requests.get(`${CLIENT_MAPPING_URL}/field/list/${id}`, params),
  getMappingFieldById: (id: number, field: string) =>
    requests.get(`${CLIENT_MAPPING_URL}/field/field/${id}/${field}`),
  updateMappingFieldById: (id: number, field: string, params: any) =>
    requests.put(`${CLIENT_MAPPING_URL}/field/field/${id}/${field}`, params),
  getMappingBlockById: (id: number, blockId: number) =>
    requests.get(`${CLIENT_MAPPING_URL}/field/block/${id}/${blockId}`),
  updateMappingBlockById: (id: number, blockId: number, params: any) =>
    requests.put(`${CLIENT_MAPPING_URL}/field/block/${id}/${blockId}`, params),
  getMappingAllDocumentsById: (id: number, params: any) =>
    requests.get(`${CLIENT_MAPPING_URL}/files/document-block/${id}`, params),
  getMappingDocumentById: (id: number, blockId: number) =>
    requests.get(`${CLIENT_MAPPING_URL}/files/document-block/${id}/${blockId}`),
  updateMappingDocumentById: (id: number, blockId: number, params: any) =>
    requests.put(
      `${CLIENT_MAPPING_URL}/files/document-block/${id}/${blockId}`,
      params
    ),
};
