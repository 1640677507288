import { createSlice } from "@reduxjs/toolkit";

const contentBasePaginationSlice = createSlice({
  name: "contentBasePagination",
  initialState: {},

  reducers: {
    setContentBasePagination: (state, action) => {
      state = action.payload;
      return state;
    },
  },
});

export default contentBasePaginationSlice;
