import React, { useEffect, useRef, useState } from "react";
import { ContentStepper } from "./ContentStepper";
import { useLocation, useNavigate, useParams } from "react-router";
import { Button, Card, Form, Row, Col, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Pagination } from "../../../shared/paginations/Paginations";
import { ContentWorkflowService } from "../service";
import { ErrorToast, SuccessToast } from "../../../shared/toasters/toasters";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { IAllStates } from "../../../store/rootReducer";
import { ConfirmDelete } from "../../../shared/components/ConfirmDelete";
import { EmptyState } from "../../../shared/components/EmptyState";
import { LoadingAnimation } from "../../../shared/components/Loading";
import { nanoid } from "nanoid";
import contentBasePaginationSlice from "../../../store/contentWorkflow/contentBasePagination";

const perPageOptions = [
  {
    label: 10,
    value: 10,
  },
  {
    label: 50,
    value: 50,
  },
  {
    label: 100,
    value: 100,
  },
  {
    label: 500,
    value: 500,
  },
];
const defaultContentTitle = {
  title: "",
  location: "",
  files: [
    {
      id: null,
      name: "",
      path: "",
      index: null,
    },
  ],
};
export const ContentStep2 = () => {
  const { search } = useLocation();
  const [files, setFiles] = useState<any[]>([]);
  const [documentFile, setDocumentFile] = useState<any>();

  const [createField, setCreateField] = useState(true);
  const [selectedContent, setSelectedContent] = useState({});
  const [show, setShow] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);
  const stepPagination = useSelector(
    (state: IAllStates) => state.contentBasePagination as any
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentUser = JSON.parse(localStorage.getItem("currentUser") as string);
  const isManager = currentUser?.roles.includes("ROLE_MANAGER");
  const isAdmin = currentUser?.roles.includes("ROLE_ADMIN");
  const isCompanyAdmin = currentUser?.roles.includes("ROLE_COMPANY_ADMIN");
  const handleShow = () => setShow(true);
  const [translatorData, setTranslatorData] = useState<any>({ contents: [] });
  const [checkedValues, setCheckedValues] = useState<number[]>([]);
  const { id } = useParams();
  const { t } = useTranslation();
  const lightOrDarkMode = useSelector(
    (state: IAllStates) => state.lightOrDarkMode
  );
  const inputFile = useRef(null);
  const [contentList, setContentList] = useState([]);
  const [userList, setUserList] = useState([]);
  const [contentTitle, setContentTitle] = useState(defaultContentTitle);
  const [currentWorkflowStatus, setCurrentWorkflowStatus] = useState("");
  const [copywriterList, setCopywriterList] = useState<any[]>([]);
  const currentPage = Number(
    +(search.split("=").at(-1) ?? 0) !== 0 ? search.split("=").at(-1) : 1
  );
  // const [pagination, setPagination] = useState({
  //   page: currentPage,
  //   perPage: 10,
  //   assigned: "",
  //   client: null,
  // });
  const [pagination, setPagination] = useState({
    ...(stepPagination as unknown as any),
    // page: currentPage,
    // perPage: 10,
  });
  const [page, setPage] = useState(currentPage);
  const [totalPages, setTotalPages] = useState(0);
  const [triggerUpdate, setTriggerUpdate] = useState(false);
  const [contentId, setContentId] = useState(0 as number);
  const showDocument = isAdmin || isCompanyAdmin || isManager ? true : false;
  const handlePages = (updatePage: number) => {
    navigate(`?page=${updatePage}`);
    setPagination({ ...pagination, page: updatePage });
    dispatch(
      contentBasePaginationSlice.actions.setContentBasePagination({
        ...(stepPagination as any),
        page: updatePage,
      })
    );
    setPage(updatePage);
  };
  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    // @ts-ignore
    const { name, value } = event.target;
    setContentTitle((prev: any) => ({ ...prev, [name]: value }));
  };
  const editHandler = (contentId: number) => {
    setShowUpdate(true);
    setCreateField(true);
    setContentId(contentId);
    ContentWorkflowService.getContentBaseById(contentId, +id!)
      .then((res) => {
        const filesArr =
          res?.files?.length > 0
            ? res?.files?.map((item: any) => ({
                source: item?.path, // Example URL of another default file
                options: {
                  type: "input",
                  file: {
                    name: item?.path,
                  },
                },
              }))
            : [
                {
                  id: null,
                  name: "",
                  path: "",
                  index: null,
                },
              ];

        setContentTitle({
          ...res,
          location: res?.source,
          files:
            res?.files?.length == 0
              ? [
                  {
                    id: null,
                    name: "",
                    path: "",
                    index: null,
                  },
                ]
              : res?.files,
        });
        setFiles(filesArr);
      })
      .catch((err) => ErrorToast(err));
  };
  const checkboxHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setCheckedValues((prev) => [...prev, +e.target.value]);
    } else {
      setCheckedValues((prev) =>
        prev.filter((value) => value !== +e.target.value)
      );
    }
  };

  const checkAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setCheckedValues(contentList?.map((content) => (content as any).id));
    } else {
      setCheckedValues([]);
    }
  };

  const handleFilesInput = (i: number, e: any, element: any) => {
    if (e.target.files.length) {
      let newFormValuesImg = [
        ...contentTitle.files.map((file: any, index: any) =>
          index === i
            ? { ...file, index: files.length, name: e.target.files[0]?.name }
            : file
        ),
      ];
      (newFormValuesImg as any)[i][e.target.name] = URL.createObjectURL(
        e.target.files[0]
      );

      setFiles((prev: any) => [...prev, e.target.files[0]]);
      setContentTitle((prev: any) => ({ ...prev, files: newFormValuesImg }));
    }
  };
  const addFormFields = () => {
    setContentTitle((prev: any) => ({
      ...prev,
      files: [
        ...prev.files,
        {
          id: null,
          path: "",
          name: "",
          index: null,
        },
      ],
    }));
    const newFormData = [...contentTitle.files];
    const id = nanoid();
    newFormData.push({
      id: null,
      path: "",
      name: "",
      index: null,
    });
    setContentTitle((prev: any) => ({ ...prev, files: newFormData }));
  };
  const removeFormFields = (index: any, id: any, item: any) => {
    if (contentTitle?.files.length === 1) {
      setContentTitle((prev: any) => ({
        ...prev,
        files: [
          ...prev.files,
          {
            id: null,
            path: "",
            name: "",
            index: null,
          },
        ],
      }));
    }
    if (id === null) {
      setContentTitle((prev: any) => ({
        ...prev,
        files: prev.files.filter((input: any, idx: any) => idx !== index),
      }));
      setFiles((prev: any) =>
        prev.filter((input: any) => input?.path != item?.path)
      );
    } else {
      ContentWorkflowService.deleteFile(id)
        .then((response) => {
          setContentTitle((prev: any) => ({
            ...prev,
            files: prev.files.filter((input: any, idx: any) => idx !== index),
          }));
          SuccessToast(t(`toasts.successfully_deleted`));
        })
        .catch((error) => ErrorToast(error));
    }
  };
  const handleKeyDown = (e: any) => {
    if (e.key === "Enter") {
      submitHandler(e);
    }
  };
  const handlePerPage = (e: any) => {
    setPagination((prev: any) => ({ ...prev, page: 1, perPage: e.value }));
    dispatch(
      contentBasePaginationSlice.actions.setContentBasePagination({
        ...(stepPagination as any),
        page: 1,
        perPage: e.value,
      })
    );
  };
  const selectTranslatorHandler = (event: any) => {
    setTranslatorData((prev: any) => ({ ...prev, copyWriter: +event.value }));
  };
  const assignTranslatorHandler = async () => {
    id &&
      (await ContentWorkflowService.assignCopywriter(Number(id), {
        ...translatorData,
        contents: checkedValues,
      })
        .then((response) => {
          if (response) {
            setTriggerUpdate((prev) => !prev);
            setCheckedValues([]);
            SuccessToast(t("toasts.successfully_assigned"));
          }
        })
        .catch((error) => ErrorToast(error)));
  };

  const deleteHandler = () => {
    (selectedContent as any)?.id &&
      ContentWorkflowService.deleteContentById(
        Number(id),
        (selectedContent as any)?.id as number
      )
        .then((response) => {
          if (response === "") {
            setTriggerUpdate((prev) => !prev);
          }
        })
        .catch((error) => ErrorToast(error));
  };
  const changeFilterHandler = (
    name: string,
    value: string,
    resetFilters?: boolean
  ) => {
    if (resetFilters) {
      setPagination((prev: any) => ({
        ...prev,
        status: "",
        assigned: "",
        page: 1,
        copywriter: null,
      }));
      dispatch(
        contentBasePaginationSlice.actions.setContentBasePagination({
          ...(stepPagination as any),
          status: "",
          assigned: "",
          page: 1,
          copywriter: null,
        })
      );
    } else {
      setPagination((prev: any) => ({
        ...prev,
        [name]: value,
        copywriter: null,
        page: 1,
      }));
      dispatch(
        contentBasePaginationSlice.actions.setContentBasePagination({
          ...(stepPagination as any),
          [name]: value,
          copywriter: null,
          page: 1,
        })
      );
    }
  };
  const handleFilterUser = (e: any) => {
    setPagination((prev: any) => ({
      ...prev,
      copywriter: e?.value,
      assigned: "",
      page: 1,
    }));
    dispatch(
      contentBasePaginationSlice.actions.setContentBasePagination({
        ...(stepPagination as any),
        copywriter: e?.value,
        assigned: "",
        page: 1,
      })
    );
  };
  useEffect(() => {
    ContentWorkflowService.getAllContents(pagination, +id!)
      .then((response) => {
        const { data, copyWriters, workflow, assignedCopywriters }: any =
          response;
        setCurrentWorkflowStatus(workflow?.status);
        setContentList(data as any);
        setCopywriterList(copyWriters);
        setTotalPages(Math.ceil(response?.count / response?.perPage));
        setPage(response?.page);
        setUserList(assignedCopywriters);
      })
      .catch((error) => ErrorToast(error));
  }, [pagination, currentPage, triggerUpdate]);

  const checkForMainRoles = () => {
    if (isAdmin || isCompanyAdmin || isManager) {
      return true;
    } else {
      return false;
    }
  };
  const submitHandler = (event: React.FormEvent) => {
    event.preventDefault();
    if (contentId && contentId !== 0) {
      const formData = new FormData();
      if (contentTitle?.files?.length > 0) {
        for (let i = 0; i < contentTitle?.files?.length; i++) {
          if (files[i]?.size) {
            formData.append("files", files[i]);
          }
        }
      }
      formData.append(
        "body",
        JSON.stringify({
          title: (contentTitle as any)?.title,
          source: (contentTitle as any)?.location,
        })
      );

      ContentWorkflowService.updateContentBaseById(
        id as any,
        contentId,
        formData
      )
        .then((response) => {
          if (response?.id) {
            SuccessToast("Success added content");
            setCreateField(false);
            setContentTitle(defaultContentTitle);
            setDocumentFile(undefined);
            setFiles([]);
            setTriggerUpdate((prev) => !prev);
          }
        })
        .catch((error) => ErrorToast(error));
    } else {
      const formData = new FormData();
      if (contentTitle?.files?.length) {
        for (let i = 0; i < contentTitle?.files?.length; i++) {
          formData.append("files", files[i]);
        }
      }
      formData.append(
        "body",
        JSON.stringify({
          title: (contentTitle as any)?.title,
          source: (contentTitle as any)?.location,
        })
      );

      ContentWorkflowService.createBase(formData, id as any)
        .then((response) => {
          if (response?.id) {
            SuccessToast("Success added content");
            setCreateField(false);
            setContentTitle(defaultContentTitle);
            setDocumentFile(undefined);
            setFiles([]);
            setTriggerUpdate((prev) => !prev);
          }
        })
        .catch((error) => ErrorToast(error));
    }
  };

  return (
    <>
      <Card>
        <Card.Body>
          <ContentStepper
            pagination={pagination}
            step={"BASE"}
            id={id}
            showNextButton={true}
            showMarkAsComplete={
              currentWorkflowStatus === "BASE" &&
              (isAdmin || isCompanyAdmin || isManager)
            }
            handlePerPage={handlePerPage}
            isDisabledComplete={true}
            precent={contentList?.length !== 0 ? 100 : 0}
          ></ContentStepper>
          {!createField && (
            <div className=" d-flex align-items-center justify-content-between my-3">
              <h4 className="card-title mg-b-0">
                {t("content_workflow.add_content")}
              </h4>
              <button
                className="btn btn-primary"
                onClick={() => setCreateField(true)}
              >
                {t("global.add")}
              </button>
            </div>
          )}
          {createField && (
            <Form onSubmit={submitHandler} onKeyDown={handleKeyDown}>
              <Row className="d-flex align-items-start my-3">
                <Col md={6}>
                  <label htmlFor="name" className="required-field">
                    {`${t("global.name")}`}
                  </label>
                  <input
                    type="text"
                    name="title"
                    placeholder="Type..."
                    className="form-control"
                    value={((contentTitle as any)?.title as string) ?? ""}
                    required
                    onChange={changeHandler}
                  />
                </Col>
                <Col md={6}>
                  <label htmlFor="name" className="">
                    {`${t("global.source")}`}
                  </label>
                  <input
                    type="text"
                    name="location"
                    className="form-control"
                    value={((contentTitle as any)?.location as string) ?? ""}
                    onChange={changeHandler}
                  />
                </Col>
                <div className="mt-2">
                  <label>{t("global.files")}</label>
                  {contentTitle?.files?.map((element: any, index: any) => {
                    const sliceNumber = element?.path?.split("/")?.length;
                    return (
                      <div className="form-inline" key={element?.id}>
                        <Row>
                          <Col md={6}>
                            <div className=" mt-2 mx-auto ">
                              <div>
                                <input
                                  name="path"
                                  id={index as any}
                                  type="file"
                                  accept="image/*"
                                  // ref={imageRef}
                                  className="profile-img-file-input "
                                  onChange={(e) =>
                                    handleFilesInput(index, e, element)
                                  }
                                />
                              </div>
                              <span className="my-2">
                                {" "}
                                {
                                  (element?.path && element?.nameOriginal) ?? ""
                                  // element?.path?.split("/")[sliceNumber - 1]
                                }
                              </span>
                            </div>
                          </Col>
                        </Row>
                        {/* {index ? ( */}

                        <div className="row">
                          <div className="col-md-6">
                            <div className="d-flex justify-content-end">
                              <button
                                type="button"
                                className="btn btn-danger  my-2"
                                onClick={() =>
                                  removeFormFields(index, element?.id, element)
                                }
                              >
                                {t("global.remove")}
                              </button>
                              {index == contentTitle?.files?.length - 1 && (
                                <button
                                  className="btn btn-success my-2 ms-2"
                                  type="submit"
                                  onClick={() => {
                                    addFormFields();
                                  }}
                                >
                                  {t("global.add_another")}
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </Row>
              <div className="">
                <Col md={12} className="d-flex align-items-end">
                  <div className="ms-auto">
                    <button
                      className="btn btn-info me-2"
                      onClick={() => {
                        setCreateField(false);
                        setContentTitle(defaultContentTitle);
                        setContentId(0);
                        // setCurrentClient({} as any);
                      }}
                    >
                      {t("global.cancel")}
                    </button>
                    <button className="btn btn-primary" type="submit">
                      {contentId && contentId !== 0 && showUpdate
                        ? `${t("global.update")}`
                        : `${t("global.add")}`}
                    </button>
                  </div>
                </Col>
              </div>
            </Form>
          )}
        </Card.Body>
      </Card>
      <div className="card">
        <div className="card-header pb-0">
          <div className="d-flex justify-content-start">
            <h4 className="card-title mg-b-0">{t("content.listOfContents")}</h4>
          </div>
        </div>
        <div className="card-body">
          <div className="d-flex align-items-end justify-content-between gap-2 mb-3">
            <div>
              <h6 className="mb-1 p-0 ">{t("workflow.copywriter")}:</h6>

              {lightOrDarkMode === "dark" ? (
                <div className="d-flex">
                  <Select
                    isDisabled={contentList?.length === 0}
                    className="react-select-field"
                    styles={{
                      option: (base, state) => ({
                        ...base,
                        backgroundColor: state.isSelected
                          ? "#007aff"
                          : "#262a2f",
                        ":hover": {
                          backgroundColor: state.isSelected
                            ? "#007aff"
                            : "black",
                        },
                      }),
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        width: 250,
                        zIndex: `${!show ? "9999" : ""}`,
                        ":hover": {
                          backgroundColor: "black",
                        },
                      }),

                      menu: (base) => ({
                        ...base,
                        backgroundColor: "#262a2f",
                      }),
                      menuPortal: (base) => ({
                        ...base,
                        zIndex: 9999,
                      }),
                    }}
                    menuPortalTarget={document.body}
                    onChange={selectTranslatorHandler}
                    options={copywriterList?.map((copywriter: any) => ({
                      label: `${copywriter?.user?.firstName} ${copywriter?.user?.lastName}`,
                      value: copywriter?.user?.id,
                    }))}
                  />
                  <button
                    onClick={assignTranslatorHandler}
                    className="btn  btn-secondary d-flex align-items-center ms-2"
                    disabled={
                      !translatorData?.copyWriter || !checkedValues?.length
                    }
                  >
                    <i className="ri-share-box-fill me-1" />
                    {t("global.assign")}
                  </button>
                </div>
              ) : (
                <div className="d-flex">
                  <Select
                    isDisabled={contentList?.length === 0}
                    className="react-select-field"
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        width: 250,
                        zIndex: `${!show ? "9999" : ""}`,
                      }),
                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    }}
                    menuPortalTarget={document.body}
                    onChange={selectTranslatorHandler}
                    options={copywriterList?.map((copywriter: any) => ({
                      label: `${copywriter?.user?.firstName} ${
                        copywriter?.user?.lastName
                      } (${copywriter?.copywriterAssignment ?? 0}  ${t(
                        "products.products"
                      ).toLowerCase()})`,
                      value: copywriter?.user?.id,
                    }))}
                  />
                  <button
                    onClick={assignTranslatorHandler}
                    className="btn  btn-secondary d-flex align-items-center ms-2"
                    disabled={
                      !translatorData?.copyWriter || !checkedValues?.length
                    }
                  >
                    <i className="ri-share-box-fill me-1" />
                    {t("global.assign")}
                  </button>
                </div>
              )}
            </div>
            <div className="d-flex align-items-center">
              <div className="d-flex align-items-center me-3">
                {checkForMainRoles() && (
                  <>
                    <span className="me-2">{t(`workflow.filter`)}:</span>
                    <Button
                      variant={`${
                        pagination?.assigned === "" ? "" : "outline-"
                      }primary`}
                      onClick={() => changeFilterHandler("status", "", true)}
                      className="d-flex align-items-center"
                      size="sm"
                    >
                      {t("global.all")}
                    </Button>
                    {true && (
                      <>
                        <Button
                          variant={`${
                            pagination?.assigned === "yes" ? "" : "outline-"
                          }warning mx-2`}
                          onClick={() => changeFilterHandler("assigned", "yes")}
                          className="d-flex align-items-center"
                          size="sm"
                        >
                          {t("global.assigned")}
                        </Button>
                        <Button
                          variant={`${
                            pagination?.assigned === "no" ? "" : "outline-"
                          }info`}
                          onClick={() => changeFilterHandler("assigned", "no")}
                          className="d-flex align-items-center"
                          size="sm"
                        >
                          {t("global.unassigned")}
                        </Button>
                      </>
                    )}
                  </>
                )}
                {(isManager || isAdmin || isCompanyAdmin) && (
                  <>
                    <Select
                      key={nanoid()}
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          width: "200px",
                        }),
                      }}
                      className="react-select-field ms-2"
                      onChange={(e: any) => handleFilterUser(e)}
                      placeholder={`User:`}
                      options={userList?.map((user: any) => ({
                        label: `${user?.firstName} ${user?.lastName}`,
                        value: user?.id,
                      }))}
                      defaultValue={
                        (pagination as any)?.copywriter > 0
                          ? userList
                              ?.map((user: any) => ({
                                label: `${user?.firstName} ${user?.lastName}`,
                                value: user?.id,
                              }))
                              ?.find(
                                (user: any) =>
                                  user?.value == (pagination as any)?.copywriter
                              )
                          : null
                      }
                      isClearable={true}
                    />
                  </>
                )}
              </div>
              <h6 className="p-0 mb-1 me-2">{t("workflow.per_page")}:</h6>
              <Select
                className="react-select-field"
                onChange={handlePerPage}
                placeholder={`${t("workflow.per_page")}:`}
                options={perPageOptions}
                defaultValue={
                  pagination?.perPage
                    ? {
                        label: pagination?.perPage,
                        value: pagination?.perPage,
                      }
                    : perPageOptions[0]
                }
              />
            </div>
          </div>
          {contentList == undefined ? (
            <LoadingAnimation />
          ) : contentList?.length === 0 ? (
            <EmptyState />
          ) : (
            <div className="table-responsive">
              <Table className="align-middle table-nowrap mb-0">
                <thead className="table-light">
                  <tr>
                    <th scope="col">
                      <input
                        className="form-check-input m-0 cursor-pointer"
                        checked={checkedValues.length === contentList?.length}
                        type="checkbox"
                        onChange={checkAll}
                      />
                    </th>
                    <th scope="col">{`${t("global.id")}`}</th>
                    <th scope="col">{`${t("global.title")}`}</th>
                    <th scope="col">{`${t("global.assign")}`}</th>
                    <th scope="col">{`${t("global.actions")}`}</th>
                  </tr>
                </thead>
                <tbody>
                  {contentList?.map((content: any) => {
                    return (
                      <tr>
                        <td>
                          <input
                            type="checkbox"
                            className="form-check-input m-0 cursor-pointer"
                            onChange={(e) =>
                              checkboxHandler && checkboxHandler(e)
                            }
                            value={content?.id}
                            checked={checkedValues?.includes(
                              Number(content?.id)
                            )}
                          />
                        </td>
                        <td>{content.id}</td>
                        <td>{content?.title ?? ""}</td>
                        <td>
                          {content?.copyWriter
                            ? content?.copyWriter?.firstName +
                              " " +
                              content?.copyWriter?.lastName
                            : "-"}
                        </td>
                        <td>
                          <div className="d-flex align-items-center justify-content-center">
                            {
                              // accessRights?.includes(
                              //   "brand_can_update"
                              // )
                              true && (
                                <button
                                  className="btn btn-sm btn-warning d-flex align-items-center me-2"
                                  onClick={() =>
                                    editHandler(content?.id as number)
                                  }
                                  // onClick={() => editHandler(brand?.id as number)}
                                >
                                  <i className="ri-edit-2-fill me-1" />
                                  {`${t("global.edit")}`}
                                </button>
                              )
                            }
                            {
                              // accessRights?.includes(
                              //   "brand_can_delete"
                              // )
                              true && (
                                <button
                                  className="btn btn-sm btn-danger d-flex align-items-center"
                                  onClick={() => {
                                    setSelectedContent(content);
                                    handleShow();
                                  }}
                                >
                                  <i className="ri-delete-bin-fill me-1" />
                                  {`${t("global.delete")}`}
                                </button>
                              )
                            }
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          )}
        </div>
        <div className="card-footer">
          <div className="d-flex justify-content-end ">
            {totalPages! <= 1 ? (
              ""
            ) : (
              <Pagination
                page={page}
                totalPages={totalPages}
                handlePagination={handlePages}
              />
            )}
          </div>
        </div>
      </div>
      <ConfirmDelete
        show={show}
        setShow={setShow}
        itemName={(selectedContent as any)?.title}
        deleteHandler={deleteHandler}
        product={undefined}
        selectedProduct={selectedContent}
      />
    </>
  );
};
