import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { ErrorToast, SuccessToast } from "../../../shared/toasters/toasters";
import { useNavigate, useParams } from "react-router-dom";
import { themeSelect } from "../../../shared/components/Select2";
import { getCorrectLanguage } from "../../../shared/functions/Functions";
import { WorkflowService } from "../../workflow/service";
import { ClientService } from "../../clients/service";
import { ClientMappingService } from "../../clientsMapping/service";
import { ApiService } from "../service";

export interface ApiItem {
  id?: number;
  name: string;
  workflow: number;
  clientProfile: number;
  language: string[];
  exportBaseData: boolean;
  exportAttributes: boolean;
  exportContent: boolean;
}

interface Props {
  state: any;
  setState: React.Dispatch<React.SetStateAction<ApiItem>>;
}

export const CreateForm = ({ state, setState }: Props) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const checkBoxBaseInfo = ["exportBaseInfo", "baseInfoSkipIfNotMapped"];
  const checkBoxBaseData = ["exportBaseData", "baseDataSkipIfNotMapped"];
  const checkBoxContent = ["exportContent", "contentSkipIfNotMapped"];
  const checkBoxAttributes = ["exportAttributes", "attributesSkipIfNotMapped"];
  // const checkBoxArray = ["exportBaseData", "exportAttributes", "exportContent"];
  const [workflowActive, setWorkflowActive] = useState<any[]>();
  const [allWorkflows, setAllWorkflows] = useState([]);
  const [workflowLang, setWorkflowLang] = useState([]);
  const [clients, setClients] = useState();
  const [clientMapping, setClientMapping] = useState();
  const mappedDataWorkflow =
    allWorkflows &&
    (allWorkflows as any[])?.map((site: any) => ({
      label: site?.name,
      value: site?.id,
      languages: site?.language,
      sourceLang: site?.sourceLang,
    }));

  const mappedDataClient =
    clients &&
    (clients as any[])?.map((site: any) => ({
      label: site?.name,
      value: site?.id,
    }));
  const mappedDataclientMapping =
    clientMapping &&
    (clientMapping as any[])?.map((site: any) => ({
      label: site?.name,
      value: site?.id,
    }));
  const currentLang =
    state?.language &&
    state?.language?.map((lang: string) => ({
      label: lang,
      value: lang,
    }));
  function handleSelectSingle2(selectedSingle: any, title: string) {
    if (title === "workflow" && selectedSingle !== null) {
      const arrLanguages = selectedSingle?.languages;
      const stringSourceLang = selectedSingle?.sourceLang;
      const newArr = [...arrLanguages, stringSourceLang];
      setWorkflowLang(newArr as any);
      setState({
        ...state,
        language: [],
        workflow: {
          label: selectedSingle?.label,
          value: selectedSingle?.value,
        },
      });
    } else if (title === "workflow") {
      setWorkflowLang([]);
      setState({
        ...state,
        language: [],
        workflow: 0,
      });
    } else {
      setState((prev: any) => ({
        ...prev,
        [title]: selectedSingle,
      }));
    }
  }
  function handleSelectSingleClient(selectedSingle: any, title: string) {
    if (title === "client" && selectedSingle !== null) {
      setState((prev: any) => ({
        ...prev,
        [title]: selectedSingle,
        clientProfile: null,
      }));
    } else if (title === "client" && selectedSingle === null) {
      setState((prev: any) => ({
        ...prev,
        [title]: selectedSingle,
        clientProfile: null,
      }));
    } else {
      setState((prev: any) => ({
        ...prev,
        [title]: selectedSingle,
      }));
    }
  }
  const checkBoxHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
    item: string
  ) => {
    setState((prev: any) => ({
      ...prev,
      [item]: event.target.checked,
    }));
  };
  function handleSelectSingleLanguage(selectedSingle: any, title: string) {
    setState((prev: any) => ({
      ...prev,
      [title]: selectedSingle?.map(
        (selectedSingle: { value: string }) => selectedSingle?.value
      ),
    }));
  }

  const changeHandler = (event: any) => {
    const { name, value } = event.target;
    setState((prev: any) => ({ ...prev, [name]: value }));
  };

  useEffect(() => {
    ClientService.getAllClients({ perPage: 0, page: 1 })
      .then((response) => {
        const { data } = response;
        setClients(data as unknown as any);
      })
      .catch((error) => ErrorToast(error));
  }, []);
  useEffect(() => {
    WorkflowService.getAllWorkflows({
      perPage: 0,
      page: 1,
      workflowStatus: "COMPLETED",
    })
      .then((response) => {
        const { data } = response;
        setAllWorkflows(data as any);
      })
      .catch((error) => ErrorToast(error));
  }, []);
  useEffect(() => {
    state?.client &&
      ClientMappingService.getClientMappingById(Number(state?.client?.value), {
        perPage: 0,
        page: 1,
      })
        .then((response) => {
          const { data } = response;
          setClientMapping(data as unknown as any);
        })
        .catch((error) => ErrorToast(error));
  }, [state?.client]);
  useEffect(() => {
    if (!!Number(id)) {
      ApiService.getById(+id!)
        .then((response) => {
          if (response?.workflow) {
            const arrForLang = response?.workflow?.language?.push(
              response?.workflow?.sourceLang
            );
            setWorkflowLang(response?.workflow?.language);
          }
          setState({
            ...response,
            workflow: {
              value: response?.workflow?.id,
              label: response?.workflow?.name,
            },
            client: {
              value: response?.clientProfile?.client?.id,
              label: response?.clientProfile?.client?.name,
            },
            clientProfile: {
              value: response?.clientProfile?.id,
              label: response?.clientProfile?.name,
            },
          });
        })
        .catch((error) => {
          ErrorToast(error);
        });
    }
  }, [id]);
  return (
    <>
      <Row className="mb-2">
        <Col md={4}>
          <label htmlFor="name" className="required-field">
            {`${t("global.name")}`}
          </label>
          <input
            type="text"
            name="name"
            className="form-control"
            value={state?.name}
            required
            onChange={changeHandler}
          />
        </Col>

        <Col md={4} className="">
          <label htmlFor="workflow" className="required-field">
            {`${t("global.workflow")}`}
          </label>
          <Select
            // key={state?.workflow?.id}
            isClearable={true}
            className="react-select-field"
            theme={themeSelect}
            value={
              state?.workflow && {
                value: state?.workflow?.value,
                label: state?.workflow?.label,
              }
            }
            onChange={(e) => {
              handleSelectSingle2(e as any, "workflow");
            }}
            options={mappedDataWorkflow}
          />
        </Col>
        {state?.workflow !== 0 && (
          <Col md={4} className="">
            <label htmlFor="language" className="required-field">
              {`${t("export_client.language")}`}
            </label>
            <Select
              // key={`lang-${state?.id}`}
              className="react-select-field"
              theme={themeSelect}
              value={state?.language?.map((lang: string) => ({
                value: lang,
                label: getCorrectLanguage(lang, t),
              }))}
              onChange={(e) => {
                handleSelectSingleLanguage(e as any, "language");
              }}
              isMulti={true}
              options={workflowLang?.map((language: string) => ({
                value: language,
                label: getCorrectLanguage(language, t),
              }))}
            />
          </Col>
        )}
      </Row>
      <Row>
        <Col md={4} className="">
          <label htmlFor="client" className="required-field">
            {`${t("global.client")}`}
          </label>
          <Select
            // key={state?.workflow?.id}
            className="react-select-field"
            theme={themeSelect}
            isClearable={true}
            value={
              state?.client && {
                value: state?.client?.value,
                label: state?.client?.label,
              }
            }
            onChange={(e) => {
              handleSelectSingleClient(e as any, "client");
            }}
            options={mappedDataClient}
          />
        </Col>
        {state?.client && (
          <Col md={4} className="">
            <label htmlFor="client_mapping" className="required-field">
              {`${t("global.client_profile")}`}
            </label>
            <Select
              // key={state?.workflow?.id}
              className="react-select-field"
              isClearable={true}
              theme={themeSelect}
              value={
                state?.clientProfile && {
                  value: state?.clientProfile?.value,
                  label: state?.clientProfile?.label,
                }
              }
              onChange={(e) => {
                handleSelectSingleClient(e as any, "clientProfile");
              }}
              options={mappedDataclientMapping}
            />
          </Col>
        )}
      </Row>
      <Row>
        <div className="mt-2">
          <label htmlFor="roles">{t("export_client.productType")}</label>
          <div className="d-flex min-input-height justify-content-start align-items-center">
            <div>
              {checkBoxBaseInfo.map((item: string) => (
                <div className="me-3" key={item}>
                  <input
                    className="form-check-input m-0 cursor-pointer"
                    defaultValue={state?.item as any}
                    checked={!!state?.[item as keyof any]}
                    type="checkbox"
                    id={item}
                    onChange={(e) => checkBoxHandler(e, item)}
                  />
                  <label className="m-0 px-3 cursor-pointer" htmlFor={item}>
                    {t(`export_client.${item}`)}
                  </label>
                </div>
              ))}
            </div>
            <div>
              {checkBoxBaseData.map((item: string) => (
                <div className="me-3" key={item}>
                  <input
                    className="form-check-input m-0 cursor-pointer"
                    defaultValue={state?.item as any}
                    checked={!!state?.[item as keyof any]}
                    type="checkbox"
                    id={item}
                    onChange={(e) => checkBoxHandler(e, item)}
                  />
                  <label className="m-0 px-3 cursor-pointer" htmlFor={item}>
                    {t(`export_client.${item}`)}
                  </label>
                </div>
              ))}
            </div>
            <div>
              {checkBoxContent.map((item: string) => (
                <div className="me-3" key={item}>
                  <input
                    className="form-check-input m-0 cursor-pointer"
                    defaultValue={state?.item as any}
                    checked={!!state?.[item as keyof any]}
                    type="checkbox"
                    id={item}
                    onChange={(e) => checkBoxHandler(e, item)}
                  />
                  <label className="m-0 px-3 cursor-pointer" htmlFor={item}>
                    {t(`export_client.${item}`)}
                  </label>
                </div>
              ))}
            </div>
            <div>
              {checkBoxAttributes.map((item: string) => (
                <div className="me-3" key={item}>
                  <input
                    className="form-check-input m-0 cursor-pointer"
                    defaultValue={state?.item as any}
                    checked={!!state?.[item as keyof any]}
                    type="checkbox"
                    id={item}
                    onChange={(e) => checkBoxHandler(e, item)}
                  />
                  <label className="m-0 px-3 cursor-pointer" htmlFor={item}>
                    {t(`export_client.${item}`)}
                  </label>
                </div>
              ))}
            </div>
          </div>
        </div>
      </Row>
      <div className="d-flex justify-content-end align-itmes-center my-2">
        <Button
          className="btn btn-info me-3"
          type="button"
          onClick={() => navigate("/admin/api")}
        >
          {t("global.back")}
        </Button>
        <Button className="btn btn-success" type="submit">
          {state?.id ? t("global.update") : t("global.create")}
        </Button>
      </div>
      {/* </div>
      </div> */}
    </>
  );
};
