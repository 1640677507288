import { IProfileDTO, IProfile, IProfilePassword } from "./dto/IProfile";
import { BaseModel } from "../../model/shared/BaseModel";
import { USER_URL } from "../../utils/helpers/api.routes";
import { requests } from "../../utils/helpers/api.services";

export const ProfileDetailsService = {
  getProfileDetails: (): Promise<BaseModel<IProfile>> =>
    requests.get(`${USER_URL}/user-profile`),
  updateProfileDetails: (params: IProfileDTO) =>
    requests.put(`${USER_URL}/edit-user-profile`, params),
  updateProfilePassword: (params: IProfilePassword) =>
    requests.put(`${USER_URL}/user-profile-password-change`, params),
  getAllNotifications: (params: any): Promise<BaseModel<any>> =>
    requests.get(`${USER_URL}/notifications`, params),
  getAssignedData: (): Promise<BaseModel<any>> =>
    requests.get(`${USER_URL}/assigned-data`),
  changeSeenStatus: (id: number): Promise<BaseModel<any>> =>
    requests.get(`${USER_URL}/notifications/${id}`),
};
