import { Button, Modal } from "react-bootstrap";
import Lottie from "lottie-react";
import unlockIcon from "../icons/unlockIcon.json";
import { useTranslation } from "react-i18next";

export const ConfirmUnlock = ({
  itemName,
  show,
  setShow,
  deleteHandler,
  selectedProduct,
  setForce,
  force,
}: any) => {
  const title = itemName?.replaceAll("_", "-");
  const handleClose = () => setShow(false);
  const handleCheck = () => setForce(!force);
  const { t } = useTranslation();
  const deleteHandleLogic = () => {
    deleteHandler(selectedProduct);
  };
  return (
    <Modal show={show} onHide={handleClose} size="sm" centered>
      <Modal.Body className="d-flex align-items-center flex-column">
        <Lottie animationData={unlockIcon} loop={true} style={{ width: 100 }} />
        <h5>{t("modals.are_you_sure")}</h5>
        <p
          className="text-center text-muted modal-title-break"
          dangerouslySetInnerHTML={{
            __html: t(`modals.are_you_sure_you_want_to_unlock`, {
              itemName: title,
            }),
          }}
        />
        <label className="text-center d-flex align-items-center">
          <span style={{ fontSize: "14px" }}>Force</span>

          <input
            style={{ fontSize: "14px" }}
            className="ms-1"
            type="checkbox"
            checked={force}
            onChange={handleCheck}
          />
          <span className="checkmark"></span>
        </label>
      </Modal.Body>
      <Modal.Footer className="d-flex align-items-center justify-content-center">
        <Button variant="light" size="sm" onClick={handleClose}>
          {t(`modals.close`)}
        </Button>
        <Button
          variant="success"
          size="sm"
          onClick={() => {
            handleClose();
            deleteHandleLogic();
          }}
        >
          {t(`global.unlock`)}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
