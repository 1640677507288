import React, { SetStateAction, useEffect, useState } from "react";
import { PageTemplate } from "../../../../shared/layout/PageTemplate";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { ErrorToast } from "../../../../shared/toasters/toasters";
import { ProductsService } from "../../../products/service";
import {
  IProducts,
  IProductsList,
  IProductValue,
} from "../../../products/dto/IProducts";
import { SingleValue } from "react-select";
import { ProductsAttributesActions } from "../../../products/attributes/components/ProductsAttributesActions";
import DataFromExcel from "./DataFromExcel";
import GalleryCardPreview from "../../../../shared/gallery/GalleryCardPreview";
import ImageContainer from "../../../../shared/gallery/ImageContainer";

export const EditAttributesImported = () => {
  const { id } = useParams();
  // za sada ovako
  const { state: pagination }: any = useLocation();
  const location = useLocation();
  const tab = "BASE_DATA";
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [currentProduct, setCurrentProduct] = useState<IProductsList>();
  const handleSelectMainCategory = (
    selectedSingle: SingleValue<{ label: string; value: number }>,
    inputKey: string
  ) => {
    setCurrentProduct((prev: any) => ({
      ...prev,
      [inputKey]: selectedSingle?.value,
    }));
  };

  const handleClose = () => {
    navigate("/app/pending-products/imported", {
      state: (location.state as any)?.pagination,
    });
    // navigate("/app/pending-products/imported", { state: { pagination: pagination?.pagination ?? {} } });
  };

  const changeValuesHandler = (values: any, attributeId: number) => {
    let tempArr: IProductValue[] = currentProduct?.values ?? [];
    const indexToReplace = tempArr?.findIndex(
      (attribute) => attribute.attribute === attributeId
    );
    const addId = values?.find((item: { isAdd: boolean }) => item.isAdd)
      ?.value as number;

    if (indexToReplace === -1) {
      tempArr?.push({
        attribute: attributeId,
        value: values
          ?.map((value: { value: number }) => value.value)
          .filter((item: number) => item !== addId),
      });
    } else {
      tempArr[indexToReplace] = {
        ...tempArr[indexToReplace],
        value: values
          ?.map((value: { value: number }) => value.value)
          .filter((item: number) => item !== addId),
      };
    }
    setCurrentProduct((prev: any) => ({ ...prev, values: tempArr }));
  };

  const navContent = () => {
    navigate(`/app/pending-products/imported/content/${id}`, {
      state: pagination,
    });
  };
  const navBaseData = () => {
    navigate(`/app/pending-products/imported/base-data/${id}`, {
      state: pagination,
    });
  };

  useEffect(() => {
    id &&
      tab &&
      ProductsService.getProductById(+id, tab)
        .then((response) => {
          setCurrentProduct(response);
        })
        .catch((error) => {
          ErrorToast(error);
          //novo
          navigate(-1);
        });
  }, [id]);

  return (
    // <PageTemplate title={`${t("side_bar.imported_product")}`}>
    <div className="col-12">
      <div className="page-title-box d-sm-flex align-items-center justify-content-between">
        <div className="d-flex align-itmes-end">
          <ul className="nav nav-pills nav-fill">
            {/* <li className="nav-item">
              <Link
                className="nav-link"
                to={`/app/pending-products/imported/base-data/${id}`}
              >
                {t("products.base_data_update")}
              </Link>
            </li> */}
            <li onClick={() => navBaseData()}>
              <span className="nav-link cursor-pointer text-dark">
                {t("products.base_data_update")}
              </span>
            </li>
            <li>
              <span className="nav-link active">
                {t("products.attributes_update")}
              </span>
            </li>
            {/* <li>
              <Link
                className="nav-link"
                to={`/app/pending-products/imported/content/${id}`}
              >
                {t("products.content_update")}
              </Link>
            </li> */}
            <li onClick={() => navContent()}>
              <span className="nav-link cursor-pointer text-dark">
                {t("products.content_update")}
              </span>
            </li>
          </ul>
        </div>
        <div
          className="alert alert-warning alert-dismissible shadow fade show m-0 pe-3"
          role="alert"
        >
          {currentProduct?.status}
        </div>
        <>
          <button
            className="btn btn-primary d-flex align-items-center me-2"
            data-bs-toggle="offcanvas"
            data-bs-target={`#offcanvasRight-${id}`}
            aria-controls={`offcanvasRight-${id}`}
          >
            <i className="ri-information-line me-1" />
            {`${t("global.info")}`}
          </button>
          <div
            className="offcanvas offcanvas-end"
            id={`offcanvasRight-${id}`}
            aria-labelledby="offcanvasRightLabel"
          >
            <div className="offcanvas-header">
              <h5 id="offcanvasRightLabel">{t("products.info_excel")}</h5>
              <button
                type="button"
                className="btn-close text-reset"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </div>
            <div className="offcanvas-body">
              <DataFromExcel currentExcelProductId={id as unknown as number} />
            </div>
          </div>
        </>
      </div>
      <div className="d-flex align-items-center justify-content-start">
        <button
          onClick={() => handleClose()}
          className="btn btn-info d-flex align-items-center mb-3"
        >
          <i className="ri-arrow-left-line me-1"></i>
          {t("products.back")}
        </button>
      </div>
      <div className="d-flex align-items-center mb-3">
        <div className="d-flex gap-3 align-items-center">
          <ImageContainer img={currentProduct?.productPictures ?? []} />
          {/* <GalleryCardPreview
            img={
              currentProduct?.productPictures[0]?.path ||
              "/images/jk-placeholder-image.jpg"
            }
          /> */}
          {/* <CustomImageHandler
            classes="avatar-lg rounded-circle me-2"
            path={currentProduct?.productPictures[1]?.path ?? ""}
            alt="User logo"
          /> */}
          <div>
            <h2>{currentProduct?.title}</h2>
            <p>{currentProduct?.eanCode}</p>
          </div>
        </div>
      </div>
      <ProductsAttributesActions
        productStaticts={{}}
        handleClose={handleClose}
        currentProduct={currentProduct as IProductsList}
        setCurrentProduct={
          setCurrentProduct as React.Dispatch<
            SetStateAction<IProductsList | IProducts>
          >
        }
        handleSelectMainCategory={handleSelectMainCategory}
        changeValuesHandler={changeValuesHandler}
      />
    </div>
    // </PageTemplate>
  );
};
