import { PageTemplate } from "../../shared/layout/PageTemplate";
import { useTranslation } from "react-i18next";
import { FilePondFile } from "filepond";
import { FilePond } from "react-filepond";
import { useState } from "react";
import { ActualFileObject } from "filepond";
import { IMPORT_URL } from "../../utils/helpers/api.routes";

export const Import = () => {
  const { t } = useTranslation();
  const [files, setFiles] = useState<ActualFileObject[]>([]);
  const token = localStorage.getItem("token");
  const [showResp, setShowResp] = useState(false);
  const [responseBack, setResponseBack] = useState({} as any);

  const handleProcessFile = () => {
    // Send the file and token to the backend
    const formData = new FormData();
    formData.append("filepond", files as any);
    formData.append("token", `Bearer ${token}`);

    // Make a POST request to your backend endpoint
    fetch(`${IMPORT_URL}/upload`, {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        // Handle the response
      })
      .catch((error) => {
        // Handle the error
        console.log(error);
      });
  };

  return (
    <>
      <div className="col-12">
        <div className="row justify-content-center align-items-center py-1 gy-3">
          <div className="col-xl-8 col-lg-12">
            <div className="card mb-0">
              <div className="card-body">
                <FilePond
                  // onprocessfile={handleProcessFile}
                  className="my-0"
                  files={files}
                  allowReorder={true}
                  labelFileProcessingError={`${t(
                    "toasts.filePondImport.error_mess"
                  )}`}
                  allowMultiple={true}
                  // server={`${IMPORT_URL}/upload`}
                  onupdatefiles={(filesArr: FilePondFile[]) => {
                    setFiles(filesArr?.map((fileItem) => fileItem?.file));
                  }}
                  labelIdle={`${t("import.drag_and_drop")}`}
                  server={{
                    // url: `${IMPORT_URL}/upload`,
                    process: {
                      url: `${IMPORT_URL}/upload`,
                      headers: {
                        "app-version": localStorage.getItem("appVersion")!,
                        Authorization: `Bearer ${localStorage.getItem(
                          "token"
                        )}`,
                        "Content-Disposition": "attachment",
                        fileName: "nesto",
                      },
                      onload: (response: any) => {
                        const error = JSON.parse(response);
                        setResponseBack(error);
                        setShowResp(true);
                        return response;
                      },
                      onerror: (response) => {
                        const error = JSON.parse(response);
                        setShowResp(false);
                        return response;
                        // refreshPage();
                      },
                    },
                  }}
                />
                {showResp && (
                  <>
                    <div className="d-flex justify-content-center">
                      {responseBack?.message}
                    </div>
                    <div className="d-flex justify-content-center">
                      <p className="me-1">
                        {t("import.successfully_added_rows")}{" "}
                        {responseBack?.successfullyAddedRows}
                      </p>
                      <p className="ms-1">
                        {t("import.skipped")} {responseBack?.skipped}
                      </p>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-lg-12">
            <div className="d-flex flex-column justify-content-center align-items-center">
              <h4>{t("import.excel_simple_file")}</h4>
              <p className="mb-1">{t("import.mess_download_file")}</p>
              <div className="d-flex me-auto">
                <a
                  href="/importTemplate.xlsx"
                  download
                  className="me-auto text-decoration-underline"
                >
                  {t("import.download")}
                </a>
                <i className="ri-folder-download-line ms-1"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
