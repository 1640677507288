import { createSlice } from "@reduxjs/toolkit";

const contentTranslationPaginationSlice = createSlice({
  name: "contentTranslationPagination",
  initialState: {},

  reducers: {
    setContentTranslationPagination: (state, action) => {
      state = action.payload;
      return state;
    },
  },
});

export default contentTranslationPaginationSlice;
