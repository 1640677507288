import React, { SetStateAction, useEffect, useState } from "react";
import { IProductsList, IProductVariation } from "../dto/IProducts";
import { ProductsService } from "../service";
import { ErrorToast, SuccessToast } from "../../../shared/toasters/toasters";
import { ConfirmDelete } from "../../../shared/components/ConfirmDelete";
import { Spinner } from "react-bootstrap";
import { t } from "i18next";
import { ExportService } from "../../exports/service";
import { IExport } from "../../exports/dto/IExports";
import { IAllStates } from "../../../store/rootReducer";
import { useSelector } from "react-redux";
import { getCorrectLanguage } from "../../../shared/functions/Functions";
import { useLocation } from "react-router";
import { ContentService } from "../../content/service";

interface IProductVariationProps {
  variation: IProductVariation;
  currentProduct?: IProductsList;
  setCurrentVariation: React.Dispatch<SetStateAction<IProductVariation>>;
  setCurrentVariationTranslation?: React.Dispatch<SetStateAction<any>>;
  setVariationList: React.Dispatch<SetStateAction<IProductVariation[]>>;
  handleShow: () => void;
  handleCheckVariation?: (id: number) => void;
  isWorkflowUpdate?: boolean;
  setTriggerVariationUpdate?: any;
}

const ProductVariation = ({
  currentProduct,
  variation,
  setCurrentVariation,
  setCurrentVariationTranslation,
  handleShow,
  handleCheckVariation,
  isWorkflowUpdate = false,
  setVariationList,
  setTriggerVariationUpdate,
}: IProductVariationProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const { title, subtitle, id } = variation;
  const [show, setShow] = useState(false);
  const location = useLocation();
  const workflowData = useSelector((state: IAllStates) => state.workflow);
  const [exportsList, setExportsList] = useState<IExport[]>([]);
  const { translationLanguageEnum } = useSelector(
    (state: IAllStates) => state.enums
  );
  const [variationToDelete, setVariationToDelete] =
    useState<IProductVariation>();
  const editHandler = (id: number) => {
    ProductsService.getVariation(id)
      .then((response) => {
        handleShow();
        setCurrentVariation(response);
        setCurrentVariationTranslation &&
          setCurrentVariationTranslation(response?.translation);
      })
      .catch((error) => ErrorToast(error));
  };

  const chatGtpHandler = (variationId: number, language: string) => {
    setIsLoading(true);
    ProductsService.addNewVaraitonAi({
      id: variationId,
      lang: language !== "" ? language : workflowData?.sourceLang,
    })
      .then((response) => {
        setTriggerVariationUpdate((prev: boolean) => !prev);
      })
      .catch((error) => ErrorToast(error))
      .finally(() => setIsLoading(false));
  };

  const cloneHandler = (variationId: number) => {
    ProductsService.addCloneVaraiton(variationId)
      .then((response) => {
        setVariationList(response.data);
      })
      .catch((error) => ErrorToast(error));
  };
  const exportVariation = (exportId: any, variationId: number) => {
    ExportService.exportVariation(exportId, variationId)
      .then((response) => {
        SuccessToast(`${t("toasts.successfully_exported")}`);
      })
      .catch((error) => ErrorToast(error));
  };
  const deleteHandler = () => {
    ProductsService.deleteVariation(variationToDelete?.id as number)
      .then((response) => {
        if (response.data) {
          setTriggerVariationUpdate((prev: boolean) => !prev);
          SuccessToast(
            `${t("toasts.successfully_deleted")} ${variationToDelete?.title}`
          );
        }
      })
      .catch((error) => ErrorToast(error));
  };
  useEffect(() => {
    ExportService.getAllExports({ page: 1, perPage: 0 })
      .then((response) => {
        const { data } = response;
        setExportsList(data);
      })
      .catch((error) => ErrorToast(error));
  }, []);

  return (
    <div className="col-md-12">
      <div className="card">
        <div className="card-body">
          <div className="row align-items-center">
            {isWorkflowUpdate && (
              <div className="col-1">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="format"
                    id={`variation-${id}`}
                    checked={id === currentProduct?.variation}
                    onChange={() =>
                      handleCheckVariation && handleCheckVariation(id ?? 0)
                    }
                  />
                </div>
              </div>
            )}
            <div className="col-4">
              <label className="title-variation" htmlFor={`variation-${id}`}>
                {title ?? "-"}
              </label>
            </div>
            <div
              className={`col-${
                isWorkflowUpdate ? 7 : 8
              } d-flex justify-content-end`}
            >
              {!isWorkflowUpdate && (
                <div className="btn-group">
                  <button
                    type="button"
                    className="btn btn-sm btn-soft-info me-1 dropdown-toggle"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i className="ri-swap-box-line"></i>
                  </button>
                  <ul className="dropdown-menu">
                    {exportsList?.map((format: any) => (
                      <li>
                        <span
                          onClick={(e) =>
                            exportVariation(format?.id as number, id as number)
                          }
                          className="dropdown-item"
                        >
                          {format?.name}
                        </span>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              <button
                className="btn btn-sm btn-soft-success me-1"
                onClick={() => cloneHandler(id as number)}
              >
                <i className="ri-file-copy-line"></i>
              </button>
              {location?.pathname?.includes("workflow") ? (
                <button
                  className="btn btn-sm btn-soft-primary me-1"
                  onClick={() => chatGtpHandler(id as number, "")}
                >
                  {isLoading ? (
                    <Spinner animation="border" size="sm" role="status" />
                  ) : (
                    <span>AI</span>
                  )}
                </button>
              ) : (
                <div className="btn-group">
                  <button
                    type="button"
                    className="btn btn-sm btn-soft-primary me-1 dropdown-toggle"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    AI
                  </button>
                  <ul className="dropdown-menu">
                    {translationLanguageEnum?.map((language: string) => {
                      return (
                        <li>
                          <span
                            className="dropdown-item"
                            onClick={() =>
                              chatGtpHandler(id as number, language as string)
                            }
                          >
                            {getCorrectLanguage(language, t)}
                          </span>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              )}
              {/**/}

              <button
                className="btn btn-sm btn-soft-warning me-1"
                onClick={() => editHandler(id as number)}
              >
                <i className="ri-pencil-line" />
              </button>
              <button
                className={"btn btn-sm btn-soft-danger me-1"}
                onClick={() => {
                  setShow(true);
                  setVariationToDelete(variation);
                }}
              >
                <i className="ri-delete-bin-line" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <ConfirmDelete
        show={show}
        setShow={setShow}
        itemName={variationToDelete?.title}
        deleteHandler={deleteHandler}
        selectedProduct={variationToDelete}
      />
    </div>
  );
};

export default ProductVariation;
