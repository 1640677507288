import { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Table } from "react-bootstrap";
import { OverviewService } from "./service";
import { ErrorToast } from "../../shared/toasters/toasters";
import WorkflowStep7Chart from "../workflow/components/WorkflowStep7Chart";
import { themeSelect } from "../../shared/components/Select2";
import Select from "react-select";
import DatePicker from "react-datepicker";

import { ClientService } from "../clients/service";
import { IExport } from "../exports/dto/IExports";
import { prependListener } from "process";

interface IInnerStatsInterface {
  done: number;
  total: number;
  percent: number;
}

interface IStatsInterface {
  base: IInnerStatsInterface;
  attributes: IInnerStatsInterface;
  copywriting: IInnerStatsInterface;
  translations: IInnerStatsInterface;
  translatorsData: any[];
  copywritersData: any[];
}

export interface IOverviewPagination {
  fromDate?: Date;
  toDate?: Date;
  client?: number[];
}
export const Overview = () => {
  const { t } = useTranslation();
  const [copywriterList, setCopywriterList] = useState([]);
  const [translatorList, setTranslatorList] = useState([]);
  const [dateA, setDateA] = useState<Date>();
  const [dateB, setDateB] = useState<Date>();
  const [clientList, setClientList] = useState<IExport[]>([]);
  const [stats, setStats] = useState<IStatsInterface>();
  const [activeInProgress, setActiveInProgress] = useState<boolean>(true);
  const [contentStats, setContentStats] = useState<IStatsInterface>();
  const day = 60 * 60 * 24 * 1000;
  const [pagination, setPagination] = useState<IOverviewPagination>({});
  function addOneDay(date: any) {
    date.setDate(date.getDate() + 1);
    return date;
  }
  const dateHandler = (date: Date | null, name: string) => {
    if (name === "fromDate") {
      setDateA(date as Date);
    } else if (name === "toDate") {
      setDateB(date as Date);
    } else {
      return;
    }
    setPagination((prev) => ({
      ...prev,
      [name]: date,
    }));

    if (
      date === null &&
      (pagination?.toDate === null || pagination?.fromDate === null)
    ) {
      setActiveInProgress(true);
    } else {
      setActiveInProgress(false);
    }
  };

  const clientHandler = (e: { value: number; label: string }[]) => {
    setPagination((prev) => ({ ...prev, client: e.map((p) => p.value) }));
  };

  useEffect(() => {
    ClientService.getAllClients({
      page: 1,
      perPage: 0,
    })
      .then((response) => {
        const { data } = response;
        setClientList(
          data?.map((client) => ({
            ...client,
            label: client.name,
            value: client.id,
          }))
        );
      })
      .catch((error) => ErrorToast(error));
  }, []);
  // (pagination as any)?.fromDate.setDate((pagination as any)?.fromDate.getDate() + 1);
  useEffect(() => {
    var myTwoDate = new Date(dateB as any);
    myTwoDate.setDate(myTwoDate.getDate() + parseInt(1 as any));
    var myDate = new Date(dateA as any);
    myDate.setDate(myDate.getDate() + parseInt(1 as any));
    OverviewService.getAllLists({
      ...pagination,
      fromDate: dateA ? myDate : undefined,
      toDate: dateB ? myTwoDate : undefined,
    })
      .then((response) => {
        const {
          copywriters,
          translators,
          productWorkflowData,
          contentWorkflowData,
        } = response;
        setCopywriterList(copywriters);
        setTranslatorList(translators);
        setStats(productWorkflowData);
        setContentStats(contentWorkflowData);
      })
      .catch((error) => ErrorToast(error));
  }, [pagination]);

  return (
    <>
      <div className="row mb-4 gap-4">
        <div className="col-3 p-0">
          <DatePicker
            id="fromDeadline"
            placeholderText="Date from"
            autoComplete="off"
            className="date-picker"
            name="fromDeadline"
            isClearable
            selected={dateA as unknown as Date}
            onChange={(date) => dateHandler(date, "fromDate")}
          />
        </div>
        <div className="col-3 p-0">
          {" "}
          <DatePicker
            id="toDeadline"
            placeholderText="Date to"
            autoComplete="off"
            className="date-picker"
            name="fromDeadline"
            isClearable
            selected={pagination.toDate as Date}
            onChange={(date) => dateHandler(date, "toDate")}
          />
        </div>

        <div className="col-3 p-0">
          <Select
            className="custom_zindex react-select-field me-2"
            theme={themeSelect}
            placeholder="Client"
            isMulti={true}
            isClearable={true}
            options={clientList?.map((client) => ({
              value: client.id,
              label: client.name,
            }))}
            //   key={state?.id}
            onChange={(e) => {
              clientHandler(e as any);
            }}
          />
        </div>
      </div>
      <Card>
        <Card.Body>
          <Card.Title>Product workflow data</Card.Title>
          <div className="row mb-5">
            <WorkflowStep7Chart
              name="Attributes"
              iconClass={"ri-vip-crown-fill display-6 text-muted"}
              total={stats?.attributes?.total}
              done={stats?.attributes?.done}
              activeInProgress={activeInProgress}
            />
            <WorkflowStep7Chart
              name="Base data"
              iconClass={"ri-vip-crown-fill display-6 text-muted"}
              total={stats?.base?.total}
              done={stats?.base?.done}
              activeInProgress={activeInProgress}
            />
            <WorkflowStep7Chart
              name="Copywriting"
              iconClass={"ri-vip-crown-fill display-6 text-muted"}
              total={stats?.copywriting?.total}
              done={stats?.copywriting?.done}
              activeInProgress={activeInProgress}
              // copywriters={stats?.copywritersData}
            />
            <WorkflowStep7Chart
              name="Translation"
              iconClass={"ri-vip-crown-fill display-6 text-muted"}
              total={stats?.translations?.total}
              done={stats?.translations?.done}
              activeInProgress={activeInProgress}
              // value={stats?.attributes.total}
              // translators={stats?.translatorsData}
            />
          </div>
        </Card.Body>
      </Card>
      <Card>
        <Card.Body>
          <Card.Title>Content workflow data</Card.Title>
          <div className="row mb-5">
            <WorkflowStep7Chart
              name="Copywriting"
              iconClass={"ri-vip-crown-fill display-6 text-muted"}
              total={(contentStats as any)?.copywriting?.total}
              done={(contentStats as any)?.copywriting?.done}
              activeInProgress={activeInProgress}
            />
            <WorkflowStep7Chart
              name="Translation"
              iconClass={"ri-vip-crown-fill display-6 text-muted"}
              total={contentStats?.translations?.total}
              done={contentStats?.translations?.done}
              activeInProgress={activeInProgress}
            />
          </div>
        </Card.Body>
      </Card>

      <Card>
        <Card.Body>
          <div className="row">
            <div className="col-12">
              <Card.Title>{t("workflow.copywriters")}</Card.Title>
              <div className="table-responsive">
                <Table className="align-middle table-nowrap mb-0 table table-bordered border-primary">
                  <thead className="table-light">
                    <tr>
                      <th scope="col" rowSpan={2}>{`${t("global.id")}`}</th>
                      <th scope="col" rowSpan={2}>{`${t("global.name")}`}</th>
                      <th
                        scope="col"
                        colSpan={activeInProgress ? 2 : 1}
                      >{`Workflow`}</th>
                      <th scope="col" colSpan={activeInProgress ? 2 : 1}>{`${t(
                        "workflow.contentWorkflow"
                      )}`}</th>
                    </tr>
                    <tr>
                      <th scope="col">{`${t("global.done")}`}</th>
                      {activeInProgress && (
                        <th scope="col">{`${t("global.inprogress")}`}</th>
                      )}

                      {/* <th scope="col">{`${t("global.total")}`}</th> */}
                      <th scope="col">{`${t("global.done")}`}</th>
                      {activeInProgress && (
                        <th scope="col">{`${t("global.inprogress")}`}</th>
                      )}

                      {/* <th scope="col">{`${t("global.total")}`}</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {copywriterList?.map((user: any) => {
                      return (
                        <tr>
                          <td>{user.id}</td>
                          <td>{user.firstName + " " + user.lastName}</td>
                          <td>{user.workflow.done}</td>
                          {activeInProgress && (
                            <td>{user.workflow.inProgress}</td>
                          )}
                          {/* <td>{user.workflow.total}</td> */}
                          <td>{user.workflowContent.done}</td>
                          {activeInProgress && (
                            <td>{user.workflowContent.inProgress}</td>
                          )}
                          {/* <td>{user.workflowContent.total}</td> */}
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
              {/* </div> */}
            </div>
          </div>
        </Card.Body>
      </Card>
      <Card>
        <Card.Body>
          <div className="col-12">
            <Card.Title> {t("workflow.translators")}</Card.Title>
            <div className="table-responsive">
              <Table className="align-middle table-nowrap mb-0 table table-bordered border-primary ">
                <thead className="table-light">
                  <tr>
                    <th scope="col" rowSpan={2}>{`${t("global.id")}`}</th>
                    <th scope="col text-center" rowSpan={2}>{`${t(
                      "global.name"
                    )}`}</th>
                    <th
                      scope="col"
                      colSpan={activeInProgress ? 2 : 1}
                    >{`Workflow`}</th>
                    <th scope="col" colSpan={activeInProgress ? 2 : 1}>{`${t(
                      "workflow.contentWorkflow"
                    )}`}</th>
                  </tr>
                  <tr>
                    <th scope="col">{`${t("global.done")}`}</th>
                    {activeInProgress && (
                      <th scope="col">{`${t("global.inprogress")}`}</th>
                    )}

                    {/* <th scope="col">{`${t("global.total")}`}</th> */}
                    <th scope="col">{`${t("global.done")}`}</th>
                    {activeInProgress && (
                      <th scope="col">{`${t("global.inprogress")}`}</th>
                    )}

                    {/* <th scope="col">{`${t("global.total")}`}</th> */}
                  </tr>
                </thead>
                <tbody>
                  {translatorList?.map((user: any) => {
                    return (
                      <tr>
                        <td>{user.id}</td>
                        <td>{user.firstName + " " + user.lastName}</td>
                        <td>{user.workflow.done}</td>
                        {activeInProgress && (
                          <td>{user.workflow.inProgress}</td>
                        )}
                        {/* <td>{user.workflow.total}</td> */}
                        <td>{user.workflowContent.done}</td>
                        {activeInProgress && (
                          <td>{user.workflowContent.inProgress}</td>
                        )}
                        {/* <td>{user.workflowContent.total}</td> */}
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </div>
        </Card.Body>
      </Card>
    </>
  );
};
