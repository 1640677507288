import { t } from "i18next";
import React, { ChangeEvent, useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import Select, { SingleValue } from "react-select";
import { themeSelect } from "../../../shared/components/Select2";
import {
  formatDateWorkflow,
  getCorrectLanguage,
} from "../../../shared/functions/Functions";
import { ErrorToast, SuccessToast } from "../../../shared/toasters/toasters";
import { IAllStates } from "../../../store/rootReducer";
import workflowSlice from "../../../store/workflow/workflowSlice";
import { ClientService } from "../../clients/service";
import { IExport } from "../../exports/dto/IExports";
import { UserService } from "../../users/service";
import { IWorkflow } from "../dto/IWorkflow";
import { WorkflowService } from "../service";
import { WorkflowStepper } from "./WorkflowStepper";
export const WorkflowStep1 = () => {
  const { id } = useParams();
  const workflowData = useSelector((state: IAllStates) => state.workflow);
  const dispatch = useDispatch();
  const [clientList, setClientList] = useState<IExport[]>([]);
  const [copywriterList, setCopywriterList] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [firstStap, setFirstStap] = useState<IWorkflow | undefined>();
  const { translationLanguageEnum } = useSelector(
    (state: IAllStates) => state.enums
  );
  const navigate = useNavigate();
  const checkBoxArray = ["baseData", "attributes", "content"];
  const checkBoxHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
    item: string
  ) => {
    setFirstStap((prev: any) => ({
      ...prev,
      [item]: event.target.checked,
    }));
  };
  const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event.target;
    setFirstStap((prev: any) => ({
      ...prev,
      [name]: value,
    }));
  };

  function handleSelectSingle(
    selectedSingle: SingleValue<{
      label: string;
      value: string;
      nesto: string;
    }>,
    title: string
  ) {
    setFirstStap((prev: any) => ({
      ...prev,
      [title]: selectedSingle?.value as string,
    }));
  }

  function handleSelectSingle2(selectedSingle: any, title: string) {
    setFirstStap((prev: any) => ({
      ...prev,
      [title]: selectedSingle?.map(
        (selectedSingle: { value: string }) => selectedSingle?.value
      ),
    }));
  }

  function handleSelectSingle3(selectedSingle: any, title: string) {
    const arr = selectedSingle?.map((value: any) => value?.value);

    setFirstStap((prev: any) => ({
      ...prev,
      [title]: arr,
    }));
  }
  function handleDate(date: any) {
    if (date === null) {
      setStartDate(date);
      setFirstStap((prev: any) => ({
        ...prev,
        deadlineData: null,
      }));
    } else {
      setStartDate(date);
      setFirstStap((prev: any) => ({
        ...prev,
        deadlineData: date,
      }));
    }
  }
  function handleSelectSingle4(selectedSingle: SingleValue<any | null>) {
    setFirstStap((prev: any) => ({
      ...prev,
      language: prev.language?.filter(
        (lang: string) => lang !== selectedSingle?.value
      ),
      sourceLang: selectedSingle?.value as string,
    }));
  }
  useEffect(() => {
    ClientService.getAllClients({
      page: 1,
      perPage: 0,
    })
      .then((response) => {
        const { data } = response;
        setClientList(
          data?.map((client) => ({
            ...client,
            label: client.name,
            value: client.id,
          }))
        );
      })
      .catch((error) => ErrorToast(error));
  }, []);
  const submitHandler = (event: React.FormEvent) => {
    event.preventDefault();
    const isQualityUnchanged = firstStap?.qualityControlPerson?.some(
      (person: any) => !!person?.id
    );
    const isCopywriterUnchanged = firstStap?.copywriters?.some(
      (person: any) => !!person?.id
    );
    WorkflowService.updateWorkflowById(Number(id), {
      deadlineDate: firstStap?.deadlineData,
      ...firstStap,
      client: firstStap?.client.id ?? firstStap?.client,
      qualityControlPerson: !isQualityUnchanged
        ? firstStap?.qualityControlPerson
        : firstStap?.qualityControlPerson?.map((person: any) =>
            Number(person?.id)
          ),
      copywriter: !isCopywriterUnchanged
        ? firstStap?.copywriters
        : firstStap?.copywriters?.map((person: any) => Number(person?.id)),
    } as any)
      .then((response) => {
        if (response?.id) {
          dispatch(workflowSlice.actions.setWorkflowData(response));
          SuccessToast(
            "Success"
            // `${t("toasts.successfully_updated_crawler")} ${response?.website
            // }`
          );
          navigate(`/app/workflow/step2/${response?.id}`);
        }
      })
      .catch((error) => ErrorToast(error));
  };
  const backNavigate = () => {
    navigate(`/app/workflow`);
  };
  useEffect(() => {
    ClientService.getAllClients({
      page: 1,
      perPage: 0,
    })
      .then((response) => {
        const { data } = response;
        setClientList(
          data?.map((client) => ({
            ...client,
            label: client.name,
            value: client.id,
          }))
        );
      })
      .catch((error) => ErrorToast(error));
  }, []);
  useEffect(() => {
    UserService.getAllUsers({
      page: 1,
      perPage: 0,
    })
      .then((response) => {
        const { data } = response;

        setCopywriterList(
          data?.filter((copywriter) => copywriter.isCopywriter) as any
        );
      })
      .catch((error) => ErrorToast(error));
  }, []);

  useEffect(() => {
    if (Boolean(workflowData?.id)) {
      setFirstStap(workflowData);
    }
  }, [workflowData?.id]);

  return (
    <Card>
      <Card.Body>
        <WorkflowStepper step={"SETTING_UP"} id={id}></WorkflowStepper>

        <Row>
          <Col md={4}>
            <label htmlFor="name" className="required-field">
              {`${t("global.name")}`}
            </label>
            <input
              type="text"
              name="name"
              placeholder="Type..."
              className="form-control"
              value={(firstStap?.name as string) ?? ""}
              required
              onChange={changeHandler}
            />
          </Col>
          <Col md={4}>
            <label htmlFor="name" className="">
              {`${t("global.manager")}`}
            </label>
            <Select
              key={firstStap?.id}
              className="react-select-field"
              theme={themeSelect}
              isMulti={true}
              defaultValue={firstStap?.copywriters?.map((value: any) => ({
                label:
                  value?.firstName?.concat(" ", value?.lastName) +
                  ` (${value.copywriterAssignment})`,
                value: value?.id,
              }))}
              onChange={(e) => handleSelectSingle3(e as any, "copywriters")}
              options={copywriterList?.map((value: any) => ({
                label:
                  value?.firstName?.concat(" ", value?.lastName) +
                  ` (${value.copywriterAssignment})`,
                value: value?.id,
              }))}
            />
          </Col>
          <Col md={4}>
            <label htmlFor="client" className="required-field">
              {`${t("export_client.client")}`}
            </label>
            <Select
              key={firstStap?.id}
              className="react-select-field"
              theme={themeSelect}
              defaultValue={{
                label: firstStap?.client?.name,
                value: firstStap?.client?.id,
              }}
              onChange={(e) => {
                handleSelectSingle(e as any, "client");
              }}
              options={clientList as any}
            />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md={4} className="mt-3">
            <label htmlFor="language" className="">
              {`${t("export_client.language")}`}
            </label>
            <Select
              className="react-select-field"
              theme={themeSelect}
              key={firstStap?.id}
              defaultValue={firstStap?.language?.map((language: string) => ({
                value: language,
                label: getCorrectLanguage(language, t),
              }))}
              onChange={(e) => {
                handleSelectSingle2(e as any, "language");
              }}
              isMulti={true}
              options={(translationLanguageEnum as any)?.map(
                (language: string) => ({
                  value: language,
                  label: getCorrectLanguage(language, t),
                })
              )}
            />
          </Col>
          <div className="col-md-4 mt-3">
            <label htmlFor="language" className="">
              {`${t("import.deadline_date")}`}
            </label>
            <div className="datepicker-container">
              <DatePicker
                className="date-picker"
                selected={startDate}
                value={
                  firstStap?.deadlineData
                    ? (formatDateWorkflow(
                        firstStap?.deadlineData as any
                      ) as any)
                    : null
                }
                dateFormat="dd/MM/yyyy"
                onChange={(date) => handleDate(date as any)}
                isClearable
                placeholderText="Choose deadline date..."
              />
            </div>
          </div>
          <Col md={4} className="mt-3">
            <label htmlFor="language" className="required-field">
              {`${t("global.source_lang")}`}
            </label>
            <Select
              isDisabled={firstStap?.id ? true : false}
              className="react-select-field"
              theme={themeSelect}
              key={firstStap?.id}
              defaultValue={
                firstStap?.sourceLang && {
                  label: getCorrectLanguage(firstStap?.sourceLang, t),
                  value: firstStap?.sourceLang,
                }
              }
              onChange={(e) => {
                handleSelectSingle4(e as any);
              }}
              options={(translationLanguageEnum as any)?.map(
                (language: string) => ({
                  value: language,
                  label: getCorrectLanguage(language, t),
                })
              )}
            />
          </Col>
          <Col md={4} className="mt-3">
            <label htmlFor="roles">{t("export_client.productType")}</label>
            <div className="d-flex min-input-height justify-content-start align-items-center">
              {checkBoxArray.map((item) => (
                <React.Fragment key={item}>
                  <input
                    className="form-check-input m-0 cursor-pointer"
                    defaultValue={firstStap?.item as any}
                    checked={!!firstStap?.[item as keyof IWorkflow]}
                    type="checkbox"
                    id={item}
                    onChange={(e) => checkBoxHandler(e, item)}
                  />
                  <label className="m-0 px-3 cursor-pointer" htmlFor={item}>
                    {t(`export_client.${item}`)}
                  </label>
                </React.Fragment>
              ))}
            </div>
          </Col>
        </Row>
      </Card.Body>
      <Card.Footer className="border-top-0">
        <div className="col-12  d-flex justify-content-between">
          <div className="ms-auto">
            <button
              className="btn btn-info"
              onClick={() => {
                backNavigate();
                setFirstStap({} as any);
              }}
            >
              {`${t("block.back")}`}
            </button>
            <button
              className="btn btn-primary ms-3"
              onClick={(e) => submitHandler(e)}
            >
              {`${t("global.save_next")}`}
            </button>
          </div>
        </div>
      </Card.Footer>
    </Card>
  );
};
