import React, { ChangeEvent, SetStateAction, useEffect, useState } from "react";
import { Button, Modal, Spinner, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Select, { SingleValue } from "react-select";
import { ContentEditableEvent } from "react-simple-wysiwyg";
import { themeSelect } from "../../../../shared/components/Select2";
import {
  getCorrectFieldContent,
  getCorrectLanguage,
} from "../../../../shared/functions/Functions";
import { Pagination } from "../../../../shared/paginations/Paginations";
import { ErrorToast, SuccessToast } from "../../../../shared/toasters/toasters";
import firstViewSlice from "../../../../store/category/firstView";
import secondViewPaginationSlice from "../../../../store/category/secondViewPagination";
import { IAllStates } from "../../../../store/rootReducer";
import i18n from "../../../../utils/translations/i18next";
import { IExport } from "../../../exports/dto/IExports";
import { ExportService } from "../../../exports/service";
import { WorkflowService } from "../../../workflow/service";
import ProductVariation from "../../components/ProductVariation";
import { ProductVariationForm } from "../../components/ProductVariationForm";
import { IProductsList, IProductVariation } from "../../dto/IProducts";
import { ProductsService } from "../../service";
import { ChatGptContent } from "./ChatGptContent";
import { ContentActions } from "./ContentActions";
import { ContentViewSide } from "./ContentViewSide";
import VariationUpdateForm from "./VariationUpdateForm";
import workflowLangSlice from "../../../../store/workflow/workflowLangSlice";
import ImageContainer from "../../../../shared/gallery/ImageContainer";
import changeLocationSlice from "../../../../store/category/changeLocation";
import { BlockService } from "../../../block/service";
import { GoogleSearchPromptService } from "../../../googleSearchPrompt/service";
import Chart from "react-apexcharts";
// title bio null
const variationInitial = {
  title: null,
  lang: "",
  bullets: "",
  introText: "",
  description: "",
  subtitle: "",
  blocks: {},
} as any;
interface IMessage {
  content: string;
  role: "assistant" | "user";
  sent?: Date;
}
const getAllVariations = ({
  variationPagination,
  id,
  setVariationList,
  setTotalPages,
}: any) => {
  ProductsService.getAllVariations({
    ...variationPagination,
    product: Number(id),
  })
    .then((response) => {
      setVariationList(response?.data);
      setTotalPages(response?.totalPages);
    })
    .catch((error) => ErrorToast(error));
};

interface Props {
  isTranslator?: boolean;
  isWorkflowUpdate?: boolean;
  selectedProductId?: number;
  setSelectedProductId?: React.Dispatch<SetStateAction<number>> | undefined;
  translationLangTab?: string | undefined;
}

export const ContentUpdate = ({
  isTranslator = false,
  isWorkflowUpdate = false,
  selectedProductId,
  setSelectedProductId,
  translationLangTab,
}: Props) => {
  const [messages, setMessages] = useState<IMessage[]>([]);
  const [allBlocks, setAllBlocks] = useState([] as any);
  const { accessRights } = useSelector((state: IAllStates) => state.auth);
  const translationStep = window.location.pathname?.includes("step6");
  const { id, productId } = useParams();
  const workflowData = useSelector((state: IAllStates) => state.workflow);
  const { search } = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingDataEnrichment, setIsLoadingDataEnrichment] = useState(false);
  const [triggerRadialbarUpdate, setTriggerRadialbarUpdate] = useState(false);
  const [triggerVariationUpdate, setTriggerVariationUpdate] = useState(false);
  const [productStaticts, setProductStaticts] = useState({} as any);
  const [isCheckedAll, setIsCheckedAll] = useState(false);
  const urlLang =
    search.split("=").at(-1) ?? 0 !== 0 ? search.split("=").at(-1) : "first";
  const { t } = useTranslation();
  const [isListView, setIsListView] = useState(true);
  const navigate = useNavigate();
  const [triggerUpdate, setTriggerUpdate] = useState(false);
  const [variationPagination, setVariationPagination] = useState({
    page: 1,
    perPage: 10,
  });
  const [recommendedLanguage, setRecommendedLanguage] = useState("");
  const [variationList, setVariationList] = useState<IProductVariation[]>([]);
  const [totalPages, setTotalPages] = useState(0);
  const [currentVariation, setCurrentVariation] = useState<IProductVariation>(
    variationInitial as any
  );
  const [googleSearchPrompt, setGoogleSearchPrompt] = useState<number>(0);
  const { state: pagination }: any = useLocation();
  const [page, setPage] = useState(1);
  const tab = "BASE_DATA";
  const location = useLocation();
  const basicContentEdit = location?.pathname?.includes(
    "products/edit-content"
  );
  const [showVariationModal, setShowVariationModal] = useState(false);
  const [currentProduct, setCurrentProduct] = useState<IProductsList>();
  const { state: close }: any = useLocation();
  const [certainBlocks, setCertainBlocks] = useState([]);
  const { translationLanguageEnum } = useSelector(
    (state: IAllStates) => state.enums
  );
  const allFields = (currentProduct as any)?.enrichmentData
    ?.formContentBuilderData
    ? Object.keys(
        (currentProduct as any)?.enrichmentData?.formContentBuilderData
      )?.filter((field: string) => field !== "language")
    : [];
  const [googleSearchList, setGoogleSearchList] = useState<any[]>([]);
  const [sourceLanguage, setSourceLanguage] = useState("");
  const [targetLanguage, setTargetLanguage] = useState("");
  const [exportsList, setExportsList] = useState<IExport[]>([]);
  const [exportData, setExportData] = useState<{
    clientExport: number;
  }>();
  const [isLoadingAi, setIsLoadingAi] = useState(false);
  const [isLoadingCopy, setIsLoadingCopy] = useState(false);
  const [field, setField] = useState("");
  const firstView = useSelector((state: IAllStates) => state.firstView);
  const dispatch = useDispatch();
  const currentLanguage = i18n?.language;

  const handleClose = () => {
    setShowVariationModal(false);
    setCurrentVariation(variationInitial as any);
  };
  const handleShow = () => {
    setShowVariationModal(true);
    setCertainBlocks(currentProduct?.block as any);
  };
  const handlePages = (updatePage: number) => {
    setVariationPagination({ ...variationPagination, page: updatePage });
    setPage(updatePage);
  };
  const navigateViewWorkflow = () => {
    if (isWorkflowUpdate) {
      navigate("");
    } else {
      navigate(`/app/products/edit-content/${id}`);
    }
  };
  const changeView1 = () => {
    setIsListView(!isListView);
    dispatch(firstViewSlice.actions.setFirstView(false));
    navigateViewWorkflow();
  };
  const changeView2 = (id: number, lng: string, tab: string) => {
    dispatch(
      secondViewPaginationSlice.actions.setSecondViewPagination(location?.state)
    );
    setIsListView(!isListView);
    dispatch(firstViewSlice.actions.setFirstView(true));
    if (!isTranslator) {
      navigate(`?lang=${lng}`);
      ProductsService.getProductLangById(
        Number(isWorkflowUpdate ? selectedProductId : id),
        tab,
        lng
      )
        .then((response) => {
          setCurrentProduct(response);
        })
        .catch((error) => {
          ErrorToast(error);
          navigate(-1);
        });
    } else {
      WorkflowService.getTranslationProductById(
        Number(id),
        Number(selectedProductId)
      )
        .then((response) => {
          setCurrentProduct(response);
        })
        .catch((error) => {
          ErrorToast(error);
          navigate(-1);
        });
    }
  };
  const [currentVariationTranslation, setCurrentVariationTranslation] =
    useState<IProductVariation>();

  const changeHandler = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | ContentEditableEvent
    >,
    language: string
  ) => {
    // @ts-ignore
    const { value, name } = event.target;
    dispatch(changeLocationSlice.actions.setChangeLocation(true));
    setCurrentProduct((prev: any) => ({
      ...prev,
      translation: {
        ...prev?.translation,
        [language]: {
          ...prev?.translation?.[language],
          [name]: value,
        },
      },
    }));
  };
  const changeVariationHandler = (
    event: React.ChangeEvent<HTMLInputElement | ContentEditableEvent>
  ) => {
    const { value, name } = event.target as HTMLInputElement;
    setCurrentVariation((prevState) => ({ ...prevState, [name]: value }));
  };

  const changeBlocknHandler = (value: string, blockId: number) => {
    setCurrentVariation((prevState) => ({
      ...prevState,
      blocks: {
        ...prevState?.blocks,
        [blockId]: value,
      },
    }));
  };
  const selectGoogleSearchHandler = (e: any) => {
    setGoogleSearchPrompt(+e.value);
  };
  const handleSubmit = (
    event: React.FormEvent,
    language: string,
    checkNext = false
  ) => {
    event.preventDefault();
    if (!isTranslator) {
      ProductsService.updateProductContentById(
        {
          ...currentProduct,
          similar: !!currentProduct?.similar,
          lang: language,
          bullets: currentProduct?.translation?.[language]?.bullets,
          introText: currentProduct?.translation?.[language]?.introText,
          description: currentProduct?.translation?.[language]?.description,
          blocks: currentProduct?.translation?.[language]?.blocks,
        } as any,
        Number(isWorkflowUpdate ? selectedProductId : id),
        isWorkflowUpdate,
        Number(id)
      )
        .then((response) => {
          setTriggerRadialbarUpdate((prev: boolean) => !prev);
          setMessages([]);
          dispatch(changeLocationSlice.actions.setChangeLocation(false));
          if (isWorkflowUpdate && checkNext) {
            WorkflowService.getNextAttributesWorkflow(
              Number(id),
              "COPY_WRITING"
            )
              .then((response) => {
                if (!response.nextProduct?.id) {
                  navigate(`/app/workflow/step5/${id}`);
                } else {
                  navigate(
                    `/app/workflow/step5/${response.nextProduct?.id}/${id}`
                  );
                  window.scrollTo(0, 0);
                }
              })
              .catch((error) => ErrorToast(error));
          }
          // navigate(1);
          SuccessToast(`${t("toasts.successfully_updated")} ${response.title}`);
          setTriggerUpdate((prev) => !prev);
        })
        .catch((error) => ErrorToast(error));
    } else {
      WorkflowService.updateTranslationProductById(
        {
          ...currentProduct,
          lang: language,
          bullets: currentProduct?.translation?.[language]?.bullets,
          introText: currentProduct?.translation?.[language]?.introText,
          description: currentProduct?.translation?.[language]?.description,
          blocks: currentProduct?.translation?.[language]?.blocks,
        } as any,
        Number(selectedProductId),
        Number(id)
      )
        .then((response) => {
          setMessages([]);
          setTriggerRadialbarUpdate((prev: boolean) => !prev);
          dispatch(changeLocationSlice.actions.setChangeLocation(false));
          if (checkNext) {
            WorkflowService.getNextTranslationWorkflow(Number(id))
              .then((response) => {
                if (!response.nextProduct?.id) {
                  navigate(`/app/workflow/step6/${id}`);
                } else {
                  //ovdje setujem next lang
                  localStorage.setItem(
                    "translationNextLang",
                    response?.nextProduct?.recommendedLanguage ?? ""
                  );
                  console.log(
                    response?.nextProduct?.recommendedLanguage,
                    "response?.nextProduct?.recommendedLanguage"
                  );

                  dispatch(workflowLangSlice.actions.setWorkflowLang("de"));
                  navigate(
                    `/app/workflow/step6/${response.nextProduct?.id}/${id}`
                  );
                  window.scrollTo(0, 0);
                }
              })
              .catch((error) => ErrorToast(error));
          }
          // navigate(1);
          window.scrollTo(0, 0);
          SuccessToast(`${t("toasts.successfully_updated")} ${response.title}`);
          setTriggerUpdate((prev) => !prev);
        })
        .catch((error) => ErrorToast(error));
    }
  };

  const handleSaveVariation = () => {
    if (currentVariation?.id) {
      console.log("error");
    } else {
      ProductsService.createVariation({
        product: currentProduct?.id as number,
        lang: currentLanguage,
        title: currentVariation?.title,
        subtitle: currentVariation?.subtitle,
        bullets: currentVariation?.bullets,
        introText: currentVariation?.introText,
        blocks: currentVariation?.blocks,
        description: currentVariation?.description,
      })
        .then((response) => {
          SuccessToast(
            `${t("toasts.successfully_created")} ${
              response?.translation?.[currentLanguage as string]?.title
            }`
          );
          // setCurrentVariation(variationInitial);
          getAllVariations({
            variationPagination: variationPagination,
            id: currentProduct?.id,
            setVariationList: setVariationList,
            setTotalPages: setTotalPages,
          });
          handleClose();
        })
        .catch((error) => ErrorToast(error));
    }
  };
  const submitGoogleSearch = (e: any) => {
    e.preventDefault();
    setIsLoadingDataEnrichment(true);
    GoogleSearchPromptService.enrichProduct({
      productId: currentProduct?.id,
      promptId: googleSearchPrompt,
      titleLanguage: workflowData?.sourceLang ?? "de",
    })
      .then((response) => {
        SuccessToast(`${t("toasts.successfully_created")}`);
        setCurrentProduct((prev: any) => ({
          ...prev,
          enrichmentData: response?.enrichmentData,
        }));
      })
      .catch((error) => ErrorToast(error))
      .finally(() => setIsLoadingDataEnrichment(false));
  };
  const checkHref = () => {
    if (
      currentProduct?.sourceUrl === null ||
      currentProduct?.sourceUrl === "" ||
      currentProduct?.sourceUrl === undefined
    ) {
      return "/403";
    } else if (
      currentProduct?.sourceUrl?.includes("https://") ||
      currentProduct?.sourceUrl?.includes("http://")
    ) {
      return currentProduct?.sourceUrl;
    } else {
      return `https://${currentProduct?.sourceUrl}`;
    }
  };
  function handleSelectSingle(
    selectedSingle: SingleValue<{ label: string; value: string }>,
    language: string,
    fieldName: string
  ) {
    setSourceLanguage(selectedSingle?.value as string);
    setTargetLanguage(language as string);
    setField(fieldName as string);
  }
  useEffect(() => {
    GoogleSearchPromptService.getAllPrompts({ page: 1, perPage: 0 })
      .then((response) => {
        const { data } = response;
        setGoogleSearchList(data);
      })
      .catch((error) => ErrorToast(error));
  }, []);
  const translateAll = () => {
    setIsLoading(true);
    ProductsService.translateAll(
      isWorkflowUpdate ? Number(selectedProductId) : Number(id),
      { sourceLanguage: sourceLanguage }
    )
      .then((response) => {
        SuccessToast(`${t("toasts.successfully_translated")}`);
        setTriggerUpdate((prev) => !prev);
      })
      .catch((error) => {
        ErrorToast(error);
      })
      .finally(() => setIsLoading(false));
  };

  const selectExportHandler = (e: any) => {
    setExportData({ clientExport: +e.value });
  };

  const exportClickHandler = () => {
    ExportService.exportProduct(exportData, +id!)
      .then((res) => {
        SuccessToast(`${t("toasts.successfully_exported")}`);
      })
      .catch((err) => console.log(err));
  };
  const handleCloseContent = () => {
    navigate("/app/products/content", {
      state: (location.state as any)?.pagination,
    });
    // navigate("/app/pending-products/imported", { state: { pagination: pagination?.pagination ?? {} } });
  };

  useEffect(() => {
    //
    //
    if (id && tab && !isTranslator) {
      ProductsService.getProductById(
        +id,
        tab,
        isWorkflowUpdate,
        selectedProductId
      )
        .then((response) => {
          setCurrentProduct(response);
        })
        .catch((error) => {
          ErrorToast(error);
          navigate(-1);
        });
    } else {
      WorkflowService.getTranslationProductById(
        Number(id),
        Number(selectedProductId)
      )
        .then((response) => {
          setCurrentProduct(response);
          setRecommendedLanguage(response?.recommendedLanguage);
        })
        .catch((error) => {
          ErrorToast(error);
          navigate(-1);
        });
    }
  }, [id, isWorkflowUpdate, triggerUpdate, selectedProductId]);
  //bio jos workflowLang
  const FillOrReplaceSubmit = (type: string) => {
    ProductsService.enrichFillOrReplaceContent(
      Number(currentProduct?.id),
      type,
      (currentProduct as any)?.enrichmentData?.formContentBuilderData
    )
      .then((response) => {
        SuccessToast(`Success`);
        WorkflowService.getTranslationProductById(
          Number(id),
          Number(selectedProductId)
        )
          .then((response) => {
            setCurrentProduct(response);
            setRecommendedLanguage(response?.recommendedLanguage);
          })
          .catch((error) => {
            ErrorToast(error);
            navigate(-1);
          });
        // setCurrentProduct(response);
      })
      .catch((error) => ErrorToast(error));
  };
  useEffect(() => {
    if (translationStep) {
      dispatch(firstViewSlice.actions.setFirstView(translationStep));
      changeView2(
        Number(id),
        translationLangTab ??
          localStorage.getItem("language") ??
          ("en" as string),
        "CONTENT"
      );
    } else {
      dispatch(firstViewSlice.actions.setFirstView(false));
    }
  }, []);

  useEffect(() => {
    BlockService.getAllBlocks({
      page: 1,
      perPage: 0,
    })
      .then((response) => {
        const { data } = response;
        setAllBlocks(data as any);
      })
      .catch((error) => ErrorToast(error));
  }, []);
  useEffect(() => {
    id &&
      getAllVariations({
        variationPagination: variationPagination,
        id: isWorkflowUpdate ? Number(selectedProductId) : +id,
        setVariationList: setVariationList,
        setTotalPages: setTotalPages,
      });
  }, [
    id,
    JSON.stringify(variationPagination),
    selectedProductId,
    triggerVariationUpdate,
  ]);
  useEffect(() => {
    ExportService.getAllExports({ page: 1, perPage: 0 })
      .then((response) => {
        const { data } = response;
        setExportsList(data);
      })
      .catch((error) => ErrorToast(error));
  }, []);
  // currentProduct
  const navBaseData = () => {
    if (close?.close === "export") {
      navigate(`/app/products/base-data/${id}`, {
        state: {
          close: "export",
          idExport: (location.state as any).idExport,
          headerName: (location.state as any).headerName,
        },
      });
    } else {
      navigate(`/app/products/base-data/${id}`, { state: pagination });
    }
  };
  //  {
  //   navigate(`/app/products/base-data/${id}`, { state: pagination });
  // };
  const checkDisabled = () => {
    let counter = 0;
    allFields.map((item: any, index: number) => {
      if (
        (currentProduct as any)?.enrichmentData?.formContentBuilderData?.[item]
          ?.insert === true
      ) {
        counter = counter + 1;
      }
    });
    return counter > 0 ? false : true;
  };
  const checkAllFields = () => {
    let counter = 0;
    allFields.map((item: any, index: number) => {
      if (
        (currentProduct as any)?.enrichmentData?.formContentBuilderData?.[item]
          ?.insert === true
      ) {
        counter = counter + 1;
      } else {
        return setIsCheckedAll(false);
      }
    });
    if (counter === allFields?.length - 1) {
      setIsCheckedAll(true);
    }
  };
  const navAttributes = () => {
    if (close?.close === "export") {
      navigate(`/app/products/attributes/${id}`, {
        state: {
          close: "export",
          idExport: (location.state as any).idExport,
          headerName: (location.state as any).headerName,
        },
      });
    } else {
      navigate(`/app/products/attributes/${id}`, { state: pagination });
    }
  };

  const handleCheckVariation = (id: number | null) => {
    setCurrentProduct((prev: any) => ({ ...prev, variation: id }));
  };
  const checkAll = (e: any) => {
    if (e.target.checked) {
      setIsCheckedAll(true);
      allFields?.forEach((field: string) => {
        setCurrentProduct((prev: any) => ({
          ...prev,
          enrichmentData: {
            ...prev?.enrichmentData,
            formContentBuilderData: {
              ...prev?.enrichmentData?.formContentBuilderData,
              [field]: {
                ...prev?.enrichmentData?.formContentBuilderData?.[field],
                insert: true,
              },
            },
          },
        }));
      });
    } else {
      setIsCheckedAll(false);
      allFields?.forEach((field: string) => {
        setCurrentProduct((prev: any) => ({
          ...prev,
          enrichmentData: {
            ...prev?.enrichmentData,
            formContentBuilderData: {
              ...prev?.enrichmentData?.formContentBuilderData,
              [field]: {
                ...prev?.enrichmentData?.formContentBuilderData?.[field],
                insert: false,
              },
            },
          },
        }));
      });
    }
    // checkAllFields();
  };
  const handleCopyVariation = () => {
    setIsLoadingCopy(true);
    id &&
      ProductsService.cloneProductContent(
        isWorkflowUpdate ? Number(selectedProductId) : +id
      )
        .then((response) => {
          setTriggerVariationUpdate((prev: boolean) => !prev);
          window.scrollTo(0, 0);
        })
        .catch((error) => ErrorToast(error))
        .finally(() => setIsLoadingCopy(false));
  };
  const handleAiVariation = (language: string) => {
    setIsLoadingAi(true);
    id &&
      ProductsService.addNewAiVaraiton({
        id: isWorkflowUpdate ? Number(selectedProductId) : +id,
        lang: language !== "" ? language : workflowData?.sourceLang,
      })
        .then((response) => {
          setTriggerVariationUpdate((prev: boolean) => !prev);
          window.scrollTo(0, 0);
        })
        .catch((error) => ErrorToast(error))
        .finally(() => setIsLoadingAi(false));
  };
  const checkboxHandler = (e: any) => {
    if (e.target.checked) {
      setCurrentProduct((prev: any) => ({
        ...prev,
        enrichmentData: {
          ...prev?.enrichmentData,
          formContentBuilderData: {
            ...prev?.enrichmentData?.formContentBuilderData,
            [e.target.value]: {
              ...prev?.enrichmentData?.formContentBuilderData?.[e.target.value],
              insert: true,
            },
          },
        },
      }));
      checkAllFields();
    } else {
      setIsCheckedAll(false);
      setCurrentProduct((prev: any) => ({
        ...prev,
        enrichmentData: {
          ...prev?.enrichmentData,
          formContentBuilderData: {
            ...prev?.enrichmentData?.formContentBuilderData,
            [e.target.value]: {
              ...prev?.enrichmentData?.formContentBuilderData?.[e.target.value],
              insert: false,
            },
          },
        },
      }));
    }
  };
  const backHandle = () => {
    if (close?.close === "export") {
      navigate(`/app/export/list/${close?.idExport}`, {
        state: (location.state as any)?.headerName,
      });
      //  navigate(-1)
    } else if (close?.close === "attributes") {
      navigate(`/admin/attributes/list/${close?.idExport}`, {
        state: (location.state as any)?.headerName,
      });
    } else if (handleCloseContent) {
      handleCloseContent();
    } else {
      navigate(-1);
    }
  };
  const backHandleCopywriting = () => {
    // window.location.reload();
    // setSelectedProductId && setSelectedProductId(0);
    if (window.location.pathname?.includes("step5")) {
      navigate(`/app/workflow/step5/${id}`);
    } else {
      navigate(`/app/workflow/step6/${id}`);
    }
  };
  useEffect(() => {
    id &&
      productId &&
      ProductsService.getWorkflowDataQuality(Number(id), Number(productId))
        .then((response) => {
          setProductStaticts(response);
        })
        .catch((error) => ErrorToast(error));
  }, [id, productId, triggerRadialbarUpdate]);
  return (
    <>
      {/* {isWorkflowUpdate && currentProduct && (
        <div className="d-flex 123 align-items-center mb-3">
          <div className="d-flex gap-3 align-items-center">
            <ImageContainer img={currentProduct?.productPictures ?? []} />
  
            <div>
              <h2>{currentProduct?.title}</h2>
              <p>{currentProduct?.eanCode}</p>
            </div>
          </div>
        </div>
      )} */}
      {/* aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa */}
      <div
        className="card"
        style={
          false
            ? {
                position: "sticky",
                zIndex: "1001",
                top: "70px",
                backgroundColor: "white",
              }
            : {}
        }
      >
        <div className="card-body">
          {isWorkflowUpdate && currentProduct && (
            <div className="mb-3">
              <div className="row">
                <div className="col-md-9">
                  <div className="d-flex gap-3 align-items-center">
                    <ImageContainer
                      img={currentProduct?.productPictures ?? []}
                    />
                    <div>
                      <h2>{currentProduct?.title}</h2>
                      <p style={{ marginBottom: "0.5rem" }}>
                        {currentProduct?.eanCode}
                      </p>
                      <p style={{ marginBottom: "0.5rem" }}>
                        {currentProduct?.brand?.name ?? "-"}
                      </p>
                      <div className="d-flex">
                        {accessRights?.includes(
                          "data_enrichment_workflow_single_product"
                        ) && (
                          <>
                            <Select
                              className="react-select-field w-250 custom-fix"
                              placeholder={"Google search prompt"}
                              onChange={selectGoogleSearchHandler}
                              // isClearable={true}
                              options={googleSearchList.map((exportItem) => ({
                                label: exportItem.title,
                                value: exportItem.id,
                              }))}
                            />
                            <button
                              style={{ minWidth: "141px" }}
                              className="btn btn-primary ms-1 me-2"
                              disabled={googleSearchPrompt === 0}
                              onClick={(e: any) => submitGoogleSearch(e)}
                            >
                              {isLoadingDataEnrichment ? (
                                <Spinner
                                  animation="border"
                                  size="sm"
                                  role="status"
                                />
                              ) : (
                                <span> {t("data_enrichment.enrichment")}</span>
                              )}
                            </button>
                          </>
                        )}
                        <button
                          disabled={
                            (currentProduct as any)?.enrichmentData === null ||
                            Boolean(
                              !(currentProduct as any)?.enrichmentData?.data
                            )
                          }
                          className="btn btn-success"
                          type="button"
                          data-bs-toggle="offcanvas"
                          data-bs-target="#offcanvasRightScrapper"
                          aria-controls="offcanvasRight"
                        >
                          {t("data_enrichment.product_data")}
                        </button>
                      </div>
                    </div>
                    {Boolean((currentProduct as any)?.enrichmentData) &&
                      Boolean(
                        (currentProduct as any)?.enrichmentData?.data
                      ) && (
                        <div
                          className="offcanvas offcanvas-end w-75"
                          tabIndex={-1}
                          id="offcanvasRightScrapper"
                          aria-labelledby="offcanvasRightLabel"
                        >
                          <div className="offcanvas-body pt-0">
                            <div className="row border-bottom sticky-header-offcanvas">
                              <div className="col-6 border-end d-flex align-items-center">
                                <h5
                                  id="offcanvasRightLabel"
                                  className="m-0 py-3"
                                >
                                  {t("data_enrichment.product_data")}
                                </h5>
                              </div>
                              <div className="col-6">
                                <div className="d-flex justify-content-between align-items-center">
                                  <h5 className="m-0 py-3">
                                    {t("global.online_information")}
                                  </h5>
                                  <button
                                    type="button"
                                    className="btn-close text-reset"
                                    data-bs-dismiss="offcanvas"
                                    aria-label="Close"
                                  ></button>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-6 border-end pt-3">
                                <div className="table-responsive">
                                  <Table className="mb-0 align-middle table-bordered">
                                    <thead className="table-light">
                                      <tr>
                                        <th className="" scope="col">{`${t(
                                          "global.field"
                                        )}`}</th>
                                        <th className="p-0" scope="col">
                                          <span
                                            className="d-block border-bottom"
                                            style={{ padding: ".375rem .3rem" }}
                                          >
                                            {`${t("global.old_value")}`}
                                          </span>
                                          <span
                                            className="d-block"
                                            style={{ padding: ".375rem .3rem" }}
                                          >
                                            {`${t("global.new_value")}`}
                                          </span>
                                        </th>

                                        <th className="" scope="col">
                                          <input
                                            className="m-auto form-check-input m-0 cursor-pointer"
                                            // value={maxId}
                                            checked={isCheckedAll}
                                            type="checkbox"
                                            onChange={checkAll}
                                          />
                                        </th>
                                      </tr>
                                    </thead>
                                    {(currentProduct as any)?.enrichmentData
                                      ?.formContentBuilderData && (
                                      <tbody>
                                        {allFields?.map((item: string) => {
                                          return (
                                            <tr>
                                              <td
                                                className="text-nowrap text-start"
                                                style={{
                                                  backgroundColor: "#f3f6f9",
                                                  fontWeight: "500",
                                                }}
                                              >
                                                {getCorrectFieldContent(
                                                  item,
                                                  t
                                                )}
                                              </td>
                                              <td className="text-start border-left p-0">
                                                <div
                                                  className="d-block border-bottom"
                                                  style={{
                                                    padding: ".75rem .6rem",
                                                    backgroundColor: "#f7d0d0",
                                                  }}
                                                  dangerouslySetInnerHTML={{
                                                    __html: (
                                                      currentProduct as any
                                                    )?.enrichmentData
                                                      ?.formContentBuilderData?.[
                                                      item
                                                    ]?.oldValue,
                                                  }}
                                                ></div>
                                                <div
                                                  className="d-block"
                                                  style={{
                                                    padding: ".75rem .6rem",
                                                    backgroundColor: "#e1f7da",
                                                  }}
                                                  dangerouslySetInnerHTML={{
                                                    __html: (
                                                      currentProduct as any
                                                    )?.enrichmentData
                                                      ?.formContentBuilderData?.[
                                                      item
                                                    ]?.newValue,
                                                  }}
                                                ></div>
                                              </td>
                                              <td className="">
                                                <input
                                                  type="checkbox"
                                                  className="form-check-input m-0 cursor-pointer"
                                                  onChange={(e) =>
                                                    checkboxHandler(e)
                                                  }
                                                  value={item}
                                                  checked={
                                                    (currentProduct as any)
                                                      ?.enrichmentData
                                                      ?.formContentBuilderData?.[
                                                      item
                                                    ]?.insert
                                                  }
                                                />
                                              </td>
                                            </tr>
                                          );
                                        })}
                                      </tbody>
                                    )}
                                  </Table>
                                </div>
                                <div className="d-flex align-items-center justify-content-end mt-3">
                                  <button
                                    onClick={() => FillOrReplaceSubmit("fill")}
                                    className="btn btn-info me-2"
                                    disabled={
                                      (currentProduct as any)?.enrichmentData
                                        ?.formContentBuilderData
                                        ? checkDisabled()
                                        : true
                                    }
                                  >
                                    {t("global.fill")}
                                  </button>

                                  <button
                                    className="btn btn-primary"
                                    onClick={() =>
                                      FillOrReplaceSubmit("fillAndReplace")
                                    }
                                    disabled={
                                      (currentProduct as any)?.enrichmentData
                                        ?.formContentBuilderData
                                        ? checkDisabled()
                                        : true
                                    }
                                  >
                                    {t("global.fill_replace")}
                                  </button>
                                </div>
                              </div>
                              <div className="col-6 mt-3">
                                {(currentProduct as any)?.enrichmentData
                                  ?.crawledSites && (
                                  <div style={{ marginBottom: "1rem" }}>
                                    <label className="d-block">
                                      {" "}
                                      {t("crawlers.website")}:
                                    </label>
                                    {(
                                      currentProduct as any
                                    )?.enrichmentData?.crawledSites?.map(
                                      (link: string) => {
                                        return (
                                          <a
                                            className="d-block"
                                            href={link}
                                            target="_blank"
                                          >
                                            {link}
                                          </a>
                                        );
                                      }
                                    )}
                                  </div>
                                )}
                                {/* {Object.entries(
                            (currentProduct as any)?.enrichmentData?.dataJson
                          ).map(([key, value]) => {
                            if (Array.isArray(value)) {
                              return (
                                <div key={key}>
                                  <h2>{key}:</h2>
                                  <ul>
                                    {value.map((item, index) => (
                                      <li key={index}>{item}</li>
                                    ))}
                                  </ul>
                                </div>
                              );
                            }

                            return (
                              <div key={key}>
                                <p>
                                  <strong>{key}:</strong> {value || "N/A"}
                                </p>
                              </div>
                            );
                          })} */}
                                {(currentProduct as any)?.enrichmentData
                                  ?.data && (
                                  <>
                                    {/* <label className="d-block">Data:</label> */}
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: (currentProduct as any)
                                          ?.enrichmentData?.data,
                                      }}
                                    />
                                    {/* <p>{(currentProduct as any)?.enrichmentData?.data}</p> */}
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                  </div>
                </div>
                <div className="col-md-3 d-flex align-items-center justify-content-end">
                  <Chart
                    options={{
                      plotOptions: {
                        radialBar: {
                          startAngle: -135,
                          endAngle: 225,
                          hollow: {
                            margin: 0,
                            size: "70%",
                            background: "#fff",
                            image: undefined,
                            imageOffsetX: 0,
                            imageOffsetY: 0,
                            position: "front",
                            dropShadow: {
                              enabled: true,
                              top: 3,
                              left: 0,
                              blur: 4,
                              opacity: 0.24,
                            },
                          },
                          track: {
                            background: "#fff",
                            strokeWidth: "67%",
                            margin: 0, // margin is in pixels
                            dropShadow: {
                              enabled: true,
                              top: -3,
                              left: 0,
                              blur: 4,
                              opacity: 0.35,
                            },
                          },

                          dataLabels: {
                            showOn: "always",
                            name: {
                              offsetY: 20,
                              show: true,
                              color: "#888",
                              fontSize: "13px",
                            },
                            value: {
                              formatter: function (val: any) {
                                return val + "%";
                              },
                              color: "#111",
                              fontSize: "25px",
                              show: true,
                              offsetY: -10,
                            },
                          },
                        },
                      },
                      fill: {
                        type: "gradient",
                        gradient: {
                          shade: "dark",
                          type: "horizontal",
                          shadeIntensity: 0.5,
                          gradientToColors: ["#ABE5A1"],
                          inverseColors: true,
                          opacityFrom: 1,
                          opacityTo: 1,
                          stops: [0, 100],
                        },
                      },
                      stroke: {
                        lineCap: "round",
                      },
                      labels: ["Content"],
                    }}
                    series={[
                      Number(
                        (productStaticts as any)?.contentStatistics?.statistics
                          ?.percent ?? 0
                      ),
                    ]}
                    type="radialBar"
                    width="150px" // Set the width to 70px
                    height="150px" // Set the height to 70px
                  />
                  <Chart
                    options={{
                      plotOptions: {
                        radialBar: {
                          startAngle: -135,
                          endAngle: 225,
                          hollow: {
                            margin: 0,
                            size: "70%",
                            background: "#fff",
                            image: undefined,
                            imageOffsetX: 0,
                            imageOffsetY: 0,
                            position: "front",
                            dropShadow: {
                              enabled: true,
                              top: 3,
                              left: 0,
                              blur: 4,
                              opacity: 0.24,
                            },
                          },
                          track: {
                            background: "#fff",
                            strokeWidth: "67%",
                            margin: 0, // margin is in pixels
                            dropShadow: {
                              enabled: true,
                              top: -3,
                              left: 0,
                              blur: 4,
                              opacity: 0.35,
                            },
                          },

                          dataLabels: {
                            showOn: "always",
                            name: {
                              offsetY: 20,
                              show: true,
                              color: "#888",
                              fontSize: "13px",
                            },
                            value: {
                              formatter: function (val: any) {
                                return val + "%";
                              },
                              color: "#111",
                              fontSize: "25px",
                              show: true,
                              offsetY: -10,
                            },
                          },
                        },
                      },
                      fill: {
                        type: "gradient",
                        gradient: {
                          shade: "dark",
                          type: "horizontal",
                          shadeIntensity: 0.5,
                          gradientToColors: ["#ABE5A1"],
                          inverseColors: true,
                          opacityFrom: 1,
                          opacityTo: 1,
                          stops: [0, 100],
                        },
                      },
                      stroke: {
                        lineCap: "round",
                      },
                      labels: ["Total"],
                    }}
                    series={[
                      Number(
                        (productStaticts as any)?.totalStatistics?.percent ?? 0
                      ),
                    ]}
                    type="radialBar"
                    width="150px" // Set the width to 70px
                    height="150px" // Set the height to 70px
                  />
                </div>
              </div>
            </div>
          )}

          {!isWorkflowUpdate && (
            <div className="d-flex align-items-end justify-content-between mb-3">
              <ul className="nav nav-pills nav-fill">
                <li onClick={() => navBaseData()}>
                  <span className="nav-link cursor-pointer text-dark">
                    {t("products.base_data_update")}
                  </span>
                </li>
                <li onClick={() => navAttributes()}>
                  <span className="nav-link cursor-pointer text-dark">
                    {t("products.attributes_update")}
                  </span>
                </li>
                <li>
                  <span className="nav-link active">
                    {t("products.content_update")}
                  </span>
                </li>
              </ul>
              <div
                className="d-flex align-items-end justify-content-between"
                style={{ zIndex: "1000" }}
              >
                <div className="" style={{ width: "226px" }}>
                  <h6 className="m-0 p-0 mb-2">{t("global.export")}:</h6>
                  <Select
                    className="react-select-field"
                    onChange={selectExportHandler}
                    options={exportsList.map((exportItem) => ({
                      label: exportItem.name,
                      value: exportItem.id,
                    }))}
                  />
                </div>
                <button
                  className="btn btn-primary ms-2"
                  onClick={exportClickHandler}
                >
                  {t("global.add_to_export")}
                </button>
              </div>
            </div>
          )}

          <div className="d-flex align-items-center justify-content-between">
            <button
              onClick={() =>
                isWorkflowUpdate ? backHandleCopywriting() : backHandle()
              }
              className="btn btn-info d-flex align-items-center mb-3"
            >
              <i className="ri-arrow-left-line me-1"></i>
              {t("products.back")}
            </button>
            <div className="d-flex">
              <button
                onClick={() =>
                  navigate(`/app/products/data-history/${productId ?? id}`)
                }
                className="btn btn-secondary d-flex align-items-start justify-content-start mb-3 me-3"
              >
                <i className="ri-history-line me-1"></i>
                {t("products.data_history")}
              </button>
              <a
                rel="noreferrer"
                target="_blank"
                href={checkHref()}
                // href={`${currentProduct?.sourceUrl === null ? "/403" : `${currentProduct?.sourceUrl}`} `}
              >
                <button className="btn btn-primary">
                  {t("products.source_url")}
                </button>
              </a>
            </div>
          </div>
          <div className="col-md-12  d-flex align-items-center justify-content-between">
            {!isWorkflowUpdate && currentProduct && (
              <div className="d-flex align-items-center mb-3">
                <div className="d-flex gap-3 align-items-center">
                  <ImageContainer img={currentProduct?.productPictures ?? []} />
                  {/* <CustomImageHandler 
            classes="avatar-lg rounded-circle me-2"
            path={currentProduct?.productPictures[1]?.path ?? ""}
            alt="User logo"
          /> */}
                  <div>
                    <h2>{currentProduct?.title}</h2>
                    <p>{currentProduct?.eanCode}</p>
                  </div>
                </div>
              </div>
            )}

            {
              // !isTranslator
              true && (
                <>
                  <div className="d-flex align-items-center">
                    {/* <button
              onClick={goBack}
              className="btn btn-info d-flex align-items-center me-2"
            >
              <i className="ri-arrow-left-line me-1"></i>
              {t("products.back")}
            </button> */}
                    <>
                      <div className="d-flex justify-content-end align-items-center my-1">
                        <>
                          <div className="">
                            <Select
                              className="custom_zindex_up react-select-field"
                              name="description"
                              theme={themeSelect}
                              placeholder="Translate from"
                              options={translationLanguageEnum?.map((lang) => ({
                                value: lang,
                                label: getCorrectLanguage(lang, t),
                              }))}
                              onChange={(e) => {
                                handleSelectSingle(
                                  e as any,
                                  sourceLanguage,
                                  "description"
                                );
                              }}
                            />
                          </div>
                          <button
                            onClick={translateAll}
                            className="btn btn-secondary d-flex align-items-center mx-2"
                          >
                            {isLoading ? (
                              <Spinner
                                animation="border"
                                size="sm"
                                role="status"
                              />
                            ) : (
                              <span>{t("global.translateAll")}</span>
                            )}
                          </button>
                        </>
                      </div>

                      <button
                        className="btn btn-primary d-flex align-items-center me-2"
                        data-bs-toggle="offcanvas"
                        data-bs-target={`#offcanvasRight-${id}`}
                        aria-controls={`offcanvasRight-${id}`}
                      >
                        <i className="ri-message-3-line me-1" />
                        {t("products.chatGpt")}
                      </button>
                      <div
                        className="offcanvas offcanvas-end main-height w-50"
                        id={`offcanvasRight-${id}`}
                        aria-labelledby="offcanvasRightLabel"
                      >
                        <div className="offcanvas-header">
                          <h5 id="offcanvasRightLabel">
                            {t("products.chatGpt")}
                          </h5>
                          <button
                            type="button"
                            className="btn-close text-reset"
                            data-bs-dismiss="offcanvas"
                            aria-label="Close"
                          ></button>
                        </div>
                        <div className="offcanvas-body">
                          <ChatGptContent
                            messages={messages}
                            setMessages={setMessages}
                          />
                        </div>
                      </div>
                    </>
                    {!translationStep && (
                      <button
                        onClick={handleShow}
                        className="btn btn-success d-flex align-items-center"
                      >
                        <i className="ri-play-list-add-line me-1" />
                        {t("products.createVariation")}
                      </button>
                    )}
                    {!translationStep && (
                      <>
                        <button
                          className="btn btn-info ms-2"
                          onClick={(e) => handleCopyVariation()}
                        >
                          {isLoadingCopy ? (
                            <Spinner
                              animation="border"
                              size="sm"
                              role="status"
                            />
                          ) : (
                            // <span>{t("products.ai_variation")}</span>
                            <span>Copy As Variation</span>
                          )}
                        </button>
                        {isWorkflowUpdate ? (
                          <button
                            style={{
                              minWidth: "135px",
                            }}
                            className="btn btn-primary ms-2"
                            onClick={(e) => handleAiVariation("")}
                          >
                            {isLoadingAi ? (
                              <Spinner
                                animation="border"
                                size="sm"
                                role="status"
                              />
                            ) : (
                              <span>{t("products.ai_variation")}</span>
                            )}
                          </button>
                        ) : (
                          <div className="dropdown">
                            <a
                              className="btn ms-2 btn-primary dropdown-toggle"
                              href="#"
                              role="button"
                              id="dropdownMenuLink"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                              style={{
                                minWidth: "154px",
                              }}
                            >
                              {isLoadingAi ? (
                                <Spinner
                                  animation="border"
                                  size="sm"
                                  role="status"
                                />
                              ) : (
                                <span>{t("products.ai_variation")}</span>
                              )}
                            </a>

                            <ul
                              className="dropdown-menu"
                              aria-labelledby="dropdownMenuLink"
                            >
                              {translationLanguageEnum?.map(
                                (language: string) => {
                                  return (
                                    <li>
                                      <span
                                        className="dropdown-item"
                                        onClick={(e) =>
                                          handleAiVariation(language)
                                        }
                                      >
                                        {getCorrectLanguage(language, t)}
                                      </span>
                                    </li>
                                  );
                                }
                              )}
                            </ul>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </>
              )
            }
          </div>
          {/* ovdjeeeee eeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee*/}
          <div className="d-flex">
            {!basicContentEdit && (
              <div className="col-md-6 mb-2">
                <label className="m-0" htmlFor="content">
                  {t("side_bar.glossary")}
                </label>
                <div className="d-flex justify-content-between align-items-center my-1">
                  <div className="col-md-5">
                    <Select
                      isDisabled={true}
                      className="react-select-field"
                      placeholder={"Glossary client"}
                      theme={themeSelect}
                      // key={state?.id}
                      defaultValue={
                        localStorage.getItem("glossaryClient") && {
                          // label: clientName?.client as string,
                          label: JSON.parse(
                            localStorage.getItem("glossaryClient") as any
                          )?.client,
                        }
                      }
                    />
                  </div>
                </div>
              </div>
            )}
            <div className="d-flex w-100 align-items-center justify-content-end mb-3">
              <button
                className={`btn btn${
                  firstView == true ? "-soft" : ""
                }-info  btn-sm`}
                onClick={() => changeView1()}
              >
                <i className="ri-list-check " />
              </button>
              <button
                className={`btn btn${
                  firstView !== true ? "-soft" : ""
                }-info btn-sm ms-2`}
                onClick={() =>
                  changeView2(
                    Number(id),
                    // recommendedLanguage ?? ("en" as string),
                    translationLangTab ??
                      localStorage.getItem("language") ??
                      ("en" as string),
                    "CONTENT"
                  )
                }
              >
                <i className="ri-bank-card-line" />
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa */}
      {!currentVariation?.id ? (
        <Modal
          show={showVariationModal}
          onHide={handleClose}
          size="lg"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>{t("products.variation.createVariation")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ProductVariationForm
              productStaticts={productStaticts}
              changeBlocknHandler={
                changeBlocknHandler as (value: string) => void
              }
              setCurrentVariation={setCurrentVariation}
              currentVariation={currentVariation}
              certainBlocks={certainBlocks}
              changeVariationHandler={
                changeVariationHandler as (
                  event: ContentEditableEvent | ChangeEvent<HTMLInputElement>
                ) => void
              }
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="info" onClick={handleClose}>
              {t("global.cancel")}
            </Button>
            <Button variant="primary" onClick={handleSaveVariation}>
              {t("category.save")}
            </Button>
          </Modal.Footer>
        </Modal>
      ) : (
        <VariationUpdateForm
          productStaticts={productStaticts}
          currentVariation={currentVariationTranslation}
          variationId={currentVariation?.id}
          setCurrentVariation={setCurrentVariationTranslation}
          showVariationModal={showVariationModal}
          handleClose={handleClose}
          certainBlocks={certainBlocks}
          setTriggerUpdate={setTriggerUpdate}
        />
      )}
      {firstView ? (
        <>
          <div className="row">
            <div className="col-md-6">
              <ContentViewSide
                productStaticts={productStaticts}
                allBlocks={allBlocks}
                selectedProductId={selectedProductId}
                currentProduct={currentProduct as IProductsList}
                setCurrentProduct={setCurrentProduct}
                firstView={firstView || isTranslator}
                handleSubmit={handleSubmit}
                isWorkflowUpdate={isWorkflowUpdate}
                setTriggerUpdate={setTriggerUpdate}
                trigerUpdate={triggerUpdate}
                isTranslator={isTranslator}
                translationLangTab={workflowData?.sourceLang}
              />
            </div>
            <div className="col-md-6">
              <ContentActions
                productStaticts={productStaticts}
                allBlocks={allBlocks}
                recommendedLanguage={
                  currentProduct?.languages?.length === 1
                    ? recommendedLanguage
                    : ""
                }
                translationLangTab={translationLangTab}
                isWorkflowUpdate={isWorkflowUpdate}
                selectedProductId={selectedProductId}
                handleCloseContent={handleCloseContent}
                setTriggerUpdate={setTriggerUpdate}
                currentProduct={currentProduct as IProductsList}
                setCurrentProduct={setCurrentProduct}
                changeHandler={changeHandler}
                handleSubmit={handleSubmit}
                isTranslator={isTranslator}
                tabLanguages={(currentProduct as any)?.languages}
              />
              {!isTranslator &&
                variationList?.map((variation) => (
                  <ProductVariation
                    setTriggerVariationUpdate={setTriggerVariationUpdate}
                    currentProduct={currentProduct as IProductsList}
                    handleCheckVariation={handleCheckVariation}
                    setCurrentVariationTranslation={
                      setCurrentVariationTranslation
                    }
                    setCurrentVariation={setCurrentVariation}
                    setVariationList={setVariationList}
                    handleShow={handleShow}
                    variation={variation}
                    key={variation?.id}
                  />
                ))}
              <div className="col-12 d-flex justify-content-end ">
                {totalPages <= 1 ? (
                  ""
                ) : (
                  <Pagination
                    page={page}
                    totalPages={totalPages}
                    handlePagination={handlePages}
                  />
                )}
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className={`col-md-${isTranslator ? 12 : 9}`}>
            {isWorkflowUpdate && !isTranslator && (
              <div className="d-flex justify-content-end w-100 ">
                <div className="d-flex align-items-center mb-1">
                  <input
                    className="form-check-input m-0 cursor-pointer"
                    // defaultValue={state?.item as any}
                    // checked={!!state?.[item as keyof IExport]}
                    type="checkbox"
                    checked={currentProduct?.similar}
                    id="similar"
                    onChange={(e) =>
                      setCurrentProduct((prev: any) => ({
                        ...prev,
                        similar: e.target.checked,
                      }))
                    }
                  />
                  <label className="m-0 cursor-pointer ps-1" htmlFor="similar">
                    {t("global.similar")}
                  </label>
                </div>
                <div className="mx-3">
                  <input
                    className="form-check-input me-1"
                    type="radio"
                    name="format"
                    id={`variation-${id}`}
                    checked={null === currentProduct?.variation}
                    onChange={() =>
                      handleCheckVariation && handleCheckVariation(null)
                    }
                  />
                  <label
                    className="title-variation"
                    htmlFor={`variation-${id}`}
                  >
                    {t("workflow.select_product")}
                  </label>
                </div>
              </div>
            )}
            {/* teeeeeeeeeeeeeeeee
            eeeeeeeeeeeeeeeeeeeeeees lang tab */}
            <ContentViewSide
              productStaticts={productStaticts}
              glossaryShow={true}
              allBlocks={allBlocks}
              handleCloseContent={handleCloseContent}
              currentProduct={currentProduct as IProductsList}
              selectedProductId={selectedProductId}
              setCurrentProduct={setCurrentProduct}
              firstView={firstView}
              handleSubmit={handleSubmit}
              isWorkflowUpdate={isWorkflowUpdate}
              setTriggerUpdate={setTriggerUpdate}
              trigerUpdate={triggerUpdate}
              isTranslator={isTranslator}
              translationLangTab={translationLangTab}
            />
          </div>
          {!isTranslator && (
            <div className="col-md-3">
              {variationList?.map((variation) => (
                <ProductVariation
                  setTriggerVariationUpdate={setTriggerVariationUpdate}
                  currentProduct={currentProduct as IProductsList}
                  isWorkflowUpdate={isWorkflowUpdate}
                  handleCheckVariation={handleCheckVariation}
                  setCurrentVariationTranslation={
                    setCurrentVariationTranslation
                  }
                  setCurrentVariation={setCurrentVariation}
                  setVariationList={setVariationList}
                  handleShow={handleShow}
                  variation={variation}
                  key={variation?.id}
                />
              ))}
              <div className="col-12 d-flex justify-content-end ">
                {totalPages <= 1 ? (
                  ""
                ) : (
                  <Pagination
                    page={page}
                    totalPages={totalPages}
                    handlePagination={handlePages}
                  />
                )}
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};
