import { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { UserService } from "../../users/service";
import { ErrorToast } from "../../../shared/toasters/toasters";
import { themeSelect } from "../../../shared/components/Select2";
import Select, { SingleValue } from "react-select";
import { CompanyService } from "../../companies/service";
import { ICompaniesDTO } from "../../companies/dto/ICompanies";
import { Pagination } from "../../../shared/paginations/Paginations";
import { EmptyState } from "../../../shared/components/EmptyState";
interface IFilter {
  perPage: number;
  page: number;
  name?: string;
  email?: string;
  company?: number;
}

export const WorkflowUnfinishedList = ({ list, userJob }: any) => {
  const { t } = useTranslation();
  const [companiesList, setCompaniesList] = useState<ICompaniesDTO[]>([]);
  const [copywriterList, setCopywriterList] = useState([]);
  const [translatorList, setTranslatorList] = useState([]);
  const [totalPagesCopywriter, setTotalPagesCopywriter] = useState(0);
  const [totalPagesTranslator, setTotalPagesTranslator] = useState(0);
  const [totalPagesQualityController, setTotalPagesQualityController] =
    useState(0);
  const [qualityControlList, setQualityControlList] = useState([]);
  const [copywriterFilters, setCopywriterFilters] = useState<IFilter>({
    perPage: 10,
    page: 1,
  });
  const [translatorFilters, setTranslatorFilters] = useState<IFilter>({
    perPage: 10,
    page: 1,
  });
  const [qualityControllerFilters, setQualityControllerFilters] =
    useState<IFilter>({
      perPage: 10,
      page: 1,
    });

  const changeCopywriterHandler = (e: any, name: string) => {
    if (name === "company") {
      setCopywriterFilters((prev) => ({
        ...prev,
        [name]: e?.value,
        page: 1,
      }));
    } else {
      setCopywriterFilters((prev) => ({
        ...prev,
        [name]: e?.target?.value,
        page: 1,
      }));
    }
  };
  const changeTranslatorHandler = (e: any, name: string) => {
    if (name === "company") {
      setTranslatorFilters((prev) => ({
        ...prev,
        [name]: e?.value,
        page: 1,
      }));
    } else {
      setTranslatorFilters((prev) => ({
        ...prev,
        [name]: e?.target?.value,
        page: 1,
      }));
    }
  };
  const handlePagesCopywriter = (updatePage: number) => {
    setCopywriterFilters((prev) => ({ ...prev, page: updatePage }));
  };
  const handlePagesTranslator = (updatePage: number) => {
    setTranslatorFilters((prev) => ({ ...prev, page: updatePage }));
  };
  useEffect(() => {
    UserService.getWorkflowUsers({
      ...copywriterFilters,
      isCopywriter: true,
    })
      .then((response) => {
        const { data } = response;

        setTotalPagesCopywriter(Math.ceil(response?.count / response?.perPage));

        setCopywriterList(data);
      })
      .catch((error) => ErrorToast(error));
  }, [copywriterFilters]);

  useEffect(() => {
    UserService.getWorkflowUsers({
      ...translatorFilters,
      translator: true,
    })
      .then((response) => {
        const { data } = response;
        setTotalPagesTranslator(Math.ceil(response?.count / response?.perPage));
        setTranslatorList(data);
      })
      .catch((error) => ErrorToast(error));
  }, [translatorFilters]);
  useEffect(() => {
    UserService.getWorkflowUsers({
      ...qualityControllerFilters,
      qualityControlPerson: true,
    })
      .then((response) => {
        const { data } = response;
        setTotalPagesQualityController(
          Math.ceil(response?.count / response?.perPage)
        );
        setQualityControlList(data);
      })
      .catch((error) => ErrorToast(error));
  }, [qualityControllerFilters]);

  useEffect(() => {
    CompanyService.getAllCompanies({ page: 1, perPage: 0 })
      .then((response) => {
        const { data } = response;
        setCompaniesList(data);
      })
      .catch((error) => ErrorToast(error));
  }, []);

  return (
    <>
      <div className="card">
        <div className="card-header pb-0">
          <div className="d-flex justify-content-start">
            <h4 className="card-title mg-b-0">{t("workflow.copywriters")}</h4>
          </div>
        </div>
        <div className="card-body">
          <div className="row mb-4">
            <div className="col">
              <div>
                <label htmlFor="basiInput" className="form-label">
                  {t("global.name")}
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="basiInput"
                  placeholder={t("global.name")}
                  onChange={(event) => {
                    changeCopywriterHandler(event, "name");
                  }}
                />
              </div>
            </div>
            <div className="col">
              <div>
                <label htmlFor="basiInput" className="form-label">
                  {t("global.email")}
                </label>
                <input
                  onChange={(event) => {
                    changeCopywriterHandler(event, "email");
                  }}
                  type="text"
                  className="form-control"
                  id="basiInput"
                  placeholder={t("global.email")}
                />
              </div>
            </div>
            <div className="col">
              <label htmlFor="basiInput" className="form-label">
                {t("global.company")}
              </label>
              <Select
                className="react-select-field "
                theme={themeSelect}
                isClearable={true}
                placeholder={t("global.company")}
                options={companiesList?.map((option) => ({
                  label: option.title ?? "-",
                  value: option.id,
                }))}
                onChange={(event) => {
                  changeCopywriterHandler(event, "company");
                }}
              />
            </div>
          </div>
          {copywriterList?.length === 0 ? (
            <EmptyState />
          ) : (
            <>
              <div className="table-responsive">
                <Table className="align-middle table-nowrap mb-0">
                  <thead className="table-light">
                    <tr>
                      <th scope="col">{`${t("global.id")}`}</th>
                      <th scope="col">{`${t("global.name")}`}</th>
                      <th scope="col">{`${t("global.email")}`}</th>
                      <th scope="col">{`${t("workflow.company")}`}</th>
                      <th scope="col">{`${t("workflow.assignment")}`}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {copywriterList?.map((user: any) => {
                      return (
                        <tr>
                          <td>{user.id}</td>
                          <td>{user.firstName + " " + user.lastName}</td>
                          <td>{user.email}</td>
                          <td>{user?.company?.title ?? "-"}</td>
                          <td>{user.copywriterAssignment}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
              <div className="card-footer">
                <div className="d-flex justify-content-end ">
                  {totalPagesCopywriter! <= 1 ? (
                    ""
                  ) : (
                    <Pagination
                      page={copywriterFilters.page!}
                      totalPages={totalPagesCopywriter!}
                      handlePagination={handlePagesCopywriter!}
                    />
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <div className="card">
        <div className="card-header pb-0">
          <div className="d-flex justify-content-start">
            <h4 className="card-title mg-b-0">{t("workflow.translators")}</h4>
          </div>
        </div>
        <div className="card-body">
          <div className="row mb-4">
            <div className="col">
              <div>
                <label htmlFor="basiInput" className="form-label">
                  {t("global.name")}
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="basiInput"
                  placeholder={t("global.name")}
                  onChange={(event) => {
                    changeTranslatorHandler(event, "name");
                  }}
                />
              </div>
            </div>
            <div className="col">
              <div>
                <label htmlFor="basiInput" className="form-label">
                  {t("global.email")}
                </label>
                <input
                  onChange={(event) => {
                    changeTranslatorHandler(event, "email");
                  }}
                  type="text"
                  className="form-control"
                  id="basiInput"
                  placeholder={t("global.email")}
                />
              </div>
            </div>
            <div className="col">
              <label htmlFor="basiInput" className="form-label">
                {t("global.company")}
              </label>
              <Select
                className="react-select-field "
                theme={themeSelect}
                isClearable={true}
                placeholder={t("global.company")}
                options={companiesList?.map((option) => ({
                  label: option.title ?? "-",
                  value: option.id,
                }))}
                onChange={(event) => {
                  changeTranslatorHandler(event, "company");
                }}
              />
            </div>
          </div>
          {translatorList?.length === 0 ? (
            <EmptyState />
          ) : (
            <>
              <div className="table-responsive">
                <Table className="align-middle table-nowrap mb-0">
                  <thead className="table-light">
                    <tr>
                      <th scope="col">{`${t("global.id")}`}</th>
                      <th scope="col">{`${t("global.name")}`}</th>
                      <th scope="col">{`${t("global.email")}`}</th>
                      <th scope="col">{`${t("workflow.company")}`}</th>
                      <th scope="col">{`${t("workflow.assignment")}`}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {translatorList?.map((user: any) => {
                      return (
                        <tr>
                          <td>{user.id}</td>
                          <td>{user.firstName + " " + user.lastName}</td>
                          <td>{user.email}</td>
                          <td>{user?.company?.title ?? "-"}</td>
                          <td>{user.translatorAssignment}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>

              <div className="card-footer">
                <div className="d-flex justify-content-end ">
                  {totalPagesTranslator! <= 1 ? (
                    ""
                  ) : (
                    <Pagination
                      page={translatorFilters.page!}
                      totalPages={totalPagesTranslator!}
                      handlePagination={handlePagesTranslator!}
                    />
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};
