import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { WorkflowService } from "../service";
import { ErrorToast, SuccessToast } from "../../../shared/toasters/toasters";
import Lottie from "lottie-react";
import lockedIcon from "../../../shared/icons/lockedIcon.json";

const LockedReasonModal = ({
  show,
  onHide,
  currentWorkflowId,
  setTriggerUpdate,
}: any) => {
  const [lockedReason, setLockedReason] = useState("");
  const handleTextArea = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setLockedReason(value as any);
  };
  const lockedWorkflow = (e: any) => {
    e.preventDefault();
    WorkflowService.lockWorkflow(Number(currentWorkflowId), {
      lockedReason: lockedReason,
    })
      .then((response: any) => {
        SuccessToast(response?.message);
        onHide();
        setLockedReason("");
        setTriggerUpdate((prev: boolean) => !prev);
      })
      .catch((error) => ErrorToast(error));
  };
  return (
    <Modal size="sm" centered show={show}>
      <Modal.Body className="d-flex align-items-center flex-column">
        <Lottie animationData={lockedIcon} loop={true} style={{ width: 100 }} />
        <h5 className="me-auto mt-2">Lock reason</h5>
        <input
          type="text"
          value={lockedReason}
          onChange={handleTextArea as any}
          className="w-100"
          name="lockedReason"
          id="lockedReason"
        />
      </Modal.Body>
      <Modal.Footer className="d-flex align-items-center justify-content-end">
        <Button variant="secondary" size="sm" onClick={onHide}>
          Cancel
        </Button>
        <Button
          variant="primary"
          size="sm"
          onClick={(e: any) => lockedWorkflow(e)}
        >
          Lock
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default LockedReasonModal;
