import { Button, Modal } from "react-bootstrap";
import Lottie from "lottie-react";
import lockedIcon from "../icons/lockedIcon.json";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { IAllStates } from "../../store/rootReducer";
import { appVersionSlice } from "../../utils/general/appVersionSlice";
import { useNavigate } from "react-router";
import open423ErrModalSlice from "../../utils/general/open423ErrModalSlice";

export const Confirm423Err = ({}: any) => {
  const navigate = useNavigate();
  const open423ErrModal = useSelector(
    (state: IAllStates) => state.open423ErrModal
  );
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const reloadVersion = () => {
    dispatch(open423ErrModalSlice.actions.setOpen423ErrModal(false));
  };
  return (
    <Modal size="sm" centered show={open423ErrModal}>
      <Modal.Body className="d-flex align-items-center flex-column">
        <Lottie animationData={lockedIcon} loop={true} style={{ width: 100 }} />
        <h5>This workflow is locked</h5>
        <p className="text-center text-muted">
          Please return to workflows list
        </p>
      </Modal.Body>
      <Modal.Footer className="d-flex align-items-center justify-content-center">
        <Button
          variant="primary"
          size="sm"
          onClick={() => {
            reloadVersion();
            navigate("/app/workflow");
          }}
        >
          Go to workflows
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
