// @ts-nocheck
import { Card, Col, Row } from "react-bootstrap";
import Select, { SingleValue } from "react-select";
import { SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import { themeSelect } from "../../../shared/components/Select2";
import { IContent } from "../dto/IContent";
import { IClient } from "../../../model/authentification/IRegister";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import "../../../ckeditor.css";

const editorConfigurationCustom = {
  toolbar: {
    items: [
      "undo",
      "redo",
      "findAndReplace",
      "alignment",
      "heading",
      "textPartLanguage",
      "|",
      "bold",
      "italic",
      "underline",
      "link",
      "|",
      "horizontalLine",
      "fontColor",
      "fontBackgroundColor",
      "fontSize",
      "highlight",
      "selectAll",
      "pageBreak",
      "strikethrough",
      "specialCharacters",
      "restrictedEditingException",
      "numberedList",
      "bulletedList",
      "indent",
      "outdent",
      "removeFormat",
      "subscript",
      "superscript",
      "HtmlEmbed",
      "sourceEditing",
      "insertTable",
      "|",
      "glossary-scan",
      // "glossary-ignore",
      "glossary-change",
    ],
    shouldNotGroupWhenFull: true, // optional, to prevent grouping when the toolbar is full
  },
  table: {
    contentToolbar: [
      "tableColumn",
      "tableRow",
      "mergeTableCells",
      "tableProperties",
      "tableCellProperties",
      "toggleTableCaption",
    ],
  },
  removePlugins: ["Title", "Markdown"], // remove the title plugin from the editor
};
interface IExportFormProps {
  setIsMenuOpened: React.Dispatch<SetStateAction<boolean>>;
  state: IContent | undefined;
  setState: React.Dispatch<SetStateAction<IContent | undefined>>;
  clientList: IClient[];
  changeClientHandler: (value: string) => void;
}

export const ContentCreate = ({
  setIsMenuOpened,
  state,
  setState,
  changeClientHandler,
  clientList,
}: IExportFormProps) => {
  const { t } = useTranslation();
  const [wordCount, setWordCount] = useState(0);
  const [charactersCount, setCharactersCount] = useState(0);
  function handleSelectSingle(selectedSingle: SingleValue<IContent | null>) {
    setState((prev: any) => ({
      ...prev,
      client: selectedSingle?.value as number,
    }));
  }
  // const changeHandler = (data: string) => {
  //   // const { name, data } = event.target;
  //   const name = "text";
  //   setState((prev: any) => ({ ...prev, [name]: data }));
  // };
  const changeHandlerEditor = (value: string, eventName: string) => {
    setState((prev: any) => ({ ...prev, [eventName]: value }));
    // const dataRemoveFirst2 = value.slice(2);
    // var result = dataRemoveFirst2.replace(/<(.|\n)*?>/g, "");
    // var newResult = result.replace(/(&nbsp;)*/g, "");
    // setWordCount(
    //   clearHtmlFromString(dataRemoveFirst2)
    //     ?.trim()
    //     ?.split(" ")
    //     .map((word) => word.trim())
    //     ?.filter((word) => !!word.length)?.length
    // );
    //
    // setCharactersCount(newResult.length);
  };
  const changeHandlerTitle = (event: any) => {
    const { name, value } = event.target;
    setState((prev: any) => ({ ...prev, [name]: value }));
  };
  return (
    <Card>
      <Card.Body>
        <Row>
          <Col md={4}>
            <label htmlFor="name" className="required-field">
              {`${t("global.title")}`}
            </label>
            <input
              type="text"
              name="title"
              className="form-control"
              value={state?.title}
              required
              onChange={(e) => changeHandlerTitle(e as any)}
            />
          </Col>
          <Col md={4} className="mb-2">
            <label htmlFor="client" className="required-field">
              {`${t("export_client.client")}`}
            </label>
            <Select
              className="custom_zindex react-select-field"
              theme={themeSelect}
              defaultValue={{
                label: state?.client?.name as string,
                value: state?.client?.id as number,
              }}
              onInputChange={(e) => changeClientHandler(e)}
              key={state?.id}
              onChange={(e) => {
                handleSelectSingle(e as any);
              }}
              options={clientList as any}
            />
          </Col>
          <Col></Col>
          <Col md={12}>
            <label htmlFor="text" className="">
              {`${t("content.content")}`}
            </label>
            <CKEditor
              // config={{ removePlugins: ["Title"] }}
              config={editorConfigurationCustom}
              editor={Editor}
              data={state?.text ?? ""}
              onReady={(editor) => {
                editor.ui.view.element.setAttribute(
                  "id",
                  String(Math.random().toString(16).slice(2))
                );
              }}
              onChange={(event, editor) => {
                const data = editor.getData();
                changeHandlerEditor(data, "text");
                editor.plugins.get("WordCount").on("update", (evt, stats) => {
                  setWordCount(stats.words);
                  setCharactersCount(stats.characters);
                });
              }}
            />
          </Col>
          <div className="col-12 my-2 d-flex justify-content-between text-align-center">
            <div className="ck ck-word-count">
              <div className="ck-word-count__words">
                {t("global.words")}: {wordCount}
              </div>
              <div className="ck-word-count__characters">
                {t("global.characters")}: {charactersCount}
              </div>
            </div>
            <div>
              <button
                className="btn btn-info"
                onClick={() => {
                  setIsMenuOpened(false);
                  setState({} as any);
                }}
              >
                {t("global.cancel")}
              </button>
              <button className="btn btn-primary ms-3" type="submit">
                {state?.id ? `${t("global.update")}` : `${t("global.create")}`}
              </button>
            </div>
          </div>
        </Row>
      </Card.Body>
    </Card>
  );
};
