import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useSelector } from "react-redux";
import { IAllStates } from "../../../store/rootReducer";
import { formatDate } from "../../../shared/functions/Functions";
import { useTranslation } from "react-i18next";

export const WorkflowStep7ApexChart = ({ statsChart }: any) => {
  const { translationLanguageEnum } = useSelector(
    (state: IAllStates) => state.enums
  );
  const { t } = useTranslation();
  const optionsFunc = (title: string) => {
    return {
      chart: {
        height: 350,
        type: "bar",
      },
      plotOptions: {
        bar: {
          borderRadius: 10,
          columnWidth: "50%",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: 0,
      },
      grid: {
        row: {
          colors: ["#fff", "#f2f2f2"],
        },
      },
      xaxis: {
        labels: {
          rotate: -45,
        },
        categories: [
          t("side_bar.base_data"),
          t("side_bar.attributes"),
          t("requirement.content_de"),
          t("requirement.content_en"),
          t("requirement.content_fr"),
          t("requirement.content_it"),
        ],
        tickPlacement: "on",
      },
      yaxis: {
        // title: {
        //   text: "Servings",
        // },
      },
      fill: {
        // type: "gradient",
        gradient: {
          shade: "light",
          type: "horizontal",
          shadeIntensity: 0.25,
          gradientToColors: undefined,
          inverseColors: true,
          opacityFrom: 0.85,
          opacityTo: 0.85,
          stops: [50, 0, 100],
        },
      },
      title: {
        text: title,
        floating: true,
        offsetY: 0,
        align: "center",
        style: {
          color: "#444",
        },
      },
    };
  };
  return (
    <div>
      {statsChart?.statisticsHistory?.map((itemHistory: any, id: number) => (
        <div id={`chart-${id}`} className="mb-3">
          <ReactApexChart
            options={optionsFunc(formatDate(itemHistory?.createdAt))}
            series={[
              {
                name: t("side_bar.import"),
                data: [
                  itemHistory?.baseDataHasValue,
                  itemHistory?.attributesWithValues,
                  ...itemHistory?.contentInfo?.map((info: any) => {
                    return info?.description + info?.intro + info?.bullets;
                  }),
                ],
              },
              {
                name: t("side_bar.export"),
                data: [
                  itemHistory?.child?.baseDataHasValue,
                  itemHistory?.child?.attributesWithValues,
                  ...itemHistory?.child?.contentInfo?.map((info: any) => {
                    return info?.description + info?.intro + info?.bullets;
                  }),
                ],
              },
            ]}
            type="bar"
            height={350}
          />
        </div>
      ))}
      <div id="html-dist"></div>
    </div>
  );
};
